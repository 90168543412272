import {
  FileModel, ShippingAddressModel, BillingAddressModel,
  PartnerShopModel, PartnerProductModel, PartnerProductUnitModel,
  PartnerProductSubscriptionModel, CustomerModel, UserModel,
} from '.';
import { unescape } from 'html-escaper';
import { translate } from '../helpers/frontend';

/*
  type : String
    - C2U
    - Store

  status : Number
    -1 = Charge Failed
    0  = Cancel
    1  = On Hold
    2  = In Process
    3  = Completed
*/

export class CustomerSubscriptionModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.type = data.type? unescape(data.type): 'C2U';
    this.channel = data.channel? unescape(data.channel): '2C2P';
    
    this.prefixOrderId2C2P = data.prefixOrderId2C2P? unescape(data.prefixOrderId2C2P): null;
    
    this.customer = new CustomerModel(data.customer || {});

    this.subscription = new PartnerProductSubscriptionModel(data.subscription || {});
    this.name = data.name? unescape(data.name): '';
    this.description = data.description? unescape(data.description): '';
    this.content = data.content? unescape(data.content): '';
    this.agreement = data.agreement? unescape(data.agreement): '';

    this.shop = new PartnerShopModel(data.shop || {});
    this.partner = new UserModel(data.partner || {});
    this.salesManager = new UserModel(data.salesManager || {});

    this.recurringType = data.recurringType? Number(data.recurringType): 2;
    this.recurringInterval = data.recurringInterval? Number(data.recurringInterval): 1;
    this.recurringCount = data.recurringCount? Number(data.recurringCount): 12;

    this.hasRelatedProducts = data.hasRelatedProducts? Number(data.hasRelatedProducts): 0;
    this.relatedCredit = data.relatedCredit? Number(data.relatedCredit): 0;
    this.relatedProducts = data?.relatedProducts?.length
      ? data.relatedProducts.map(d => this.relatedProduct(d)): [];

    this.selectionSteps = data.selectionSteps?.length
      ? data.selectionSteps.map(d => this.selectionStep(d)): [];
    
    this.signature = data.signature? unescape(data.signature): '';

    this.shippingAddress = new ShippingAddressModel(data.shippingAddress || {});
    this.billingAddress = new BillingAddressModel(data.billingAddress || {});

    this.discount = data.discount || data.discount===0? Number(data.discount): null;
    this.subtotal = data.subtotal || data.subtotal===0? Number(data.subtotal): null;
        
    this.vatName = data.vatName? unescape(data.vatName): '';
    this.vat = data.vat || data.vat===0? Number(data.vat): null;
    this.vatPercent = data.vatPercent || data.vatPercent===0? Number(data.vatPercent): null;

    this.total = data.total || data.total===0? Number(data.total): null;
    this.initialPriceInVAT = data.initialPriceInVAT || data.initialPriceInVAT===0 
      ? Number(data.initialPriceInVAT): null;

    this.shippingFrontend = data.shippingFrontend || {};
    this.shippingCost = data.shippingCost || data.shippingCost===0? Number(data.shippingCost): null;

    this.grandTotal = data.grandTotal || data.grandTotal===0? Number(data.grandTotal): null;

    this.status = data.status ?? 0;

    this.recurringDates = data.recurringDates || [];
    this.startAt = data.startAt || null;
    this.endAt = data.endAt || null;

    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
  }

  isValid(){ return this._id? true: false; }

  relatedProduct(d={}){
    return {
      product: new PartnerProductModel(d.product || {}),
      productId: d.product?._id,
      unit: new PartnerProductUnitModel(d.unit || {}),
      unitId: d.unit?._id,
      credit: d.credit ?? 1,
      addPriceInVAT: d.addPriceInVAT ?? 0,
      inCart: d.inCart ?? 0,
    };
  }
  selectionStep(d={}){
    return {
      step: d.step || null,
      name: d.name? unescape(d.name): null,
      icon: new FileModel(d.icon || {}),
      credit: d.credit ?? 1,
      order: d.order || 1,
      products: d.products?.length? d.products.map(k => {
        return {
          product: new PartnerProductModel(k.product || {}),
          productId: k.product?._id,
          unit: new PartnerProductUnitModel(k.unit || {}),
          unitId: k.unit?._id,
          credit: d.credit ?? 1,
          inCart: k.inCart ?? 0,
        };
      }): [],
    };
  }
  
  displayStatus(langObj={}){
    if(!this.isValid()) return (<></>);
    if(this.status === -1) return (<span className="ss-tag bg-danger">{translate('Charge Failed', langObj)}</span>);
    if(this.status === 0) return (<span className="ss-tag bg-warning">{translate('Cancel', langObj)}</span>);
    if(this.status === 1) return (<span className="ss-tag bg-gray">{translate('On Hold', langObj)}</span>);
    if(this.status === 2) return (<span className="ss-tag bg-info">{translate('In Process', langObj)}</span>);
    if(this.status === 3) return (<span className="ss-tag bg-success">{translate('Completed', langObj)}</span>);
    return (<></>);
  }
  displayFrequency(langObj={}){
    if(!this.isValid()) return '';
    let _text = '';
    if(this.recurringType === 1){
      if(this.recurringInterval === 1) _text += translate('Per Day', langObj);
      else _text += translate('Per _X_ Days', langObj, { '_X_': this.recurringInterval });
    }else if(this.recurringType === 2){
      if(this.recurringInterval === 1) _text += translate('Per Month', langObj);
      else _text += translate('Per _X_ Months', langObj, { '_X_': this.recurringInterval });
    }else if(this.recurringType === 3){
      if(this.recurringInterval === 1) _text += translate('Per Year', langObj);
      else _text += translate('Per _X_ Years', langObj, { '_X_': this.recurringInterval });
    }
    if(this.recurringCount === 1) _text += ` ${translate('For 1 Time', langObj)}`;
    else _text += ` ${translate('For _X_ Times', langObj, { '_X_': this.recurringCount })}`;
    return _text;
  }
}
