import { PartnerProductModel } from '.';
import { unescape } from 'html-escaper';
import { translate } from '../helpers/frontend';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  choices : Array
    choice : Object
      _id : String
      name : String
      addPrice : Number
      addPriceInVAT : Number
      stock : Number
      order : Number
      status : Number
        0 = Inactive
        2 = Active

  status :
    0 = Inactive
    2 = Active
*/

export class PartnerProductAttributeModel {
  constructor(data={}) {
    this._id = data?._id || null;
    this.product = new PartnerProductModel(data.product? data.product: {});

    this.name = data.name? unescape(data.name): null;
    this.description = data.description? unescape(data.description): null;
    
    this.choices = this.cleanChoices(data.choices);

    this.isMultiple = data.isMultiple? Number(data.isMultiple): 0;
    this.isRequired = data.isRequired? Number(data.isRequired): 0;

    this.order = data.order? Number(data.order): 1;
    this.status = data.status? Number(data.status): 0;

    // Localization
    this.localization(data);    
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'name', 'description' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }

  cleanChoices(choices) {
    let temp = [];
    if(choices && choices.length){
      choices.forEach(d => {
        temp.push({
          _id: d._id,
          name: unescape(`${d.name}`),
          addPrice: d.addPrice? Number(d.addPrice): 0,
          addPriceInVAT: d.addPriceInVAT? Number(d.addPriceInVAT): 0,
          stock: d.stock? Number(d.stock): 0,
          order: d.order? Number(d.order): 1,
          status: d.status? Number(d.status): 0,
        });
      });
      temp.sort((a, b) => {
        if(a.status > b.status) return -1;
        else if(a.status < b.status) return 1;
        else{
          if(a.order > b.order) return 1;
          else if(a.order < b.order) return -1;
          else return 0;
        }
      });
    }
    return temp;
  }
  initChoice() {
    return { _id: '', name: '', addPrice: 0, addPriceInVAT: 0, stock: 0, order: 1, status: 2 };
  }
  
  displayIsRequired(langObj={}) {
    if(this.isValid()){
      if(this.isRequired === 1) return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }
  displayIsMultiple(langObj={}) {
    if(this.isValid()){
      if(this.isMultiple === 1) return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }
  
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 6) return (<span className="ss-tag bg-p">{translate('text_product_status_6', langObj)}</span>);
      else if(this.status === 5) return (<span className="ss-tag bg-info">{translate('text_product_status_5', langObj)}</span>);
      else if(this.status === 4) return (<span className="ss-tag bg-p">{translate('text_product_status_4', langObj)}</span>);
      else if(this.status === 3) return (<span className="ss-tag bg-gray">{translate('text_product_status_3', langObj)}</span>);
      else if(this.status === 2) return (<span className="ss-tag bg-success">{translate('text_product_status_2', langObj)}</span>);
      else if(this.status === 1) return (<span className="ss-tag bg-gray">{translate('text_product_status_1', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('text_product_status_0', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('text_product_status_0', langObj)}</span>);
    }
  }
}
