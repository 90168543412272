import {
  PartnerShippingModel,
  PartnerShopModel, PartnerProductCategoryModel, PartnerProductModel,
  CustomerGroupModel, CustomerTierModel, FileModel, UserModel, DataProvinceModel,
} from '.';
import { unescape } from 'html-escaper';
import { translate,formatNumber } from '../helpers/frontend';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  channel : String
    - C2U
    - STORE
    
  limitCustomerFrequency
    0 = ต่อคูปอง
    1 = ต่อวัน
    2 = ต่อสัปดาห์
    3 = ต่อเดือน
    4 = ต่อปี

  discountType
    1 = Flat rate
    2 = Percentage
    
  availableType : Number
    1 = All products
    2 = Include products
    3 = Exclude products
    4 = Include product categories
    5 = Exclude product categories

  discountRules : Array
    discountRule : Object
      productId : String
      unitId : String

  status
    0 = Inactive
    1 = Active
    
  isPersonal : Number
    0 = No
    1 = Yes

  availability : Number
    0  = ไม่สามารถใช้งานได้
    1  = คูปองหมดแล้ว
    2  = คุณได้ใช้คูปองเต็มโควต้าแล้ว
    3  = ยังไม่ถึงเวลาการใช้คูปอง
    4  = สิ้นสุดเวลาการใช้คูปอง
    5  = ร้านค้าไม่ร่วมรายการ
    6  = วิธีการจัดส่งไม่ได้ร่วมรายการ
    7  = ระดับของคุณไม่ร่วมรายการ
    8  = สั่งซื้อขั้นต่ำไม่ถึงที่กำหนด
    9  = สั่งซื้อสูงสุดเกินที่กำหนด
    10 = มีประเภทสินค้าในตะกร้าที่ไม่ร่วมรายการ
    11 = มีสินค้าในตะกร้าที่ไม่ร่วมรายการ
    99 = ใช้งานได้
*/

export class PartnerShippingCouponModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.channel = data.channel? data.channel: 'C2U';

    this.shippings = data.shippings && data.shippings.length
      ? data.shippings.map(d => new PartnerShippingModel(d)): [];
    
    this.code = data.code? data.code: null;
    this.name = data.name? unescape(data.name): null;
    this.shortDescription = data.shortDescription? unescape(data.shortDescription): null;
    this.description = data.description? unescape(data.description): null;
    this.image = new FileModel(data.image? data.image: {});
    
    this.quantity = data.quantity? Number(data.quantity): null;
    this.leftQuantity = data.leftQuantity? Number(data.leftQuantity): null;
    this.limitPerCustomer = data.limitPerCustomer? Number(data.limitPerCustomer): null;
    this.limitCustomerFrequency = data.limitCustomerFrequency? Number(data.limitCustomerFrequency): 0;
    
    this.startAt = data.startAt? data.startAt: null;
    this.endAt = data.endAt? data.endAt: null;

    this.order = data.order? Number(data.order): 1000;
    this.status = data.status? data.status: 0;

    this.isCouponCode = data.isCouponCode? data.isCouponCode: 0;

    this.isRedeemPoints = data.isRedeemPoints? data.isRedeemPoints: 0;
    this.redeemPoints = data.redeemPoints || data.redeemPoints===0? Number(data.redeemPoints): null;

    this.minimumOrder = data.minimumOrder? Number(data.minimumOrder): null;
    this.maximumOrder = data.maximumOrder? Number(data.maximumOrder): null;

    this.discountType = data.discountType? Number(data.discountType): null;
    this.discount = data.discount? Number(data.discount): null;
    this.maximumDiscount = data.maximumDiscount? Number(data.maximumDiscount): null;

    this.availableType = data.availableType? Number(data.availableType): 0;
    this.discountRules = data.discountRules && data.discountRules.length? data.discountRules: [];
    
    this.products = data.products && data.products.length
      ? data.products.map(d => new PartnerProductModel(d)): [];
    this.productCategories = data.productCategories && data.productCategories.length
      ? data.productCategories.map(d => new PartnerProductCategoryModel(d)): [];

    this.forCustomerGroups = data.forCustomerGroups && data.forCustomerGroups.length
      ? data.forCustomerGroups.map(d => new CustomerGroupModel(d)): [];

    this.forAllPartnerShops = data.forAllPartnerShops? data.forAllPartnerShops: 0;
    this.forPartnerShops = data.forPartnerShops && data.forPartnerShops.length
      ? data.forPartnerShops.map(d => new PartnerShopModel(d)): [];

    this.forAllCustomerTiers = data.forAllCustomerTiers? data.forAllCustomerTiers: 0;
    this.forCustomerTiers = data.forCustomerTiers && data.forCustomerTiers.length
      ? data.forCustomerTiers.map(d => new CustomerTierModel(d)): [];
      
    this.forAllProvinces = data.forAllProvinces? data.forAllProvinces: 0;
    this.forProvinces = data.forProvinces && data.forProvinces.length 
      ? data.forProvinces.map(d => new DataProvinceModel(d)): [];

    this.createdBy = new UserModel(data.createdBy || {});
    this.createdAt = data.createdAt || null;
    this.updatedBy = new UserModel(data.updatedBy || {});
    this.updatedAt = data.updatedAt || null;

    // Customer Checkout
    this.isPersonal = data.isPersonal? Number(data.isPersonal): 0;
    this.availability = data.availability? Number(data.availability): 0;
    this.actualDiscount = data.actualDiscount? Number(data.actualDiscount): 0;

    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'name', 'shortDescription', 'description' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }
  isAvailable() {
    return this.isValid() && this.status===1 && this.leftQuantity? true: false;
  }

  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
  displayLimitCustomer(langObj={}) {
    if(this.isValid()){
      if(this.limitCustomerFrequency===0) return `${this.limitPerCustomer} ${translate('Coupon', langObj)}`;
      else if(this.limitCustomerFrequency===1) return `${this.limitPerCustomer} ${translate('Coupon Per Day', langObj)}`;
      else if(this.limitCustomerFrequency===2) return `${this.limitPerCustomer} ${translate('Weekly Coupon', langObj)}`;
      else if(this.limitCustomerFrequency===3) return `${this.limitPerCustomer} ${translate('Coupon Per Month', langObj)}`;
      else if(this.limitCustomerFrequency===4) return `${this.limitPerCustomer} ${translate('Coupon Per Year', langObj)}`;
      else return '';
    }else{
      return '';
    }
  }
  displayDiscount(langObj={}) {
    if(this.isValid()){
      if(this.discountType===1){
        return `${formatNumber(this.discount)}`;
      }else if(this.discountType===2){
        if(this.maximumDiscount){
          return `${formatNumber(this.discount)}% (${translate('Maximum', langObj)} ${formatNumber(this.maximumDiscount)} ${translate('Bath', langObj)})`;
        }else{
          return `${formatNumber(this.discount)}%`;
        }
      }else{
        return '';
      }
    }else{
      return '';
    }
  }

  displaySubType(langObj={}) {
    if(this.isValid()){
      if(this.isRedeemPoints === 1) return (<span className="ss-tag bg-info">{translate('Redeem Points', langObj)}</span>);
      if(this.isCouponCode === 1) return (<span className="ss-tag bg-success">{translate('Use Code', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('By Selection', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('By Selection', langObj)}</span>);
    }
  }

  displayAvailableType(langObj={}) {
    if(!this.isValid()) return '';
    else{
      if(this.availableType === 1) return translate('All Products', langObj);
      else if(this.availableType === 2) return translate('Include by Products', langObj);
      else if(this.availableType === 3) return translate('Exclude by Products', langObj);
      else if(this.availableType === 4) return translate('Include by Product Categories', langObj);
      else if(this.availableType === 5) return translate('Exclude by Product Categories', langObj);
      else return '';
    }
  }

  displayAvailability(langObj={}) {
    if(this.availability === 99) return translate('text_available_coupon_99', langObj);
    else if(this.availability === 0) return translate('text_available_coupon_0', langObj);
    else if(this.availability === 1) return translate('text_available_coupon_1', langObj);
    else if(this.availability === 2) return translate('text_available_coupon_2', langObj);
    else if(this.availability === 3) return translate('text_available_coupon_3', langObj);
    else if(this.availability === 4) return translate('text_available_coupon_4', langObj);
    else if(this.availability === 5) return translate('text_available_coupon_5', langObj);
    else if(this.availability === 6) return translate('text_available_coupon_6', langObj);
    else if(this.availability === 7) return translate('text_available_coupon_7', langObj);
    else if(this.availability === 8) return translate('text_available_coupon_8', langObj);
    else if(this.availability === 9) return translate('text_available_coupon_9', langObj);
    else if(this.availability === 10) return translate('text_available_coupon_10', langObj);
    else if(this.availability === 11) return translate('text_available_coupon_11', langObj);
    else if(this.availability === 12) return translate('text_available_coupon_12', langObj);
    else if(this.availability === 13) return translate('text_available_coupon_13', langObj);
  }

  existInGroup(groupId) {
    if(groupId && this.forCustomerGroups && this.forCustomerGroups.length) {
      const containGroup = this.forCustomerGroups.find(d => d._id === groupId) !== undefined;
      if(containGroup){
        return true;
      }else return false;
    } else return false;
  }
}
