import { AppThemeModel, FileModel } from '.';
import { unescape } from 'html-escaper';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  type : String
    - Backend
    - Frontend

  status :
    0 = Inactive
    1 = Active
*/

export class AppThemeBlockModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.theme = new AppThemeModel(data?.theme || {});

    this.value = data?.value || '';
    this.name = data?.name || '';
    
    this.order = data.order? Number(data.order): 1;
    this.status = data.status? Number(data.status): 0;

    this.enabled = data?.enabled || {};


    this.icon1 = data.icon1? unescape(data.icon1): '';
    this.title1 = data.title1? unescape(data.title1): '';
    this.description1 = data.description1? unescape(data.description1): '';
    this.subDescription1 = data.subDescription1? unescape(data.subDescription1): '';
    this.btnTitle1 = data.btnTitle1? unescape(data.btnTitle1): '';
    this.btnUrl1 = data.btnUrl1? unescape(data.btnUrl1): '';
    this.image1 = new FileModel(data.image1? data.image1: {});
    
    this.icon2 = data.icon2? unescape(data.icon2): '';
    this.title2 = data.title2? unescape(data.title2): '';
    this.description2 = data.description2? unescape(data.description2): '';
    this.subDescription2 = data.subDescription2? unescape(data.subDescription2): '';
    this.btnTitle2 = data.btnTitle2? unescape(data.btnTitle2): '';
    this.btnUrl2 = data.btnUrl2? unescape(data.btnUrl2): '';
    this.image2 = new FileModel(data.image2? data.image2: {});
    
    this.icon3 = data.icon3? unescape(data.icon3): '';
    this.title3 = data.title3? unescape(data.title3): '';
    this.description3 = data.description3? unescape(data.description3): '';
    this.subDescription3 = data.subDescription3? unescape(data.subDescription3): '';
    this.btnTitle3 = data.btnTitle3? unescape(data.btnTitle3): '';
    this.btnUrl3 = data.btnUrl3? unescape(data.btnUrl3): '';
    this.image3 = new FileModel(data.image3? data.image3: {});
    
    this.icon4 = data.icon4? unescape(data.icon4): '';
    this.title4 = data.title4? unescape(data.title4): '';
    this.description4 = data.description4? unescape(data.description4): '';
    this.subDescription4 = data.subDescription4? unescape(data.subDescription4): '';
    this.btnTitle4 = data.btnTitle4? unescape(data.btnTitle4): '';
    this.btnUrl4 = data.btnUrl4? unescape(data.btnUrl4): '';
    this.image4 = new FileModel(data.image4? data.image4: {});
    
    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [
      'title1', 'description1', 'subDescription1', 'btnTitle1',
      'title2', 'description2', 'subDescription2', 'btnTitle2',
      'title3', 'description3', 'subDescription3', 'btnTitle3',
      'title4', 'description4', 'subDescription4', 'btnTitle4',
    ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }
}
