import { lazy, Suspense, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { Routes, Route, Navigate } from 'react-router-dom';

import { AuthPageLoading } from '../views/auth';

export default function RouteSupplyChain() {
  const { status, user } = useContext(AuthContext);

  const LazyRoute = ({ dom: Element }) => <Element />;
  return status === 'authenticated' && user.isSupplyChainAdmin()? (<>
    <Suspense fallback={<AuthPageLoading />}>
      <Routes>
        {/* Supply Chain */}
        <Route path="/supply-chain" element={<LazyRoute dom={lazy(() => import('../views/supply-chain/DashboardPage'))} />} />
        <Route path="/supply-chain/dashboard" element={<LazyRoute dom={lazy(() => import('../views/supply-chain/DashboardPage'))} />} />
          
        <Route path="/supply-chain/admins" element={<LazyRoute dom={lazy(() => import('../views/supply-chain/AdminsPage'))} />} />
        <Route path="/supply-chain/admin/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/supply-chain/AdminViewPage'))} />} />
        {user.isSupplyChainSuperAdmin()? (
          <Route path="/supply-chain/admin/:process/*" element={<LazyRoute dom={lazy(() => import('../views/supply-chain/AdminPage'))} />} />
        ): (<></>)}

        <Route path="/supply-chain/warehouses" element={<LazyRoute dom={lazy(() => import('../views/supply-chain/WarehousesPage'))} />} />
        <Route path="/supply-chain/warehouse/view/:dataId/*" element={<LazyRoute dom={lazy(() => import('../views/supply-chain/WarehouseViewPage'))} />} />
        {user.isSupplyChainSuperAdmin()? (
          <Route path="/supply-chain/warehouse/:process/*" element={<LazyRoute dom={lazy(() => import('../views/supply-chain/WarehousePage'))} />} />
        ): (<></>)}

        <Route path="/supply-chain/partner-shops" element={<LazyRoute dom={lazy(() => import('../views/supply-chain/PartnerShopsPage'))} />} />
        <Route path="/supply-chain/partner-shop/view/:dataId/*" element={<LazyRoute dom={lazy(() => import('../views/supply-chain/PartnerShopViewPage'))} />} />
        {user.isSupplyChainSuperAdmin()? (
          <Route path="/supply-chain/partner-shop/update/*" element={<LazyRoute dom={lazy(() => import('../views/supply-chain/PartnerShopPage'))} />} />
        ): (<></>)}

        {user.isSupplyChainSuperAdmin()? (
          <Route path="/supply-chain/settings/*" element={<LazyRoute dom={lazy(() => import('../views/supply-chain/SettingsPage'))} />} />
        ): (<></>)}
              
        {/* Personal */}
        <Route path="/supply-chain/profile" element={<LazyRoute dom={lazy(() => import('../views/supply-chain/ProfileViewPage'))} />} />
        <Route path="/supply-chain/profile/update" element={<LazyRoute dom={lazy(() => import('../views/supply-chain/ProfilePage'))} />} />

        {/* Must Have */}
        <Route path="/auth/customer-signup-qr-code/:partnerShopCode/*" element={<LazyRoute dom={lazy(() => import('../views/auth/CustomerSignupQrCodePage'))} />} />
        <Route path="/payment/failed" element={<LazyRoute dom={lazy(() => import('../views/payment/FailedPage'))} />} />
        <Route path="/payment/success/:dataId" element={<LazyRoute dom={lazy(() => import('../views/payment/SuccessPage'))} />} />

        <Route path="*" element={<Navigate replace to="/supply-chain" />} />
      </Routes>
    </Suspense>
  </>): (<></>);
}
