import { translate } from '../helpers/frontend';
import { unescape } from 'html-escaper';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  type : String
    - C2U
    - External

  status : Number
    0 = Inactive
    1 = Active
*/

export class CmsTopnavMenuModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.title = data.title? unescape(data.title): null;

    this.isExternal = data.isExternal? Number(data.isExternal): 0;
    this.url = data.url? unescape(data.url): '';
    
    this.withIcon = data.withIcon? Number(data.withIcon): 0;
    this.iconClass = data.iconClass? unescape(data.iconClass): null;
    
    this.order = data.order? Number(data.order): 1;
    this.status = data.status? Number(data.status): 0;

    // Tree
    this.index = data.index? data.index: data._id? data._id: null;
    this.data = data.title? unescape(data.title): null;
    this.isFolder = data.isFolder? true: false;
    this.canMove = data.canMove? true: false;
    this.canUpdate = data.canUpdate? true: false;
    this.canDelete = data.canDelete? true: false;
    this.children = data.children && data.children.length? data.children: [];
    
    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'title' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id || this.index? true: false;
  }
  
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
}
