import {
  WarehouseModel, PartnerShopModel,
  LogisticVehicleModel, PackingDayModel,
} from '.';
import { translate } from '../helpers/frontend';
import dataLogisticTypes from '../data/logistic-types.json';

/*
  type : Number
    1 = Send Out
    2 = For Pickup

  status : Number
    0  = Inactive
    1  = Active
*/

export class LogisticTransportRouteModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.logisticType = data.logisticType? Number(data.logisticType): 1;
    this.type = data.type? Number(data.type): 1;
    
    this.warehouse = new WarehouseModel(data.warehouse? data.warehouse: {});
    this.partnerShop = new PartnerShopModel(data.partnerShop? data.partnerShop: {});

    this.vehicles = data.vehicles && data.vehicles.length 
      ? data.vehicles.map(d => new LogisticVehicleModel(d)): [];
    this.transportRoutes = data.transportRoutes && data.transportRoutes.length 
      ? data.transportRoutes.map(d => {
        let w = new WarehouseModel(d.warehouse? d.warehouse: {});
        let p = new PartnerShopModel(d.partnerShop? d.partnerShop: {});
        return {
          warehouse: w,
          partnerShop: p,
          destination: w.isValid()? w: p.isValid()? p: null,
          isToWarehouse: w.isValid(),
          summary: d.summary && d.summary.length 
            ? d.summary.map(k => {
              return {
                vehicle: k.vehicle? k.vehicle: null,
                cost: Number(k.cost? k.cost: 0),
              };
            }): [],
        };
      }): [];

    this.schedules = data.schedules && data.schedules.length 
      ? data.schedules.map(d => new PackingDayModel(d)): [];
    this.packingDays = data.packingDays && data.packingDays.length 
      ? data.packingDays.map(d => new PackingDayModel(d)): [];
    
    this.priority = data.priority? Number(data.priority): 1;
    this.status = data.status? Number(data.status): 0;
  }

  isValid() {
    return this._id && (this.warehouse.isValid() || this.partnerShop.isValid());
  }

  isFromWarehouse() {
    if(!this.isValid()) return false;
    else return this.warehouse.isValid();
  }
  fromId() {
    if(!this.isValid()) return '';
    else if(this.warehouse.isValid()) return this.warehouse._id;
    else return this.partnerShop._id;
  }
  fromName() {
    if(!this.isValid()) return '';
    else if(this.warehouse.isValid()) return this.warehouse.name;
    else return this.partnerShop.name;
  }
  fromLatLng() {
    if(!this.isValid()) return { lat: null, lng: null };
    else if(this.partnerShop.isValid()){
      return { lat: this.partnerShop.address.lat, lng: this.partnerShop.address.lng };
    }else{
      return { lat: this.warehouse.address.lat, lng: this.warehouse.address.lng };
    }
  }

  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
  displayLogisticType(langObj={}) {
    if(!this.isValid()) return '';
    let type = dataLogisticTypes.find(d => d.value === this.logisticType);
    if(!type) return '';
    else return translate(type.name, langObj);
  }

  displayType(dataId=null, langObj={}) {
    if(!dataId || !this.fromId()) return (<></>);
    else if(dataId === this.fromId()){
      if(this.type === 1) return (<span className="ss-tag bg-success">{translate('Send Out', langObj)}</span>);
      else return (<span className="ss-tag bg-info">{translate('For Pickup', langObj)}</span>);
    }else{
      if(this.type === 1) return (<span className="ss-tag bg-success">{translate('Receipt', langObj)}</span>);
      else return (<span className="ss-tag bg-info">{translate('Pickup', langObj)}</span>);
    }
  }

  sourcePath() {
    if(!this.isValid()) return '';
    else if(this.partnerShop.isValid()) return `/partner-shop/view/${this.partnerShop._id}`;
    else return `/warehouse/view/${this.warehouse._id}`;
  }
  
  initRoute() {
    return { destination: null, summary: [] };
  }

  isForPickup(dataId=null) {
    if(!dataId) return false;
    else if(dataId === this.warehouse._id || dataId === this.partnerShop._id) return false;
    else if(this.type === 2){
      return this.transportRoutes.length === 1 
        && this.transportRoutes[0].destination 
        && dataId === this.transportRoutes[0].destination._id;
    }else return false;
  }
  canUpdate(dataId=null) {
    if(!dataId) return false;
    else if(dataId === this.warehouse._id || dataId === this.partnerShop._id) return true;
    else if(this.type === 2){
      return this.transportRoutes.length === 1 
        && this.transportRoutes[0].destination 
        && dataId === this.transportRoutes[0].destination._id;
    }else return false;
  }
}
