import {
  CMS_CONTENT_CATEGORIES, CMS_CONTENTS, CMS_BANNERS, 
  CMS_POPUPS, CMS_CHECKOUT_CONTENTS,
} from '../actions/types';

const initialState = {
  contentCategories: [],
  contents: [],
  banners: [],
  popups: [],
  checkoutContents: [],
};

const cmsReducer = (state = initialState, action) => {
  switch(action.type) {
    
    case CMS_CONTENT_CATEGORIES:
      return { ...state, contentCategories: action.payload };
    case CMS_CONTENTS:
      return { ...state, contents: action.payload };
    
    case CMS_BANNERS:
      return { ...state, banners: action.payload };
    
    case CMS_POPUPS:
      return { ...state, popups: action.payload };

    case CMS_CHECKOUT_CONTENTS:
      return { ...state, checkoutContents: action.payload };

    default:
      return state;
  }
};

export default cmsReducer;