import { SYSTEM_LANGUAGES } from '../actions/variables';

export class ChatroomWelcomeModel {
  constructor(data={}) {
    this._id = data?._id || null;
    this.type = data.type? data.type: 'C2U';
    this.value = data.value? data.value: null;
    
    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'value' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }
}
