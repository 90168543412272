import { combineReducers } from 'redux';
import alertReducer from './alert.reducer';
import appReducer from './app.reducer';
import dataReducer from './data.reducer';
import partnerReducer from './partner.reducer';
import customerReducer from './customer.reducer';
import sellerReducer from './seller.reducer';
import cmsReducer from './cms.reducer';
import logisticReducer from './logistic.reducer';
import toolReducer from './tool.reducer';

export default combineReducers({
  alert: alertReducer,
  data: dataReducer,
  app: appReducer,
  partner: partnerReducer,
  customer: customerReducer,
  seller: sellerReducer,
  cms: cmsReducer,
  logistic: logisticReducer,
  tool: toolReducer,
});