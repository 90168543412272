import {
  PARTNER_PRODUCT_CATEGORIES, PARTNER_PRODUCT_SUB_CATEGORIES,
  PARTNER_PRODUCT_SUB_SUB_CATEGORIES,
  PARTNER_PRODUCT_BRANDS, PARTNER_PRODUCT_STATUSES,
  PARTNER_PRODUCTS, PARTNER_PRODUCT_RATINGS, PARTNER_PRODUCT_UNITS, 
  PARTNER_PRODUCT_ATTRIBUTES, PARTNER_PRODUCT_GIVEAWAY_RULES,
  PARTNER_SHIPPINGS,
  PARTNER_SHOPS, PARTNER_SHOP_STOCKS, PARTNER_SHOP_SHIPPINGS,
  PARTNER_SHOP_COMMISSIONS, PARTNER_PRODUCT_SUBSCRIPTIONS,
  PARTNER_PRODUCT_COUPONS, PARTNER_SHIPPING_COUPONS,
  PARTNER_EVENTS,
} from '../actions/types';

const initialState = {
  productCategories: [],
  productSubCategories: [],
  productSubSubCategories: [],
  productBrands: [],
  productStatuses: [],
  products: [],
  productRatings: [],
  productUnits: [],
  productAttributes: [],
  productGiveawayRules: [],
  shippings: [],
  shops: [],
  shopStocks: [],
  shopShippings: [],
  shopCommissions: [],
  productSubscriptions: [],
  productCoupons: [],
  shippingCoupons: [],
  events: [],
};

const partnerReducer = (state = initialState, action) => {
  switch(action.type) {
    
    case PARTNER_PRODUCT_CATEGORIES:
      return { ...state, productCategories: action.payload };
    case PARTNER_PRODUCT_SUB_CATEGORIES:
      return { ...state, productSubCategories: action.payload };
    case PARTNER_PRODUCT_SUB_SUB_CATEGORIES:
      return { ...state, productSubSubCategories: action.payload };

    case PARTNER_PRODUCT_BRANDS:
      return { ...state, productBrands: action.payload };
      
    case PARTNER_PRODUCT_STATUSES:
      return { ...state, productStatuses: action.payload };

    case PARTNER_PRODUCTS:
      return { ...state, products: action.payload };
    case PARTNER_PRODUCT_RATINGS:
        return { ...state, productRatings: action.payload };
    case PARTNER_PRODUCT_UNITS:
      return { ...state, productUnits: action.payload };
    case PARTNER_PRODUCT_ATTRIBUTES:
      return { ...state, productAttributes: action.payload };

    case PARTNER_PRODUCT_GIVEAWAY_RULES:
      return { ...state, productGiveawayRules: action.payload };
    
    case PARTNER_SHIPPINGS:
      return { ...state, shippings: action.payload };
    
    case PARTNER_SHOPS:
      return { ...state, shops: action.payload };
    case PARTNER_SHOP_STOCKS:
      return { ...state, shopStocks: action.payload };
    case PARTNER_SHOP_SHIPPINGS:
      return { ...state, shopShippings: action.payload };
    case PARTNER_SHOP_COMMISSIONS:
      return { ...state, shopCommissions: action.payload };

    case PARTNER_PRODUCT_SUBSCRIPTIONS:
      return { ...state, productSubscriptions: action.payload };

    case PARTNER_PRODUCT_COUPONS:
      return { ...state, productCoupons: action.payload };
    case PARTNER_SHIPPING_COUPONS:
      return { ...state, shippingCoupons: action.payload };
      
    case PARTNER_EVENTS:
      return { ...state, events: action.payload };

    default:
      return state;
  }
};

export default partnerReducer;