import { alertLoading, alertChange } from './alert.actions';
import { getLanguage } from '../helpers/frontend';
import { apiHeader } from '../helpers/header';
import { API_URL, SYSTEM_LANGUAGES } from './variables';
import {
  APP_LANGUAGES, APP_CURRENCY, APP_CURRENCIES, APP_THEMES,
  DATA_COUNTRIES, DATA_PROVINCES, DATA_DISTRICTS, DATA_SUBDISTRICTS,
  PARTNER_PRODUCT_CATEGORIES, PARTNER_PRODUCT_SUB_CATEGORIES,
  PARTNER_PRODUCT_SUB_SUB_CATEGORIES,
  PARTNER_PRODUCT_BRANDS, PARTNER_PRODUCT_STATUSES, 
  PARTNER_PRODUCTS, PARTNER_PRODUCT_UNITS, PARTNER_PRODUCT_ATTRIBUTES, 
  PARTNER_SHOPS, PARTNER_SHOP_STOCKS, PARTNER_PRODUCT_SUBSCRIPTIONS,
  CUSTOMER_SHIPPING_ADDRESSES, CUSTOMER_BILLING_ADDRESSES,
} from './types';
import {
  PaginateModel, LanguageModel, CurrencyModel, AppThemeModel, AppThemeBlockModel,
  DataCountryModel, DataProvinceModel, DataDistrictModel, DataSubdistrictModel,
  UserModel, ShippingAddressModel, BillingAddressModel, PaymentMethodModel,
  PartnerProductCategoryModel, PartnerProductSubCategoryModel,
  PartnerProductSubSubCategoryModel, PartnerProductBrandModel, PartnerProductStatusModel, 
  PartnerProductModel, PartnerProductUnitModel, PartnerProductAttributeModel,
  PartnerShopModel, PartnerShopStockModel, PartnerShippingFrontendModel,
  PartnerProductCouponModel, PartnerShippingCouponModel, PartnerProductSubscriptionModel,
  CustomerOrderModel, CustomerCartModel, CustomerSubscriptionModel,
  CmsSettingModel,
} from '../models';


export const processClear = (type) => (dispatch) => {
  if(type === 'languages') dispatch({ type: APP_LANGUAGES, payload: [] });
  else if(type === 'currencies') dispatch({ type: APP_CURRENCIES, payload: [] });
  else if(type === 'app-themes') dispatch({ type: APP_THEMES, payload: [] });
  else if(type === 'data-countries') dispatch({ type: DATA_COUNTRIES, payload: [] });
  else if(type === 'data-provinces') dispatch({ type: DATA_PROVINCES, payload: [] });
  else if(type === 'data-districts') dispatch({ type: DATA_DISTRICTS, payload: [] });
  else if(type === 'data-subdistricts') dispatch({ type: DATA_SUBDISTRICTS, payload: [] });
  else if(type === 'partner-product-categories') dispatch({ type: PARTNER_PRODUCT_CATEGORIES, payload: [] });
  else if(type === 'partner-product-sub-categories') dispatch({ type: PARTNER_PRODUCT_SUB_CATEGORIES, payload: [] });
  else if(type === 'partner-product-sub-sub-categories') dispatch({ type: PARTNER_PRODUCT_SUB_SUB_CATEGORIES, payload: [] });
  else if(type === 'partner-product-brands') dispatch({ type: PARTNER_PRODUCT_BRANDS, payload: [] });
  else if(type === 'partner-product-statuses') dispatch({ type: PARTNER_PRODUCT_STATUSES, payload: [] });
  else if(type === 'partner-products') dispatch({ type: PARTNER_PRODUCTS, payload: [] });
  else if(type === 'partner-product-units') dispatch({ type: PARTNER_PRODUCT_UNITS, payload: [] });
  else if(type === 'partner-product-attributes') dispatch({ type: PARTNER_PRODUCT_ATTRIBUTES, payload: [] });
  else if(type === 'partner-product-subscriptions') dispatch({ type: PARTNER_PRODUCT_SUBSCRIPTIONS, payload: [] });
  else if(type === 'partner-shops') dispatch({ type: PARTNER_SHOPS, payload: [] });
  else if(type === 'partner-shop-stocks') dispatch({ type: PARTNER_SHOP_STOCKS, payload: [] });
  else if(type === 'customer-shipping-addresses') dispatch({ type: CUSTOMER_SHIPPING_ADDRESSES, payload: [] });
  else if(type === 'customer-billing-addresses') dispatch({ type: CUSTOMER_BILLING_ADDRESSES, payload: [] });
};


export const processList = (type, input={}, loading=false) => async (dispatch=null) => {
  return new Promise(async (resolve, reject) => {
    if(loading && dispatch) dispatch(alertLoading(true));
    try {
      if(SYSTEM_LANGUAGES.length > 1){
        if(input){
          if(input.dataFilter) input.dataFilter = { ...input.dataFilter, lang: getLanguage() };
          else input = { ...input, dataFilter: { lang: getLanguage() } };
        }else input = { dataFilter: { lang: getLanguage() } };
      }

      const _fetch = await fetch(`${API_URL}frontend/${type}`, {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(input),
      });
      const _data = await _fetch.json();
      if(!_fetch.ok || _fetch.status !== 200){
        if(loading && dispatch) dispatch(alertLoading(false));
        reject(_data); return false;
      }

      let res = {
        paginate: new PaginateModel(_data.data?.paginate || {}),
        dataFilter: _data.data?.dataFilter || {},
        result: _data.data?.result || [],
      };
      if(type === 'languages'){
        res.result = res.result.map(d => new LanguageModel(d));
        if(dispatch) dispatch({ type: APP_LANGUAGES, payload: res.result });
      }else if(type === 'currencies'){
        let _currencies = _data.data.result.map(d => new CurrencyModel(d));
        if(dispatch) dispatch({ type: APP_CURRENCIES, payload: _currencies });
        res.result = _currencies;
        let _currency = _currencies.find(d => d.isDefault);
        if(_currency && dispatch) dispatch({ type: APP_CURRENCY, payload: new CurrencyModel(_currency) });
      }else if(type === 'app-themes'){
        res.result = res.result.map(d => new AppThemeModel(d));
        if(dispatch) dispatch({ type: APP_THEMES, payload: res.result });
      }else if(type === 'app-theme-blocks'){
        res.result = res.result.map(d => new AppThemeBlockModel(d));
      }else if(type === 'data-countries'){
        res.result = res.result.map(d => new DataCountryModel(d));
        if(dispatch) dispatch({ type: DATA_COUNTRIES, payload: res.result });
      }else if(type === 'data-provinces'){
        res.result = res.result.map(d => new DataProvinceModel(d));
        if(dispatch) dispatch({ type: DATA_PROVINCES, payload: res.result });
      }else if(type === 'data-districts'){
        res.result = res.result.map(d => new DataDistrictModel(d));
        if(dispatch) dispatch({ type: DATA_DISTRICTS, payload: res.result });
      }else if(type === 'data-subdistricts'){
        res.result = res.result.map(d => new DataSubdistrictModel(d));
        if(dispatch) dispatch({ type: DATA_SUBDISTRICTS, payload: res.result });
      }else if(type === 'partner-product-categories'){
        res.result = res.result.map(d => new PartnerProductCategoryModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_CATEGORIES, payload: res.result });
      }else if(type === 'partner-product-sub-categories'){
        res.result = res.result.map(d => new PartnerProductSubCategoryModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_SUB_CATEGORIES, payload: res.result });
      }else if(type === 'partner-product-sub-sub-categories'){
        res.result = res.result.map(d => new PartnerProductSubSubCategoryModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_SUB_SUB_CATEGORIES, payload: res.result });
      }else if(type === 'partner-product-brands'){
        res.result = res.result.map(d => new PartnerProductBrandModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_BRANDS, payload: res.result });
      }else if(type === 'partner-product-statuses'){
        res.result = res.result.map(d => new PartnerProductStatusModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_STATUSES, payload: res.result });
      }else if(type === 'partner-products'){
        res.result = res.result.map(d => new PartnerProductModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCTS, payload: res.result });
      }else if(type === 'partner-product-units'){
        res.result = res.result.map(d => new PartnerProductUnitModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_UNITS, payload: res.result });
      }else if(type === 'partner-product-attributes'){
        res.result = res.result.map(d => new PartnerProductAttributeModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_ATTRIBUTES, payload: res.result });
      }else if(type === 'partner-product-subscriptions'){
        res.result = res.result.map(d => new PartnerProductSubscriptionModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_SUBSCRIPTIONS, payload: res.result });
      }else if(type === 'partner-shops'){
        res.result = res.result.map(d => new PartnerShopModel(d));
        if(dispatch) dispatch({ type: PARTNER_SHOPS, payload: res.result });
      }else if(type === 'partner-shop-stocks'){
        res.result = res.result.map(d => new PartnerShopStockModel(d));
        if(dispatch) dispatch({ type: PARTNER_SHOP_STOCKS, payload: res.result });
      }else if(type === 'customer-shipping-addresses'){
        res.result = res.result.map(d => new ShippingAddressModel(d));
        if(dispatch) dispatch({ type: CUSTOMER_SHIPPING_ADDRESSES, payload: res.result });
      }else if(type === 'customer-billing-addresses'){
        res.result = res.result.map(d => new BillingAddressModel(d));
        if(dispatch) dispatch({ type: CUSTOMER_BILLING_ADDRESSES, payload: res.result });
      }else if(type === 'customer-checkout-partner-product-coupons'){
        res.result = res.result.map(d => new PartnerProductCouponModel(d));
      }else if(type === 'customer-checkout-partner-cash-coupons'){
        res.result = res.result.map(d => new PartnerProductCouponModel(d));
      }else if(type === 'customer-checkout-shipping-methods'){
        res.result = res.result.map(d => new PartnerShippingFrontendModel(d));
      }else if(type === 'customer-checkout-click-and-collect-shipping-methods'){
        res.result = res.result.map(d => new PartnerShippingFrontendModel(d));
      }else if(type === 'customer-checkout-partner-shipping-coupons'){
        res.result = res.result.map(d => new PartnerShippingCouponModel(d));
      }else if(type === 'customer-checkout-payment-methods'){
        res.result = res.result.map(d => new PaymentMethodModel(d));
      }else if(type === 'customer-subscription-shipping-methods'){
        res.result = res.result.map(d => new PartnerShippingFrontendModel(d));
      }else if(type === 'customer-subscription-click-and-collect-shipping-methods'){
        res.result = res.result.map(d => new PartnerShippingFrontendModel(d));
      }

      if(loading && dispatch) dispatch(alertLoading(false));
      resolve(res); return true;
    } catch (err) {
      console.log(err);
      if(loading && dispatch) dispatch(alertLoading(false));
      reject(err); return false;
    }
  });
};
export const processRead = (type, input={}, loading=false) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    if(loading) dispatch(alertLoading(true));
    try {
      let url = `${API_URL}frontend/${type}`;
      let sep = '?';
      Object.keys(input).forEach(k => {
        if (input[k] || input[k]===0){ url += `${sep}${k}=${input[k]}`; sep = '&'; }
      });
      if(SYSTEM_LANGUAGES.length > 1) url += `${sep}lang=${getLanguage()}`;

      const _fetch = await fetch(url, {
        method: 'GET',
        headers: apiHeader(),
      });
      const _data = await _fetch.json();
      if(!_fetch.ok || _fetch.status !== 200){
        if(loading) dispatch(alertLoading(false));
        reject(_data); return false;
      }
      
      let result = _data.data?.result ?? {};
      if(type === 'language'){
        result = new LanguageModel(result);
      }else if(type === 'currency'){
        result = new CurrencyModel(result);
      }else if(type === 'app-theme'){
        result = new AppThemeModel(result);
      }else if(type === 'sales-manager'){
        result = new UserModel(result);
      }else if(type === 'partner-product-category'){
        result = new PartnerProductCategoryModel(result);
      }else if(type === 'partner-product-sub-category'){
        result = new PartnerProductSubCategoryModel(result);
      }else if(type === 'partner-product-sub-sub-category'){
        result = new PartnerProductSubSubCategoryModel(result);
      }else if(type === 'partner-product-brand'){
        result = new PartnerProductBrandModel(result);
      }else if(type === 'partner-product-status'){
        result = new PartnerProductStatusModel(result);
      }else if(type === 'partner-product'){
        result = new PartnerProductModel(result);
      }else if(type === 'partner-product-unit'){
        result = new PartnerProductUnitModel(result);
      }else if(type === 'partner-product-subscription'){
        result = new PartnerProductSubscriptionModel(result);
      }else if(type === 'partner-shop'){
        result = new PartnerShopModel(result);
      }else if(type === 'partner-shop-center'){
        result = new PartnerShopModel(result);
      }else if(type === 'partner-shop-stock'){
        result = new PartnerShopStockModel(result);
      }else if(type === 'customer-shipping-address'){
        result = new ShippingAddressModel(result);
      }else if(type === 'customer-billing-address'){
        result = new BillingAddressModel(result);
      }else if(type === 'customer-order'){
        result = new CustomerOrderModel(result);
      }else if(type === 'customer-cart'){
        result = new CustomerCartModel(result);
      }else if(type === 'customer-subscription'){
        result = new CustomerSubscriptionModel(result);
      }else if(type === 'cms-setting'){
        result = new CmsSettingModel(result);
      }

      if(loading) dispatch(alertLoading(false));
      resolve(result); return true;
    } catch (err) {
      console.log(err);
      if(loading) dispatch(alertLoading(false));
      reject(err); return false;
    }
  });
};
export const processCreate = (type, input={}, loading=false) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    if(loading) dispatch(alertLoading(true));
    try {
      const _fetch = await fetch(`${API_URL}frontend/${type}`, {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(input),
      });
      const _data = await _fetch.json();
      if(!_fetch.ok || _fetch.status !== 200){
        if(loading) dispatch(alertChange('Warning', _data.message, _data.error? _data.error: []));
        reject(_data); return false;
      }
      
      if(loading) dispatch(alertChange('Info', _data.message));
      resolve(_data); return true;
    } catch (err) {
      console.log(err);
      if(loading) dispatch(alertChange('Danger', 'Internal server error.'));
      reject(err); return false;
    }
  });
};
export const processUpdate = (type, input={}, loading=false) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    if(loading) dispatch(alertLoading(true));
    try {
      if(SYSTEM_LANGUAGES.length > 1){
        if(input) input = { ...input, lang: getLanguage() };
        else input = { lang: getLanguage() };
      }

      const _fetch = await fetch(`${API_URL}frontend/${type}`, {
        method: 'PATCH',
        headers: apiHeader(),
        body: JSON.stringify(input),
      });
      const _data = await _fetch.json();
      if(!_fetch.ok || _fetch.status !== 200){
        if(loading) dispatch(alertChange('Warning', _data.message, _data.error? _data.error: []));
        reject(_data); return false;
      }
      
      if(loading) dispatch(alertChange('Info', _data.message));
      resolve(_data); return true;
    } catch (err) {
      console.log(err);
      if(loading) dispatch(alertChange('Danger', 'Internal server error.'));
      reject(err); return false;
    }
  });
};
