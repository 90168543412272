import { alertLoading, alertChange } from './alert.actions';
import { getLanguage } from '../helpers/frontend';
import { apiHeader } from '../helpers/header';
import { API_URL, SYSTEM_LANGUAGES } from './variables';
import {
  APP_PAYMENT_METHODS,
  APP_SHIPPING_STATUSES, APP_SHIPPING_SUB_STATUSES, APP_SHIPPING_STATUS_MAPPINGS,
  PARTNER_PRODUCT_CATEGORIES, PARTNER_PRODUCT_SUB_CATEGORIES,
  PARTNER_PRODUCT_SUB_SUB_CATEGORIES,
  PARTNER_PRODUCT_BRANDS, PARTNER_PRODUCTS, PARTNER_PRODUCT_RATINGS,
  PARTNER_PRODUCT_GIVEAWAY_RULES, PARTNER_PRODUCT_SUBSCRIPTIONS,
  PARTNER_PRODUCT_UNITS, PARTNER_PRODUCT_ATTRIBUTES, PARTNER_SHIPPINGS,
  PARTNER_SHOPS, PARTNER_SHOP_STOCKS, PARTNER_SHOP_SHIPPINGS, PARTNER_SHOP_COMMISSIONS,
  PARTNER_PRODUCT_COUPONS, PARTNER_SHIPPING_COUPONS,
  CUSTOMERS, CUSTOMER_TIERS, CUSTOMER_SHIPPING_ADDRESSES, CUSTOMER_BILLING_ADDRESSES,
  CUSTOMER_PRODUCT_COUPONS, CUSTOMER_SHIPPING_COUPONS, CUSTOMER_POINTS,
  CUSTOMER_ORDERS, CUSTOMER_SUBSCRIPTIONS, CUSTOMER_SUBSCRIPTION_PLANS,
  SELLER_SHOP_TYPES, SELLER_SHOPS, SELLER_SHOP_RATINGS,
} from './types';
import {
  PaginateModel, ShippingAddressModel, BillingAddressModel,
  PaymentMethodModel,
  ShippingStatusModel, ShippingSubStatusModel, ShippingStatusMappingModel,
  PartnerProductCategoryModel, PartnerProductSubCategoryModel,
  PartnerProductSubSubCategoryModel,
  PartnerProductBrandModel, PartnerProductModel, PartnerProductRatingModel,
  PartnerProductGiveawayRuleModel, PartnerProductSubscriptionModel,
  PartnerProductUnitModel, PartnerProductAttributeModel,
  PartnerShippingModel, PartnerShippingFrontendModel,
  PartnerShopModel, PartnerShopStockModel, PartnerShopShippingModel,
  PartnerShopCommissionModel,
  PartnerProductCouponModel, PartnerProductCouponLogModel,
  PartnerShippingCouponModel, PartnerShippingCouponLogModel,
  PartnerFreeShippingRuleModel,
  CustomerModel, CustomerTierModel, CustomerCartModel, CustomerPointModel,
  CustomerOrderModel, CustomerSubscriptionCartModel,
  CustomerSubscriptionModel, CustomerSubscriptionPlanModel,
  SellerShopTypeModel, SellerShopModel, SellerShopRatingModel,
  UserCommissionTableModel, UserCommissionModel,
} from '../models';


export const processClear = (type) => (dispatch) => {
  if(type === 'payment-methods') dispatch({ type: APP_PAYMENT_METHODS, payload: [] });
  else if(type === 'shipping-statuses') dispatch({ type: APP_SHIPPING_STATUSES, payload: [] });
  else if(type === 'shipping-sub-statuses') dispatch({ type: APP_SHIPPING_SUB_STATUSES, payload: [] });
  else if(type === 'shipping-status-mappings') dispatch({ type: APP_SHIPPING_STATUS_MAPPINGS, payload: [] });
  else if(type === 'partner-product-categories') dispatch({ type: PARTNER_PRODUCT_CATEGORIES, payload: [] });
  else if(type === 'partner-product-sub-categories') dispatch({ type: PARTNER_PRODUCT_SUB_CATEGORIES, payload: [] });
  else if(type === 'partner-product-sub-sub-categories') dispatch({ type: PARTNER_PRODUCT_SUB_SUB_CATEGORIES, payload: [] });
  else if(type === 'partner-product-brands') dispatch({ type: PARTNER_PRODUCT_BRANDS, payload: [] });
  else if(type === 'partner-products') dispatch({ type: PARTNER_PRODUCTS, payload: [] });
  else if(type === 'partner-product-ratings') dispatch({ type: PARTNER_PRODUCT_RATINGS, payload: [] });
  else if(type === 'partner-product-giveaway-rules') dispatch({ type: PARTNER_PRODUCT_GIVEAWAY_RULES, payload: [] });
  else if(type === 'partner-product-units') dispatch({ type: PARTNER_PRODUCT_UNITS, payload: [] });
  else if(type === 'partner-product-attributes') dispatch({ type: PARTNER_PRODUCT_ATTRIBUTES, payload: [] });
  else if(type === 'partner-shippings') dispatch({ type: PARTNER_SHIPPINGS, payload: [] });
  else if(type === 'partner-shops') dispatch({ type: PARTNER_SHOPS, payload: [] });
  else if(type === 'partner-shop-stocks') dispatch({ type: PARTNER_SHOP_STOCKS, payload: [] });
  else if(type === 'partner-shop-shippings') dispatch({ type: PARTNER_SHOP_SHIPPINGS, payload: [] });
  else if(type === 'partner-shop-commissions') dispatch({ type: PARTNER_SHOP_COMMISSIONS, payload: [] });
  else if(type === 'partner-product-subscriptions') dispatch({ type: PARTNER_PRODUCT_SUBSCRIPTIONS, payload: [] });
  else if(type === 'partner-product-coupons') dispatch({ type: PARTNER_PRODUCT_COUPONS, payload: [] });
  else if(type === 'partner-shipping-coupons') dispatch({ type: PARTNER_SHIPPING_COUPONS, payload: [] });
  else if(type === 'customers') dispatch({ type: CUSTOMERS, payload: [] });
  else if(type === 'customer-tiers') dispatch({ type: CUSTOMER_TIERS, payload: [] });
  else if(type === 'customer-shipping-addresses') dispatch({ type: CUSTOMER_SHIPPING_ADDRESSES, payload: [] });
  else if(type === 'customer-billing-addresses') dispatch({ type: CUSTOMER_BILLING_ADDRESSES, payload: [] });
  else if(type === 'customer-product-coupons') dispatch({ type: CUSTOMER_PRODUCT_COUPONS, payload: [] });
  else if(type === 'customer-shipping-coupons') dispatch({ type: CUSTOMER_SHIPPING_COUPONS, payload: [] });
  else if(type === 'customer-points') dispatch({ type: CUSTOMER_POINTS, payload: [] });
  else if(type === 'customer-orders') dispatch({ type: CUSTOMER_ORDERS, payload: [] });
  else if(type === 'customer-subscriptions') dispatch({ type: CUSTOMER_SUBSCRIPTIONS, payload: [] });
  else if(type === 'customer-subscription-plans') dispatch({ type: CUSTOMER_SUBSCRIPTION_PLANS, payload: [] });
  else if(type === 'seller-shop-types') dispatch({ type: SELLER_SHOP_TYPES, payload: [] });
  else if(type === 'seller-shops') dispatch({ type: SELLER_SHOPS, payload: [] });
  else if(type === 'seller-shop-ratings') dispatch({ type: SELLER_SHOP_RATINGS, payload: [] });
};


export const processList = (type, input={}, loading=false) => async (dispatch=null) => {
  return new Promise(async (resolve, reject) => {
    if(loading && dispatch) dispatch(alertLoading(true));
    try {
      if(SYSTEM_LANGUAGES.length > 1){
        if(input){
          if(input.dataFilter) input.dataFilter = { ...input.dataFilter, lang: getLanguage() };
          else input = { ...input, dataFilter: { lang: getLanguage() } };
        }else input = { dataFilter: { lang: getLanguage() } };
      }

      const _fetch = await fetch(`${API_URL}sales-manager/${type}`, {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(input),
      });
      const _data = await _fetch.json();
      if(!_fetch.ok || _fetch.status !== 200){
        if(loading && dispatch) dispatch(alertLoading(false));
        reject(_data); return false;
      }

      let res = {
        paginate: new PaginateModel(_data.data?.paginate || {}),
        dataFilter: _data.data?.dataFilter || {},
        result: _data.data?.result || [],
      };
      if(type === 'payment-methods'){
        res.result = res.result.map(d => new PaymentMethodModel(d));
        if(dispatch) dispatch({ type: APP_PAYMENT_METHODS, payload: res.result });
      }else if(type === 'shipping-statuses'){
        res.result = res.result.map(d => new ShippingStatusModel(d));
        if(dispatch) dispatch({ type: APP_SHIPPING_STATUSES, payload: res.result });
      }else if(type === 'shipping-sub-statuses'){
        res.result = res.result.map(d => new ShippingSubStatusModel(d));
        if(dispatch) dispatch({ type: APP_SHIPPING_SUB_STATUSES, payload: res.result });
      }else if(type === 'shipping-status-mappings'){
        res.result = res.result.map(d => new ShippingStatusMappingModel(d));
        if(dispatch) dispatch({ type: APP_SHIPPING_STATUS_MAPPINGS, payload: res.result });
      }else if(type === 'partner-product-categories'){
        res.result = res.result.map(d => new PartnerProductCategoryModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_CATEGORIES, payload: res.result });
      }else if(type === 'partner-product-sub-categories'){
        res.result = res.result.map(d => new PartnerProductSubCategoryModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_SUB_CATEGORIES, payload: res.result });
      }else if(type === 'partner-product-sub-sub-categories'){
        res.result = res.result.map(d => new PartnerProductSubSubCategoryModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_SUB_SUB_CATEGORIES, payload: res.result });
      }else if(type === 'partner-product-brands'){
        res.result = res.result.map(d => new PartnerProductBrandModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_BRANDS, payload: res.result });
      }else if(type === 'partner-products'){
        res.result = res.result.map(d => new PartnerProductModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCTS, payload: res.result });
      }else if(type === 'partner-product-ratings'){
        res.result = res.result.map(d => new PartnerProductRatingModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_RATINGS, payload: res.result });
      }else if(type === 'partner-product-giveaway-rules'){
        res.result = res.result.map(d => new PartnerProductGiveawayRuleModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_GIVEAWAY_RULES, payload: res.result });
      }else if(type === 'partner-product-units'){
        res.result = res.result.map(d => new PartnerProductUnitModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_UNITS, payload: res.result });
      }else if(type === 'partner-product-attributes'){
        res.result = res.result.map(d => new PartnerProductAttributeModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_ATTRIBUTES, payload: res.result });
      }else if(type === 'partner-shippings'){
        res.result = res.result.map(d => new PartnerShippingModel(d));
        if(dispatch) dispatch({ type: PARTNER_SHIPPINGS, payload: res.result });
      }else if(type === 'partner-shops'){
        res.result = res.result.map(d => new PartnerShopModel(d));
        if(dispatch) dispatch({ type: PARTNER_SHOPS, payload: res.result });
      }else if(type === 'partner-shop-stocks'){
        res.result = res.result.map(d => new PartnerShopStockModel(d));
        if(dispatch) dispatch({ type: PARTNER_SHOP_STOCKS, payload: res.result });
      }else if(type === 'partner-shop-shippings'){
        res.result = res.result.map(d => new PartnerShopShippingModel(d));
        if(dispatch) dispatch({ type: PARTNER_SHOP_SHIPPINGS, payload: res.result });
      }else if(type === 'partner-shop-commissions'){
        res.result = res.result.map(d => new PartnerShopCommissionModel(d));
        if(dispatch) dispatch({ type: PARTNER_SHOP_COMMISSIONS, payload: res.result });
      }else if(type === 'partner-product-subscriptions'){
        res.result = res.result.map(d => new PartnerProductSubscriptionModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_SUBSCRIPTIONS, payload: res.result });
      }else if(type === 'partner-product-coupons'){
        res.result = res.result.map(d => new PartnerProductCouponModel(d));
        if(dispatch) dispatch({ type: PARTNER_PRODUCT_COUPONS, payload: res.result });
      }else if(type === 'partner-shipping-coupons'){
        res.result = res.result.map(d => new PartnerShippingCouponModel(d));
        if(dispatch) dispatch({ type: PARTNER_SHIPPING_COUPONS, payload: res.result });
      }else if(type === 'customers'){
        res.result = res.result.map(d => new CustomerModel(d));
        if(dispatch) dispatch({ type: CUSTOMERS, payload: res.result });
      }else if(type === 'customer-tiers'){
        res.result = res.result.map(d => new CustomerTierModel(d));
        if(dispatch) dispatch({ type: CUSTOMER_TIERS, payload: res.result });
      }else if(type === 'customer-shipping-addresses'){
        res.result = res.result.map(d => new ShippingAddressModel(d));
        if(dispatch) dispatch({ type: CUSTOMER_SHIPPING_ADDRESSES, payload: res.result });
      }else if(type === 'customer-billing-addresses'){
        res.result = res.result.map(d => new BillingAddressModel(d));
        if(dispatch) dispatch({ type: CUSTOMER_BILLING_ADDRESSES, payload: res.result });
      }else if(type === 'customer-product-coupons'){
        res.result = res.result.map(d => new PartnerProductCouponLogModel(d));
        if(dispatch) dispatch({ type: CUSTOMER_PRODUCT_COUPONS, payload: res.result });
      }else if(type === 'customer-shipping-coupons'){
        res.result = res.result.map(d => new PartnerShippingCouponLogModel(d));
        if(dispatch) dispatch({ type: CUSTOMER_SHIPPING_COUPONS, payload: res.result });
      }else if(type === 'customer-points'){
        res.result = res.result.map(d => new CustomerPointModel(d));
        if(dispatch) dispatch({ type: CUSTOMER_POINTS, payload: res.result });
      }else if(type === 'customer-orders'){
        res.result = res.result.map(d => new CustomerOrderModel(d));
        if(dispatch) dispatch({ type: CUSTOMER_ORDERS, payload: res.result });
      }else if(type === 'customer-subscriptions'){
        res.result = res.result.map(d => new CustomerSubscriptionModel(d));
        if(dispatch) dispatch({ type: CUSTOMER_SUBSCRIPTIONS, payload: res.result });
      }else if(type === 'customer-subscription-plans'){
        res.result = res.result.map(d => new CustomerSubscriptionPlanModel(d));
        if(dispatch) dispatch({ type: CUSTOMER_SUBSCRIPTIONS, payload: res.result });
      }else if(type === 'seller-shop-types'){
        res.result = res.result.map(d => new SellerShopTypeModel(d));
        if(dispatch) dispatch({ type: SELLER_SHOP_TYPES, payload: res.result });
      }else if(type === 'seller-shops'){
        res.result = res.result.map(d => new SellerShopModel(d));
        if(dispatch) dispatch({ type: SELLER_SHOPS, payload: res.result });
      }else if(type === 'seller-shop-ratings'){
        res.result = res.result.map(d => new SellerShopRatingModel(d));
        if(dispatch) dispatch({ type: SELLER_SHOP_RATINGS, payload: res.result });
      }else if(type === 'customer-order-shops'){
        res.result = res.result.map(d => new PartnerShopModel(d));
      }else if(type === 'customer-frequently-bought-products'){
        res.result = res.result.map(d => {
          return { product: new PartnerProductModel(d.product), count: d.count };
        });
      }else if(type === 'customer-never-bought-products'){
        res.result = res.result.map(d => new PartnerProductModel(d));
      }else if(type === 'customer-checkout-shipping-methods'){
        res.result = res.result.map(d => new PartnerShippingFrontendModel(d));
      }else if(type === 'customer-checkout-payment-methods'){
        res.result = res.result.map(d => new PaymentMethodModel(d));
      }else if(type === 'user-commissions'){
        res.result = res.result.map(d => new UserCommissionModel(d));
      }

      if(loading && dispatch) dispatch(alertLoading(false));
      resolve(res); return true;
    } catch (err) {
      console.log(err);
      if(loading && dispatch) dispatch(alertLoading(false));
      reject(err); return false;
    }
  });
};
export const processRead = (type, input={}, loading=false) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    if(loading) dispatch(alertLoading(true));
    try {
      let url = `${API_URL}sales-manager/${type}`;
      let sep = '?';
      Object.keys(input).forEach(k => {
        if (input[k] || input[k]===0){ url += `${sep}${k}=${input[k]}`; sep = '&'; }
      });
      if(SYSTEM_LANGUAGES.length > 1) url += `${sep}lang=${getLanguage()}`;

      const _fetch = await fetch(url, {
        method: 'GET',
        headers: apiHeader(),
      });
      const _data = await _fetch.json();
      if(!_fetch.ok || _fetch.status !== 200){
        if(loading) dispatch(alertLoading(false));
        reject(_data); return false;
      }
      
      let result = _data.data?.result ?? {};
      if(type === 'payment-method'){
        result = new PaymentMethodModel(result);
      }else if(type === 'shipping-status'){
        result = new ShippingStatusModel(result);
      }else if(type === 'shipping-sub-status'){
        result = new ShippingSubStatusModel(result);
      }else if(type === 'shipping-status-mapping'){
        result = new ShippingStatusMappingModel(result);
      }else if(type === 'partner-product-category'){
        result = new PartnerProductCategoryModel(result);
      }else if(type === 'partner-product-sub-category'){
        result = new PartnerProductSubCategoryModel(result);
      }else if(type === 'partner-product-sub-sub-category'){
        result = new PartnerProductSubSubCategoryModel(result);
      }else if(type === 'partner-product-brand'){
        result = new PartnerProductBrandModel(result);
      }else if(type === 'partner-product'){
        result = new PartnerProductModel(result);
      }else if(type === 'partner-product-rating'){
        result = new PartnerProductRatingModel(result);
      }else if(type === 'partner-product-giveaway-rule'){
        result = new PartnerProductGiveawayRuleModel(result);
      }else if(type === 'partner-product-unit'){
        result = new PartnerProductUnitModel(result);
      }else if(type === 'partner-product-attribute'){
        result = new PartnerProductAttributeModel(result);
      }else if(type === 'partner-shipping'){
        result = new PartnerShippingModel(result);
      }else if(type === 'partner-shop'){
        result = new PartnerShopModel(result);
      }else if(type === 'partner-shop-stock'){
        result = new PartnerShopStockModel(result);
      }else if(type === 'partner-shop-shipping'){
        result = new PartnerShopShippingModel(result);
      }else if(type === 'partner-shop-commission'){
        result = new PartnerShopCommissionModel(result);
      }else if(type === 'partner-product-subscription'){
        result = new PartnerProductSubscriptionModel(result);
      }else if(type === 'partner-product-coupon'){
        result = new PartnerProductCouponModel(result);
      }else if(type === 'partner-shipping-coupon'){
        result = new PartnerShippingCouponModel(result);
      }else if(type === 'partner-free-shipping-rule'){
        result = new PartnerFreeShippingRuleModel(result);
      }else if(type === 'customer'){
        result = new CustomerModel(result);
      }else if(type === 'customer-tier'){
        result = new CustomerTierModel(result);
      }else if(type === 'customer-cart'){
        result = new CustomerCartModel(result);
      }else if(type === 'customer-shipping-address'){
        result = new ShippingAddressModel(result);
      }else if(type === 'customer-billing-address'){
        result = new BillingAddressModel(result);
      }else if(type === 'customer-product-coupon'){
        result = new PartnerProductCouponLogModel(result);
      }else if(type === 'customer-shipping-coupon'){
        result = new PartnerShippingCouponLogModel(result);
      }else if(type === 'customer-point'){
        result = new CustomerPointModel(result);
      }else if(type === 'customer-order'){
        result = new CustomerOrderModel(result);
      }else if(type === 'customer-subscription-cart'){
        result = new CustomerSubscriptionCartModel(result);
      }else if(type === 'customer-subscription'){
        result = new CustomerSubscriptionModel(result);
      }else if(type === 'customer-subscription-plan'){
        result = new CustomerSubscriptionPlanModel(result);
      }else if(type === 'seller-shop-type'){
        result = new SellerShopTypeModel(result);
      }else if(type === 'seller-shop'){
        result = new SellerShopModel(result);
      }else if(type === 'seller-shop-rating'){
        result = new SellerShopRatingModel(result);
      }else if(type === 'user-commission-table'){
        result = new UserCommissionTableModel(result);
      }

      if(loading) dispatch(alertLoading(false));
      resolve(result); return true;
    } catch (err) {
      console.log(err);
      if(loading) dispatch(alertLoading(false));
      reject(err); return false;
    }
  });
};
export const processCreate = (type, input={}, loading=false) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    if(loading) dispatch(alertLoading(true));
    try {
      const _fetch = await fetch(`${API_URL}sales-manager/${type}`, {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(input)
      });
      const _data = await _fetch.json();
      if(!_fetch.ok || _fetch.status !== 200){
        if(loading) dispatch(alertChange('Warning', _data.message, _data.error? _data.error: []));
        reject(_data); return false;
      }
      
      if(loading) dispatch(alertChange('Info', _data.message));
      resolve(_data); return true;
    } catch (err) {
      console.log(err);
      if(loading) dispatch(alertChange('Danger', 'Internal server error.'));
      reject(err); return false;
    }
  });
};
export const processUpdate = (type, input={}, loading=false) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    if(loading) dispatch(alertLoading(true));
    try {
      if(SYSTEM_LANGUAGES.length > 1){
        if(input) input = { ...input, lang: getLanguage() };
        else input = { lang: getLanguage() };
      }

      const _fetch = await fetch(`${API_URL}sales-manager/${type}`, {
        method: 'PATCH',
        headers: apiHeader(),
        body: JSON.stringify(input),
      });
      const _data = await _fetch.json();
      if(!_fetch.ok || _fetch.status !== 200){
        if(loading) dispatch(alertChange('Warning', _data.message, _data.error? _data.error: []));
        reject(_data); return false;
      }
      
      if(loading) dispatch(alertChange('Info', _data.message));
      resolve(_data); return true;
    } catch (err) {
      console.log(err);
      if(loading) dispatch(alertChange('Danger', 'Internal server error.'));
      reject(err); return false;
    }
  });
};
export const processDelete = (type, input={}, loading=false) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    if(loading) dispatch(alertLoading(true));
    try {
      const _fetch = await fetch(`${API_URL}sales-manager/${type}`, {
        method: 'DELETE',
        headers: apiHeader(),
        body: JSON.stringify(input)
      });
      const _data = await _fetch.json();
      if(!_fetch.ok || _fetch.status !== 200){
        if(loading) dispatch(alertChange('Warning', _data.message, _data.error? _data.error: []));
        reject(_data); return false;
      }
      
      if(loading) dispatch(alertChange('Info', _data.message));
      resolve(_data); return true;
    } catch (err) {
      console.log(err);
      if(loading) dispatch(alertChange('Danger', 'Internal server error.'));
      reject(err); return false;
    }
  });
};
