import {
  LOGISTIC_WAREHOUSES, LOGISTIC_VEHICLES, LOGISTIC_TRANSPORT_ROUTES,
  LOGISTIC_ROUTES,
} from '../actions/types';

const initialState = {
  warehouses: [],
  vehicles: [],
  transportRoutes: [],
  routes: [],
};

const logisticReducer = (state = initialState, action) => {
  switch(action.type) {
    
    case LOGISTIC_WAREHOUSES:
      return { ...state, warehouses: action.payload };
      
    case LOGISTIC_VEHICLES:
      return { ...state, vehicles: action.payload };

    case LOGISTIC_TRANSPORT_ROUTES:
      return { ...state, transportRoutes: action.payload };
      
    case LOGISTIC_ROUTES:
      return { ...state, routes: action.payload };

    default:
      return state;
  }
};

export default logisticReducer;