import { UserModel } from './';
import { translate } from '../helpers/frontend';
import dataLogUserTypes from '../data/log-user-types';

/*
  source
    1 = By System
    2 = By User
    3 = By External
      - sourceName is required

  status
    0 = Failed
    1 = Success
*/

export class LogUserModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.source = data?.source || 2;
    this.sourceName = data?.sourceName || '';
    this.user = new UserModel(data?.user || {});
    this.byUser = new UserModel(data?.byUser || {});

    this.ipAddress = data?.ipAddress || '';
    this.lat = data?.lat ?? null;
    this.lng = data?.lng ?? null;
    
    this.type = data?.type ?? null;
    this.description = data?.description || '';
    this.metadata = data?.metadata || {};

    this.status = data.status ?? 0;
    
    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
  }

  isValid() { return this._id? true: false; }

  displayType() {
    if(!this.isValid()) return '';
    const temp = dataLogUserTypes.find(d => d.value === this.type);
    if(!temp) return '';
    return temp.name;
  }
  displayStatus(langObj={}) {
    if(!this.isValid()) return '';
    if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Success', langObj)}</span>);
    return (<span className="ss-tag bg-warning">{translate('Failed', langObj)}</span>);
  }
}
