import {
  TOOL_PARTNER_PRODUCT_PROMOTION
} from '../actions/types';

const initialState = {
  productPromotions: [],
};

const partnerReducer = (state = initialState, action) => {
  switch(action.type) {
    
    case TOOL_PARTNER_PRODUCT_PROMOTION:
      return { ...state, productPromotions: action.payload };

    default:
      return state;
  }
};

export default partnerReducer;