import {
  SELLER_SHOP_BRANDS, SELLER_SHOP_TYPES, SELLER_SHOPS, SELLER_SHOP_RATINGS
} from '../actions/types';

const initialState = {
  shopBrands: [],
  shopTypes: [],
  shops: [],
  ratings: [],
};

const sellerReducer = (state = initialState, action) => {
  switch(action.type) {
    
    case SELLER_SHOP_BRANDS:
      return { ...state, shopBrands: action.payload };
    
    case SELLER_SHOP_TYPES:
      return { ...state, shopTypes: action.payload };
    
    case SELLER_SHOPS:
      return { ...state, shops: action.payload };
    
    case SELLER_SHOP_RATINGS:
      return { ...state, ratings: action.payload };

    default:
      return state;
  }
};

export default sellerReducer;