import {
  CustomerModel, PartnerShopModel, PartnerProductModel,
  PartnerProductCouponModel, PartnerProductGiveawayRuleModel,
} from '.';
import { unescape } from 'html-escaper';

export class CustomerCartModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.customer = new CustomerModel(data.customer? data.customer: {});
    this.shop = new PartnerShopModel(data.shop? data.shop: {});

    this.products = data.products && data.products.length
      ? data.products.map(d => new PartnerProductModel(d)): []
    this.subtotal = data.subtotal || data.subtotal===0? Number(data.subtotal): null;
    
    this.vatName = data.vatName? unescape(data.vatName): '';
    this.vat = data.vat || data.vat===0? Number(data.vat): null;
    this.vatPercent = data.vatPercent || data.vatPercent===0? Number(data.vatPercent): null;
    
    this.discount = data.discount || data.discount===0? Number(data.discount): null;
    this.total = data.total || data.total===0? Number(data.total): null;
    
    this.hasDownPayment = data.hasDownPayment? Number(data.hasDownPayment): 0;
    this.downPayment = data.downPayment? Number(data.downPayment): 0;
    this.missingPayment = data.missingPayment? Number(data.missingPayment): 0;
    
    this.totalCommission = data.totalCommission? Number(data.totalCommission): 0;

    this.pointEarn = data.pointEarn? Number(data.pointEarn): 0;
    this.pointEarnStatus = data.pointEarnStatus? Number(data.pointEarnStatus): 0;

    this.receivedCoupons = data.receivedCoupons && data.receivedCoupons.length 
      ? data.receivedCoupons.map(d => new PartnerProductCouponModel(d)): [];
    this.productGiveawayRule = new PartnerProductGiveawayRuleModel(data?.productGiveawayRule ?? {});

    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
  }

  isValid() {
    return this._id? true: false;
  }

  hasVAT() {
    if(this.vat && this.vat > 0 && this.vatPercent && this.vatPercent > 0){
      return true;
    }else{
      return false;
    }
  }

  hasShipBySupplier() {
    let shipBySupplier = false;
    this.products.forEach(d => {
      if(d.shipBySupplier) shipBySupplier = true;
    });
    return shipBySupplier;
  }
  shipBySupplierProducts() {
    return this.products.filter(d => d.shipBySupplier);
  }
}
