import PropTypes from 'prop-types';
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { connect } from 'react-redux';
import { appCopyright } from '../helpers/frontend';

function Footer(props) {
  const { settings } = useContext(AuthContext);
  return (
    <footer className={`footer bcolor-fgray color-dark ${props.classer}`}>
      <div className="container">
        <p className="sm fw-500 text-center">
          {appCopyright(settings['SYSTEM_COMPANY_NAME'])}
          {' '}<span className="xs-hide">All rights reserved.</span>
        </p>
      </div>
    </footer>
  );
}

Footer.defaultProps = {
	classer: '',
};
Footer.propTypes = {
	classer: PropTypes.string,
};
export default connect(() => ({}), {})(Footer);