import { UserModel, CustomerTierModel, CustomerGroupModel } from '.';
import { formatNumber } from '../helpers/frontend';
import { translate } from '../helpers/frontend';
import { unescape } from 'html-escaper';

/*
  status
    0 = แบบร่าง
    1 = ส่งแล้ว
*/

export class CustomerPushNotiModel {
  constructor(data={}) {
    this._id = data?._id || null;
    this.user = new UserModel(data.user? data.user: {});
    
    this.title = data.title? unescape(data.title): null;
    this.message = data.message? unescape(data.message): null;
    
    this.forCustomerGroups = data.forCustomerGroups && data.forCustomerGroups.length
      ? data.forCustomerGroups.map(d => new CustomerGroupModel(d)): [];
    this.forAllCustomerGroupTiers = data.forAllCustomerGroupTiers? Number(data.forAllCustomerGroupTiers): 0;
    this.forCustomerGroupTiers = data.forCustomerGroupTiers && data.forCustomerGroupTiers.length
        ? data.forCustomerGroupTiers.map(d => new CustomerTierModel(d)): [];

    this.forAllCustomerTiers = data.forAllCustomerTiers? Number(data.forAllCustomerTiers): 0;
    this.forCustomerTiers = data.forCustomerTiers && data.forCustomerTiers.length
      ? data.forCustomerTiers.map(d => new CustomerTierModel(d)): [];

    this.countCustomers = data.countCustomers? Number(data.countCustomers): 0;
    this.sent = data.sent? Number(data.sent): 0;

    this.status = data.status? data.status: 0;

    this.createdBy = new UserModel(data.createdBy || {});
    this.createdAt = data.createdAt || null;
    this.updatedBy = new UserModel(data.updatedBy || {});
    this.updatedAt = data.updatedAt || null;
  }

  isValid() {
    return this._id? true: false;
  }

  displayCountCustomers() {
    if(this.isValid() && this.status === 1){
      return formatNumber(this.countCustomers, 0);
    }else{
      return '-';
    }
  }
  displaySent() {
    if(this.isValid() && this.status === 1){
      return formatNumber(this.sent, 0);
    }else{
      return '-';
    }
  }
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Sent',langObj)}</span>);
      else return (<span className="ss-tag bg-fgray color-dark">{translate('Draft',langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-fgray color-dark">{translate('Draft',langObj)}</span>);
    }
  }
}
