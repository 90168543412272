import { translate } from '../helpers/frontend';

/*
  dayIndex :
    0 = วันอาทิตย์
    1 = วันจันทร์
    2 = วันอังคาร
    3 = วันพุธ
    4 = วันพฤหัสบดี
    5 = วันศุกร์
    6 = วันเสาร์
*/

export class PackingDayModel {
  constructor(data={}) {
    this.dayIndex = data.dayIndex || data.dayIndex===0? Number(data.dayIndex): null;

    this.scheduleIndex = data.scheduleIndex || data.scheduleIndex===0? Number(data.scheduleIndex): null;

    this.startTime = data.startTime? data.startTime: null;
    this.endTime = data.endTime? data.endTime: null;
    this.duration = data.duration || data.duration===0? Number(data.duration): null;

    this.departureTime = data.departureTime? data.departureTime: null;
    this.arrivalTime = data.arrivalTime? data.arrivalTime: null;
  }

  displayDay(langObj={}) {
    if(this.dayIndex === null) return '';
    else if(this.dayIndex === 0) return translate('Sunday', langObj);
    else if(this.dayIndex === 1) return translate('Monday', langObj);
    else if(this.dayIndex === 2) return translate('Tuesday', langObj);
    else if(this.dayIndex === 3) return translate('Wednesday', langObj);
    else if(this.dayIndex === 4) return translate('Thursday', langObj);
    else if(this.dayIndex === 5) return translate('Friday', langObj);
    else if(this.dayIndex === 6) return translate('Saturday', langObj);
    else return '';
  }
}
