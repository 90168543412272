import { 
  UserModel, CustomerGroupModel, CustomerTierModel, 
  CmsContentModel, CmsPopupModel, PartnerProductModel, 
  PartnerShopModel, DataProvinceModel, 
} from '.';
import { translate } from '../helpers/frontend';
import { unescape } from 'html-escaper';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
channel : String
  - C2U
  - STORE

status : Number
  0 = Inactive
  1 = Active
*/

export class CmsCheckoutContentModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.name = data.name? unescape(data.name): null;
    this.type = data.type? unescape(data.type): null;

    this.startAt = data.startAt ?? null;
    this.endAt = data.endAt ?? null;

    this.order = data.order ?? 1;
    this.status = data.status ?? 0;

    this.relatedProducts = data.relatedProducts && data.relatedProducts.length
      ? data.relatedProducts.map(d => new PartnerProductModel(d)): [];
    this.relatedContents = data.relatedContents && data.relatedContents.length
      ? data.relatedContents.map(d => new CmsContentModel(d)): [];

    this.enabledPopup = data.enabledPopup ?? 0;
    this.relatedPopup = new CmsPopupModel(data.relatedPopup ?? {});

    this.forCustomerGroups = data.forCustomerGroups && data.forCustomerGroups.length
      ? data.forCustomerGroups.map(d => new CustomerGroupModel(d)): [];

    this.forAllPartnerShops = data.forAllPartnerShops ?? 0;
    this.forPartnerShops = data.forPartnerShops && data.forPartnerShops.length
      ? data.forPartnerShops.map(d => new PartnerShopModel(d)): [];

    this.forAllCustomerTiers = data.forAllCustomerTiers ?? 0;
    this.forCustomerTiers = data.forCustomerTiers && data.forCustomerTiers.length
      ? data.forCustomerTiers.map(d => new CustomerTierModel(d)): [];
      
    this.forAllProvinces = data.forAllProvinces ?? 0;
    this.forProvinces = data.forProvinces && data.forProvinces.length 
      ? data.forProvinces.map(d => new DataProvinceModel(d)): [];

    this.createdBy = new UserModel(data.createdBy ?? {});
    this.createdAt = data.createdAt || null;
    this.updatedBy = new UserModel(data.updatedBy ?? {});
    this.updatedAt = data.updatedAt || null;

    // Localization
    this.localization(data);
  }

  localization(data){
    let self = this;
    self.localizedKeys = [ 'name', 'description' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }

  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }

  existInGroup(groupId) {
    if(groupId && this.forCustomerGroups && this.forCustomerGroups.length) {
      const containGroup = this.forCustomerGroups.find(d => d._id === groupId) !== undefined;
      if(containGroup){
        return true;
      }else return false;
    } else return false;
  }
}