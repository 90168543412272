import { translate } from '../helpers/frontend';

export class DatabaseBackupModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.filename = data.filename? data.filename: '';
    this.database = data.database? data.database: '';

    this.status = data.status? data.status: 0;

    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Success', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Failed', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Failed', langObj)}</span>);
    }
  }
}
