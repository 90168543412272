import { lazy, Suspense, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { Routes, Route, Navigate } from 'react-router-dom';

import { AuthPageLoading, AuthSignInPage } from '../views/auth';

export default function RouteFrontend() {
  const { status } = useContext(AuthContext);
  
  const LazyRoute = ({ dom: Element }) => <Element />;
  return status === 'unauthenticated'? (<>
    <Suspense fallback={<AuthPageLoading />}>
      <Routes>
        <Route path="/" element={<AuthSignInPage />} />
        <Route path="/auth/signin" element={<AuthSignInPage />} />
        <Route path="/auth/forget-password" element={<LazyRoute dom={lazy(() => import('../views/auth/ForgetPasswordPage'))} />} />
        <Route path="/auth/reset-password/*" element={<LazyRoute dom={lazy(() => import('../views/auth/ResetPasswordPage'))} />} />
        
        {/* Setup */}
        <Route path="/setup/*" element={<LazyRoute dom={lazy(() => import('../views/setup/SetupPage'))} />} />

        {/* Must Have */}
        <Route path="/auth/customer-signup-qr-code/:partnerShopCode/*" element={<LazyRoute dom={lazy(() => import('../views/auth/CustomerSignupQrCodePage'))} />} />
        <Route path="/payment/failed" element={<LazyRoute dom={lazy(() => import('../views/payment/FailedPage'))} />} />
        <Route path="/payment/success/:dataId" element={<LazyRoute dom={lazy(() => import('../views/payment/SuccessPage'))} />} />

        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Suspense>
  </>): (<></>);
}
