import { FileModel } from '.';
import { unescape } from 'html-escaper';
import { translate } from '../helpers/frontend';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  type : String
    - C2U
    - Store

  status :
    0 = Inactive
    1 = Active
    2 = Featured
*/

export class CmsContentCategoryModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.type = data.type? unescape(data.type): 'C2U';
    
    this.title = data.title? unescape(data.title): null;
    this.description = data.description? unescape(data.description): null;
    this.keywords = data.keywords? unescape(data.keywords): null;
    
    this.metaTitle = data.metaTitle? unescape(data.metaTitle): null;
    this.metaDescription = data.metaDescription? unescape(data.metaDescription): null;
    this.metaKeywords = data.metaKeywords? unescape(data.metaKeywords): null;

    this.url = data.url? data.url: null;
    this.image = new FileModel(data.image? data.image: {});
    
    this.showOnMobile = data.showOnMobile? Number(data.showOnMobile): 0;
    this.showOnContent = data.showOnContent? Number(data.showOnContent): 0;
    
    this.withIcon = data.withIcon? Number(data.withIcon): 0;
    this.iconClass = data.iconClass? unescape(data.iconClass): null;

    this.order = data.order? Number(data.order): 1;
    this.status = data.status? Number(data.status): 0;

    this.isDeletable = data.isDeletable? Number(data.isDeletable): 0;

    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'title', 'description' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayShowOnMobile(langObj={}) {
    if(this.isValid()){
      if(this.showOnMobile === 1) return (<span className="ss-tag bg-success">{translate('Show', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Hide', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Hide', langObj)}</span>);
    }
  }
  displayShowOnContent(langObj={}) {
    if(this.isValid()){
      if(this.showOnContent === 1) return (<span className="ss-tag bg-success">{translate('Show', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Hide', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Hide', langObj)}</span>);
    }
  }

  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
}
