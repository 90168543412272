import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { onMounted, translate, appLogo, appBg, appCopyright } from '../../helpers/frontend';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { setSidenavActiveIndex } from '../../actions/app.actions';
import { userForgetPassword } from '../../actions/user.actions';
import { processRead } from '../../actions/frontend.actions';

function ForgetPasswordPage(props) {
  const { settings, langObj } = useContext(AuthContext);

  const [process, setProcess] = useState(false);
  
  const [values, setValues] = useState({ email: '' });
  const onChangeInput = (key, val) => {
    setValues({ ...values, [key]: val });
  };

  const onSubmit = async (e) => {
    e?.preventDefault();
    const res = await props.processForgetPassword(values);
    if(res){
      onChangeInput('email', '');
      setProcess(true);
    }
  };
  
  /* eslint-disable */
	useEffect(() => { onMounted(); props.setSidenavActiveIndex(0) }, []);
  useEffect(() => {
    const checkSetup = async () => {
      const res = await props.processRead('setup');
      if(!res?.isSetup) window.location.href = '/setup';
    };
    checkSetup();
  }, []);
  /* eslint-enable */

  return (
    <section className="auth-01 section-padding">
      <div className="bg-img" style={{ backgroundImage: `url('${appBg(settings['SYSTEM_BG'])}')` }}></div>
      <div className="container">
        <div className="auth-container bradius box-shadow">
          <div className="auth-body bg-white">
            <div className="title">
              <img className="logo" src={appLogo(settings['SYSTEM_LOGO'])} alt="Logo" />
              <div className="text">
                <h5 className="fw-900 lh-sm">{translate('Forgot Password', langObj)}</h5>
                <p className="fw-600 op-70">{translate('Back Office System', langObj)}</p>
              </div>
            </div>
            {!process? (
              <>
                <form onSubmit={onSubmit}>
                  <div className="grids mt-4">
                    <div className="grid sm-100">
                      <div className="form-control">
                        <input
                          type="email" required={true} 
                          placeholder={translate('your email', langObj)+' *'} 
                          value={values.email? values.email: ''} className="lg" 
                          onChange={e => onChangeInput('email', e.target.value)} 
                        />
                      </div>
                    </div>
                  </div>
                  <div className="btns">
                    <button type="submit" className="btn btn-action btn-p btn-lg w-full">
                      {translate('reset password', langObj)}
                    </button>
                  </div>
                </form>
                <p className="sm color-gray text-center mt-6">
                  <Link to="/auth/signin" className="color-s h-color-p fw-600">
                    {translate('Back to Signin Page', langObj)}
                  </Link>
                </p>
              </>
            ): (
              <>
                <p className="fw-500 text-center mt-6">
                  {translate('text_forgot_password_1', langObj)}
                </p>
                <div className="btns pt-2">
                  <Link to="/auth/signin" className="btn btn-action btn-p btn-lg w-full">
                    {translate('Sign In', langObj)}
                  </Link>
                </div>
              </>
            )}
          </div>
          <div className="auth-footer bg-s color-white">
            <p className="xxs fw-300 text-center">
              {appCopyright(settings['SYSTEM_COMPANY_NAME'])}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

ForgetPasswordPage.defaultProps = {};
ForgetPasswordPage.propTypes = {
	setSidenavActiveIndex: PropTypes.func.isRequired,
	processForgetPassword: PropTypes.func.isRequired,
  processRead: PropTypes.func.isRequired,
};
export default connect(() => ({}), {
  setSidenavActiveIndex: setSidenavActiveIndex,
  processForgetPassword: userForgetPassword,
  processRead: processRead,
})(ForgetPasswordPage);