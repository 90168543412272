import { PartnerShopModel } from '.';
import { translate } from '../helpers/frontend';
import { unescape } from 'html-escaper';
import { SYSTEM_LANGUAGES } from '../actions/variables';

export class PartnerShopGroupModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.name = data.name? unescape(data.name): null;
    this.description = data.description? unescape(data.description): null;
    this.url = data.url? data.url: null;

    this.shops = data.shops?.length? data.shops.map(d => {
      return {
        shop: new PartnerShopModel(d.shop),
        order: d.order || 1,
        status: d.status ?? 0,
      };
    }): [];

    this.order = data.order || 1;
    this.status = data.status ?? 0;

    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'name', 'description' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
}
