import { UserModel, CustomerOrderModel, PartnerProductModel } from '.';
import { translate } from '../helpers/frontend';

/*
  status
    0 = Pending
    1 = Earn
    2 = Paid
*/

export class UserCommissionModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.user = new UserModel(data.user? data.user: {});
    this.order = new CustomerOrderModel(data.order? data.order: {});
    
    this.products = data.products && data.products.length
      ? data.products.map(d => new PartnerProductModel(d)): [];
    this.totalCommission = data.totalCommission? Number(data.totalCommission): 0;

    this.status = data.status? Number(data.status): 0;
    
    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
  }

  isValid() {
    return this._id? true: false;
  }

  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 2) return (<span className="ss-tag bg-info">{translate('Paid', langObj)}</span>);
      else if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Earn', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Pending', langObj)}</span>);
    }else{
      return (<></>);
    }
  }
}
