import { 
  CustomerTierModel, FileModel, 
  PartnerShopModel, UserModel,
  CustomerGroupModel
} from '.';
import { translate, formatDate, formatTime } from '../helpers/frontend';
import { unescape } from 'html-escaper';

/*
  type : String
    - C2U
    - Store
    
  status :
    -1 = ขอลบบัญชี
    0  = ปิดใช้งาน
    1  = เปิดใช้งาน

  gender :
    0 = ไม่ระบุ
    1 = ชาย
    2 = หญิง
    3 = อื่นๆ
*/

export class CustomerModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.type = data.type? unescape(data.type): 'C2U';
    this.channel = data.channel? unescape(data.channel): 'C2U';
    
    this.group = new CustomerGroupModel(data.group? data.group: {});
    this.tier = new CustomerTierModel(data.tier? data.tier: {});
    this.partnerShop = new PartnerShopModel(data.partnerShop? data.partnerShop: {});
    this.salesManager = new UserModel(data.salesManager? data.salesManager: {});

    this.code = data.code? data.code: null;
    this.erpCode = data.erpCode? data.erpCode: null;
    this.posCode = data.posCode? data.posCode: null;
    
    this.firstname = data.firstname? unescape(data.firstname): null;
    this.lastname = data.lastname? unescape(data.lastname): null;
    this.username = data.username? data.username: null;
    this.email = data.email? data.email: null;
    this.avatar = new FileModel(
      data.avatar? data.avatar: { path: '/assets/img/default/avatar.jpg' }
    );
    
    this.apple = data.apple? data.apple: null;
    this.facebook = data.facebook? data.facebook: null;
    this.google = data.google? data.google: null;
    this.line = data.line? data.line: null;

    this.points = data.points? Number(data.points): 0;

    this.telephone = data.telephone? data.telephone: null;

    this.fcmToken = data.fcmToken? data.fcmToken: null;
    this.isGuest = data.isGuest? Number(data.isGuest): 0;
    this.orderCount = data.orderCount? Number(data.orderCount): 0;

    this.pointExpirationDate = data.pointExpirationDate? data.pointExpirationDate: null;
    this.pointResetDate = data.pointResetDate? data.pointResetDate: null;

    // START: Detail
    this.nickname = data.nickname? data.nickname: null;
    this.socialId = data.socialId? data.socialId: null;
    this.birthDate = data.birthDate? data.birthDate: null;
    this.gender = data.gender? Number(data.gender): 0;
    
    this.isConsent = data.isConsent? Number(data.isConsent): 0;
    this.consentDate = data.consentDate? data.consentDate: null;
    this.isPrivacy = data.isPrivacy? Number(data.isPrivacy): 0;
    this.privacyDate = data.privacyDate? data.privacyDate: null;
    // END: Detail

    this.status = data.status? data.status: 0;

    this.orderCount = data.orderCount? Number(data.orderCount): 0;
    
    this.registeredDate = data.registeredDate? data.registeredDate: null;
    this.firstTimeOrderDate = data.firstTimeOrderDate? data.firstTimeOrderDate: null;
    
    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
  }

  isValid() {
    return this._id? true: false;
  }

  displayName(langObj={}) {
    if(this.firstname || this.lastname) return this.firstname+' '+this.lastname;
    else if(this.username) return this.username;
    else return 'Guest Account';
  }
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else if(this.status === -1) return (<span className="ss-tag bg-danger">{translate('Request to Delete Account', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }

  displayTelephone() {
    if(this.isValid()){
      return this.telephone? this.telephone.replace(/^\+66/, '0'): '-';
    }else{
      return '';
    }
  }

  displayGender(langObj={}) {
    if(this.isValid()){
      if(this.gender === 3) return translate('Other', langObj);
      else if(this.gender === 2) return translate('Female', langObj);
      else if(this.gender === 1) return translate('Male', langObj);
      else return translate('Not Specified', langObj);
    }else{
      return '';
    }
  }
  displayConsent(langObj={}) {
    if(this.isValid()){
      if(this.isConsent === 1){
        return `${translate('Accept Date', langObj)} ${formatDate(this.consentDate)} ${formatTime(this.consentDate)}`;
      }else{
        return translate('Do Not Accept', langObj);
      }
    }else{
      return '';
    }
  }
  displayPrivacy(langObj={}) {
    if(this.isValid()){
      if(this.isPrivacy === 1){
        return `${translate('Accept Date', langObj)} ${formatDate(this.consentDate)} ${formatTime(this.consentDate)}`;
      }else{
        return translate('Do Not Accept', langObj);
      }
    }else{
      return '';
    }
  }

  earnPoints(price) {
    if(this.isValid() && this.tier.isValid()){
      return price / this.tier.pointEarnRate;
    }else{
      return 0;
    }
  }

  isSignedIn() {
    return this._id && this.status === 1? true: false;
  }
  isRegistered() {
    return this._id? true: false;
  }
}
