import { useContext } from 'react';
import AuthContext from './context/AuthContext';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider, createTheme } from '@mui/material';

import Topnav from './layouts/Topnav';
import AlertPopup from './components/AlertPopup';

import { AuthPageLoading } from './views/auth';
import RouteFrontend from './routes/RouteFrontend';
import RouteAdmin from './routes/RouteAdmin';
import RoutePartner from './routes/RoutePartner';
import RouteSalesManager from './routes/RouteSalesManager';
import RouteExAdmin from './routes/RouteExAdmin';
import RouteSupplyChain from './routes/RouteSupplyChain';

function App() {
  const { status, user } = useContext(AuthContext);

  const muiTheme = createTheme({
    typography: {
      fontFamily: [ 'Sarabun' ].join(','),
    },
    palette: {
      info: { main: '#5a8dee', contrastText: '#ffffff' },
      success: { main: '#4ecc48', contrastText: '#ffffff' },
      warning: { main: '#ecb403', contrastText: '#ffffff' },
      error: { main: '#f5334f', contrastText: '#ffffff' },
    },
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <BrowserRouter>
        <div className="page-main">
            <Topnav />
            {status === 'unauthenticated'? (
              <RouteFrontend />
            ): status === 'authenticated'? (
              user.isAdmin()? <RouteAdmin />
                : user.isPartner()? <RoutePartner />
                : user.isSalesManager()? <RouteSalesManager />
                : user.isExAdmin()? <RouteExAdmin />
                : user.isSupplyChainAdmin()? <RouteSupplyChain />
                : (<></>)
            ): (<AuthPageLoading />)}
          </div>
        <AlertPopup />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;