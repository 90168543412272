import { CustomerCartModel, PaymentMethodModel } from '.';

/*
  channel : String
    - 2C2P
    - Stripe
*/

export class CustomerCart2C2PModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.type = data.type || '';
    this.channel = data.channel || '';
    
    this.orderId2C2P = data.orderId2C2P || null;
    this.paymentToken2C2P = data.paymentToken2C2P || null;
    
    this.cart = new CustomerCartModel(data.cart || {});
    this.paymentMethod = new PaymentMethodModel(data.paymentMethod || {});

    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
  }

  isValid() {
    return this._id && this.orderId2C2P && this.paymentToken2C2P? true: false;
  }
}
