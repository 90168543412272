import { CustomerGroupModel, FileModel } from '.';
import { unescape } from 'html-escaper';
import { translate } from '../helpers/frontend';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  type : Number
    1 = 2C2P - บัตรเครดิต/เดบิต
    2 = 2C2P - จ่ายโดย QR Code
    3 = 2C2P - ทรูมันนี่ วอลเล็ท
    7 = 2C2P - ผ่อนชำระมัดจำ
    8 = 2C2P - ผ่อนชำระ

    4 = Kerry - เก็บเงินปลายทาง

    5 = Stripe - บัตรเครดิต/เดบิต
    6 = Stripe - PromptPay

  paymentRules : Array
    paymentRule : Object
      minimumOrder : Double
      allowInstallmentPeriod : Number

  status : Number
    0 = Inactive
    1 = Active
*/

export class PaymentMethodModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.type = data.type? Number(data.type): null;
    
    this.name = data.name? unescape(data.name): null;
    this.icon = new FileModel(data.icon? data.icon: {});

    this.paymentRules = data.paymentRules && data.paymentRules.length? data.paymentRules: [];

    this.order = data.order? Number(data.order): null;
    this.status = data.status? Number(data.status): 0;

    // Customer Checkout
    this.payNow = data.payNow? Number(data.payNow): 0;
    this.payLater = data.payLater? Number(data.payLater): 0;
    this.diffInstallment = data.diffInstallment? Number(data.diffInstallment): 0;

    this.customDownPayment = data.customDownPayment || data.customDownPayment===0 
      ? Number(data.customDownPayment): null;
    this.payNowDefault = data.payNowDefault || data.payNowDefault===0 
      ? Number(data.payNowDefault): null;
    this.payLaterDefault = data.payLaterDefault || data.payLaterDefault===0 
      ? Number(data.payLaterDefault): null;

    this.forCustomerGroups = data.forCustomerGroups && data.forCustomerGroups.length
          ? data.forCustomerGroups.map(d => new CustomerGroupModel(d)): [];

    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'name' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  initPaymentRule(type=0) {
    if([7, 8].indexOf(type) > -1){
      return { minimumOrder: null, allowInstallmentPeriod: 0 };
    }else return null;
  }

  isValid() {
    return this._id? true: false;
  }

  hasDownPayment() {
    return this.isValid() && this.payLater;
  }
  hasCustomDownPayment() {
    return this.hasDownPayment() && (this.customDownPayment || this.customDownPayment===0) && (
      (this.payNowDefault || this.payNowDefault===0) 
      || (this.payLaterDefault || this.payLaterDefault===0) 
    );
  }
  
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
  existInGroup(groupId) {
    if(groupId && this.forCustomerGroups && this.forCustomerGroups.length) {
      const containGroup = this.forCustomerGroups.find(d => d._id === groupId) !== undefined;
      if(containGroup) return true;
      else return false;
    } else return false;
  }
}
