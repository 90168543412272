import {
  CUSTOMERS, CUSTOMER_TIERS, CUSTOMER_GROUPS, CUSTOMER_SHIPPING_ADDRESSES, 
  CUSTOMER_BILLING_ADDRESSES, CUSTOMER_PRODUCT_COUPONS, CUSTOMER_SHIPPING_COUPONS,
  CUSTOMER_POINTS, CUSTOMER_ORDERS, CUSTOMER_PUSH_NOTIS,
  CUSTOMER_SUBSCRIPTIONS, CUSTOMER_SUBSCRIPTION_PLANS,
  CUSTOMER_2C2P_CARTS, CUSTOMER_2C2P_SUBSCRIPTION_CARTS,
} from '../actions/types';

const initialState = {
  list: [],
  tiers: [],
  groups: [],
  shippingAddresses: [],
  billingAddresses: [],
  productCoupons: [],
  shippingCoupons: [],
  points: [],
  orders: [],
  pushNotis: [],
  subscriptions: [],
  subscriptionPlans: [],
  tempCarts: [],
  tempCarts2: [],
};

const customerReducer = (state = initialState, action) => {
  switch(action.type) {
    
    case CUSTOMERS:
      return { ...state, list: action.payload };
    
    case CUSTOMER_TIERS:
      return { ...state, tiers: action.payload };

    case CUSTOMER_GROUPS:
      return { ...state, groups: action.payload };
    
    case CUSTOMER_SHIPPING_ADDRESSES:
      return { ...state, shippingAddresses: action.payload };
    case CUSTOMER_BILLING_ADDRESSES:
      return { ...state, billingAddresses: action.payload };
    
    case CUSTOMER_PRODUCT_COUPONS:
      return { ...state, productCoupons: action.payload };
    case CUSTOMER_SHIPPING_COUPONS:
      return { ...state, shippingCoupons: action.payload };
    
    case CUSTOMER_POINTS:
      return { ...state, points: action.payload };
    
    case CUSTOMER_ORDERS:
      return { ...state, orders: action.payload };
    
    case CUSTOMER_PUSH_NOTIS:
      return { ...state, pushNotis: action.payload };

    case CUSTOMER_SUBSCRIPTIONS:
      return { ...state, subscriptions: action.payload };
    case CUSTOMER_SUBSCRIPTION_PLANS:
      return { ...state, subscriptionPlans: action.payload };
      
    case CUSTOMER_2C2P_CARTS:
      return { ...state, tempCarts: action.payload };
    case CUSTOMER_2C2P_SUBSCRIPTION_CARTS:
      return { ...state, tempCarts2: action.payload };
      
    default:
      return state;
  }
};

export default customerReducer;