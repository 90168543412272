import PropTypes from 'prop-types';
import { useState, useEffect, useContext, useMemo } from 'react';
import AuthContext from '../context/AuthContext';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { connect, useDispatch } from 'react-redux';
import { alertChange } from '../actions/alert.actions';
import { sendOTP, verifyOTP } from '../actions/user.actions';

import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import { getFirebaseAuth } from '../helpers/firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

function TelephoneInput(props) {
  const { settings } = useContext(AuthContext);
  const [firebaseAuth, setFirebaseAuth] = useState(null);
  const dispatch = useDispatch();

  const countryCodes = ['TH', 'US', 'CN'];

  const [ready, setReady] = useState(true);
	const [value, setValue] = useState(props.value? props.value: '');

  const [telephone, setTelephone] = useState(props.value? props.value: '');
  const isPossiblePhone = useMemo(() => isPossiblePhoneNumber(telephone || ''), [telephone]);
  
  const [state, setState] = useState(0);
  const [codeOTP, setCodeOTP] = useState('');
  const [objectOTP, setObjectOTP] = useState(null);
  const [requestOTP, setRequestOTP] = useState(null);
  const [refCodeOTP, setRefCodeOTP] = useState(null);

  const [isModalActive, setIsModalActive] = useState(false);
  const onModalToggle = (e=null, data=false) => {
    e?.preventDefault();
    setReady(true);
    if(!data){
      setTelephone(value);
      setState(0);
      setCodeOTP('');
      setObjectOTP(null);
      setIsModalActive(!isModalActive);
    }else if(state === 0){
      setTelephone(value);
      setState(0);
      setCodeOTP('');
      setObjectOTP(null);
      setIsModalActive(true);
    }
  };

  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const setupRecaptcha = async () => {
    if(firebaseAuth){
      if(recaptchaVerifier){
        recaptchaVerifier.clear();
        setRecaptchaVerifier(null);
      }

      const verifier = new RecaptchaVerifier('recaptcha-container', {}, firebaseAuth);
      verifier.render();
      setRecaptchaVerifier(verifier);

      return await signInWithPhoneNumber(firebaseAuth, telephone, verifier)
        .then(res => {
          setState(2);
          setObjectOTP(res);
        }).catch((err) => {
          props.alertChange('Warning', err || 'ไม่สามารถส่ง OTP ได้เนื่องจากเบอร์โทรศัพท์ไม่ถูกต้อง');
          onModalToggle();
        });
    }
  };
  const onSubmit = async (e) => {
    e?.preventDefault();
    if(ready){
      setReady(false);
      if(value !== telephone && isPossiblePhone){
        if(settings['ENABLED_CUSTOMER_SIGNUP_OTP'] === '1'){
          try {
            setState(1);
            await setupRecaptcha();
          } catch(err) {
            console.log(err);
            setState(0);
          }
        }else if(settings['ENABLED_CUSTOMER_SIGNUP_OTP']){
          try {
            const res = await sendOTP({ telephoneCode: '+66', telephone: telephone }, true)(dispatch);
            setRequestOTP(res?.requestId);
            setRefCodeOTP(res?.refCode);
            setState(2);
          } catch {
            setState(0);
          }
        }
      }
      setReady(true);
    }
  };

  const onSubmitOTP = async (e) => {
    e?.preventDefault();
    if(ready){
      if(!codeOTP) return props.alertChange('Warning', 'โปรดระบุหมายเลข OTP');
      setReady(false);
      if(settings['ENABLED_CUSTOMER_SIGNUP_OTP'] === '1'){
        if(!objectOTP){
          setReady(true);
          return props.alertChange('Warning', 'การดำเนินการ OTP ไม่ถูกต้อง');
        }
        try {
          const res = await objectOTP.confirm(codeOTP);
          if(res){
            props.onChange(telephone);
            onModalToggle();
            setReady(true);
            return props.alertChange('Info', 'ยืนยันหมายเลข OTP สำเร็จ');
          }
        } catch {}
      }else if(settings['ENABLED_CUSTOMER_SIGNUP_OTP']){
        try{
          const res = await verifyOTP({ requestId: requestOTP, refCode: refCodeOTP, code: codeOTP }, true)(dispatch);
          if(res){
            props.onChange(telephone);
            onModalToggle();
            setReady(true);
            return;
          }
        } catch {}
      }
      setReady(true);
      return;
    }
  };

  /* eslint-disable */
	useEffect(() => { setValue(props.value || ''); }, [props.value]);
  useEffect(() => { setFirebaseAuth(() => getFirebaseAuth(settings, null)); }, [settings]);
  /* eslint-enable */

  return !firebaseAuth && ['2'].indexOf(settings['ENABLED_CUSTOMER_SIGNUP_OTP']) < 0? (<></>): (<>
    <div onClick={onModalToggle} className={`input-phone ${props.disabled? 'disabled': ''}`}>
      <input type="text" disabled={props.disabled} required={props.required} 
        defaultValue={value || ''} onFocus={e => onModalToggle(e, true)} 
      />
    </div>
    {settings['ENABLED_CUSTOMER_SIGNUP_OTP']? (
      <Dialog open={isModalActive? true: false} onClose={() => onModalToggle()} 
        fullWidth={true} maxWidth="xs" scroll="paper" 
      >
        {state < 2? (<>
          <DialogTitle component="div" className="p-0">
            <div className="dialog-header">
              <h6 className="fw-600 lh-xs">เบอร์โทรศัพท์</h6>
              <div className="btn-close" onClick={onModalToggle}>
                <div className="hamburger active">
                  <div></div><div></div><div></div>
                </div>
              </div>
            </div>
          </DialogTitle>
          <DialogContent dividers={true}>
            <PhoneInput 
              disabled={state !== 0} 
              defaultCountry="TH" 
              countries={countryCodes} 
              placeholder="ระบุเบอร์โทรศัพท์" 
              value={telephone} 
              onChange={setTelephone} 
            />
            <div className={`mt-4 ${state !== 1? 'd-none': ''}`}>
              <div id="recaptcha-container"></div>
            </div>
          </DialogContent>
          {state === 0? (
            <DialogActions>
              <div className="btns mt-0">
                <button onClick={onSubmit} type="button" 
                  className={`btn btn-action ${!ready || value === telephone || !isPossiblePhone
                    ? 'btn-default pe-none op-70': 'btn-p'}`} 
                >
                  ยืนยันเบอร์โทรศัพท์
                </button>
                <button onClick={onModalToggle} type="button" className="btn btn-action btn-default">
                  ปิด
                </button>
              </div>
            </DialogActions>
          ): (<></>)}
        </>): (<>
          <DialogTitle component="div" className="p-0">
            <div className="dialog-header">
              <h6 className="fw-600 lh-xs">ยืนยันเลข OTP</h6>
              <div className="btn-close" onClick={onModalToggle}>
                <div className="hamburger active">
                  <div></div><div></div><div></div>
                </div>
              </div>
            </div>
          </DialogTitle>
          <DialogContent dividers={true}>
            <p className="fw-500">กรุณายืนยันเลข OTP ที่ส่งไปยังหมายเลขโทรศัพท์</p>
            <p className="lg fw-600">{telephone? telephone: ''}</p>
            <div className="form-control mt-2">
              <input type="text" required={true} 
                value={codeOTP? codeOTP: ''} 
                onChange={e => setCodeOTP(e.target.value)} 
              />
              {/* <p className="xs fw-400 mt-2">
                <span onClick={onResendOTP} className="op-80 c-pointer h-color-p">
                  <u>ส่งเลข OTP อีกครั้ง</u>
                </span>
              </p> */}
            </div>
          </DialogContent>
          <DialogActions>
            <div className="btns mt-0">
              <button onClick={onSubmitOTP} type="button" 
                className={`btn btn-action ${!ready? 'btn-default pe-none op-70': 'btn-p'}`} 
              >
                ยืนยันเลข OTP
              </button>
              <button onClick={onModalToggle} type="button" 
                className={`btn btn-action btn-default ${!ready? 'pe-none op-40': ''}`} 
              >
                ปิด
              </button>
            </div>
          </DialogActions>
        </>)}
      </Dialog>
    ): (<></>)}
  </>);
}

TelephoneInput.defaultProps = {
  disabled: false,
  required: false,
  value: '',
  onChange: () => {},
};
TelephoneInput.propTypes = {
  alertChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
export default connect(() => ({}), {
  alertChange: alertChange,
})(TelephoneInput);