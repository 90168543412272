import { FileModel } from '.';
import { unescape } from 'html-escaper';

/*
  type : String
    - Backend
    - Frontend

  status :
    0 = Inactive
    1 = Active
*/

export class AppThemeModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.type = data.type? data.type: 'Backend';

    this.value = data.value? data.value: false;
    this.name = data.name? unescape(data.name): '';
    this.description = data.description? unescape(data.description): '';

    this.image = new FileModel(data.image? data.image: {});

    this.colors = data.colors && data.colors.length 
      ? data.colors.map(d => {
        return {
          value: d.value? d.value: false,
          name: d.name? unescape(d.name): '',
          description: d.description? unescape(d.description): '',

          isDark: d.isDark? Number(d.isDark): 0,
          image: new FileModel(d.image? d.image: {}),
          
          isDefault: d.isDefault? Number(d.isDefault): 0,
          order: d.order? Number(d.order): 1,
          status: d.status? Number(d.status): 0,
        };
      }): [];
    
    this.isSelected = data.isSelected? Number(data.isSelected): 0;
    this.isDefault = data.isDefault? Number(data.isDefault): 0;
    this.order = data.order? Number(data.order): 1;
    this.status = data.status? Number(data.status): 0;
  }

  isValid() {
    return this._id? true: false;
  }
}
