import {
  FileModel, PartnerShopModel, PartnerProductAttributeModel,
  PartnerProductCategoryModel, PartnerProductSubCategoryModel,
  PartnerProductSubSubCategoryModel, PartnerProductBrandModel,
} from '.';
import { translate } from '../helpers/frontend';
import { unescape } from 'html-escaper';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  type
    1 = Individual
    2 = Set

  status
    0 = Inactive
    1 = Coming soon
    2 = Active
    3 = Clearance
    4 = New
    5 = Featured
    6 = On Sales

  selectedUnit : Object
    _id
    sku
    barcode
    unit
    unitLF
    unitDescription
    convertedQuantity
    price
    memberPrice
    isPackagingReady
    weight
    width
    length
    height
    status

  productSet : Array
    product : Object
    productId : String
    productUnit : Object
    productUnitId : String
    quantity : Number
    totalSalesPriceInVAT : Number

  directPurchase
    1 = จำหน่ายออนไลน์
    0 = ติดต่อผ่านเซลล์
*/

export class PartnerProductModel {
  constructor(data={}) {
    this._id = data?._id || null;
    this.type = data.type? Number(data.type): 1;
    
    this.sku = data.sku? data.sku: null;
    this.barcode = data.barcode? data.barcode: null;
    this.url = data.url? data.url: null;

    this.name = data.name? unescape(data.name): null;
    this.shortDescription = data.shortDescription? unescape(data.shortDescription): null;
    this.description = data.description? unescape(data.description): null;
    
    this.keywords = data.keywords? unescape(data.keywords): null;
    this.specification = data.specification? unescape(data.specification): null;
    this.youtubeVideoId = data.youtubeVideoId? data.youtubeVideoId: null;

    this.category = new PartnerProductCategoryModel(data.category? data.category: {});
    this.subCategory = new PartnerProductSubCategoryModel(data.subCategory? data.subCategory: {});
    this.subSubCategory = new PartnerProductSubSubCategoryModel(data.subSubCategory? data.subSubCategory: {});
    this.brand = new PartnerProductBrandModel(data.brand? data.brand: {});

    this.unit = data.unit? unescape(data.unit): null;
    this.unitDescription = data.unitDescription? unescape(data.unitDescription): null;
    this.unitVariantName = data.unitVariantName? unescape(data.unitVariantName): null;
    
    this.unitLF = data.unitLF? unescape(data.unitLF): null;
    this.unitSAP = data.unitSAP? unescape(data.unitSAP): null;
    this.unitEnpro = data.unitEnpro? unescape(data.unitEnpro): null;

    this.price = data.price? Number(data.price): null;
    this.priceInVAT = data.priceInVAT? Number(data.priceInVAT): null;
    this.memberPrice = data.memberPrice? Number(data.memberPrice): null;
    this.memberPriceInVAT = data.memberPriceInVAT? Number(data.memberPriceInVAT): null;
    
    this.image = new FileModel(data.image? data.image: {});
    this.gallery = data.gallery && data.gallery.length
      ? data.gallery.map(d => new FileModel(d)): [];

    this.isPackagingReady = data.isPackagingReady? Number(data.isPackagingReady): 0;
    this.preparingDays = data.preparingDays? Number(data.preparingDays): 0;

    this.weight = data.weight? Number(data.weight): null;
    this.logisticWeight = data.logisticWeight || data.logisticWeight===0 
      ? Number(data.logisticWeight): null;
    this.width = data.width? Number(data.width): null;
    this.length = data.length? Number(data.length): null;
    this.height = data.height? Number(data.height): null;

    this.syncStockSAP = data.syncStockSAP? Number(data.syncStockSAP): 0;
    
    this.salesCount = data.salesCount? data.salesCount: 0;
    this.order = data.order || data.order===0? Number(data.order): null;
    this.status = data.status? Number(data.status): 0;

    
    // Product Set
    this.partnerShop = new PartnerShopModel(data.partnerShop? data.partnerShop: {});
    this.productSet = data.productSet && data.productSet.length? data.productSet: [];
    this.productSetCategories = data.productSetCategories && data.productSetCategories.length 
      ? data.productSetCategories.map(d => new PartnerProductCategoryModel(d)): [];
    this.productSetPriceInVAT = Number(data?.productSetPriceInVAT ?? 0);

    // Ecommerce
    this.stock = data.stock? Math.floor(Number(data.stock)): 0;
    this.stockCenter = data.stockCenter? Math.floor(Number(data.stockCenter)): 0;

    this.inCart = data.inCart || data.inCart===0? Number(data.inCart): null;
    this.selectedUnit = data.selectedUnit? data.selectedUnit: null;
    this.attributes = data.attributes && data.attributes.length 
      ? data.attributes.map(d => new PartnerProductAttributeModel(d)): [];

    this.discountPrice = data.discountPrice? Number(data.discountPrice): null;
    this.discountPriceInVAT = data.discountPriceInVAT? Number(data.discountPriceInVAT): null;
    this.discountStartAt = data.discountStartAt? data.discountStartAt: null;
    this.discountEndAt = data.discountEndAt? data.discountEndAt: null;

    this.salesPrice = data.salesPrice || data.salesPrice===0? Number(data.salesPrice): null;
    this.salesPriceInVAT = data.salesPriceInVAT || data.salesPriceInVAT===0? Number(data.salesPriceInVAT): null;
    this.totaSalesPrice = data.totaSalesPrice || data.totaSalesPrice===0? Number(data.totaSalesPrice): null;
    this.totaSalesPriceInVAT = data.totaSalesPriceInVAT || data.totaSalesPriceInVAT===0? Number(data.totaSalesPriceInVAT): null;
    
    this.vat = data.vat || data.vat===0? Number(data.vat): null;
    this.totalVAT = data.totalVAT || data.totalVAT===0? Number(data.totalVAT): null;

    this.discount = data.discount? Number(data.discount): 0;
    this.discountInVAT = data.discountInVAT? Number(data.discountInVAT): 0;
    this.totalDiscount = data.totalDiscount? Number(data.totalDiscount): 0;
    this.totalDiscountInVAT = data.totalDiscountInVAT? Number(data.totalDiscountInVAT): 0;

    this.acceptDownPayment = data.acceptDownPayment? Number(data.acceptDownPayment): 0;
    this.downPayment = data.downPayment? Number(data.downPayment): 0;
    this.downPaymentInVAT = data.downPaymentInVAT? Number(data.downPaymentInVAT): 0;
    this.totalDownPayment = data.totalDownPayment? Number(data.totalDownPayment): 0;
    this.totalDownPaymentInVAT = data.totalDownPaymentInVAT? Number(data.totalDownPaymentInVAT): 0;
    this.missingPayment = data.missingPayment? Number(data.missingPayment): 0;
    this.missingPaymentInVAT = data.missingPaymentInVAT? Number(data.missingPaymentInVAT): 0;
    
    this.pointMultiplier = data.pointMultiplier? Number(data.pointMultiplier): 0;
    this.pointEarn = data.pointEarn? Number(data.pointEarn): 0;
    
    this.partnerCommission = data.partnerCommission? Number(data.partnerCommission): 0;
    this.totalCommission = data.totalCommission? Number(data.totalCommission): 0;

    // Subscription
    this.subscriptionInitial = data.subscriptionInitial? 1: 0;
    this.addPriceInVAT = data.addPriceInVAT? Number(data.addPriceInVAT): 0;

    // Supplier
    this.shipBySupplier = data.shipBySupplier? Number(data.shipBySupplier): 0;

    // Sales Channel
    this.directPurchase = data.directPurchase || data.directPurchase===0? Number(data.directPurchase): 1;
    this.contactLine = data.contactLine? data.contactLine: null;
    this.contactFacebook = data.contactFacebook? data.contactFacebook: null;
    this.contactGoogle = data.contactGoogle? data.contactGoogle: null;

    // Event
    this.eventId = data.eventId? data.eventId: '';
    this.eventName = data.eventName? unescape(data.eventName): '';

    this.rating = data.rating? Number(data.rating): null;
    this.ratingCount = data.ratingCount? Number(data.ratingCount): null;

    this.tags = data.tags && data.tags.length 
      ? data.tags.map(d => `${d}`): [];
    this.tagsStr = this.tags.join(', ');

    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [
      'name', 'shortDescription', 'description', 'specification',
      'unit', 'unitDescription', 'unitVariantName',
    ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }
  isValidDownPayment() {
    return this.isValid() && this.status > 1 
      && this.acceptDownPayment && this.downPaymentInVAT;
  }

  displayDimension() {
    if(this.isValid()){
      return (<>{this.width} x {this.length} x {this.height} CM<sup>3</sup></>);
    }else{
      return (<></>);
    }
  }
  displayAcceptDownPayment(langObj={}) {
    if(this.isValid()){
      if(this.acceptDownPayment === 1) return (<span className="ss-tag bg-success">{translate('Accept Down Payment', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Not Accept Down Payment', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Not Accept Down Payment', langObj)}</span>);
    }
  }
  displayDirectPurchase(langObj={}) {
    if(this.isValid()){
      if(this.directPurchase === 0) return translate('Contact Sales', langObj);
      else return translate('Available Online', langObj);
    }else{
      return '';
    }
  }
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 6) return (<span className="ss-tag bg-p">{translate('text_product_status_6', langObj)}</span>);
      else if(this.status === 5) return (<span className="ss-tag bg-info">{translate('text_product_status_5', langObj)}</span>);
      else if(this.status === 4) return (<span className="ss-tag bg-p">{translate('text_product_status_4', langObj)}</span>);
      else if(this.status === 3) return (<span className="ss-tag bg-gray">{translate('text_product_status_3', langObj)}</span>);
      else if(this.status === 2) return (<span className="ss-tag bg-success">{translate('text_product_status_2', langObj)}</span>);
      else if(this.status === 1) return (<span className="ss-tag bg-gray">{translate('text_product_status_1', langObj)}</span>);
      else if(this.status === -2) return (<span className="ss-tag bg-danger">{translate('Giveaway', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('text_product_status_0', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('text_product_status_0', langObj)}</span>);
    }
  }
}
