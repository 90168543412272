import { PartnerShopModel, PartnerShippingModel, ShippingDurationModel } from '.';
import { translate } from '../helpers/frontend';

export class PartnerShopShippingModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.shop = new PartnerShopModel(data.shop? data.shop: {});
    this.shipping = new PartnerShippingModel(data.shipping? data.shipping: {});
    
    this.durations = data.durations && data.durations.length
      ? data.durations.map(d => new ShippingDurationModel(d)): [];
    this.status = data.status? Number(data.status): 0;
  }

  isValid() {
    return this._id? true: false;
  }

  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
}
