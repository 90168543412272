import { CustomerModel, PartnerProductCouponModel, CustomerOrderModel } from '.';
import { translate } from '../helpers/frontend';
/*
  redeemStatus
    0 = Recieved from system
    1 = Redeem by points
    3 = Recieved from order
*/

export class PartnerProductCouponLogModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.customer = new CustomerModel(data.customer? data.customer: {});
    this.coupon = new PartnerProductCouponModel(data.coupon? data.coupon: {});
    this.order = new CustomerOrderModel(data.order? data.order: {});

    this.redeemStatus = data.redeemStatus? Number(data.redeemStatus): 0;
    this.points = data.points? Number(data.points): 0;

    this.receivedByOrder = new CustomerOrderModel(data.receivedByOrder? data.receivedByOrder: {});
    this.expiredAt = data.expiredAt? data.expiredAt: null;
    
    this.isUsed = data.isUsed? Number(data.isUsed): 0;
    this.usedAt = data.usedAt? data.usedAt: null;

    this.status = data.status? Number(data.status): 0;
    
    this.createdAt = data.createdAt || null;
  }

  isValid() {
    return this._id? true: false;
  }

  displayRedeemStatus(langObj={}) {
    if(this.isValid()){
      if(this.redeemStatus === 0) return (<span className="ss-tag bg-success">{translate('ได้รับจากระบบ', langObj)}</span>);
      else if(this.redeemStatus === 1) return (<span className="ss-tag bg-info">{translate('แลกด้วย Points', langObj)}</span>);
      else if(this.redeemStatus === 3) return (<span className="ss-tag bg-warning">{translate('Received from order', langObj)}</span>);
      else return (<></>);
    }else{
      return (<></>);
    }
  }
  displayIsUsed(langObj={}) {
    if(this.isValid()){
      if(this.isUsed === 0) return (<span className="ss-tag bg-success">{translate('ยังไม่ได้ใช้', langObj)}</span>);
      else if(this.isUsed === 1) return (<span className="ss-tag bg-info">{translate('ใช้งานแล้ว', langObj)}</span>);
      else return (<></>);
    }else{
      return (<></>);
    }
  }
}
