import { SellerShopModel, CustomerModel, FileModel } from '.';
import { translate } from '../helpers/frontend';
import { unescape } from 'html-escaper';

export class SellerShopRatingModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.shop = new SellerShopModel(data.shop? data.shop: {});
    this.customer = new CustomerModel(data.customer? data.customer: {});
    
    this.rating = data.rating? Number(data.rating): null;
    this.comment = data.comment? unescape(data.comment): null;
    
    this.images = data.images?.length? data.images.map(d => new FileModel(d)): [];

    this.isShown = data.isShown? Number(data.isShown): 0;
    
    this.createdAt = data.createdAt? data.createdAt: 0;
    this.updatedAt = data.updatedAt? data.updatedAt: 0;
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.isShown === 1) return (<span className="ss-tag bg-success">{translate('Show', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Hide', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Hide', langObj)}</span>);
    }
  }
}
