import { PartnerProductModel } from '.';
import { unescape } from 'html-escaper';
import { translate } from '../helpers/frontend';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  status
    0 = Inactive
    2 = Active
    3 = Clearance
    6 = On Sales

  directPurchase
    1 = จำหน่ายออนไลน์
    0 = ติดต่อผ่านเซลล์
*/

export class PartnerProductUnitModel {
  constructor(data={}) {
    this._id = data?._id || null;
    this.product = new PartnerProductModel(data.product? data.product: {});
    
    this.sku = data.sku? data.sku: null;
    this.barcode = data.barcode? data.barcode: null;

    this.unit = data.unit? unescape(data.unit): null;
    this.unitDescription = data.unitDescription? unescape(data.unitDescription): null;
    
    this.unitLF = data.unitLF? unescape(data.unitLF): null;
    this.unitSAP = data.unitSAP? unescape(data.unitSAP): null;
    this.unitEnpro = data.unitEnpro? unescape(data.unitEnpro): null;
    
    this.convertedQuantity = data.convertedQuantity || data.convertedQuantity===0
      ? Number(data.convertedQuantity): null;

    this.price = data.price? Number(data.price): null;
    this.priceInVAT = data.priceInVAT? Number(data.priceInVAT): null;
    this.memberPrice = data.memberPrice? Number(data.memberPrice): null;
    this.memberPriceInVAT = data.memberPriceInVAT? Number(data.memberPriceInVAT): null;

    this.isPackagingReady = data.isPackagingReady? data.isPackagingReady: 0;

    this.weight = data.weight? Number(data.weight): null;
    this.logisticWeight = data.logisticWeight || data.logisticWeight===0 
      ? Number(data.logisticWeight): null;
    this.width = data.width? Number(data.width): null;
    this.length = data.length? Number(data.length): null;
    this.height = data.height? Number(data.height): null;
    
    this.status = data.status? data.status: 0;
    
    this.stock = data.stock? Math.floor(Number(data.stock)): 0;
    
    this.discountPrice = data.discountPrice? Number(data.discountPrice): null;
    this.discountPriceInVAT = data.discountPriceInVAT? Number(data.discountPriceInVAT): null;
    this.discountStartAt = data.discountStartAt? data.discountStartAt: null;
    this.discountEndAt = data.discountEndAt? data.discountEndAt: null;

    this.acceptDownPayment = data.acceptDownPayment? Number(data.acceptDownPayment): 0;
    this.downPayment = data.downPayment? Number(data.downPayment): 0;
    this.downPaymentInVAT = data.downPaymentInVAT? Number(data.downPaymentInVAT): 0;

    // Supplier
    this.shipBySupplier = data.shipBySupplier? Number(data.shipBySupplier): 0;

    // Sales Channel
    this.directPurchase = data.directPurchase || data.directPurchase===0? Number(data.directPurchase): 1;
    this.contactLine = data.contactLine? data.contactLine: null;
    this.contactFacebook = data.contactFacebook? data.contactFacebook: null;
    this.contactGoogle = data.contactGoogle? data.contactGoogle: null;

    // Localization
    this.localization(data);    
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'unit', 'unitDescription' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }
  isValidDownPayment() {
    return this.isValid() && this.status > 1 
      && this.acceptDownPayment && this.downPaymentInVAT;
  }

  displayDimension() {
    if(this.isValid()){
      return (<>{this.width} x {this.length} x {this.height} CM<sup>3</sup></>);
    }else{
      return (<></>);
    }
  }
  displayConvertedQuantity() {
    if(this.isValid() && this.product.isValid()){
      return `${this.convertedQuantity} ${this.product.unit}`;
    }else{
      return '-';
    }
  }
  displayAcceptDownPayment() {
    if(this.isValid()){
      if(this.acceptDownPayment === 1) return (<span className="ss-tag bg-success">รับมัดจำ</span>);
      else return (<span className="ss-tag bg-warning">ไม่รับมัดจำ</span>);
    }else{
      return (<span className="ss-tag bg-warning">ไม่รับมัดจำ</span>);
    }
  }
  displayDirectPurchase() {
    if(this.isValid()){
      if(this.directPurchase === 0) return 'ติดต่อผ่านเซลล์';
      else return 'จำหน่ายออนไลน์';
    }else{
      return '';
    }
  }
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 6) return (<span className="ss-tag bg-p">{translate('text_product_status_6', langObj)}</span>);
      else if(this.status === 5) return (<span className="ss-tag bg-info">{translate('text_product_status_5', langObj)}</span>);
      else if(this.status === 4) return (<span className="ss-tag bg-p">{translate('text_product_status_4', langObj)}</span>);
      else if(this.status === 3) return (<span className="ss-tag bg-gray">{translate('text_product_status_3', langObj)}</span>);
      else if(this.status === 2) return (<span className="ss-tag bg-success">{translate('text_product_status_2', langObj)}</span>);
      else if(this.status === 1) return (<span className="ss-tag bg-gray">{translate('text_product_status_1', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('text_product_status_0', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('text_product_status_0', langObj)}</span>);
    }
  }
}
