import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { translate } from '../helpers/frontend';

import { connect } from 'react-redux';

function PasswordValidator(props) {
  const { langObj } = useContext(AuthContext);

  const [valid1, setValid1] = useState(false);
  const [valid2, setValid2] = useState(false);
  const [valid3, setValid3] = useState(false);
  
  /* eslint-disable */
	useEffect(() => {
    setValid1(props.password.length >= 6);
    setValid2((/^(?=.*[0-9]).*$/).test(props.password));
    setValid3((/^(?=.*[!@#$&*?%^()]).*$/).test(props.password));
  }, [props.password]);
  /* eslint-enable */

  return (
    props.process !== 'view'? (
      <div>
        <p className="xs fw-500">{translate('text_suggest_password_1', langObj)}</p>
        <ul>
          <li className={`xs fw-500 tt-uppercase ${valid1? 'color-p': 'op-60'}`}>
            {translate('At least', langObj)} 6 {translate('characters', langObj)}
          </li>
          <li className={`xs fw-500 tt-uppercase ${valid2? 'color-p': 'op-60'}`}>
            {translate('Number', langObj)} 0-9 
            {' '}{translate('At least', langObj)} 1 {translate('characters', langObj)}
          </li>
          <li className={`xs fw-500 tt-uppercase ${valid3? 'color-p': 'op-60'}`}>
            {translate('Symbol', langObj)} * ! @ # $ & ? % ^ ( ) 
            {' '}{translate('At least', langObj)} 1 {translate('characters', langObj)}
          </li>
        </ul>
      </div>
    ): (<></>)
  );
}

PasswordValidator.defaultProps = {
  process: 'create',
  password: '',
};
PasswordValidator.propTypes = {
	process: PropTypes.string,
	password: PropTypes.string,
};
export default connect(() => ({}), {})(PasswordValidator);