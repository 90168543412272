import { unescape } from 'html-escaper';
import { translate } from '../helpers/frontend';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  order
    1 = ที่ต้องจัดส่ง
    2 = กำลังจัดส่ง
    3 = ส่งสินค้าสำเร็จ
    4 = ยกเลิก/คืนสินค้า
    
  status
    0 = ไม่แสดง
    1 = แสดง
*/

export class ShippingStatusModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.name = data.name? unescape(data.name): null;

    this.order = data.order? Number(data.order): null;
    this.status = data.status? Number(data.status): 0;

    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'name' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Show', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Hide', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Hide', langObj)}</span>);
    }
  }
}
