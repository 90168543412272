import { PartnerShopModel, WarehouseModel } from '.';
import { translate } from '../helpers/frontend';
import dataLogisticTypes from '../data/logistic-types.json';

export class LogisticRouteModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.logisticTypes = data.logisticTypes && data.logisticTypes.length 
      ? data.logisticTypes: [ this.logisticType ];
    
    this.warehouse = new WarehouseModel(data.warehouse? data.warehouse: {});
    this.partnerShop = new PartnerShopModel(data.partnerShop? data.partnerShop: {});
    
    this.toWarehouse = new WarehouseModel(data.toWarehouse? data.toWarehouse: {});
    this.toPartnerShop = new PartnerShopModel(data.toPartnerShop? data.toPartnerShop: {});
    
    this.status = data.status? Number(data.status): 0;
  }

  isValid() {
    return this._id && (this.partnerShop.isValid() || this.warehouse.isValid()) 
      && (this.toPartnerShop.isValid() || this.toWarehouse.isValid());
  }

  isFromWarehouse() {
    if(!this.isValid()) return false;
    else return this.warehouse.isValid();
  }
  isToWarehouse() {
    if(!this.isValid()) return false;
    else return this.toWarehouse.isValid();
  }

  fromId() {
    if(!this.isValid()) return '';
    else if(this.warehouse.isValid()) return this.warehouse._id;
    else return this.partnerShop._id;
  }
  fromName() {
    if(!this.isValid()) return '';
    else if(this.warehouse.isValid()) return this.warehouse.name;
    else return this.partnerShop.name;
  }
  fromLatLng() {
    if(!this.isValid()) return { lat: null, lng: null };
    else if(this.partnerShop.isValid()){
      return { lat: this.partnerShop.address.lat, lng: this.partnerShop.address.lng };
    }else{
      return { lat: this.warehouse.address.lat, lng: this.warehouse.address.lng };
    }
  }

  toId() {
    if(!this.isValid()) return '';
    else if(this.toWarehouse.isValid()) return this.toWarehouse._id;
    else return this.toPartnerShop._id;
  }
  toName() {
    if(!this.isValid()) return '';
    else if(this.toWarehouse.isValid()) return this.toWarehouse.name;
    else return this.toPartnerShop.name;
  }
  toLatLng() {
    if(!this.isValid()) return { lat: null, lng: null };
    else if(this.toPartnerShop.isValid()){
      return { lat: this.toPartnerShop.address.lat, lng: this.toPartnerShop.address.lng };
    }else{
      return { lat: this.toWarehouse.address.lat, lng: this.toWarehouse.address.lng };
    }
  }

  directionOutbound(dataId=null) {
    if(!dataId || !this.isValid()) return false;
    else if(dataId === this.warehouse._id) return true;
    else if(dataId === this.partnerShop._id) return true;
    else if(dataId === this.toWarehouse._id) return false;
    else if(dataId === this.toPartnerShop._id) return false;
    else return false;
  }

  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
  displayLogisticType(langObj={}) {
    if(!this.isValid()) return '';
    let type = dataLogisticTypes.find(d => d.value === this.logisticType);
    if(!type) return '';
    else return translate(type.name, langObj);
  }
  displayDirection(dataId=null, langObj={}) {
    if(!dataId || !this.isValid()){
      return (<></>);
    }else if(dataId === this.warehouse._id){
      return (<span className="ss-tag bg-success">{translate('Outbound', langObj)}</span>);
    }else if(dataId === this.partnerShop._id){
      return (<span className="ss-tag bg-success">{translate('Outbound', langObj)}</span>);
    }else if(dataId === this.toWarehouse._id){
      return (<span className="ss-tag bg-info">{translate('Inbound', langObj)}</span>);
    }else if(dataId === this.toPartnerShop._id){
      return (<span className="ss-tag bg-info">{translate('Inbound', langObj)}</span>);
    }else{
      return (<></>);
    }
  }

  sourcePath(dataId=null) {
    if(!dataId) return '';
    let temp = this.directionOutbound(dataId);
    if(temp){
      if(this.isToWarehouse()) return `/warehouse/view/${this.toWarehouse._id}`;
      else return `/partner-shop/view/${this.toPartnerShop._id}`;
    }else{
      if(this.isFromWarehouse()) return `/warehouse/view/${this.warehouse._id}`;
      else return `/partner-shop/view/${this.partnerShop._id}`;
    }
  }
}
