import { FileModel } from '.';
import { unescape } from 'html-escaper';
import { translate } from '../helpers/frontend';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  status :
    0 = Inactive
    1 = Active
*/

export class CurrencyModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.code = data.code? data.code: '';
    this.icon = data.icon? data.icon: '';
    this.unit = data.unit? data.unit: '';

    this.name = data.name? unescape(data.name): '';
    this.exchangeRate = data.exchangeRate? Number(data.exchangeRate): 1;

    this.image = new FileModel(data.image? data.image: {});
    
    this.isDefault = data.isDefault? Number(data.isDefault): 0;
    this.order = data.order? Number(data.order): 1;
    this.status = data.status? Number(data.status): 0;
    
    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'unit' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
  displayDefault(langObj={}) {
    if(this.isValid()){
      if(this.isDefault === 1) return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }
}
