import { translate } from '../helpers/frontend';

/*
  dayIndex :
    0 = วันอาทิตย์
    1 = วันจันทร์
    2 = วันอังคาร
    3 = วันพุธ
    4 = วันพฤหัสบดี
    5 = วันศุกร์
    6 = วันเสาร์

  isOpened : Number
    1 = เปิดทำการ
    0 = ปิดทำการ
    
  startTime
  endTime
*/

export class WorkingHourModel {
  constructor(data={}) {
    this.dayIndex = data.dayIndex || data.dayIndex===0? Number(data.dayIndex): null;
    this.isOpened = data.isOpened || data.isOpened===0? Number(data.isOpened): 0;
    this.startTime = data.startTime? data.startTime: null;
    this.endTime = data.endTime? data.endTime: null;
  }

  displayDay(langObj={}) {
    if(this.dayIndex===null) return '';
    else return [
      translate('Sunday', langObj), 
      translate('Monday', langObj), 
      translate('Tuesday', langObj), 
      translate('Wednesday', langObj), 
      translate('Thursday', langObj), 
      translate('Friday', langObj), 
      translate('Saturday', langObj)
    ][this.dayIndex];
  }
}
