import {
  PartnerShopModel, PartnerProductCategoryModel, PartnerProductModel,
  CustomerGroupModel, CustomerTierModel, FileModel, UserModel,
  DataProvinceModel, PartnerProductUnitModel, 
} from '.';
import { unescape } from 'html-escaper';
import { translate, formatNumber } from '../helpers/frontend';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  channel : String
    - C2U
    - Store

  limitCustomerFrequency : Number
    0 = ต่อคูปอง
    1 = ต่อวัน
    2 = ต่อสัปดาห์
    3 = ต่อเดือน
    4 = ต่อปี

  type : Number
    1 = (Before VAT) Flat rate / percentage discount
      - minimumPrice
      - maximumPrice
      - discountType
          1 = Flat rate
          2 = Percentage
      - discount
      - availableType : Number
          1 = All products
          2 = Include products
          3 = Exclude products
          4 = Include product categories
          5 = Exclude product categories
      - discountRules : Array
          discountRule : Object
            productId : String
            unitId : String
    2 = (Before VAT) Buy X items get Y with percent discount
      - discountType
          1 = Flat rate
          2 = Percentage
      - discount
      - discountRules : Array
          discountRule : Object
            buy : Object
              productId : String
              unitId : String
              quantity : Number
            discount : Object
              productId : String
              unitId : String
              quantity : Number
    3 = (After VAT) Cash Reward
      - discountType
          1 = Grand Total Discount
          2 = Missing Payment Discount
    4 = (Before VAT) Product Set Discount
      - allowCustomDownPayment : Number
          0 = No
          1 = Yes
      - customDownPayments : Array
          customDownPayment : Number
      - discountRules : Array
          discountRule : Object
            buys : Array
              buy : Object
                productId : String
                unitId : String
                quantity : Number
            salesPriceInVAT : Number
            installmentPriceInVAT : Number
    5 = (Before VAT) Step Discount
      - discountRules : Array
          discountRule : Object
            discountType : Number
              1 = Flat rate
              2 = Percentage
            discount
            maximumDiscount
            availableType : Number
              1 = All products
              2 = Include products
              3 = Exclude products
              4 = Include product categories
              5 = Exclude product categories
            rules : Array
              rule : Object
                availableType = 2, 3
                  productId : String
                  unitId : String
                availableType = 4, 5
                  categoryId : String

  status : Number
    0 = Inactive
    1 = Active
    
  isPersonal : Number
    0 = No
    1 = Yes

  availability : Number
    0  = ไม่สามารถใช้งานได้
    1  = คูปองหมดแล้ว
    2  = คุณได้ใช้คูปองเต็มโควต้าแล้ว
    3  = ยังไม่ถึงเวลาการใช้คูปอง
    4  = สิ้นสุดเวลาการใช้คูปอง
    5  = ร้านค้าไม่ร่วมรายการ
    6  = สินค้าในตะกร้าไม่เข้าเงื่อนไข
    7  = ระดับของคุณไม่ร่วมรายการ
    8  = สั่งซื้อขั้นต่ำไม่ถึงที่กำหนด
    9  = สั่งซื้อสูงสุดเกินที่กำหนด
    10 = มีประเภทสินค้าในตะกร้าที่ไม่ร่วมรายการ
    11 = มีสินค้าในตะกร้าที่ไม่ร่วมรายการ
    12 = สินค้าในตะกร้าไม่เข้าเงื่อนไข
    13 = พื้นที่จัดส่งไม่ร่วมรายการ
    14 = อยู่ในระหว่างการชำระเงิน COD
    99 = ใช้งานได้

  giveawayType: Number
    1 = Participate by Coupons
      - discountRules : Array
          discountRule : Object
            couponId : String
    2 = Participate by Order
      - giveawayMinimumOrder
      - giveawayAvailableType : Number
          1 = All products
          2 = Include products
          3 = Exclude products
          4 = Include product categories
          5 = Exclude product categories
      - giveawayRules : Array
          giveawayRules : Object
            giveawayAvailableType = 2, 3
              productId : String
              unitId : String
            giveawayAvailableType = 4, 5
              categoryId : String
    2 = Participate by Monthly minimum total orders
            

*/

export class PartnerProductCouponModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.channel = data.channel? data.channel: 'C2U';

    this.code = data.code? data.code: null;
    this.name = data.name? unescape(data.name): null;
    this.shortDescription = data.shortDescription? unescape(data.shortDescription): null;
    this.description = data.description? unescape(data.description): null;
    this.image = new FileModel(data.image? data.image: {});
    
    this.quantity = data.quantity? Number(data.quantity): null;
    this.leftQuantity = data.leftQuantity? Number(data.leftQuantity): null;
    this.limitPerCustomer = data.limitPerCustomer? Number(data.limitPerCustomer): null;
    this.limitCustomerFrequency = data.limitCustomerFrequency? Number(data.limitCustomerFrequency): 0;
    
    this.startAt = data.startAt? data.startAt: null;
    this.endAt = data.endAt? data.endAt: null;

    this.order = data.order? Number(data.order): 1000;
    this.status = data.status? data.status: 0;

    this.isCouponCode = data.isCouponCode? data.isCouponCode: 0;
    
    this.isRedeemPoints = data.isRedeemPoints? data.isRedeemPoints: 0;
    this.redeemPoints = data.redeemPoints || data.redeemPoints===0? Number(data.redeemPoints): null;
    
    this.type = data.type? Number(data.type): null;

    this.minimumOrder = data.minimumOrder? Number(data.minimumOrder): null;
    this.maximumOrder = data.maximumOrder? Number(data.maximumOrder): null;

    this.discountType = data.discountType? Number(data.discountType): 1;
    this.discount = data.discount || data.discount===0? Number(data.discount): null;
    this.maximumDiscount = data.maximumDiscount || data.maximumDiscount===0? Number(data.maximumDiscount): null;

    this.availableType = data.availableType? Number(data.availableType): 0;
    this.discountRules = data.discountRules && data.discountRules.length? data.discountRules: [];
    
    this.products = data.products && data.products.length
      ? data.products.map(d => new PartnerProductModel(d)): [];
    this.productCategories = data.productCategories && data.productCategories.length
      ? data.productCategories.map(d => new PartnerProductCategoryModel(d)): [];

    this.hasMustBuy = data.hasMustBuy? Number(data.hasMustBuy): 0;
    this.mustBuyQuantity = data.mustBuyQuantity? Number(data.mustBuyQuantity): 0;
    this.mustBuyProducts = data.mustBuyProducts && data.mustBuyProducts.length
      ? data.mustBuyProducts.map(d => ({
        product: new PartnerProductModel(d.product? d.product: {}),
        productId: d.productId? d.productId: null,
        unit: new PartnerProductUnitModel(d.unit? d.unit: {}),
        unitId: d.unitId? d.unitId: null,
      })): [];

    this.isGiveaway = data.isGiveaway? Number(data.isGiveaway): 0;
    this.usageDuration = data.usageDuration || data.usageDuration===0? Number(data.usageDuration): null;
    this.giveawayType = data.giveawayType? Number(data.giveawayType): 1;
    this.giveawayRules = data.giveawayRules && data.giveawayRules.length? data.giveawayRules: [];
    this.giveawayMinimumOrder = data.giveawayMinimumOrder || data.giveawayMinimumOrder===0 
      ? Number(data.giveawayMinimumOrder): null;
    // this.giveawayMonthlyOrder = data.giveawayMonthlyOrder || data.giveawayMonthlyOrder===0 
    //   ? Number(data.giveawayMonthlyOrder): null;
    this.giveawayAvailableType = data.giveawayAvailableType? Number(data.giveawayAvailableType): 1;
    this.giveawayCoupons = data.giveawayCoupons && data.giveawayCoupons.length
      ? data.giveawayCoupons.map(d => new PartnerProductCouponModel(d)): [];
    this.giveawayProducts = data.giveawayProducts && data.giveawayProducts.length
      ? data.giveawayProducts.map(d => ({
        product: new PartnerProductModel(d.product? d.product: {}),
        productId: d.productId? d.productId: null,
        unit: new PartnerProductUnitModel(d.unit? d.unit: {}),
        unitId: d.unitId? d.unitId: null,
      })): [];
    this.giveawayProductCategories = data.giveawayProductCategories && data.giveawayProductCategories.length
      ? data.giveawayProductCategories.map(d => new PartnerProductCategoryModel(d)): [];

    this.allowCustomDownPayment = data.allowCustomDownPayment? Number(data.allowCustomDownPayment): 0;
    this.customDownPayments = data.customDownPayments && data.customDownPayments.length 
      ? data.customDownPayments.map(d => Number(d)): [];

    this.forCustomerGroups = data.forCustomerGroups && data.forCustomerGroups.length
      ? data.forCustomerGroups.map(d => new CustomerGroupModel(d)): [];

    this.forAllPartnerShops = data.forAllPartnerShops? data.forAllPartnerShops: 0;
    this.forPartnerShops = data.forPartnerShops && data.forPartnerShops.length
      ? data.forPartnerShops.map(d => new PartnerShopModel(d)): [];

    this.forAllCustomerTiers = data.forAllCustomerTiers? data.forAllCustomerTiers: 0;
    this.forCustomerTiers = data.forCustomerTiers && data.forCustomerTiers.length
      ? data.forCustomerTiers.map(d => new CustomerTierModel(d)): [];
      
    this.forAllProvinces = data.forAllProvinces? data.forAllProvinces: 0;
    this.forProvinces = data.forProvinces && data.forProvinces.length 
      ? data.forProvinces.map(d => new DataProvinceModel(d)): [];

    this.createdBy = new UserModel(data.createdBy || {});
    this.createdAt = data.createdAt || null;
    this.updatedBy = new UserModel(data.updatedBy || {});
    this.updatedAt = data.updatedAt || null;

    // Customer Checkout
    this.isPersonal = data.isPersonal? Number(data.isPersonal): 0;
    this.availability = data.availability? Number(data.availability): 0;
    this.actualDiscount = data.actualDiscount? Number(data.actualDiscount): 0;
    this.missingPaymentDiscount = data.missingPaymentDiscount? Number(data.missingPaymentDiscount): 0;
    this.diffInstallmentDiscount = data.diffInstallmentDiscount? Number(data.diffInstallmentDiscount): 0;

    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'name', 'shortDescription', 'description' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  initDiscountRule(type=0) {
    if(type === 4){
      return {
        buys: [],
        salesPriceInVAT: 0,
      };
    }else if(type === 5){
      return {
        discountType: 1,
        discount: null,
        maximumDiscount: null,
        availableType: 1,
        rules: [],
      };
    }else{
      return null;
    }
  }

  isValid() {
    return this._id? true: false;
  }
  isAvailable() {
    return this.isValid() && this.status===1 && this.leftQuantity? true: false;
  }

  hasMissingPaymentDiscount() {
    return this.isValid() && this.missingPaymentDiscount;
  }
  hasIntallmentDiscount() {
    return this.isValid() && this.diffInstallmentDiscount;
  }

  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
  displayLimitCustomer(langObj={}) {
    if(this.isValid()){
      if(this.limitCustomerFrequency===0) return `${this.limitPerCustomer} ${translate('Coupon', langObj)}`;
      else if(this.limitCustomerFrequency===1) return `${this.limitPerCustomer} ${translate('Coupon Per Day', langObj)}`;
      else if(this.limitCustomerFrequency===2) return `${this.limitPerCustomer} ${translate('Weekly Coupon', langObj)}`;
      else if(this.limitCustomerFrequency===3) return `${this.limitPerCustomer} ${translate('Coupon Per Month', langObj)}`;
      else if(this.limitCustomerFrequency===4) return `${this.limitPerCustomer} ${translate('Coupon Per Year', langObj)}`;
      else return '';
    }else{
      return '';
    }
  }
  displayDiscount(langObj={}) {
    if(this.isValid()){
      if(this.discountType===1){
        return `${formatNumber(this.discount)}`;
      }else if(this.discountType===2){
        if(this.maximumDiscount){
          return `${formatNumber(this.discount)}% (${translate('Maximum', langObj)} ${formatNumber(this.maximumDiscount)} ${translate('THB', langObj)})`;
        }else{
          return `${formatNumber(this.discount)}%`;
        }
      }else if(this.type === 3){
        return `${formatNumber(this.discount)} (${translate(this.discountType===2? 'Missing Payment Discount': 'Grand Total Discount', langObj)})`;
      }else{
        return '';
      }
    }else{
      return '';
    }
  }
  displayType(langObj={}) {
    if(this.isValid()){
      if(this.type === 1) return translate('Product Discount', langObj);
      else if(this.type === 2) return translate('Buy X Get Y Discounted', langObj);
      else if(this.type === 3) return translate('Cash Coupon', langObj);
      else if(this.type === 4) return translate('Product Set Discount', langObj);
      else if(this.type === 5) return translate('Product Step Discount', langObj);
      else return '';
    }else{
      return '';
    }
  }
  displaySubType(langObj={}) {
    if(this.isValid()){
      if(this.isRedeemPoints === 1) return (<span className="ss-tag bg-info">{translate('Redeem Points', langObj)}</span>);
      else if(this.isCouponCode === 1) return (<span className="ss-tag bg-success">{translate('Use Code', langObj)}</span>);
      else if(this.isGiveaway === 1) return (<span className="ss-tag bg-danger">{translate('Giveaway', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('By Selection', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('By Selection', langObj)}</span>);
    }
  }

  displayAvailableType(langObj={}) {
    if(!this.isValid()) return '';
    else{
      if(this.availableType === 1) return translate('All Products', langObj);
      else if(this.availableType === 2) return translate('Include by Products', langObj);
      else if(this.availableType === 3) return translate('Exclude by Products', langObj);
      else if(this.availableType === 4) return translate('Include by Product Categories', langObj);
      else if(this.availableType === 5) return translate('Exclude by Product Categories', langObj);
      else return '';
    }
  }

  displayAvailability(langObj={}) {
    if(this.availability === 99) return translate('text_available_coupon_99', langObj);
    else if(this.availability === 0) return translate('text_available_coupon_0', langObj);
    else if(this.availability === 1) return translate('text_available_coupon_1', langObj);
    else if(this.availability === 2) return translate('text_available_coupon_2', langObj);
    else if(this.availability === 3) return translate('text_available_coupon_3', langObj);
    else if(this.availability === 4) return translate('text_available_coupon_4', langObj);
    else if(this.availability === 5) return translate('text_available_coupon_5', langObj);
    else if(this.availability === 6) return translate('text_available_coupon_6', langObj);
    else if(this.availability === 7) return translate('text_available_coupon_7', langObj);
    else if(this.availability === 8) return translate('text_available_coupon_8', langObj);
    else if(this.availability === 9) return translate('text_available_coupon_9', langObj);
    else if(this.availability === 10) return translate('text_available_coupon_10', langObj);
    else if(this.availability === 11) return translate('text_available_coupon_11', langObj);
    else if(this.availability === 12) return translate('text_available_coupon_12', langObj);
    else if(this.availability === 13) return translate('text_available_coupon_13', langObj);
    else if(this.availability === 14) return translate('text_available_coupon_14', langObj);
  }
  
  displayAllowCustomDownPayment(langObj={}) {
    if(this.isValid()){
      if(this.allowCustomDownPayment === 1) return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }
  validAllowCustomDownPayment() {
    if(this.isValid()){
      return this.type === 4 && this.allowCustomDownPayment === 1 
        && this.customDownPayments && this.customDownPayments.length;
    }else{
      return false;
    }
  }

  displayGiveawayType(langObj={}) {
    if(!this.isValid()) return '';
    else{
      if(this.giveawayType === 1) return translate('Participate by Coupons', langObj);
      else if(this.giveawayType === 2) return translate('Participate by Order', langObj);
      else if(this.giveawayType === 3) return translate('Participate by minimum monthly total orders', langObj);
      else return '';
    }
  }
  displayGiveawayAvailableType(langObj={}) {
    if(!this.isValid()) return '';
    else{
      if(this.giveawayAvailableType === 1) return translate('All Products', langObj);
      else if(this.giveawayAvailableType === 2) return translate('Include by Products', langObj);
      else if(this.giveawayAvailableType === 3) return translate('Exclude by Products', langObj);
      else if(this.giveawayAvailableType === 4) return translate('Include by Product Categories', langObj);
      else if(this.giveawayAvailableType === 5) return translate('Exclude by Product Categories', langObj);
      else return '';
    }
  }
  
  existInGroup(groupId) {
    if(groupId && this.forCustomerGroups && this.forCustomerGroups.length) {
      const containGroup = this.forCustomerGroups.find(d => d._id === groupId) !== undefined;
      if(containGroup){
        return true;
      }else return false;
    } else return false;
  }
}
