import { UserModel } from '.';

export class UserCommissionTableModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.user = new UserModel(data.user? data.user: {});
    
    this.table = {};
    if(data.table && data.table.length){
      data.table.forEach(d => {
        if(d?.product){
          this.table[d.product] = d.commission || d.commission===0? Number(d.commission): null;
        }
      });
    }
  }

  isValid() {
    return this._id? true: false;
  }
}
