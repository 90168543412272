import { ShippingStatusModel, ShippingSubStatusModel } from '.';
import { unescape } from 'html-escaper';
import { translate } from '../helpers/frontend';


/*
  type
    - C2U
    - SAP
    - LF
    - Kerry = Kerry / Kerry Express
    - Grab

  status
    0 = ไม่แสดง
    1 = แสดง
*/

export class ShippingStatusMappingModel {
  constructor(data={}, createdAt=null) {
    this._id = data?._id || null;
    
    this.type = data.type? data.type: null;

    this.externalStatus = data.externalStatus? unescape(data.externalStatus): null;
    this.externalSubStatus = data.externalSubStatus? unescape(data.externalSubStatus): null;
    this.externalDescription = data.externalDescription? unescape(data.externalDescription): null;
    
    this.shippingStatus = new ShippingStatusModel(data.shippingStatus? data.shippingStatus: {});
    this.shippingSubStatus = new ShippingSubStatusModel(data.shippingSubStatus? data.shippingSubStatus: {});

    this.status = data.status? Number(data.status): 0;
    
    this.createdAt = createdAt? createdAt: data.createdAt? data.createdAt: null;
    this.updatedAt = data.updatedAt || null;
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Show', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Hide', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Hide', langObj)}</span>);
    }
  }
}
