export const DEV_PROCESS = process.env.REACT_APP_DEV_PROCESS === '1';
export const DEV_MODE = `${process.env?.REACT_APP_DEV_MODE ?? 'DEV'}`.toUpperCase();

export const APP_YEAR = Number(process.env.REACT_APP_YEAR? process.env.REACT_APP_YEAR: 2022);

export const APP_URL = `${process.env.REACT_APP_URL}`;
export const API_URL = `${process.env.REACT_APP_API_URL}`;

export const FRONTEND_URL = `${process.env.REACT_APP_FRONTEND_URL}`;
export const FRONTEND_THEME = Number(`${process.env.REACT_APP_FRONTEND_THEME || 0}`);

export const APP_NAME = `${process.env.REACT_APP_NAME? process.env.REACT_APP_NAME: ''}`;
export const APP_SHORT_NAME = `${process.env.REACT_APP_SHORT_NAME? process.env.REACT_APP_SHORT_NAME: ''}`;
export const APP_LOGO = `${process.env.REACT_APP_LOGO? process.env.REACT_APP_LOGO: ''}`;

export const CDN_URL = `${process.env.REACT_APP_CDN_URL}`;
export const CDN_PUBLIC_KEY = `${process.env.REACT_APP_CDN_PUBLIC_KEY}`;

export const APP_PREFIX = `${process.env.REACT_APP_PREFIX}`;

export const TOKEN_KEY = `${process.env.REACT_APP_TOKEN_KEY}`;
export const REFRESH_KEY = `${process.env.REACT_APP_REFRESH_KEY}`;

export const SYSTEM_LANGUAGES = process.env.REACT_APP_LANGUAGES
  ? process.env.REACT_APP_LANGUAGES.split(','): ['TH'];
