import { lazy, Suspense, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { Routes, Route, Navigate } from 'react-router-dom';
import { SYSTEM_LANGUAGES } from '../actions/variables';

import { AuthPageLoading } from '../views/auth';

export default function RouteAdmin() {
  const { status, user, permissions, prefix, settings } = useContext(AuthContext);
  
  const checkPermission = (key) => {
    if(user.isSuperAdmin()) return true;
    const p = permissions.find(k => k.value === key && k.type === 'C2U' && k.read === 1);
    return p? true: false;
  }
  
  const LazyRoute = ({ dom: Element }) => <Element />;
  return status === 'authenticated' && user.isAdmin()? (<>
    <Suspense fallback={<AuthPageLoading />}>
      <Routes>
        {/* Admin */}
        <Route path="/admin" element={<LazyRoute dom={lazy(() => import('../views/admin/DashboardPage'))} />} />
        <Route path="/admin/dashboard" element={<LazyRoute dom={lazy(() => import('../views/admin/DashboardPage'))} />} />
          
        {user.isSuperAdmin()? (
          <Route path="/admin/data-import" element={<LazyRoute dom={lazy(() => import('../views/admin/DataImportPage'))} />} />
        ): (<></>)}
            
        <Route path="/admin/admins" element={<LazyRoute dom={lazy(() => import('../views/admin/AdminsPage'))} />} />
        <Route path="/admin/admin/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/AdminViewPage'))} />} />
        {user.isSuperAdmin()? (
          <Route path="/admin/admin/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/AdminPage'))} />} />
        ): (<></>)}
            
        {settings['APP_ENABLE_MODULE_SALES_MANAGER']==='1'? (<>
          <Route path="/admin/sales-managers" element={<LazyRoute dom={lazy(() => import('../views/admin/SalesManagersPage'))} />} />
          <Route path="/admin/sales-manager/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/SalesManagerViewPage'))} />} />
          <Route path="/admin/sales-manager/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/SalesManagerPage'))} />} />
        </>): (<></>)}

        <Route path="/admin/shipping-statuses" element={<LazyRoute dom={lazy(() => import('../views/admin/ShippingStatusesPage'))} />} />
        <Route path="/admin/shipping-status/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/ShippingStatusViewPage'))} />} />
          
        {user.isSuperAdmin() && SYSTEM_LANGUAGES.length > 1? (<>
          <Route path="/admin/languages" element={<LazyRoute dom={lazy(() => import('../views/admin/LanguagesPage'))} />} />
          <Route path="/admin/language/update/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/LanguagePage'))} />} />
        </>): (<></>)}
        
        {user.isSuperAdmin()? (<>
          <Route path="/admin/data-countries" element={<LazyRoute dom={lazy(() => import('../views/admin/DataCountriesPage'))} />} />
          <Route path="/admin/data-country/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/DataCountryViewPage'))} />} />
          <Route path="/admin/data-country/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/DataCountryPage'))} />} />
          <Route path="/admin/data-province/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/DataProvinceViewPage'))} />} />
          <Route path="/admin/data-district/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/DataDistrictViewPage'))} />} />
        </>): (<></>)}
        
        {user.isSuperAdmin() && settings['APP_LOG_USER_ENABLE']==='1'? (
          <Route path="/admin/user-logs" element={<LazyRoute dom={lazy(() => import('../views/admin/UserLogsPage'))} />} />
        ): (<></>)}
        {user.isSuperAdmin() && settings['APP_DATABASE_BACKUP_ENABLE']==='1'? (
          <Route path="/admin/database-backups" element={<LazyRoute dom={lazy(() => import('../views/admin/DatabaseBackupsPage'))} />} />
        ): (<></>)}

        {user.isSuperAdmin()? (
          <Route path="/admin/settings/*" element={<LazyRoute dom={lazy(() => import('../views/admin/SettingsPage'))} />} />
        ): (<></>)}
        
        {/* App Settings */}
        {user.isOwner? (<>
          <Route path="/admin/app-settings" element={<LazyRoute dom={lazy(() => import('../views/admin/AppSettingsPage'))} />} />
          <Route path="/admin/app-settings/*" element={<LazyRoute dom={lazy(() => import('../views/admin/AppSettingsPage'))} />} />
        </>): (<></>)}

        {/* Personal */}
        <Route path="/admin/profile" element={<LazyRoute dom={lazy(() => import('../views/admin/ProfileViewPage'))} />} />
        <Route path="/admin/profile/update" element={<LazyRoute dom={lazy(() => import('../views/admin/ProfilePage'))} />} />

        {/* Partner */}
        <Route path="/admin/partners" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnersPage'))} />} />
        <Route path="/admin/partner/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerViewPage'))} />} />
        <Route path="/admin/partner/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerPage'))} />} />
              
        <Route path="/admin/partner-shops" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerShopsPage'))} />} />
        <Route path="/admin/partner-shop/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerShopViewPage'))} />} />
        <Route path="/admin/partner-shop/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerShopPage'))} />} />
              
        <Route path="/admin/partner-product-categories" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductCategoriesPage'))} />} />
        <Route path="/admin/partner-product-category/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductCategoryViewPage'))} />} />
        <Route path="/admin/partner-product-category/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductCategoryPage'))} />} />
        <Route path="/admin/partner-product-sub-category/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductSubCategoryViewPage'))} />} />

        <Route path="/admin/partner-product-brands" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductBrandsPage'))} />} />
        <Route path="/admin/partner-product-brand/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductBrandViewPage'))} />} />
        <Route path="/admin/partner-product-brand/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductBrandPage'))} />} />
              
        <Route path="/admin/partner-product-statuses" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductStatusesPage'))} />} />
        <Route path="/admin/partner-product-status/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductStatusPage'))} />} />
              
        <Route path="/admin/partner-products" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductsPage'))} />} />
        <Route path="/admin/partner-product/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductViewPage'))} />} />
        <Route path="/admin/partner-product/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductPage'))} />} />

        {settings['APP_MODULE_PARTNER_PRODUCT_SET']==='1'? (<>
          <Route path="/admin/partner-product-sets" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductSetsPage'))} />} />
          <Route path="/admin/partner-product-set/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductSetViewPage'))} />} />
          <Route path="/admin/partner-product-set/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductSetPage'))} />} />
        </>): (<></>)}
        
        {settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_REWARD']==='1'? (<>
          <Route path="/admin/partner-product-giveaway-rules" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductGiveawayRulesPage'))} />} />
          <Route path="/admin/partner-product-giveaway-rule/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductGiveawayRuleViewPage'))} />} />
          <Route path="/admin/partner-product-giveaway-rule/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductGiveawayRulePage'))} />} />
        </>): (<></>)}

        {settings['APP_MODULE_PARTNER_SUBSCRIPTION']==='1'? (<>
          <Route path="/admin/partner-product-subscriptions" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductSubscriptionsPage'))} />} />
          <Route path="/admin/partner-product-subscription/view/:dataId/*" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductSubscriptionViewPage'))} />} />
          <Route path="/admin/partner-product-subscription/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductSubscriptionPage'))} />} />
        </>): (<></>)}

        {settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_COUPON']==='1' || settings['APP_ENABLE_FEATURE_PARTNER_CASH_COUPON']==='1'? (<>
          <Route path="/admin/partner-product-coupons" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductCouponsPage'))} />} />
          <Route path="/admin/partner-product-coupon/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductCouponViewPage'))} />} />
          <Route path="/admin/partner-product-coupon/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerProductCouponPage'))} />} />
        </>): (<></>)}

        {settings['APP_ENABLE_FEATURE_PARTNER_SHIPPING_COUPON']==='1'? (<>
          <Route path="/admin/partner-shippings" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerShippingsPage'))} />} />
          <Route path="/admin/partner-shipping/view/*" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerShippingViewPage'))} />} />
          <Route path="/admin/partner-shipping/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerShippingPage'))} />} />
        </>): (<></>)}

        <Route path="/admin/partner-shipping-coupons" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerShippingCouponsPage'))} />} />
        <Route path="/admin/partner-shipping-coupon/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerShippingCouponViewPage'))} />} />
        <Route path="/admin/partner-shipping-coupon/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerShippingCouponPage'))} />} />
          
        {settings['APP_MODULE_PARTNER_EVENT']==='1'? (<>
          <Route path="/admin/partner-events" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerEventsPage'))} />} />
          <Route path="/admin/partner-event/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerEventViewPage'))} />} />
          <Route path="/admin/partner-event/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerEventPage'))} />} />
        </>): (<></>)}

        {user.isSuperAdmin()? (
          <Route path="/admin/partner-settings/*" element={<LazyRoute dom={lazy(() => import('../views/admin/PartnerSettingsPage'))} />} />
        ): (<></>)}

        {/* Customer */}
        {settings['APP_MODULE_CUSTOMER_GROUP']==='1'? (<>
          <Route path="/admin/customer-groups" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerGroupsPage'))} />} />
          <Route path="/admin/customer-group/view/:dataId/*" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerGroupViewPage'))} />} />
          <Route path="/admin/customer-group/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerGroupPage'))} />} />
        </>): (<></>)}

        <Route path="/admin/customer-tiers" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerTiersPage'))} />} />
        <Route path="/admin/customer-tier/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerTierViewPage'))} />} />
        <Route path="/admin/customer-tier/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerTierPage'))} />} />

        <Route path="/admin/customers" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomersPage'))} />} />
        <Route path="/admin/customer/view/:dataId/*" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerViewPage'))} />} />
        <Route path="/admin/customer/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerPage'))} />} />
          
        <Route path="/admin/customer-guests" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerGuestsPage'))} />} />
        <Route path="/admin/customer-guest/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerGuestViewPage'))} />} />
          
        <Route path="/admin/customer-orders" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerOrdersPage'))} />} />
        <Route path="/admin/customer-order/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerOrderViewPage'))} />} />
        <Route path="/admin/customer-order/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerOrderPage'))} />} />
          
        {settings['APP_MODULE_PAYMENT_2C2P']==='1'? (
          <Route path="/admin/customer-2c2p-carts" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerCarts2C2PPage'))} />} />
        ): (<></>)}
        {settings['APP_MODULE_PAYMENT_STRIPE']==='1'? (
          <Route path="/admin/customer-stripe-carts" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerCartsStripePage'))} />} />
        ): (<></>)}
        {settings['APP_MODULE_PARTNER_SUBSCRIPTION']==='1'? (<>
          <Route path="/admin/customer-subscription-2c2p-carts" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerSubscriptionCarts2C2PPage'))} />} />
        </>): (<></>)}

        {settings['APP_MODULE_CHAT_CUSTOMER']==='1' && settings[`${prefix}ENABLE_CHAT_CUSTOMER`]==='1'? (
          <Route path="/admin/customer-messages/*" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerMessagesPage'))} />} />
        ): (<></>)}
        {settings['APP_MODULE_CHAT_GUEST']==='1' && settings[`${prefix}ENABLE_CHAT_GUEST`]==='1'? (
          <Route path="/admin/guest-messages/*" element={<LazyRoute dom={lazy(() => import('../views/admin/GuestMessagesPage'))} />} />
        ): (<></>)}

        {settings['MOBILE_ENABLE']==='1'? (<>
          <Route path="/admin/customer-notifications" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerNotificationsPage'))} />} />
          <Route path="/admin/customer-notification/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/CustomerNotificationPage'))} />} />
        </>): (<></>)}

        {/* Seller */}
        {settings['APP_MODULE_SELLER']==='1'? (<>
          <Route path="/admin/seller-shop-brands" element={<LazyRoute dom={lazy(() => import('../views/admin/SellerShopBrandsPage'))} />} />
          <Route path="/admin/seller-shop-brand/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/SellerShopBrandViewPage'))} />} />
          <Route path="/admin/seller-shop-brand/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/SellerShopBrandPage'))} />} />

          <Route path="/admin/seller-shop-types" element={<LazyRoute dom={lazy(() => import('../views/admin/SellerShopTypesPage'))} />} />
          <Route path="/admin/seller-shop-type/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/SellerShopTypeViewPage'))} />} />
          <Route path="/admin/seller-shop-type/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/SellerShopTypePage'))} />} />
            
          <Route path="/admin/seller-shops" element={<LazyRoute dom={lazy(() => import('../views/admin/SellerShopsPage'))} />} />
          <Route path="/admin/seller-shop/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/SellerShopViewPage'))} />} />
          <Route path="/admin/seller-shop/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/SellerShopPage'))} />} />
        </>): (<></>)}

        {/* CMS */}
        <Route path="/admin/cms-content-categories" element={<LazyRoute dom={lazy(() => import('../views/admin/CmsContentCategoriesPage'))} />} />
        <Route path="/admin/cms-content-category/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/CmsContentCategoryViewPage'))} />} />
        <Route path="/admin/cms-content-category/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/CmsContentCategoryPage'))} />} />
          
        <Route path="/admin/cms-contents" element={<LazyRoute dom={lazy(() => import('../views/admin/CmsContentsPage'))} />} />
        <Route path="/admin/cms-content/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/CmsContentViewPage'))} />} />
        <Route path="/admin/cms-content/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/CmsContentPage'))} />} />
          
        <Route path="/admin/cms-banners" element={<LazyRoute dom={lazy(() => import('../views/admin/CmsBannersPage'))} />} />
        <Route path="/admin/cms-banner/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/CmsBannerViewPage'))} />} />
        <Route path="/admin/cms-banner/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/CmsBannerPage'))} />} />

        <Route path="/admin/cms-popups" element={<LazyRoute dom={lazy(() => import('../views/admin/CmsPopupsPage'))} />} />
        <Route path="/admin/cms-popup/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/CmsPopupViewPage'))} />} />
        <Route path="/admin/cms-popup/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/CmsPopupPage'))} />} />

        {settings['APP_MODULE_CMS_CHECKOUT']==='1'? (<>
          <Route path="/admin/cms-checkout-contents" element={<LazyRoute dom={lazy(() => import('../views/admin/CmsCheckoutContentsPage'))} />} />
          <Route path="/admin/cms-checkout-content/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/CmsCheckoutContentViewPage'))} />} />
          <Route path="/admin/cms-checkout-content/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/CmsCheckoutContentPage'))} />} />
        </>): (<></>)}

        {user.isSuperAdmin()? (
          <Route path="/admin/cms-settings/*" element={<LazyRoute dom={lazy(() => import('../views/admin/CmsSettingsPage'))} />} />
        ): (<></>)}

        {/* Report */}
        <Route path="/admin/report-partner-shops" element={<LazyRoute dom={lazy(() => import('../views/admin/ReportPartnerShopPage'))} />} />
        {checkPermission('report_partner_performances') 
        && settings['APP_REPORT_PARTNER_PERFORMANCE']==='1'? (
          <Route path="/admin/report-partner-performances" element={<LazyRoute dom={lazy(() => import('../views/admin/ReportPartnerPerformancePage'))} />} />
        ): (<></>)}
        {false? (
          <Route path="/admin/report-partner-commissions" element={<LazyRoute dom={lazy(() => import('../views/admin/ReportPartnerCommissionPage'))} />} />
        ): (<></>)}
        {checkPermission('report_partner_products') 
        && settings['APP_REPORT_PARTNER_PRODUCT']==='1'? (
          <Route path="/admin/report-partner-products" element={<LazyRoute dom={lazy(() => import('../views/admin/ReportPartnerProductPage'))} />} />
        ): (<></>)}
        {checkPermission('report_partner_product_mix_trackings') 
        && settings['APP_REPORT_PARTNER_PRODUCT_MIX_TRACKING']==='1'? (
          <Route path="/admin/report-partner-product-mix-trackings" element={<LazyRoute dom={lazy(() => import('../views/admin/ReportPartnerProductMixTrackingPage'))} />} />
        ): (<></>)}
        {checkPermission('report_partner_coupons') 
        && settings['APP_REPORT_PARTNER_COUPON']==='1' 
        && (settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_COUPON']==='1' 
        || settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_COUPON']==='1' 
        || settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_COUPON']==='1')? (
          <Route path="/admin/report-partner-coupons" element={<LazyRoute dom={lazy(() => import('../views/admin/ReportPartnerCouponPage'))} />} />
        ): (<></>)}
        {checkPermission('report_partner_events') 
        && settings['APP_REPORT_PARTNER_EVENT']==='1' && settings['APP_MODULE_PARTNER_EVENT']==='1'? (
          <Route path="/admin/report-partner-events" element={<LazyRoute dom={lazy(() => import('../views/admin/ReportPartnerEventPage'))} />} />
        ): (<></>)}
        {checkPermission('report_customers') 
        && settings['APP_REPORT_CUSTOMER']==='1'? (
          <Route path="/admin/report-customers" element={<LazyRoute dom={lazy(() => import('../views/admin/ReportCustomerPage'))} />} />
        ): (<></>)}
        {checkPermission('report_customer_orders') 
        && settings['APP_REPORT_CUSTOMER_ORDER']==='1'? (
          <Route path="/admin/report-customer-orders" element={<LazyRoute dom={lazy(() => import('../views/admin/ReportCustomerOrderPage'))} />} />
        ): (<></>)}
        {checkPermission('report_customer_product_orders') 
        && settings['APP_REPORT_CUSTOMER_PRODUCT_ORDER']==='1'? (
          <Route path="/admin/report-customer-product-orders" element={<LazyRoute dom={lazy(() => import('../views/admin/ReportCustomerProductOrderPage'))} />} />
        ): (<></>)}
        {checkPermission('report_customer_purchasings') 
        && settings['APP_REPORT_CUSTOMER_PURCHASING']==='1'? (
          <Route path="/admin/report-customer-purchasings" element={<LazyRoute dom={lazy(() => import('../views/admin/ReportCustomerPurchasingPage'))} />} />
        ): (<></>)}
        {checkPermission('report_customer_purchase_categories') 
        && settings['APP_REPORT_CUSTOMER_PURCHASE_CATEGORY']==='1'? (
          <Route path="/admin/report-customer-purchase-categories" element={<LazyRoute dom={lazy(() => import('../views/admin/ReportCustomerPurchaseCategoryPage'))} />} />
        ): (<></>)}
        {checkPermission('report_customer_unpurchase_categories') 
        && settings['APP_REPORT_CUSTOMER_UNPURCHASE_CATEGORY']==='1'? (
          <Route path="/admin/report-customer-unpurchase-categories" element={<LazyRoute dom={lazy(() => import('../views/admin/ReportCustomerUnpurchaseCategoryPage'))} />} />
        ): (<></>)}
        {false? (
          <Route path="/admin/report-customer-settlements" element={<LazyRoute dom={lazy(() => import('../views/admin/ReportCustomerSettlementPage'))} />} />
        ): (<></>)}
        {checkPermission('report_customer_trackings') 
        && settings['APP_REPORT_CUSTOMER_TRACKING']==='1'? (
          <Route path="/admin/report-customer-trackings" element={<LazyRoute dom={lazy(() => import('../views/admin/ReportCustomerTrackingPage'))} />} />
        ): (<></>)}
        {checkPermission('report_customer_purchase_tiers') 
        && settings['APP_REPORT_CUSTOMER_PURCHASE_TIER']==='1'? (
          <Route path="/admin/report-customer-purchase-tiers" element={<LazyRoute dom={lazy(() => import('../views/admin/ReportCustomerPurchaseTierPage'))} />} />
        ): (<></>)}

        {/* Marketing Tools */}
        {settings['APP_MODULE_MARKETING_TOOL']==='1'? (<>
          <Route path="/admin/tool-partner-product-promotions" element={<LazyRoute dom={lazy(() => import('../views/admin/ToolPartnerProductPromotionsPage'))} />} />
          <Route path="/admin/tool-partner-product-promotion/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/ToolPartnerProductPromotionPage'))} />} />
          
          <Route path="/admin/tool-customer-card-scanner" element={<LazyRoute dom={lazy(() => import('../views/admin/ToolCustomerCardScannerPage'))} />} />
        </>): (<></>)}

        {/* External */}
        {user.isSuperAdmin()? (<>
          <Route path="/admin/external-admins" element={<LazyRoute dom={lazy(() => import('../views/admin/ExternalAdminsPage'))} />} />
          <Route path="/admin/external-admin/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/ExternalAdminViewPage'))} />} />
          <Route path="/admin/external-admin/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/ExternalAdminPage'))} />} />
            
          <Route path="/admin/external-shops" element={<LazyRoute dom={lazy(() => import('../views/admin/ExternalShopsPage'))} />} />
          <Route path="/admin/external-shop/view/:dataId/*" element={<LazyRoute dom={lazy(() => import('../views/admin/ExternalShopViewPage'))} />} />
          <Route path="/admin/external-shop/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/ExternalShopPage'))} />} />
        </>): (<></>)}

        {/* Supply Chain */}
        {user.isSuperAdmin()? (<>
          <Route path="/admin/supply-chain-admins" element={<LazyRoute dom={lazy(() => import('../views/admin/SupplyChainAdminsPage'))} />} />
          <Route path="/admin/supply-chain-admin/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/admin/SupplyChainAdminViewPage'))} />} />
          <Route path="/admin/supply-chain-admin/:process/*" element={<LazyRoute dom={lazy(() => import('../views/admin/SupplyChainAdminPage'))} />} />
        </>): (<></>)}

        {/* Intelligence */}
        {user.isTester()? (<>
          <Route path="/admin/intelligence" element={<LazyRoute dom={lazy(() => import('../views/intelligence/PartnerProductsPage'))} />} />
          <Route path="/admin/intelligence/partner-products" element={<LazyRoute dom={lazy(() => import('../views/intelligence/PartnerProductsPage'))} />} />
          <Route path="/admin/intelligence/customer-orders" element={<LazyRoute dom={lazy(() => import('../views/intelligence/CustomerOrdersPage'))} />} />
        </>): (<></>)}

        {/* Must Have */}
        <Route path="/auth/customer-signup-qr-code/:partnerShopCode/*" element={<LazyRoute dom={lazy(() => import('../views/auth/CustomerSignupQrCodePage'))} />} />
        <Route path="/payment/failed" element={<LazyRoute dom={lazy(() => import('../views/payment/FailedPage'))} />} />
        <Route path="/payment/success/:dataId" element={<LazyRoute dom={lazy(() => import('../views/payment/SuccessPage'))} />} />

        <Route path="*" element={<Navigate replace to="/admin" />} />
      </Routes>
    </Suspense>
  </>): (<></>);
}
