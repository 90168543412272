import { lazy, Suspense, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { Routes, Route, Navigate } from 'react-router-dom';

import { AuthPageLoading } from '../views/auth';

export default function RouteExAdmin() {
  const { status, user, externalShop, prefix, settings } = useContext(AuthContext);

  const LazyRoute = ({ dom: Element }) => <Element />;
  return status === 'authenticated' && user.isExAdmin() && externalShop.isValid()? (<>
    <Suspense fallback={<AuthPageLoading />}>
      <Routes>
        {/* External */}
        <Route path="/external" element={<LazyRoute dom={lazy(() => import('../views/external/DashboardPage'))} />} />
        <Route path="/external/dashboard" element={<LazyRoute dom={lazy(() => import('../views/external/DashboardPage'))} />} />
        
        <Route path="/external/admins" element={<LazyRoute dom={lazy(() => import('../views/external/AdminsPage'))} />} />
        <Route path="/external/admin/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/AdminViewPage'))} />} />
        {user.isExSuperAdmin()? (
          <Route path="/external/admin/:process/*" element={<LazyRoute dom={lazy(() => import('../views/external/AdminPage'))} />} />
        ): (<></>)}
          
        {settings['APP_ENABLE_MODULE_SALES_MANAGER']==='1'? (<>
          <Route path="/external/sales-managers" element={<LazyRoute dom={lazy(() => import('../views/external/SalesManagersPage'))} />} />
          <Route path="/external/sales-manager/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/SalesManagerViewPage'))} />} />
          <Route path="/external/sales-manager/:process/*" element={<LazyRoute dom={lazy(() => import('../views/external/SalesManagerPage'))} />} />
        </>): (<></>)}

        <Route path="/external/shipping-statuses" element={<LazyRoute dom={lazy(() => import('../views/external/ShippingStatusesPage'))} />} />
        <Route path="/external/shipping-status/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/ShippingStatusViewPage'))} />} />
          
        {user.isExSuperAdmin()? (
          <Route path="/external/settings/*" element={<LazyRoute dom={lazy(() => import('../views/external/SettingsPage'))} />} />
        ): (<></>)}

        {/* Personal */}
        <Route path="/external/profile" element={<LazyRoute dom={lazy(() => import('../views/external/ProfileViewPage'))} />} />
        <Route path="/external/profile/update" element={<LazyRoute dom={lazy(() => import('../views/external/ProfilePage'))} />} />

        {/* Partner */}
        <Route path="/external/partner-shop/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerShopViewPage'))} />} />
        {user.isExSuperAdmin()? (
          <Route path="/external/partner-shop/:process/*" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerShopPage'))} />} />
        ): (<></>)}
          
        <Route path="/external/partner-product-categories" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductCategoriesPage'))} />} />
        <Route path="/external/partner-product-category/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductCategoryViewPage'))} />} />
        <Route path="/external/partner-product-sub-category/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductSubCategoryViewPage'))} />} />
          
        <Route path="/external/partner-product-brands" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductBrandsPage'))} />} />
        <Route path="/external/partner-product-brand/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductBrandViewPage'))} />} />
        <Route path="/external/partner-product-brand/:process/*" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductBrandPage'))} />} />
          
        <Route path="/external/partner-products" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductsPage'))} />} />
        <Route path="/external/partner-product/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductViewPage'))} />} />
        <Route path="/external/partner-product/:process/*" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductPage'))} />} />

        {settings['APP_MODULE_PARTNER_PRODUCT_SET']==='1'? (<>
          <Route path="/external/partner-product-sets" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductSetsPage'))} />} />
          <Route path="/external/partner-product-set/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductSetViewPage'))} />} />
          <Route path="/external/partner-product-set/:process/*" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductSetPage'))} />} />
        </>): (<></>)}

        {settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_REWARD']==='1'? (<>
          <Route path="/external/partner-product-giveaway-rules" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductGiveawayRulesPage'))} />} />
          <Route path="/external/partner-product-giveaway-rule/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductGiveawayRuleViewPage'))} />} />
          <Route path="/external/partner-product-giveaway-rule/:process/*" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductGiveawayRulePage'))} />} />
        </>): (<></>)}
        
        {settings['APP_MODULE_PARTNER_SUBSCRIPTION']==='1'? (<>
          <Route path="/external/partner-product-subscriptions" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductSubscriptionsPage'))} />} />
          <Route path="/external/partner-product-subscription/view/:dataId/*" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductSubscriptionViewPage'))} />} />
          <Route path="/external/partner-product-subscription/:process/*" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductSubscriptionPage'))} />} />
        </>): (<></>)}

        {settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_COUPON']==='1' || settings['APP_ENABLE_FEATURE_PARTNER_CASH_COUPON']==='1'? (<>
          <Route path="/external/partner-product-coupons" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductCouponsPage'))} />} />
          <Route path="/external/partner-product-coupon/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductCouponViewPage'))} />} />
          <Route path="/external/partner-product-coupon/:process/*" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerProductCouponPage'))} />} />
        </>): (<></>)}

        <Route path="/external/partner-shippings" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerShippingsPage'))} />} />
        <Route path="/external/partner-shipping/view/*" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerShippingViewPage'))} />} />
          
        {settings['APP_ENABLE_FEATURE_PARTNER_SHIPPING_COUPON']==='1'? (<>
          <Route path="/external/partner-shipping-coupons" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerShippingCouponsPage'))} />} />
          <Route path="/external/partner-shipping-coupon/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerShippingCouponViewPage'))} />} />
          <Route path="/external/partner-shipping-coupon/:process/*" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerShippingCouponPage'))} />} />
        </>): (<></>)}

        {user.isExSuperAdmin()? (
          <Route path="/external/partner-settings/*" element={<LazyRoute dom={lazy(() => import('../views/external/PartnerSettingsPage'))} />} />
        ): (<></>)}

        {/* Customer */}
        {/* {settings['APP_MODULE_CUSTOMER_GROUP']==='1'? (<>
          <Route path="/external/customer-groups" element={<LazyRoute dom={lazy(() => import('../views/external/CustomerGroupsPage'))} />} />
          <Route path="/external/customer-group/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/CustomerGroupViewPage'))} />} />
        </>): (<></>)} */}

        <Route path="/external/customer-tiers" element={<LazyRoute dom={lazy(() => import('../views/external/CustomerTiersPage'))} />} />
        <Route path="/external/customer-tier/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/CustomerTierViewPage'))} />} />

        <Route path="/external/customers" element={<LazyRoute dom={lazy(() => import('../views/external/CustomersPage'))} />} />
        <Route path="/external/customer/view/:dataId/*" element={<LazyRoute dom={lazy(() => import('../views/external/CustomerViewPage'))} />} />
        <Route path="/external/customer/:process/*" element={<LazyRoute dom={lazy(() => import('../views/external/CustomerPage'))} />} />

        <Route path="/external/customer-orders" element={<LazyRoute dom={lazy(() => import('../views/external/CustomerOrdersPage'))} />} />
        <Route path="/external/customer-order/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/CustomerOrderViewPage'))} />} />
        <Route path="/external/customer-order/:process/*" element={<LazyRoute dom={lazy(() => import('../views/external/CustomerOrderPage'))} />} />
          
        {settings['APP_MODULE_PAYMENT_2C2P']==='1'? (
          <Route path="/external/customer-2c2p-carts" element={<LazyRoute dom={lazy(() => import('../views/external/CustomerCarts2C2PPage'))} />} />
        ): (<></>)}
        {settings['APP_MODULE_PARTNER_SUBSCRIPTION']==='1'? (<>
          <Route path="/external/customer-subscription-2c2p-carts" element={<LazyRoute dom={lazy(() => import('../views/external/CustomerSubscriptionCarts2C2PPage'))} />} />
        </>): (<></>)}

        {settings['APP_MODULE_CHAT_CUSTOMER']==='1' && settings[`${prefix}ENABLE_CHAT_CUSTOMER`]==='1'? (
          <Route path="/external/customer-messages/*" element={<LazyRoute dom={lazy(() => import('../views/external/CustomerMessagesPage'))} />} />
        ): (<></>)}
        {settings['APP_MODULE_CHAT_GUEST']==='1' && settings[`${prefix}ENABLE_CHAT_GUEST`]==='1'? (
          <Route path="/external/guest-messages/*" element={<LazyRoute dom={lazy(() => import('../views/external/GuestMessagesPage'))} />} />
        ): (<></>)}

        {/* CMS */}
        <Route path="/external/cms-content-categories" element={<LazyRoute dom={lazy(() => import('../views/external/CmsContentCategoriesPage'))} />} />
        <Route path="/external/cms-content-category/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/CmsContentCategoryViewPage'))} />} />
        <Route path="/external/cms-content-category/:process/*" element={<LazyRoute dom={lazy(() => import('../views/external/CmsContentCategoryPage'))} />} />
          
        <Route path="/external/cms-contents" element={<LazyRoute dom={lazy(() => import('../views/external/CmsContentsPage'))} />} />
        <Route path="/external/cms-content/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/CmsContentViewPage'))} />} />
        <Route path="/external/cms-content/:process/*" element={<LazyRoute dom={lazy(() => import('../views/external/CmsContentPage'))} />} />
          
        <Route path="/external/cms-banners" element={<LazyRoute dom={lazy(() => import('../views/external/CmsBannersPage'))} />} />
        <Route path="/external/cms-banner/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/CmsBannerViewPage'))} />} />
        <Route path="/external/cms-banner/:process/*" element={<LazyRoute dom={lazy(() => import('../views/external/CmsBannerPage'))} />} />
          
        <Route path="/external/cms-popups" element={<LazyRoute dom={lazy(() => import('../views/external/CmsPopupsPage'))} />} />
        <Route path="/external/cms-popup/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/external/CmsPopupViewPage'))} />} />
        <Route path="/external/cms-popup/:process/*" element={<LazyRoute dom={lazy(() => import('../views/external/CmsPopupPage'))} />} />

        {user.isExSuperAdmin()? (
          <Route path="/external/cms-settings/*" element={<LazyRoute dom={lazy(() => import('../views/external/CmsSettingsPage'))} />} />
        ): (<></>)}

        {/* Report */}
        <Route path="/external/report-partner-shops" element={<LazyRoute dom={lazy(() => import('../views/external/ReportPartnerShopPage'))} />} />
        <Route path="/external/report-partner-products" element={<LazyRoute dom={lazy(() => import('../views/external/ReportPartnerProductPage'))} />} />
        <Route path="/external/report-partner-coupons" element={<LazyRoute dom={lazy(() => import('../views/external/ReportPartnerCouponPage'))} />} />
          
        <Route path="/external/report-customers" element={<LazyRoute dom={lazy(() => import('../views/external/ReportCustomerPage'))} />} />
        <Route path="/external/report-customer-orders" element={<LazyRoute dom={lazy(() => import('../views/external/ReportCustomerOrderPage'))} />} />
        <Route path="/external/report-customer-product-orders" element={<LazyRoute dom={lazy(() => import('../views/external/ReportCustomerProductOrderPage'))} />} />

        {/* Must Have */}
        <Route path="/auth/customer-signup-qr-code/:partnerShopCode/*" element={<LazyRoute dom={lazy(() => import('../views/auth/CustomerSignupQrCodePage'))} />} />
        <Route path="/payment/failed" element={<LazyRoute dom={lazy(() => import('../views/payment/FailedPage'))} />} />
        <Route path="/payment/success/:dataId" element={<LazyRoute dom={lazy(() => import('../views/payment/SuccessPage'))} />} />

        <Route path="*" element={<Navigate replace to="/external" />} />
      </Routes>
    </Suspense>
  </>): (<></>);
}
