import {
  ShippingAddressModel, BillingAddressModel, FileModel, CustomerModel,
  PartnerProductSubscriptionModel, PartnerProductModel, PartnerProductUnitModel,
} from '.';
import { unescape } from 'html-escaper';

export class CustomerSubscriptionCartModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.customer = new CustomerModel(data.order || {});
    
    this.shippingAddress = new ShippingAddressModel(data.shippingAddress || {});
    this.billingAddress = new BillingAddressModel(data.billingAddress || {});
    
    this.subscription = new PartnerProductSubscriptionModel(data.subscription ||  {});
    this.relatedCredit = data.relatedCredit ?? 1;
    this.relatedProducts = data.relatedProducts?.length
      ? data.relatedProducts.map(d => this.relatedProduct(d)): [];
    this.selectionSteps = data.selectionSteps?.length
      ? data.selectionSteps.map(d => this.selectionStep(d)): [];

    this.discount = data.discount || data.discount===0? Number(data.discount): null;
    this.subtotal = data.subtotal || data.subtotal===0? Number(data.subtotal): null;
    
    this.vatName = data.vatName? unescape(data.vatName): '';
    this.vat = data.vat || data.vat===0? Number(data.vat): null;
    this.vatPercent = data.vatPercent || data.vatPercent===0? Number(data.vatPercent): null;

    this.total = data.total || data.total===0? Number(data.total): null;
    this.initialPriceInVAT = data.initialPriceInVAT || data.initialPriceInVAT===0 
      ? Number(data.initialPriceInVAT): null;
    this.grandTotal = data.grandTotal || data.grandTotal===0? Number(data.grandTotal): null;

    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
  }

  isValid(){
    return this._id? true: false;
  }

  relatedProduct(d={}){
    return {
      product: new PartnerProductModel(d.product || {}),
      productId: d.product?._id,
      unit: new PartnerProductUnitModel(d.unit || {}),
      unitId: d.unit?._id,
      credit: d.credit ?? 1,
      addPriceInVAT: d.addPriceInVAT ?? 0,
      inCart: d.inCart ?? 0,
    };
  }
  selectionStep(d={}){
    return {
      step: d.step || null,
      name: d.name? unescape(d.name): null,
      icon: new FileModel(d.icon || {}),
      credit: d.credit ?? 1,
      order: d.order || 1,
      products: d.products?.length? d.products.map(k => {
        return {
          product: new PartnerProductModel(k.product || {}),
          productId: k.product?._id,
          unit: new PartnerProductUnitModel(k.unit || {}),
          unitId: k.unit?._id,
          credit: d.credit ?? 1,
          inCart: k.inCart ?? 0,
        };
      }): [],
    };
  }
  
  hasShipBySupplier() {
    let shipBySupplier = false;
    [
      ...this.relatedProducts.map(d => d.product),
      ...this.selectionSteps.map(d => d.products).flat().map(d => d.product),
    ].forEach(d => {
      if(d.shipBySupplier) shipBySupplier = true;
    });
    return shipBySupplier;
  }
  shipBySupplierProducts() {
    return [
      ...this.relatedProducts.map(d => d.product),
      ...this.selectionSteps.map(d => d.products).flat().map(d => d.product),
    ].filter(d => d.product?.shipBySupplier);
  }
}
