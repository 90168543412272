import { PartnerShopModel, WarehouseModel, FileModel } from '.';
import { unescape } from 'html-escaper';
import { translate } from '../helpers/frontend';
import { SYSTEM_LANGUAGES } from '../actions/variables';
import dataLogisticTypes from '../data/logistic-types.json';

export class LogisticVehicleModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.logisticType = data.logisticType? Number(data.logisticType): 1;
    
    this.warehouse = new WarehouseModel(data.warehouse? data.warehouse: {});
    this.partnerShop = new PartnerShopModel(data.partnerShop? data.partnerShop: {});
    
    this.name = data.name? unescape(data.name): null;
    this.licenseNumber = data.licenseNumber? unescape(data.licenseNumber): null;
    this.description = data.description? unescape(data.description): null;
    
    this.image = new FileModel(data.image? data.image: {});
    this.gallery = data.gallery && data.gallery.length
      ? data.gallery.map(d => new FileModel(d)): [];
      
    this.containers = this.formatContainers(data.containers);

    this.costOverhead = data.costOverhead || data.costOverhead===0? Number(data.costOverhead): null;
    this.costPerKm = data.costPerKm || data.costPerKm===0? Number(data.costPerKm): null;
    
    this.priority = data.priority? Number(data.priority): 1;
    this.status = data.status? Number(data.status): 0;

    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'name', 'description' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id && (this.warehouse.isValid() || this.partnerShop.isValid());
  }

  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
  displayLogisticType(langObj={}) {
    if(!this.isValid()) return '';
    let type = dataLogisticTypes.find(d => d.value === this.logisticType);
    if(!type) return '';
    else return translate(type.name, langObj);
  }

  formatContainers(containers=[]) {
    let result = [];
    if(containers && containers.length){
      containers.forEach(d => {
        result.push({
          weight: d.weight? Number(d.weight): 0,
          width: d.width? Number(d.width): 0,
          length: d.length? Number(d.length): 0,
          height: d.height? Number(d.height): 0,
        });
      });
    }
    return result;
  }
  initContainer() {
    return { weight: 0, width: 0, length: 0, height: 0 };
  }
}
