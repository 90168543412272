import {
  FileModel, UserModel, CustomerGroupModel,
  PartnerShopModel, PartnerProductModel, PartnerProductUnitModel, 
} from '.';
import { unescape } from 'html-escaper';
import { translate } from '../helpers/frontend';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  channel :
    C2U
    STORE

  recurringType : Number
    1 = Every X Days
    2 = Every X Months
    3 = Every X Years

  status
    0 = Inactive
    1 = Coming soon
    2 = Active
    4 = New
    5 = Featured
    6 = On Sales
*/

export class PartnerProductSubscriptionModel {
  constructor(data={}) {
    this._id = data?._id || null;
    this.channel = data.channel? unescape(data.channel): null;

    this.name = data.name? unescape(data.name): null;
    this.description = data.description? unescape(data.description): null;
    this.content = data.content? unescape(data.content): null;
    this.agreement = data.agreement? unescape(data.agreement): null;

    this.priceInVAT = data.priceInVAT? Number(data.priceInVAT): null;
    this.discountPriceInVAT = data.discountPriceInVAT? Number(data.discountPriceInVAT): null;
    this.discountStartAt = data.discountStartAt? data.discountStartAt: null;
    this.discountEndAt = data.discountEndAt? data.discountEndAt: null;

    this.recurringType = data.recurringType? Number(data.recurringType): 2;
    this.recurringInterval = data.recurringInterval? Number(data.recurringInterval): 1;
    this.recurringCount = data.recurringCount? Number(data.recurringCount): 12;
    
    this.image = new FileModel(data.image || {});
    this.gallery = data.gallery?.length? data.gallery.map(d => new FileModel(d)): [];

    this.hasRelatedProducts = data.hasRelatedProducts? Number(data.hasRelatedProducts): 0;
    this.relatedCredit = data.relatedCredit ?? 1;
    this.relatedProducts = data.relatedProducts?.length
      ? data.relatedProducts.map(d => this.relatedProduct(d)): [];

    this.selectionSteps = data.selectionSteps?.length
      ? data.selectionSteps.map(d => this.selectionStep(d)): [];

    this.forCustomerGroups = data.forCustomerGroups?.length
      ? data.forCustomerGroups.map(d => new CustomerGroupModel(d)): [];

    this.forAllPartnerShops = data.forAllPartnerShops? data.forAllPartnerShops: 0;
    this.forPartnerShops = data?.forPartnerShops?.length
      ? data.forPartnerShops.map(d => new PartnerShopModel(d)): [];

    this.freeShippingCost = data.freeShippingCost ?? 1;
      
    this.order = data.order? data.order: 1;
    this.status = data.status? data.status: 0;

    this.createdBy = new UserModel(data.createdBy || {});
    this.createdAt = data.createdAt || null;
    this.updatedBy = new UserModel(data.updatedBy || {});
    this.updatedAt = data.updatedAt || null;

    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'name', 'description', 'content' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid(){
    return this._id? true: false;
  }

  relatedProduct(d={}){
    return {
      product: new PartnerProductModel(d.product || {}),
      productId: d.product?._id,
      unit: new PartnerProductUnitModel(d.unit || {}),
      unitId: d.unit?._id,
      credit: d.credit ?? 1,
      quantity: d.quantity ?? 1,
      addPriceInVAT: d.addPriceInVAT ?? 0,
      inCart: d.inCart ?? d.quantity ?? 0,
    };
  }
  selectionStep(d={}){
    return {
      step: d.step || null,
      name: d.name? unescape(d.name): null,
      icon: new FileModel(d.icon || {}),
      credit: d.credit ?? 1,
      order: d.order || 1,
      products: d.products?.length? d.products.map(k => {
        return {
          product: new PartnerProductModel(k.product || {}),
          productId: k.product?._id,
          unit: new PartnerProductUnitModel(k.unit || {}),
          unitId: k.unit?._id,
          credit: k.credit ?? 1,
          quantity: k.quantity ?? 1,
          inCart: k.inCart ?? k.quantity ?? 0,
        };
      }): [],
    };
  }

  displayStatus(langObj={}){
    if(this.isValid()){
      if(this.status === 6) return (<span className="ss-tag bg-p">{translate('text_product_status_6', langObj)}</span>);
      else if(this.status === 5) return (<span className="ss-tag bg-info">{translate('text_product_status_5', langObj)}</span>);
      else if(this.status === 4) return (<span className="ss-tag bg-p">{translate('text_product_status_4', langObj)}</span>);
      else if(this.status === 2) return (<span className="ss-tag bg-success">{translate('text_product_status_2', langObj)}</span>);
      else if(this.status === 1) return (<span className="ss-tag bg-gray">{translate('text_product_status_1', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('text_product_status_0', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('text_product_status_0', langObj)}</span>);
    }
  }
  displayFrequency(langObj={}){
    if(!this.isValid()) return '';
    let _text = '';
    if(this.recurringType === 1){
      if(this.recurringInterval === 1) _text += translate('Per Day', langObj);
      else _text += translate('Per _X_ Days', langObj, { '_X_': this.recurringInterval });
    }else if(this.recurringType === 2){
      if(this.recurringInterval === 1) _text += translate('Per Month', langObj);
      else _text += translate('Per _X_ Months', langObj, { '_X_': this.recurringInterval });
    }else if(this.recurringType === 3){
      if(this.recurringInterval === 1) _text += translate('Per Year', langObj);
      else _text += translate('Per _X_ Years', langObj, { '_X_': this.recurringInterval });
    }
    if(this.recurringCount === 1) _text += ` ${translate('For 1 Time', langObj)}`;
    else _text += ` ${translate('For _X_ Times', langObj, { '_X_': this.recurringCount })}`;
    return _text;
  }
  displayFreeShippingCost(langObj={}) {
    if(this.isValid()){
      if(this.freeShippingCost === 1) return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }
}
