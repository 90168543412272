import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import { connect } from 'react-redux';
import { alertLoading } from '../actions/alert.actions';

import { ToastContainer } from 'react-toastify';

function AlertPopup(props) {
  const location = useLocation();
	const [currentPath, setCurrentPath] = useState('/');
  
	const [offsetY, setOffsetY] = useState(0);
	const backToTop = (e) => {
    e?.preventDefault();
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	};
  
  /* eslint-disable */
	useEffect(() => {
    if(currentPath !== location.pathname){
      setCurrentPath(location.pathname);
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);
	useEffect(() => {
		setOffsetY(window.pageYOffset);
		const handleScroll = () => {
			setOffsetY(window.pageYOffset);
		}
		window.addEventListener('scroll', handleScroll);
		return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  /* eslint-enable */

  return (<>
    <ToastContainer />
    <div id="global-loader" className={`${props.alert.loading? 'active': ''}`}>
      <div className="loader color-p">
        <CircularProgress color="inherit" size={60} thickness={4} />
      </div>
    </div>
    <div id="back-to-top" className={`${offsetY > 50? 'active': ''}`} onClick={backToTop}>
      <em className="fa fa-long-arrow-up"></em>
    </div>
  </>);
}

AlertPopup.defaultProps = {
  
};
AlertPopup.propTypes = {
  alertLoading: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	alert: state.alert,
});

export default connect(mapStateToProps, {
  alertLoading: alertLoading,
})(AlertPopup);