import { CustomerSubscriptionModel } from '.';
import { unescape } from 'html-escaper';
import { translate } from '../helpers/frontend';

/*
  type : String
    - C2U
    - Store

  status : Number
    -1 = Charge Failed
    0  = Cancel
    1  = On Hold
    2  = In Process
    3  = Completed
*/

export class CustomerSubscriptionPlanModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.subscription = new CustomerSubscriptionModel(data.subscription || {});
    
    this.recurringDate = data.recurringDate || null;
    
    this.orderId2C2P = data.orderId2C2P? unescape(data.orderId2C2P): null;
    this.paymentToken2C2P = data.paymentToken2C2P ?? null;
    
    this.total = data.total || data.total===0? Number(data.total): null;
    this.initialPriceInVAT = data.initialPriceInVAT || data.initialPriceInVAT===0 
      ? Number(data.initialPriceInVAT): null;
    this.shippingCost = data.shippingCost || data.shippingCost===0? Number(data.shippingCost): null;

    this.paymentAt = data.paymentAt || null;
    this.status = data.status ?? 0;

    this.order = data.order || {};

    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
  }

  isValid(){ return this._id? true: false; }
  isValidOrder(){ return this.isValid() && this.order?._id? true: false; }
  
  displayStatus(langObj={}){
    if(!this.isValid()) return (<></>);
    if(this.status === -1) return (<span className="ss-tag bg-danger">{translate('Charge Failed', langObj)}</span>);
    if(this.status === 0) return (<span className="ss-tag bg-warning">{translate('Cancel', langObj)}</span>);
    if(this.status === 1) return (<span className="ss-tag bg-gray">{translate('On Hold', langObj)}</span>);
    if(this.status === 2) return (<span className="ss-tag bg-info">{translate('In Process', langObj)}</span>);
    if(this.status === 3) return (<span className="ss-tag bg-success">{translate('Completed', langObj)}</span>);
    return (<></>);
  }
}
