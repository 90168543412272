import { DataProvinceModel } from '.';

/*
  isDefault : Number
    1 = Yes
    0 = No
  provinces : Array
  minDuration: Number
  maxDuration: Number
*/

export class ShippingDurationModel {
  constructor(data={}) {
    this.isDefault = data.isDefault? Number(data.isDefault): 0;
    this.provinces = data.provinces && data.provinces.length 
      ? data.provinces.map(d => new DataProvinceModel(d)): [];
    this.minDuration = data.minDuration || data.minDuration===0? Number(data.minDuration): null;
    this.maxDuration = data.maxDuration || data.maxDuration===0? Number(data.maxDuration): null;
    this.shippingCost = data.shippingCost? Number(data.shippingCost): 0;
  }
}
