import { CmsContentModel, FileModel, CustomerGroupModel } from '.';
import { translate } from '../helpers/frontend';
import { unescape } from 'html-escaper';

/*
  type : String
    - C2U
    - Store

  status :
    0 = Inactive
    1 = Active
    2 = Featured
*/

export class CmsPopupModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.type = data.type? unescape(data.type): 'C2U';
    
    this.isExternal = data.isExternal? Number(data.isExternal): 0;
    this.externalUrl = data.externalUrl? unescape(data.externalUrl): '';
    this.content = new CmsContentModel(data.content? data.content: {});
    
    this.image = new FileModel(data.image? data.image: {});
    this.imageTablet = new FileModel(data.imageTablet? data.imageTablet: {});
    this.imageMobile = new FileModel(data.imageMobile? data.imageMobile: {});
    
    this.order = data.order? Number(data.order): 1;
    this.status = data.status? Number(data.status): 0;

    this.forCustomerGroups = data.forCustomerGroups && data.forCustomerGroups.length
    ? data.forCustomerGroups.map(d => new CustomerGroupModel(d)): [];
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 2) return (<span className="ss-tag bg-info">{translate('Featured', langObj)}</span>);
      else if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
  
  existInGroup(groupId) {
    const contentContainGroup = this.content.forCustomerGroups.find(d => d._id === groupId) !== undefined;
    if(groupId && this.forCustomerGroups && this.forCustomerGroups.length && (this.isExternal === 1 || (this.isExternal === 0 && contentContainGroup))) {
      const containGroup = this.forCustomerGroups.find(d => d._id === groupId) !== undefined;
      if(containGroup){
        return true;
      }else return false;
    } else return false;
  }
  canEditCustomerGroup(groupId) {
    const containGroup = this.content.forCustomerGroups.find(d => d._id === groupId) !== undefined;
    if(this.isExternal === 1 || (this.isExternal === 0 && containGroup)) return true;
    else return false;
  }
}
