import {
  CustomerModel, CustomerOrderModel,
  PartnerProductCouponModel, PartnerShippingCouponModel
} from '.';
import { translate } from '../helpers/frontend';

/*
  status
    -1  = Canceled order

    0  = Pending points
    1  = Burn points for partner product coupon
    2  = Earn points from returning partner product coupon
    3  = Burn points for partner shipping coupon
    4  = Earn points from returning partner shipping coupon
    5  = Burn points for order
    6  = Earn points from order

    80 = Sync points from SAP
    81 = Use points in SAP

    90 = Sync points from POS
    91 = Use points in POS

    100 = System
*/

export class CustomerPointModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.customer = new CustomerModel(data.customer? data.customer: {});
    
    this.productCoupon = new PartnerProductCouponModel(data.productCoupon? data.productCoupon: {});
    this.shippingCoupon = new PartnerShippingCouponModel(data.shippingCoupon? data.shippingCoupon: {});
    this.order = new CustomerOrderModel(data.order? data.order: {});

    this.points = data.points? Number(data.points): 0;

    this.channel = data.channel? data.channel: 'C2U';
    this.description = data.description? data.description: null;

    this.status = data.status || [-1, 0].indexOf(data.status)>-1? Number(data.status): 0;

    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
  }

  isValid() {
    return this._id? true: false;
  }

  displayType() {
    if(this.isValid()){
      if([-1].indexOf(this.status) > -1){
        return (<span className="ss-tag bg-danger">Canceled Order</span>);
      }else if([1, 3, 5].indexOf(this.status) > -1){
        return (<span className="ss-tag bg-warning">Burn Points</span>);
      }else if([2, 4, 6].indexOf(this.status) > -1){
        return (<span className="ss-tag bg-success">Earn Points</span>);
      }else if([0].indexOf(this.status) > -1){
        return (<span className="ss-tag bg-p">Pending Points</span>);
      }else if([80, 90].indexOf(this.status) > -1){
        return (<span className="ss-tag bg-info">Sync Points</span>);
      }else if([81].indexOf(this.status) > -1){
        if(this.points > 0){
          return (<span className="ss-tag bg-success">From SAP</span>);
        }else{
          return (<span className="ss-tag bg-warning">From SAP</span>);
        }
      }else if([91].indexOf(this.status) > -1){
        if(this.points > 0){
          return (<span className="ss-tag bg-success">From POS</span>);
        }else{
          return (<span className="ss-tag bg-warning">From POS</span>);
        }
      }else if([100].indexOf(this.status) > -1){
        return (<span className="ss-tag bg-info">System</span>);
      }else{
        return (<></>);
      }
    }else{
      return (<></>);
    }
  }
  displayDescription(langObj={}) {
    if(this.isValid()){
      if(this.description){
        return this.description;
      }else{
        if(this.status===-1){
          return `${translate('Canceled Order', langObj)} ${this.order.orderId? this.order.orderId: ''}`;
        }else if(this.status===1){
          return `${translate('Redeem product discount coupon', langObj)} ${this.productCoupon.name? this.productCoupon.name: ''}`; 
        }else if(this.status===2){
          return `${translate('Return Discount Coupon', langObj)} ${this.productCoupon.name? this.productCoupon.name: ''}`;
        }else if(this.status===3){
          return `${translate('Redeem Shipping Coupon', langObj)} ${this.shippingCoupon.name? this.shippingCoupon.name: ''}`;
        }else if(this.status===4){
          return `${translate('Return Shipping Coupon', langObj)} ${this.shippingCoupon.name? this.shippingCoupon.name: ''}`;
        }else if(this.status===5){
          return `${translate('Use it as an order discount', langObj)} ${this.order.orderId? this.order.orderId: ''}`;
        }else if(this.status===6){
          return `${translate('From the order', langObj)} ${this.order.orderId? this.order.orderId: ''}`;
        }else if(this.status===0){
          return `${translate('Waiting for payment', langObj)} ${this.order.orderId? this.order.orderId: ''}`;
        }else if(this.status===80){
          return `${translate('Sync Points from SAP system', langObj)}`; 
        }else if(this.status===90){
          return `${translate('Sync Points from POS system', langObj)}`; 
        }else{
          return '';
        }
      }
    }else{
      return '';
    }
  }
}
