import { UserModel, FileModel, AddressModel, WorkingHourModel } from '.';
import { unescape } from 'html-escaper';
import { translate } from '../helpers/frontend';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  type :
    1 = Center
    2 = CoCo
    3 = Dealer
    9 = External

  status :
    0 = ปิดใช้งาน
    1 = เปิดใช้งาน
*/

export class PartnerShopModel {
  constructor(data={}) {
    this._id = data?._id || null;
    this.partner = new UserModel(data.partner? data.partner: {});
    
    this.code = data.code || null;
    this.erpCode = data.erpCode || null;
    this.erpEmployeeCode = data.erpEmployeeCode || null;
    this.erpStockCode = data.erpStockCode || null;

    this.usePOS = data.usePOS? Number(data.usePOS): 0;

    this.name = data.name? unescape(data.name): null;
    this.description = data.description? unescape(data.description): null;

    this.type = data.type? Number(data.type): 0;
    this.url = data.url? data.url: null;
    
    this.image = new FileModel(data.image? data.image: {});
    this.gallery = data.gallery?.length? data.gallery.map(d => new FileModel(d)): [];

    this.email = data.email? data.email: null;
    this.address = new AddressModel(data.address? data.address: {});
    this.telephones = data.telephones && data.telephones.length? data.telephones: [];
    
    this.line = data.line? data.line: null;
    this.facebook = data.facebook? data.facebook: null;
    this.instagram = data.instagram? data.instagram: null;
    this.website = data.website? data.website: null;

    this.workingHours = data.workingHours?.length
      ? data.workingHours.map(d => new WorkingHourModel(d))
      : [0, 1, 2, 3, 4, 5, 6].map(d => new WorkingHourModel({ dayIndex: d, isOpened: 0 }));
    
    this.status = data.status? data.status: 0;

    // Supply Chain
    this.canDispatch = data.canDispatch? Number(data.canDispatch): 0;

    // Calculation
    this.distance = data.distance || data.distance===0 || data.distance!==999999
      ? Number(data.distance): null;

    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'name', 'description' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayUsePOS(langObj={}) {
    if(this.isValid()){
      if(this.usePOS === 1) return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }

  displayType(langObj={}) {
    if(this.isValid()){
      if(this.type === 1) return (<span className="ss-tag bg-p">{translate('shop_type_1', langObj)}</span>);
      else if(this.type === 2) return (<span className="ss-tag bg-info">{translate('shop_type_2', langObj)}</span>);
      else if(this.type === 3) return (<span className="ss-tag bg-success">{translate('shop_type_3', langObj)}</span>);
      else if(this.type === 9) return (<span className="ss-tag bg-p">{translate('shop_type_9', langObj)}</span>);
      else return (<></>);
    }else{
      return (<></>);
    }
  }
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
}
