import { translate } from '../helpers/frontend';
import { unescape } from 'html-escaper';
import { SYSTEM_LANGUAGES } from '../actions/variables';

export class PackagingModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.name = data.name? unescape(data.name): '';

    this.width = data.width? Number(data.width): 0;
    this.length = data.length? Number(data.length): 0;
    this.height = data.height? Number(data.height): 0;
    
    this.maxWeight = data.maxWeight? Number(data.maxWeight): 0;
    this.price = data.price? Number(data.price): 0;

    this.order = data.order? Number(data.order): 1;
    this.status = data.status? Number(data.status): 0;
    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'name' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }

  displayDimension() {
    if(this.isValid()){
      return (<>{this.width} x {this.length} x {this.height} CM<sup>3</sup></>);
    }else{
      return (<></>);
    }
  }
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
}
