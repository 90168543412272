import { CurrencyModel } from '.';

export class UserPreferenceModel {
  constructor(data={}) {
    this._id = data?._id || null;
    this.preference = data.preference? data.preference: '';
    this.value = this.cleanValue(this.preference, data.value);
  }

  isValid() {
    return this._id? true: false;
  }

  cleanValue(pref, value=null) {
    if(pref === 'Currency'){
      return new CurrencyModel(value? value: {});
    }else if(pref === 'Theme'){
      return {
        theme: value && value.theme? value.theme: 'theme-01',
        color: value && value.color? value.color: 'color-01',
        isDark: value && value.isDark? 1: 0,
      };
    }else if(pref === 'IntelligenceMapbox'){
      return {
        center: value && (value.center && value.center.length === 2) 
          ? value.center: [ 100.635, 13.72 ],
        zoom: Number(value && value.zoom? value.zoom: 15.436503936356745),
        pitch: Number(value && value.pitch? value.pitch: 60),
        bearing: Number(value && value.bearing? value.bearing: 0),
        style: value && value.style? value.style: 'mapbox://styles/mapbox/dark-v9',
        projection: value && value.projection? value.projection: 'equirectangular',
        lightPreset: value && value.lightPreset? value.lightPreset: 'day',
        showPlaceLabels: value && (value.showPlaceLabels || value.showPlaceLabels === false) 
          ? value.showPlaceLabels: true,
        showRoadLabels: value && (value.showRoadLabels || value.showRoadLabels === false) 
          ? value.showRoadLabels: true,
        showPointOfInterestLabels: value && (value.showPointOfInterestLabels || value.showPointOfInterestLabels === false) 
          ? value.showPointOfInterestLabels: true,
        showTransitLabels: value && (value.showTransitLabels || value.showTransitLabels === false) 
          ? value.showTransitLabels: true,
        excludeWarehouseIds: value && value.excludeWarehouseIds && value.excludeWarehouseIds.length 
          ? value.excludeWarehouseIds: [],
        excludePartnerShopIds: value && value.excludePartnerShopIds && value.excludePartnerShopIds.length 
          ? value.excludePartnerShopIds: [],
        direction: value && value.direction? true: false,
      };
    }else if(pref === 'SupplyChainMapbox'){
      return {
        center: value && (value.center && value.center.length === 2) 
          ? value.center: [ 2.2948308741127903, 48.86046794008712 ],
        zoom: Number(value && value.zoom? value.zoom: 15.436503936356745),
        pitch: Number(value && value.pitch? value.pitch: 60),
        bearing: Number(value && value.bearing? value.bearing: 0),
        style: value && value.style? value.style: 'mapbox://styles/mapbox/standard',
        projection: value && value.projection? value.projection: 'equirectangular',
        lightPreset: value && value.lightPreset? value.lightPreset: 'day',
        showPlaceLabels: value && (value.showPlaceLabels || value.showPlaceLabels === false) 
          ? value.showPlaceLabels: true,
        showRoadLabels: value && (value.showRoadLabels || value.showRoadLabels === false) 
          ? value.showRoadLabels: true,
        showPointOfInterestLabels: value && (value.showPointOfInterestLabels || value.showPointOfInterestLabels === false) 
          ? value.showPointOfInterestLabels: true,
        showTransitLabels: value && (value.showTransitLabels || value.showTransitLabels === false) 
          ? value.showTransitLabels: true,
        excludeWarehouseIds: value && value.excludeWarehouseIds && value.excludeWarehouseIds.length 
          ? value.excludeWarehouseIds: [],
        excludePartnerShopIds: value && value.excludePartnerShopIds && value.excludePartnerShopIds.length 
          ? value.excludePartnerShopIds: [],
        direction: value && value.direction? true: false,
      };
    }else{
      return {};
    }
  }
}
