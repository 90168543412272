import { lazy, Suspense, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { Routes, Route, Navigate } from 'react-router-dom';

import { AuthPageLoading } from '../views/auth';

export default function RoutePartner() {
  const { status, user, partnerShops, prefix, settings } = useContext(AuthContext);
  
  const shopTypes = partnerShops.map(d => d?.type ?? 0);
  const withCommission = (settings['APP_COMMISSION_PARTNER_CENTER']==='1' && shopTypes.indexOf(1) > -1) 
    || (settings['APP_COMMISSION_PARTNER_PARTNER']==='1' && shopTypes.indexOf(2) > -1) 
    || (settings['APP_COMMISSION_PARTNER_DEALER']==='1' && shopTypes.indexOf(3) > -1);

  const LazyRoute = ({ dom: Element }) => <Element />;
  return status === 'authenticated' && user.isPartner()? (<>
    <Suspense fallback={<AuthPageLoading />}>
      <Routes>
        {/* Partner */}
        <Route path="/partner" element={<LazyRoute dom={lazy(() => import('../views/partner/DashboardPage'))} />} />
        <Route path="/partner/dashboard" element={<LazyRoute dom={lazy(() => import('../views/partner/DashboardPage'))} />} />
        {partnerShops.length? (
          <Route path="/partner/register-customer" element={<LazyRoute dom={lazy(() => import('../views/partner/RegisterCustomerPage'))} />} />
        ): (<></>)}
        <Route path="/partner/sales-report" element={<LazyRoute dom={lazy(() => import('../views/partner/SalesReportPage'))} />} />
          
        {withCommission? (<>
          <Route path="/partner/commission-table" element={<LazyRoute dom={lazy(() => import('../views/partner/CommissionTablePage'))} />} />
          <Route path="/partner/commissions" element={<LazyRoute dom={lazy(() => import('../views/partner/CommissionsPage'))} />} />
          <Route path="/partner/commission-report" element={<LazyRoute dom={lazy(() => import('../views/partner/CommissionReportPage'))} />} />
        </>): (<></>)}

        {/* Personal */}
        <Route path="/partner/profile" element={<LazyRoute dom={lazy(() => import('../views/partner/ProfileViewPage'))} />} />
        <Route path="/partner/profile/update" element={<LazyRoute dom={lazy(() => import('../views/partner/ProfilePage'))} />} />
              
        {/* Partner */}
        {partnerShops.length? (<>
          <Route path="/partner/partner-shops" element={<LazyRoute dom={lazy(() => import('../views/partner/PartnerShopsPage'))} />} />
          <Route path="/partner/partner-shop/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/partner/PartnerShopViewPage'))} />} />
          <Route path="/partner/partner-shop/update/*" element={<LazyRoute dom={lazy(() => import('../views/partner/PartnerShopPage'))} />} />
        </>): (<></>)}

        <Route path="/partner/partner-products" element={<LazyRoute dom={lazy(() => import('../views/partner/PartnerProductsPage'))} />} />
        <Route path="/partner/partner-product/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/partner/PartnerProductViewPage'))} />} />

        {settings['APP_MODULE_PARTNER_PRODUCT_SET']==='1'? (<>
          <Route path="/partner/partner-product-sets" element={<LazyRoute dom={lazy(() => import('../views/partner/PartnerProductSetsPage'))} />} />
          <Route path="/partner/partner-product-set/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/partner/PartnerProductSetViewPage'))} />} />
        </>): (<></>)}

        {settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_REWARD']==='1'? (<>
          <Route path="/partner/partner-product-giveaway-rules" element={<LazyRoute dom={lazy(() => import('../views/partner/PartnerProductGiveawayRulesPage'))} />} />
          <Route path="/partner/partner-product-giveaway-rule/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/partner/PartnerProductGiveawayRuleViewPage'))} />} />
        </>): (<></>)}

        {settings['APP_MODULE_PARTNER_SUBSCRIPTION']==='1'? (<>
          <Route path="/partner/partner-product-subscriptions" element={<LazyRoute dom={lazy(() => import('../views/partner/PartnerProductSubscriptionsPage'))} />} />
          <Route path="/partner/partner-product-subscription/view/:dataId/*" element={<LazyRoute dom={lazy(() => import('../views/partner/PartnerProductSubscriptionViewPage'))} />} />
        </>): (<></>)}

        {settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_COUPON']==='1' || settings['APP_ENABLE_FEATURE_PARTNER_CASH_COUPON']==='1'? (<>
          <Route path="/partner/partner-product-coupons" element={<LazyRoute dom={lazy(() => import('../views/partner/PartnerProductCouponsPage'))} />} />
          <Route path="/partner/partner-product-coupon/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/partner/PartnerProductCouponViewPage'))} />} />
        </>): (<></>)}

        {settings['APP_ENABLE_FEATURE_PARTNER_SHIPPING_COUPON']==='1'? (<>
          <Route path="/partner/partner-shipping-coupons" element={<LazyRoute dom={lazy(() => import('../views/partner/PartnerShippingCouponsPage'))} />} />
          <Route path="/partner/partner-shipping-coupon/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/partner/PartnerShippingCouponViewPage'))} />} />
        </>): (<></>)}

        {/* Customer */}
        <Route path="/partner/customers" element={<LazyRoute dom={lazy(() => import('../views/partner/CustomersPage'))} />} />
        <Route path="/partner/customer/view/:dataId/*" element={<LazyRoute dom={lazy(() => import('../views/partner/CustomerViewPage'))} />} />

        <Route path="/partner/customer-orders" element={<LazyRoute dom={lazy(() => import('../views/partner/CustomerOrdersPage'))} />} />
        <Route path="/partner/customer-order/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/partner/CustomerOrderViewPage'))} />} />
        <Route path="/partner/customer-order/:process/*" element={<LazyRoute dom={lazy(() => import('../views/partner/CustomerOrderPage'))} />} />

        {settings['APP_MODULE_CHAT_CUSTOMER']==='1' && settings[`${prefix}ENABLE_CHAT_CUSTOMER`]==='1'? (
          <Route path="/partner/customer-messages/*" element={<LazyRoute dom={lazy(() => import('../views/partner/CustomerMessagesPage'))} />} />
        ): (<></>)}
        
        {/* Must Have */}
        <Route path="/auth/customer-signup-qr-code/:partnerShopCode/*" element={<LazyRoute dom={lazy(() => import('../views/auth/CustomerSignupQrCodePage'))} />} />
        <Route path="/payment/failed" element={<LazyRoute dom={lazy(() => import('../views/payment/FailedPage'))} />} />
        <Route path="/payment/success/:dataId" element={<LazyRoute dom={lazy(() => import('../views/payment/SuccessPage'))} />} />

        <Route path="*" element={<Navigate replace to="/partner" />} />
      </Routes>
    </Suspense>
  </>): (<></>);
}
