import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { onMounted, translate, appLogo, appBg, appCopyright } from '../../helpers/frontend';
import PasswordValidator from '../../components/PasswordValidator';

import { connect } from 'react-redux';
import { setSidenavActiveIndex } from '../../actions/app.actions';
import { userCheckResetPassword, userResetPassword } from '../../actions/user.actions';
import { processRead } from '../../actions/frontend.actions';

function ResetPasswordPage(props) {
  const { settings, langObj } = useContext(AuthContext);

  const history = useNavigate();
  const params = useParams();
  const token = params['*']? params['*']: null;

  const [process, setProcess] = useState(false);
  
  const [values, setValues] = useState({
    resetToken: token, newPassword: '', confirmNewPassword: ''
  });
  const onChangeInput = (key, val) => {
    setValues({ ...values, [key]: val });
  };

  const onSubmit = async (e) => {
    e?.preventDefault();
    let res = await props.processResetPassword(values);
    if(res){
      setValues({ resetToken: '', newPassword: '', confirmNewPassword: '' });
      setProcess(true);
    }
  };
  
  /* eslint-disable */
	useEffect(() => { onMounted(); props.setSidenavActiveIndex(0) }, []);
  useEffect(() => {
    const checkSetup = async () => {
      const res = await props.processRead('setup');
      if(!res?.isSetup) window.location.href = '/setup';
    };
    checkSetup();
  }, []);
	useEffect(() => {
    const onPreload = async () => {
      const res = await props.processCheckResetPassword(token);
      if(!res) history('/');
    };
    onPreload();
  }, []);
  /* eslint-enable */

  return (
    <section className="auth-01 section-padding">
      <div className="bg-img" style={{ backgroundImage: `url('${appBg(settings['SYSTEM_BG'])}')` }}></div>
      <div className="container">
        <div className="auth-container bradius box-shadow">
          <div className="auth-body bg-white">
            <div className="title">
              <img className="logo" src={appLogo(settings['SYSTEM_LOGO'])} alt="Logo" />
              <div className="text">
                <h5 className="fw-900 lh-sm">{translate('Reset Password', langObj)}</h5>
                <p className="fw-600 op-70">{translate('Back Office System', langObj)}</p>
              </div>
            </div>
            {!process? (<>
              <form onSubmit={onSubmit}>
                <div className="grids mt-4">
                  <div className="grid sm-100">
                    <div className="form-control">
                      <input
                        type="password" required={true} 
                        placeholder={translate('New Password', langObj)+' *'} 
                        value={values.newPassword? values.newPassword: ''} className="lg" 
                        onChange={e => onChangeInput('newPassword', e.target.value)} 
                      />
                    </div>
                  </div>
                  <div className="grid sm-100">
                    <div className="form-control">
                      <input
                        type="password" required={true} 
                        placeholder={translate('Confirm New Password', langObj)+' *'} 
                        value={values.confirmNewPassword? values.confirmNewPassword: ''} className="lg" 
                        onChange={e => onChangeInput('confirmNewPassword', e.target.value)} 
                      />
                    </div>
                  </div>
                  <div className="grid sm-100">
                    <PasswordValidator password={values.newPassword} />
                  </div>
                </div>
                <div className="btns">
                  <button type="submit" className="btn btn-action btn-p btn-lg w-full">
                    {translate('Reset Password', langObj)}
                  </button>
                </div>
              </form>
              <p className="sm color-gray text-center mt-6">
                <Link to="/auth/signin" className="color-s h-color-p fw-600">
                  {translate('Back to Signin Page', langObj)}
                </Link>
              </p>
            </>): (<>
              <p className="fw-500 text-center mt-6">
                {translate('text_reset_password_1', langObj)}
              </p>
              <div className="btns pt-2">
                <Link to="/auth/signin" className="btn btn-action btn-p btn-lg w-full">
                  {translate('Sign In', langObj)}
                </Link>
              </div>
            </>)}
          </div>
          <div className="auth-footer bg-s color-white">
            <p className="xxs fw-300 text-center">
              {appCopyright(settings['SYSTEM_COMPANY_NAME'])}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

ResetPasswordPage.defaultProps = {};
ResetPasswordPage.propTypes = {
	setSidenavActiveIndex: PropTypes.func.isRequired,
	processCheckResetPassword: PropTypes.func.isRequired,
  processResetPassword: PropTypes.func.isRequired,
  processRead: PropTypes.func.isRequired,
};
export default connect(() => ({}), {
  setSidenavActiveIndex: setSidenavActiveIndex,
  processCheckResetPassword: userCheckResetPassword,
  processResetPassword: userResetPassword,
  processRead: processRead,
})(ResetPasswordPage);