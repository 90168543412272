import { createContext, useState, useEffect } from 'react';
import { UserModel, PartnerShopModel } from '../models';

import CryptoJS from 'crypto-js';
import { API_URL, APP_PREFIX, TOKEN_KEY, REFRESH_KEY, SYSTEM_LANGUAGES } from '../actions/variables';

const AuthContext = createContext({
  status: 'loading',
  user: new UserModel(),
  partnerShops: [],
  externalShop: new PartnerShopModel(),
  shopCode: '',
  prefix: '',
  permissions: [],
  isSignedIn: false,
  onSignin: () => {},
  onSignout: () => {},

  onUpdate: () => {},
  onUpdateShops: () => {},

  settings: {},
  onSettingsUpdate: () => {},

  lang: 'TH',
  langObj: {},
  onLanguageUpdate: () => {},
});

export const AuthContextProvider = (props) => {
  const [status, setStatus] = useState('loading');
  const [user, setUser] = useState(new UserModel());
  const [partnerShops, setPartnerShops] = useState([]);
  const [externalShop, setExternalShop] = useState(new PartnerShopModel());
  const [permissions, setPermissions] = useState([]);

  const [settings, setSettings] = useState({});

  const [lang, setLang] = useState('TH');
  const [langObj, setLangObj] = useState({});
  
  const onSignin = async (u, aToken, rToken) => {
    try {
      if(u && aToken && rToken){
        const _user = new UserModel(u? u: {});
        if(_user.isSignedIn()){
          const _fetch = await fetch(`${API_URL}user/permission`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${aToken}`,
            },
          });
          if(_fetch.ok && _fetch.status === 200){
            const _data = await _fetch.json();
            
            const _u = CryptoJS.AES.encrypt(JSON.stringify(_user), TOKEN_KEY).toString();
            const _accessToken = CryptoJS.AES.encrypt(aToken, TOKEN_KEY).toString();
            const _refreshToken = CryptoJS.AES.encrypt(rToken, REFRESH_KEY).toString();
            
            localStorage.setItem(`${APP_PREFIX}_USER`, _u);
            localStorage.setItem(`${APP_PREFIX}_ACCESS`, _accessToken);
            localStorage.setItem(`${APP_PREFIX}_REFRESH`, _refreshToken);

            setStatus('authenticated');
            setUser(_user);
            setPartnerShops(_user.partnerShops.filter(d => d.type !== 9));
            setExternalShop(new PartnerShopModel(_user.partnerShops.find(d => d.type === 9) ?? {}));
            setPermissions(_data?.data?.result ?? []);
            return true;
          }
        }
      }
    } catch {}
    onSignout();
    return false;
  }
  const onSignout = () => {
    setStatus('unauthenticated');
    setUser(new UserModel());
    setPartnerShops([]);
    setExternalShop(new PartnerShopModel());
    setPermissions([]);
    localStorage.removeItem(`${APP_PREFIX}_USER`);
    localStorage.removeItem(`${APP_PREFIX}_ACCESS`);
    localStorage.removeItem(`${APP_PREFIX}_REFRESH`);
    return true;
  }

  const onUpdate = (_user=new UserModel()) => {
    if(status === 'authenticated' && user.isSignedIn()){
      const _newUser = {
        ...user,
        firstname: _user?.firstname || user.firstname,
        lastname: _user?.lastname || user.lastname,
        username: _user?.username || user.username,
        email: _user?.email || user.email,
        avatar: _user?.avatar || user.avatar,
        address: _user?.address || user.address,
      };
      setUser(new UserModel(_newUser));
      localStorage.setItem(`${APP_PREFIX}_USER`, CryptoJS.AES.encrypt(JSON.stringify(_newUser), TOKEN_KEY).toString());
    }
    return true;
  }
  const onUpdateShops = (_shops=[]) => {
    if(status === 'authenticated' && user.isSignedIn()){
      const _newUser = {
        ...user,
        partnerShops: _shops,
      };
      setUser(new UserModel(_newUser));
      setPartnerShops(_shops.map(d => d.type !== 9));
      setExternalShop(new PartnerShopModel(_shops.find(d => d.type === 9) ?? {}));
      localStorage.setItem(`${APP_PREFIX}_USER`, CryptoJS.AES.encrypt(JSON.stringify(_newUser), TOKEN_KEY).toString());
    }
    return true;
  }

  const onSettingsUpdate = async () => {
    let _settings = {};
    try {
      const _fetch = await fetch(`${API_URL}app/settings`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      if(_fetch.ok && _fetch.status === 200){
        const _data = await _fetch.json();
        _data?.data?.result.forEach(d => _settings[d.name] = d.value);
      }
    } catch {}
    setSettings(_settings);
  }

  const onLanguageUpdate = async (lc='', reload=false) => {
    if(lc && reload){
      localStorage.setItem(`${APP_PREFIX}_LANGUAGE`, lc);
      window.location.reload();
      return true;
    }
    
    if(!lc){
      let _lang = localStorage.getItem(`${APP_PREFIX}_LANGUAGE`);
      lc = _lang? _lang: SYSTEM_LANGUAGES.length? SYSTEM_LANGUAGES[0]: 'TH';
    }
    setLang(lc);
    localStorage.setItem(`${APP_PREFIX}_LANGUAGE`, lc);

    let _langObj = {};
    try {
      const _fetch = await fetch(`${API_URL}frontend/languages`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ dataFilter: { lang: lc } }),
      });
      if(_fetch.ok && _fetch.status === 200){
        const _data = await _fetch.json();
        _langObj = _data?.data?.result ?? {};
      }
    } catch (_) {}
    setLangObj(_langObj);
  }

  /* eslint-disable */
  useEffect(() => {
    const onLoad = async () => {
      if(status !== 'loading') return () => {};

      await Promise.all([
        new Promise(async resolve => { await onSettingsUpdate(); resolve(true); }),
        new Promise(async resolve => { await onLanguageUpdate(); resolve(true); }),
      ]);

      let _user = localStorage.getItem(`${APP_PREFIX}_USER`);
      let _accessToken = localStorage.getItem(`${APP_PREFIX}_ACCESS`);
      let _refreshToken = localStorage.getItem(`${APP_PREFIX}_REFRESH`);
      try {
        if(_user && _accessToken && _refreshToken){
          _user = CryptoJS.AES.decrypt(_user, TOKEN_KEY).toString(CryptoJS.enc.Utf8);
          _user = new UserModel(_user? JSON.parse(_user): {});
          _accessToken = CryptoJS.AES.decrypt(_accessToken, TOKEN_KEY).toString(CryptoJS.enc.Utf8);
          _refreshToken = CryptoJS.AES.decrypt(_refreshToken, REFRESH_KEY).toString(CryptoJS.enc.Utf8);

          const _fetch = await fetch(`${API_URL}auth/refresh`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ refreshToken: _refreshToken }),
          });
          if(_fetch.ok && _fetch.status === 200){
            const _data = await _fetch.json();
            if(_data?.data){
              await onSignin(_data.data.user, _data.data.accessToken, _data.data.refreshToken);
              return () => {};
            }
          }
        }
      } catch {}
      onSignout();
      return () => {};
    }
    onLoad();
  }, [])
  /* eslint-enable */

  return (
    <AuthContext.Provider 
      value={{
        status: status,
        user: user,
        partnerShops: partnerShops,
        externalShop: externalShop,
        shopCode: externalShop.isValid()? externalShop.code: '',
        prefix: externalShop.isValid()? `${externalShop.code}_`: '',
        permissions: permissions,
        isSignedIn: user.isSignedIn() && status === 'authenticated',
        onSignin: onSignin,
        onSignout: onSignout,

        onUpdate: onUpdate,
        onUpdateShops: onUpdateShops,

        settings: settings,
        onSettingsUpdate: onSettingsUpdate,

        lang: lang,
        langObj: langObj,
        onLanguageUpdate: onLanguageUpdate,
      }} 
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;