import { unescape } from 'html-escaper';
import { FileModel } from '.';
import { translate } from '../helpers/frontend';

export class CustomerGroupModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.name = data.name? unescape(data.name): null;
    this.description = data.description? unescape(data.description): null;
    
    this.icon = new FileModel(data.icon? data.icon: {});
    this.image = new FileModel(data.image? data.image: {});
    
    this.canUpdateShipping = data?.canUpdateShipping ?? 1;
    this.autoUseShipping = data?.autoUseShipping ?? 1;
    this.canUpdateBilling = data?.canUpdateBilling ?? 1;
    this.autoUseBilling = data?.autoUseBilling ?? 0;

    this.requiredTaxId = data?.requiredTaxId ??  1;
    this.enableRegistration = data?.enableRegistration ??  1;
    
    this.order = data?.order ?? 1;
    this.status = data?.status ?? 0;
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }

  displayCanUpdateShipping(langObj={}) {
    if(this.isValid()){
      if(this.canUpdateShipping === 1) return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }

  displayAutoUseShipping(langObj={}) {
    if(this.isValid()){
      if(this.autoUseShipping === 1) return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }

  displayCanUpdateBilling(langObj={}) {
    if(this.isValid()){
      if(this.canUpdateBilling === 1) return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }

  displayAutoUseBilling(langObj={}) {
    if(this.isValid()){
      if(this.autoUseBilling === 1) return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }

  displayEnableRegistration(langObj={}) {
    if(this.isValid()){
      if(this.enableRegistration === 1) return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }

  displayRequiredTaxId(langObj={}) {
    if(this.isValid()){
      if(this.requiredTaxId === 1 && this.enableRegistration === 1) return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }
}
