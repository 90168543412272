import { unescape } from 'html-escaper';
import { FileModel, CustomerGroupModel } from '.';
import { translate } from '../helpers/frontend';

export class CustomerTierModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.customerGroup = new CustomerGroupModel(data?.customerGroup || {});
    
    this.name = data.name? unescape(data.name): null;
    this.description = data.description? unescape(data.description): null;
    this.url = data.url? data.url: null;
    
    this.icon = new FileModel(data.icon? data.icon: {});
    
    this.pointEarnRate = data.pointEarnRate || data.pointEarnRate===0? Number(data.pointEarnRate): null;
    this.pointBurnRate = data.pointBurnRate || data.pointBurnRate===0? Number(data.pointBurnRate): null;

    this.minOrderMonthly = data.minOrderMonthly || data.minOrderMonthly===0? Number(data.minOrderMonthly): null;

    this.pointBurnStep = Number(data?.pointBurnStep ?? 1000);

    this.isDefault = data.isDefault? data.isDefault: 0;
    this.order = data.order? data.order: 1;
    this.status = data.status? data.status: 0;
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayDefault(langObj={}) {
    if(this.isValid()){
      if(this.isDefault === 1) return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
}
