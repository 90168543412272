import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import { onMounted, translate, appLogo, appBg, appCopyright } from '../../helpers/frontend';

import { connect } from 'react-redux';
import { setSidenavActiveIndex } from '../../actions/app.actions';
import { userSignin } from '../../actions/user.actions';
import { processRead } from '../../actions/frontend.actions';

function SigninPage(props) {
  const { onSignin, settings, langObj } = useContext(AuthContext);

  const [values, setValues] = useState({ username: '', password: '' });
  const onChangeInput = (key, val, isNumber=false) => {
    if(isNumber) val = val || val===0? Number(val): '';
    setValues({ ...values, [key]: val });
  }

  const onSubmit = async (e) => {
    e?.preventDefault();
    const res = await props.processSignin(values, true);
    if(res?.user) onSignin(res.user, res.accessToken, res.refreshToken);
    else onChangeInput('password', '');
  }
  
  /* eslint-disable */
	useEffect(() => { onMounted(); props.setSidenavActiveIndex(0) }, []);
  useEffect(() => {
    const checkSetup = async () => {
      const res = await props.processRead('setup');
      if(!res?.isSetup) window.location.href = '/setup';
    };
    checkSetup();
  }, []);
  /* eslint-enable */

  return (
    <section className="auth-01 section-padding">
      <div className="bg-img" style={{ backgroundImage: `url('${appBg(settings['SYSTEM_BG'])}')` }}></div>
      <div className="container">
        <div className="auth-container bradius box-shadow">
          <div className="auth-body bg-white">
            <div className="title">
              <img className="logo" src={appLogo(settings['SYSTEM_LOGO'])} alt="Logo" />
              <div className="text">
                <h5 className="fw-900 lh-sm">{translate('Sign In', langObj)}</h5>
                <p className="fw-600 op-70">{translate('Back Office System', langObj)}</p>
              </div>
            </div>
            <form onSubmit={onSubmit}>
              <div className="grids mt-4">
                <div className="grid sm-100">
                  <div className="form-control">
                    <input type="text" required={true} className="lg" 
                      placeholder={translate('Username / Email', langObj)} 
                      value={values.username? values.username: ''} 
                      onChange={e => onChangeInput('username', e.target.value)} 
                    />
                  </div>
                </div>
                <div className="grid sm-100">
                  <div className="form-control">
                    <input type="password" required={true} className="lg" 
                      placeholder={translate('Password', langObj)} 
                      value={values.password? values.password: ''} 
                      onChange={e => onChangeInput('password', e.target.value)} 
                    />
                  </div>
                </div>
              </div>
              <div className="btns">
                <button type="submit" className="btn btn-action btn-p btn-lg w-full">
                  {translate('Sign In', langObj)}
                </button>
              </div>
            </form>
            <p className="sm color-gray text-center mt-6">
              {translate('Forgot password', langObj)}?
              {' '}<Link to="/auth/forget-password" className="color-s h-color-p fw-600">
                {translate('Reset password', langObj)}
              </Link>
            </p>
          </div>
          <div className="auth-footer bg-s color-white">
            <p className="xxs fw-300 text-center">
              {appCopyright(settings['SYSTEM_COMPANY_NAME'])}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

SigninPage.defaultProps = {};
SigninPage.propTypes = {
	setSidenavActiveIndex: PropTypes.func.isRequired,
	processSignin: PropTypes.func.isRequired,
  processRead: PropTypes.func.isRequired,
};
export default connect(() => ({}), {
  setSidenavActiveIndex: setSidenavActiveIndex,
  processSignin: userSignin,
  processRead: processRead,
})(SigninPage);