import { unescape } from 'html-escaper';

/*
  level : Number
    99 = Super Admin
    98 = Admin
    
    89 = External Super Admin
    88 = External Admin

    50 = Supply Chain Super Admin
    49 = Supply Chain Admin

    40 = Warehouse Super Admin
    39 = Warehouse Admin
    35 = Driver
    
    10 = Partner
    2  = Sales Manager

    1  = User
*/

export class UserRoleModel {
  constructor(data={}) {
    this._id = data?._id || null;
    this.name = data.name? unescape(data.name): null;
    this.level = data.level? data.level: 0;
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayName() {
    if(this.isValid()) return this.name;
    else return '';
  }
}
