import { FileModel } from '.';
import { unescape } from 'html-escaper';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  type : Number
    1 = Default
    2 = Use Text
    3 = Use Icon
*/

export class PartnerProductStatusModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.productStatus = data.productStatus? Number(data.productStatus): 0;
    this.type = data.type? Number(data.type): 1;
    
    this.name = data.name? unescape(data.name): null;
    this.value = this.productStatus;
      
    this.text = data.text? unescape(data.text) 
      : this.productStatus === 6? '-_DISCOUNT_PERCENT_ %' 
      : this.productStatus === 5? 'Featured' 
      : this.productStatus === 4? 'New' 
      : this.productStatus === 3? 'Clearance' 
      : this.productStatus ===-1? 'Out of Stock' 
      : null;
    this.textColor = data.textColor? data.textColor 
      : this.productStatus === 6? '#FFFFFF' 
      : this.productStatus === 5? '#FFFFFF' 
      : this.productStatus === 4? '#FFFFFF' 
      : this.productStatus === 3? '#FFFFFF' 
      : this.productStatus ===-1? '#FFFFFF' 
      : null;
    this.textBgColor = data.textBgColor? data.textBgColor 
      : this.productStatus === 6? '#CF0A2C' 
      : this.productStatus === 5? '#5A8DEE' 
      : this.productStatus === 4? '#CF0A2C' 
      : this.productStatus === 3? '#777777' 
      : this.productStatus ===-1? '#999999' 
      : null;

    this.icon = new FileModel(data.icon? data.icon: {});

    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'text' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this.productStatus? true: false;
  }
}
