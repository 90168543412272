import { 
    UserModel, CustomerGroupModel, CustomerTierModel, 
    PartnerProductModel, PartnerShopModel, DataProvinceModel, 
    PartnerProductCategoryModel, PartnerProductUnitModel
} from '.';
import { translate } from '../helpers/frontend';
import { unescape } from 'html-escaper';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  channel : String
    - C2U
    - STORE

  status : Number
    0 = Inactive
    1 = Active

  availableType : Number
    1 = All products
    2 = Include products
    3 = Exclude products
    4 = Include product categories
    5 = Exclude product categories

  orderRules : Array
    availableType = 2, 3
        productId : String
        unitId : String
    availableType = 4, 5
        categoryId : String

  products : Array
    Product status = -1
*/

export class PartnerProductGiveawayRuleModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.name = data.name? unescape(data.name): null;
    this.channel = data.channel? unescape(data.channel): null;

    this.startAt = data.startAt? data.startAt: null;
    this.endAt = data.endAt? data.endAt: null;

    this.minimumOrder = data.minimumOrder? Number(data.minimumOrder): null;
    this.maximumOrder = data.maximumOrder? Number(data.maximumOrder): null;

    this.availableType = data.availableType? Number(data.availableType): 0;
    this.orderRules = data.orderRules && data.orderRules.length? data.orderRules: [];

    this.products = data.products && data.products.length
      ? data.products.map(d => new PartnerProductModel(d)): [];
    this.productCategories = data.productCategories && data.productCategories.length
      ? data.productCategories.map(d => new PartnerProductCategoryModel(d)): [];

    this.giveawayProducts = data.giveawayProducts && data.giveawayProducts.length
      ? data.giveawayProducts.map(d => ({
        product: new PartnerProductModel(d.product? d.product: {}),
        productId: d.productId? d.productId: null,
        unit: new PartnerProductUnitModel(d.unit? d.unit: {}),
        unitId: d.unitId? d.unitId: null,
        quantity: d.quantity ?? 1
      })): [];

    this.order = data.order? data.order: 1;
    this.status = data.status? data.status: 0;

    this.forCustomerGroups = data.forCustomerGroups && data.forCustomerGroups.length
      ? data.forCustomerGroups.map(d => new CustomerGroupModel(d)): [];

    this.forAllPartnerShops = data.forAllPartnerShops? data.forAllPartnerShops: 0;
    this.forPartnerShops = data.forPartnerShops && data.forPartnerShops.length
      ? data.forPartnerShops.map(d => new PartnerShopModel(d)): [];

    this.forAllCustomerTiers = data.forAllCustomerTiers? data.forAllCustomerTiers: 0;
    this.forCustomerTiers = data.forCustomerTiers && data.forCustomerTiers.length
      ? data.forCustomerTiers.map(d => new CustomerTierModel(d)): [];
      
    this.forAllProvinces = data.forAllProvinces? data.forAllProvinces: 0;
    this.forProvinces = data.forProvinces && data.forProvinces.length 
      ? data.forProvinces.map(d => new DataProvinceModel(d)): [];

    this.createdBy = new UserModel(data.createdBy || {});
    this.createdAt = data.createdAt || null;
    this.updatedBy = new UserModel(data.updatedBy || {});
    this.updatedAt = data.updatedAt || null;

    // Localization
    this.localization(data);
  }

  localization(data){
    let self = this;
    self.localizedKeys = [ 'name', 'description' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }

  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }

  displayAvailableType(langObj={}) {
    if(!this.isValid()) return '';
    else{
      if(this.availableType === 1) return translate('All Products', langObj);
      else if(this.availableType === 2) return translate('Include by Products', langObj);
      else if(this.availableType === 3) return translate('Exclude by Products', langObj);
      else if(this.availableType === 4) return translate('Include by Product Categories', langObj);
      else if(this.availableType === 5) return translate('Exclude by Product Categories', langObj);
      else return '';
    }
  }
  
  existInGroup(groupId) {
    if(groupId && this.forCustomerGroups && this.forCustomerGroups.length) {
      const containGroup = this.forCustomerGroups.find(d => d._id === groupId) !== undefined;
      if(containGroup){
        return true;
      }else return false;
    } else return false;
  }
}