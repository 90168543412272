
// Data
export const DATA_COUNTRIES = 'data/countries';
export const DATA_PROVINCES = 'data/provinces';
export const DATA_DISTRICTS = 'data/districts';
export const DATA_SUBDISTRICTS = 'data/subdistricts';


// App
export const CLIENT_IP = 'client/ip';
export const SIDENAV_ACTIVE_INDEX = 'sidenav/active-index';

export const APP_DATABASE_BACKUPS = 'app/database-backups';
export const APP_DATA_IMPORTS = 'app/data-imports';

export const APP_LANGUAGES = 'app/languages';

export const APP_USE_CURRENCY = 'app/use-currency';
export const APP_CURRENCY = 'app/currency';
export const APP_CURRENCIES = 'app/currencies';

export const APP_THEME = 'app/theme';
export const APP_THEMES = 'app/themes';

export const APP_USERS = 'app/users';
export const APP_PAYMENT_METHODS = 'app/payment-methods';
export const APP_PACKAGINGS = 'app/packagings';
export const APP_SHIPPING_STATUSES = 'app/shipping-statuses';
export const APP_SHIPPING_SUB_STATUSES = 'app/shipping-sub-statuses';
export const APP_SHIPPING_STATUS_MAPPINGS = 'app/shipping-status-mappings';


// Alert
export const ALERT_LOADING = 'alert/loading';


// Partner
export const PARTNER_PRODUCT_CATEGORIES = 'partner/product-categories';
export const PARTNER_PRODUCT_SUB_CATEGORIES = 'partner/product-sub-categories';
export const PARTNER_PRODUCT_SUB_SUB_CATEGORIES = 'partner/product-sub-sub-categories';
export const PARTNER_PRODUCT_BRANDS = 'partner/product-brands';
export const PARTNER_PRODUCT_STATUSES = 'partner/product-statuses';
export const PARTNER_PRODUCTS = 'partner/products';
export const PARTNER_PRODUCT_RATINGS = 'partner/product-ratings';
export const PARTNER_PRODUCT_GIVEAWAY_RULES = 'partner/product-giveaway-rules';
export const PARTNER_PRODUCT_UNITS = 'partner/product-units';
export const PARTNER_PRODUCT_ATTRIBUTES = 'partner/product-attributes';
export const PARTNER_SHIPPINGS = 'partner/shippings';
export const PARTNER_SHOPS = 'partner/shops';
export const PARTNER_SHOP_STOCKS = 'partner/shop-stocks';
export const PARTNER_SHOP_SHIPPINGS = 'partner/shop-shippings';
export const PARTNER_SHOP_COMMISSIONS = 'partner/shop-commissions';
export const PARTNER_PRODUCT_COUPONS = 'partner/product-coupons';
export const PARTNER_SHIPPING_COUPONS = 'partner/shipping-coupons';
export const PARTNER_PRODUCT_SUBSCRIPTIONS = 'partner/product-subscriptions';
export const PARTNER_EVENTS = 'partner/events';


// Customer
export const CUSTOMERS = 'customer/list';
export const CUSTOMER_TIERS = 'customer/tiers';
export const CUSTOMER_GROUPS = 'customer/groups';
export const CUSTOMER_SHIPPING_ADDRESSES = 'customer/shipping-addresses';
export const CUSTOMER_BILLING_ADDRESSES = 'customer/billing-addresses';
export const CUSTOMER_PRODUCT_COUPONS = 'customer/product-coupons';
export const CUSTOMER_SHIPPING_COUPONS = 'customer/shipping-coupons';
export const CUSTOMER_POINTS = 'customer/points';
export const CUSTOMER_ORDERS = 'customer/orders';
export const CUSTOMER_PUSH_NOTIS = 'customer/push-notis';
export const CUSTOMER_SUBSCRIPTIONS = 'customer/subscriptions';
export const CUSTOMER_SUBSCRIPTION_PLANS = 'customer/subscription-plans';
export const CUSTOMER_2C2P_CARTS = 'customer/2c2p-carts';
export const CUSTOMER_2C2P_SUBSCRIPTION_CARTS = 'customer/2c2p-subscription-carts';

// Seller
export const SELLER_SHOP_BRANDS = 'seller/shop-brands';
export const SELLER_SHOP_TYPES = 'seller/shop-types';
export const SELLER_SHOPS = 'seller/shops';
export const SELLER_SHOP_RATINGS = 'seller/shop-ratings';


// CMS
export const CMS_CONTENT_CATEGORIES = 'cms/content-categories';
export const CMS_CONTENTS = 'cms/contents';
export const CMS_BANNERS = 'cms/banners';
export const CMS_POPUPS = 'cms/popups';
export const CMS_CHECKOUT_CONTENTS = 'cms/checkout-contents';


// Logistic
export const LOGISTIC_WAREHOUSES = 'logistic/warehouses';
export const LOGISTIC_VEHICLES = 'logistic/vehicles';
export const LOGISTIC_TRANSPORT_ROUTES = 'logistic/transport-routes';
export const LOGISTIC_DISPATCH_ROUTES = 'logistic/dispatch-routes';
export const LOGISTIC_ROUTES = 'logistic/routes';

//Tools
export const TOOL_PARTNER_PRODUCT_PROMOTION = 'tool/partner-products';

