import { FileModel, PartnerProductModel } from '.';
import { translate } from '../helpers/frontend';
import { unescape } from 'html-escaper';

/*
  channel : String
    - C2U
    - STORE
*/

export class PartnerEventModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.channel = data.channel? data.channel: 'C2U';
    
    this.name = data.name? unescape(data.name): null;
    this.description = data.description? unescape(data.description): null;
    this.url = data.url? data.url: null;
    
    this.image = new FileModel(data.image? data.image: {});
    this.icon = new FileModel(data.icon? data.icon: {});

    this.startAt = data.startAt? data.startAt: null;
    this.endAt = data.endAt? data.endAt: null;
    
    this.products = data.products && data.products.length
      ? data.products.map(d => new PartnerProductModel(d)): [];

    this.order = data.order? data.order: 1;
    this.status = data.status? data.status: 0;
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
}
