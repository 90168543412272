import { CustomerGroupModel, FileModel, PackingDayModel, DataProvinceModel } from '.';
import { unescape } from 'html-escaper';
import { translate } from '../helpers/frontend';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  type : Number
    1  = Delivery by LF / Kerry Express
      subtype : Number
        1 = LF
        2 = Kerry Express
    2  = Click & Collect
    3  = Delivery by 3rd Party
      subtype : Number
        1 = Grab
        2 = Kerry Express
    4  = By Appointment
    5  = ??
    6  = Delivery by LF / Nim Express
      subtype : Number
        1 = LF
        2 = Nim Express
    7  = Delivery by LF
    8  = Internal Logistics
    9  = Internal Logistics (Kerry Express)
    10 = Internal Logistics (Nim Express)
    11 = DHL
      subtype : Number
        1 = Pickup from LF
        2 = Pickup from Store
        3 = Internal Warehouse

  shippingRules : Array
    shippingRule : Object
      province : ObjectId
      inService : Number
      forAllZipcodes : Number
      forZipcodes : Array
        
  packingDays : Array
    packingDay : Object
      dayIndex :
        0 = วันอาทิตย์
        1 = วันจันทร์
        2 = วันอังคาร
        3 = วันพุธ
        4 = วันพฤหัสบดี
        5 = วันศุกร์
        6 = วันเสาร์
      startTime
      endTime
      duration : Number
*/

export class PartnerShippingModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.name = data.name? unescape(data.name): null;
    this.displayName = data.displayName? unescape(data.displayName): null;
    this.description = data.description? unescape(data.description): null;

    this.minimumOrder = data.minimumOrder || data.minimumOrder===0? Number(data.minimumOrder): null;
    this.maximumOrder = data.maximumOrder || data.maximumOrder===0? Number(data.maximumOrder): null;

    this.icon = new FileModel(data.icon? data.icon: {});

    this.type = data.type? Number(data.type): null;
    this.subtype = data.subtype? Number(data.subtype): null;
    
    this.lfWeightFactor = data.lfWeightFactor? Number(data.lfWeightFactor): 0;

    this.kerryMaximumOrder = data.kerryMaximumOrder || data.kerryMaximumOrder===0
      ? Number(data.kerryMaximumOrder): 0;
    this.kerryEnableCOD = data.kerryEnableCOD || data.kerryEnableCOD===0
      ? Number(data.kerryEnableCOD): 0;
    this.kerryMaximumCOD = data.kerryMaximumCOD || data.kerryMaximumCOD===0
      ? Number(data.kerryMaximumCOD): null;

    this.packingDays = data.packingDays && data.packingDays.length
      ? data.packingDays.map(d => new PackingDayModel(d)): [];

    this.order = data.order? data.order: 1;
    this.status = data.status? data.status: 0;

    this.shippingRules = data.shippingRules && data.shippingRules.length 
      ? data.shippingRules.map(d => {
        let forZipcodes = d.forZipcodes && d.forZipcodes.length? d.forZipcodes.map(d => `${d}`): []
        return {
          province: new DataProvinceModel(d.province? d.province: {}),
          inService: d.inService? Number(d.inService): 0,
          forAllZipcodes: d.forAllZipcodes? Number(d.forAllZipcodes): 0,
          forZipcodes: forZipcodes,
        };
      }): [];

    this.shippingPrices = data.shippingPrices && data.shippingPrices.length 
      ? data.shippingPrices.map(d => {
        return {
          maxWeight: d.maxWeight || d.maxWeight===0? Number(d.maxWeight): null,
          maxDimensionTotal: d.maxDimensionTotal || d.maxDimensionTotal===0 
            ? Number(d.maxDimensionTotal): 0,
          price: d.price || d.price===0? Number(d.price): 0,
          forAllProvinces: d.forAllProvinces? 1: 0,
          conditions: d.conditions && d.conditions.length 
            ? d.conditions.map(k => {
              return {
                provinces: k.provinces && k.provinces.length 
                  ? k.provinces.map(m => new DataProvinceModel(m)): [],
                price: k.price || k.price===0? Number(k.price): 0,
              };
            }): [],
        };
      }): [];

    this.forCustomerGroups = data.forCustomerGroups && data.forCustomerGroups.length
      ? data.forCustomerGroups.map(d => new CustomerGroupModel(d)): [];

    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'displayName', 'description' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }

  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
  
  initShippingPrice() {
    return {
      maxWeight: null,
      maxDimensionTotal: null,
      price: null,
      forAllProvinces: 1,
      conditions: [],
    };
  }
  initShippingPriceCondition() {
    return {
      provinces: [],
      price: null,
    };
  }

  existInGroup(groupId) {
    if(groupId && this.forCustomerGroups && this.forCustomerGroups.length) {
      const containGroup = this.forCustomerGroups.find(d => d._id === groupId) !== undefined;
      if(containGroup){
        return true;
      }else return false;
    } else return false;
  }
}
