import { 
  FileModel, CustomerGroupModel, PartnerShopModel, 
  PartnerProductModel, PartnerProductCategoryModel, 
  PartnerProductUnitModel 
} from '.';

export class CustomerGroupProductTableModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.customerGroup = new CustomerGroupModel(data.customerGroup? data.customerGroup: {});
    this.shop = new PartnerShopModel(data.shop? data.shop: {});

    this.table = {};
    if(data.table && data.table.length){
      data.table.forEach(d => {
        // if(d?.product && !d?.productUnit){
        if(d?.product){
          this.table[d.product+(d?.productUnit? d?.productUnit: '')] = new PartnerProductModel({
            _id: d.product,
            productUnit: new PartnerProductUnitModel(d.productUnit? d.productUnit: {}),
            image: new FileModel(d.image? d.image: {}),
            gallery: d.gallery && d.gallery.length
              ? d.gallery.map(d => new FileModel(d)): [],

            // Product Set
            productSet: d.productSet && d.productSet.length? d.productSet: [],
            productSetCategories: d.productSetCategories && d.productSetCategories.length 
              ? d.productSetCategories.map(k => new PartnerProductCategoryModel(k)): [],
            productSetPriceInVAT: Number(d?.productSetPriceInVAT ?? 0),
            
            price: d.price? Number(d.price): null,
            priceInVAT: d.priceInVAT? Number(d.priceInVAT): null,
            memberPrice: d.memberPrice? Number(d.memberPrice): null,
            memberPriceInVAT: d.memberPriceInVAT? Number(d.memberPriceInVAT): null,
            discountPrice: d.discountPrice? Number(d.discountPrice): null,
            discountPriceInVAT: d.discountPriceInVAT? Number(d.discountPriceInVAT): null,
            discountStartAt: d.discountStartAt? d.discountStartAt: null,
            discountEndAt: d.discountEndAt? d.discountEndAt: null,
            status: d.status? Number(d.status): 0,
          });
        }
      });
    }
  }

  isValid() {
    return this._id? true: false;
  }
}