import { UserRoleModel, FileModel, AddressModel, PartnerShopModel } from '.';
import { translate } from '../helpers/frontend';
import { unescape } from 'html-escaper';

export class UserModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.role = new UserRoleModel(data.role? data.role: {});
    this.partnerShops = data.partnerShops && data.partnerShops.length
      ? data.partnerShops.filter(d => d).map(d => new PartnerShopModel(d)): [];
    this.partnerShopIds = this.partnerShops.map(d => d._id);

    this.erpEmployeeCode = data.erpEmployeeCode? data.erpEmployeeCode: null;
    
    this.firstname = data.firstname? unescape(data.firstname): null;
    this.lastname = data.lastname? unescape(data.lastname): null;
    this.username = data.username? data.username: null;
    this.email = data.email? data.email: null;
    this.avatar = new FileModel(
      data.avatar? data.avatar: { path: '/assets/img/default/avatar.jpg' }
    );

    this.address = new AddressModel(data.address? data.address: {});

    this.fcmToken = data.fcmToken? data.fcmToken: null;
    this.isOwner = Number(data.isOwner? 1: 0);
    this.status = data.status? data.status: 0;
  }

  isValid() { return this._id? true: false; }

  displayName() {
    if(this.firstname || this.lastname) return this.firstname+' '+this.lastname;
    else if(this.username) return this.username;
    else return '';
  }
  displayRole() {
    return this.role.displayName();
  }
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }

  isSignedIn() { return this._id && this.status === 1 && this.role.isValid()? true: false; }

  isSuperAdmin() { return this.isSignedIn() && [99].indexOf(this.role.level) > -1? true: false; }
  isAdmin() { return this.isSignedIn() && [98, 99].indexOf(this.role.level) > -1? true: false; }
  isExSuperAdmin() { return this.isSignedIn() && [89].indexOf(this.role.level) > -1? true: false; }
  isExAdmin() { return this.isSignedIn() && [88, 89].indexOf(this.role.level) > -1? true: false; }
  isSupplyChainSuperAdmin() { return this.isSignedIn() && [50].indexOf(this.role.level) > -1? true: false; }
  isSupplyChainAdmin() { return this.isSignedIn() && [49, 50].indexOf(this.role.level) > -1? true: false; }
  isPartner() { return this.isSignedIn() && [10].indexOf(this.role.level) > -1? true: false; }
  isSalesManager() { return this.isSignedIn() && [2].indexOf(this.role.level) > -1? true: false; }
  isUser() { return this.isSignedIn() && [1].indexOf(this.role.level) > -1? true: false; }
  
  isSuperUser() { return this.isOwner 
    || (this.isSuperAdmin() && ['sseepun'].indexOf(this.username) > -1); }
  isTester() { return this.isOwner 
    || (this.isSuperAdmin() && ['kijjw', 'SuperAdmin'].indexOf(this.username) > -1); }

  isInPartnerShops(shopId) { return this.partnerShopIds.indexOf(shopId) > -1; }

  path() {
    if(this.isSignedIn()){
      if(this.isAdmin()) return '/admin';
      else if(this.isExAdmin()) return '/external';
      else if(this.isSupplyChainAdmin()) return '/supply-chain';
      else if(this.isPartner()) return '/partner';
      else if(this.isSalesManager()) return '/sales-manager';
      else return '';
    }else{
      return '';
    }
  }
}
