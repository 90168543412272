import PropTypes from 'prop-types';
import { Fragment, useContext, useEffect, useState } from 'react';
import AuthContext from '../context/AuthContext';
import { Link, useLocation } from 'react-router-dom';
import { appLogo, appName, appShortName, hex2rgb, translate } from '../helpers/frontend';
import { unescape } from 'html-escaper';

import TopnavRealTime from './TopnavRealTime';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { connect, useDispatch } from 'react-redux';
import { processList as feProcessList } from '../actions/frontend.actions';
import { processList as smProcessList } from '../actions/sales-manager.actions';
import { APP_THEME, APP_USE_CURRENCY } from '../actions/types';
import { userRead, userSignout, userUpdate } from '../actions/user.actions';
import { DEV_MODE, DEV_PROCESS, SYSTEM_LANGUAGES } from '../actions/variables';
import { CurrencyModel, UserPreferenceModel } from '../models';

import { Swiper, SwiperSlide } from 'swiper/react';
import { getFirebaseDB } from '../helpers/firebase';

function Topnav(props) {
  const {
    user, isSignedIn, permissions,
    partnerShops, externalShop, prefix, onSignout,
    settings, lang, langObj, onLanguageUpdate,
  } = useContext(AuthContext);
  const dispatch = useDispatch();

  const location = useLocation();
	const [currentPath, setCurrentPath] = useState('/');
  const [isReady, setIsReady] = useState(false);

  
  // START: Menu
	const [menu, setMenu] = useState([]);
	const [menuToggle, setMenuToggle] = useState([]);
  const onMenuToggle = (e, i) => {
    e?.preventDefault();
    let temp = [...menuToggle];
    temp[i] = !temp[i];
    setMenuToggle(temp);
  };
  
	const [sidenavActive, setSidenavActive] = useState(false);
  const onSidenavActiveToggle = (e) => {
    e?.preventDefault();
    setSidenavActive(!sidenavActive);
  };
  // END: Menu


  // START: Currency
  const onCurrencyLoad = async () => {
    if(isSignedIn){
      const _pref = await props.userRead('preference', { preference: 'Currency' });
      dispatch({ type: APP_USE_CURRENCY, payload: _pref.value });
    }
  };
  const onCurrencyChange = async (e, d) => {
    e?.preventDefault();
    if(isSignedIn){
      const _currency = new CurrencyModel(d);
      await props.userUpdate('preference', {
        preference: 'Currency', value: { _id: _currency._id },
      }, true);
      dispatch({ type: APP_USE_CURRENCY, payload: _currency });
    }
  };
  // END: Currency


  // START: Language
  const processLanguageUpdate = (e, _lang) => {
    e?.preventDefault();
    if(_lang && _lang !== lang) onLanguageUpdate(_lang, true);
  };
  // END: Language
  

  // START: Theme
  const [useTheme, setUseTheme] = useState(false);
  const [theme, setTheme] = useState(new UserPreferenceModel({ preference: 'Theme' }));
  const [themeProcess, setThemeProcess] = useState(false);
  const onThemeLoad = async (newLoad=false) => {
    if(settings['APP_ENABLE_THEME'] === '1'){
      const _inTheme = (
        (currentPath.indexOf('/admin')===0 && user.isAdmin()) 
        || (currentPath.indexOf('/partner')===0 && user.isPartner()) 
        || (currentPath.indexOf('/sales-manager')===0 && user.isSalesManager()) 
        || (currentPath.indexOf('/external')===0 && user.isExAdmin()) 
        || (currentPath.indexOf('/supply-chain')===0 && user.isSupplyChainAdmin())
      );

      let _pref = new UserPreferenceModel({ preference: 'Theme' });
      if(isSignedIn && _inTheme && (!_pref.isValid() || newLoad)){
        _pref = await props.userRead('preference', { preference: 'Theme' });
      }
      
      const _prefVal = _pref.value;
      if((isSignedIn && _inTheme && !useTheme && _pref.isValid()) || newLoad){
        setUseTheme(true);
        const _el1 = document.getElementById('theme-grid');
        if(_el1) _el1.href = `/assets/theme/${_prefVal.theme}/css/grid.css`;
        const _el2 = document.getElementById('theme-style');
        if(_el2) _el2.href = `/assets/theme/${_prefVal.theme}/css/style.css`;
        const _el3 = document.getElementById('theme-nav');
        if(_el3) _el3.href = `/assets/theme/${_prefVal.theme}/css/nav.css`;
        const _el4 = document.getElementById('theme-block');
        if(_el4) _el4.href = `/assets/theme/${_prefVal.theme}/css/block.css`;
        const _el5 = document.getElementById('theme-color');
        if(_el5) _el5.href = `/assets/theme/${_prefVal.theme}/css/${_prefVal.color}.css`;
      }else if(!_inTheme && useTheme){
        setUseTheme(false);
        const _el1 = document.getElementById('theme-grid');
        if(_el1) _el1.href = `/assets/theme/theme-01/css/grid.css`;
        const _el2 = document.getElementById('theme-style');
        if(_el2) _el2.href = `/assets/theme/theme-01/css/style.css`;
        const _el3 = document.getElementById('theme-nav');
        if(_el3) _el3.href = `/assets/theme/theme-01/css/nav.css`;
        const _el4 = document.getElementById('theme-block');
        if(_el4) _el4.href = `/assets/theme/theme-01/css/block.css`;
        const _el5 = document.getElementById('theme-color');
        if(_el5) _el5.href = `/assets/theme/theme-01/css/color-01.css`;
      }

      setTheme(_pref);
      setTimeout(() => dispatch({ type: APP_THEME, payload: _prefVal }), 1000);
    }
  };
  const onThemeToggle = (e=null) => {
    e?.preventDefault();
    setThemeProcess(!themeProcess);
  };
  const onThemeChage = (key, val) => {
    if(key === 'theme'){
      let _theme = [ ...props.themes ].find(d => d.value === val);
      if(_theme){
        let _color = _theme.colors && _theme.colors.length? _theme.colors[0]: null;
        if(_color){
          setTheme(new UserPreferenceModel({
            ...theme,
            value: { ...theme.value, theme: val, color: _color.value, isDark: _color.isDark },
          }));
        }
      }
    }else{
      setTheme(new UserPreferenceModel({
        ...theme,
        value: { ...theme.value, [key]: val },
      }));
    }
  };
  const onThemeUpdate = async (e) => {
    e?.preventDefault();
    await props.userUpdate('preference', { preference: 'Theme', value: theme.value }, true);
    onThemeToggle();
    onThemeLoad(true);
  };
  // END: Theme


	const processSignout = async (e) => {
    e?.preventDefault();
    dispatch({ type: APP_THEME, payload: null });
		await props.processSignout();
    onSignout();
	};


  /* eslint-disable */
	useEffect(() => {
    props.feProcessList('app-themes');
    props.feProcessList('currencies');
  }, []);
  useEffect(() => {
    onCurrencyLoad();
  }, [user, lang]);
  useEffect(() => {
    onThemeLoad();
  }, [settings['APP_ENABLE_THEME'], isSignedIn, currentPath, props.themes]);
  
	useEffect(() => {
    if(Object.keys(settings).length){
      document.title = `${appName(settings['SYSTEM_NAME'])} Back Office`;
      if(settings['SYSTEM_COLOR_PRIMARY']){
        const _el = document.getElementById('system-style');
        if(_el){
          const rgb = hex2rgb(settings['SYSTEM_COLOR_PRIMARY']);
          const isLight = (rgb.r + rgb.g + rgb.b) / 3 > 200;
          _el.innerHTML = `
            :root{
              --p-color:${settings['SYSTEM_COLOR_PRIMARY']};
              --p-color-btn:${isLight? '#000000': '#ffffff'};
            }
          `;
        }
      }
      if(settings['SYSTEM_FAVICON']){
        const favicon = JSON.parse(unescape(settings['SYSTEM_FAVICON']));
        if(favicon.path){
          const favicon1 = document.getElementById('favicon-1');
          if(favicon1) favicon1.href = favicon.path;
          const favicon2 = document.getElementById('favicon-2');
          if(favicon2) favicon2.href = favicon.path;
        }
      }
      setIsReady(true);
    }
  }, [settings]);

	useEffect(() => {
    if(currentPath !== location.pathname){
      setCurrentPath(location.pathname);
      setSidenavActive(false);
      setMenuToggle(menu.map(() => false));
    }
  }, [location.pathname]);
  
	useEffect(() => {
    const onPreload = async () => {
      let _menu = [];
      if(isSignedIn){
        const shopTypes = partnerShops.map(d => d?.type ?? 0);
        const firebaseDB = getFirebaseDB(settings, externalShop);
        if(user.isAdmin()){
          const isSuperAdmin = user.isSuperAdmin();
          _menu = [
            {
              title: 'Admin',
              activeIndexes: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
              children: [
                { title: 'Dashboard', to: '/admin', activeIndex: 1, icon: 'fa-solid fa-chart-simple' },
                { title: 'Admins', to: '/admin/admins', activeIndex: 2, icon: 'fa-solid fa-users' },
                settings['APP_ENABLE_MODULE_SALES_MANAGER']==='1' 
                  ? { title: 'Sales Managers', to: '/admin/sales-managers', activeIndex: 3, icon: 'fa-solid fa-people-group' }: null,
                { title: 'Shipping Statuses', to: '/admin/shipping-statuses', activeIndex: 4, icon: 'fa-solid fa-truck-ramp-box' },
                isSuperAdmin && SYSTEM_LANGUAGES.length > 1? { title: 'Languages', to: '/admin/languages', activeIndex: 6, icon: 'fa-solid fa-flag' }: null,
                isSuperAdmin? { title: 'Countries & Taxes', to: '/admin/data-countries', activeIndex: 7, icon: 'fa-solid fa-earth-americas' }: null,
                isSuperAdmin && settings['APP_LOG_USER_ENABLE']==='1' 
                  ? { title: 'User Logs', to: '/admin/user-logs', activeIndex: 9, icon: 'fa-solid fa-clock-rotate-left' }: null,
                isSuperAdmin && settings['APP_DATABASE_BACKUP_ENABLE']==='1' 
                  ? { title: 'Database Backups', to: '/admin/database-backups', activeIndex: 8, icon: 'fa-solid fa-database' }: null,
                isSuperAdmin? { title: 'Settings', to: '/admin/settings', activeIndex: 5, icon: 'fa-solid fa-gear' }: null,
              ].filter(d => d?.title),
            },
            {
              title: 'Partner',
              activeIndexes: [21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40],
              children: [
                { title: 'Partners', to: '/admin/partners', activeIndex: 21, icon: 'fa-solid fa-users' },
                { title: 'Partner Shops', to: '/admin/partner-shops', activeIndex: 22, icon: 'fa-solid fa-shop' },
                { title: 'Product Categories', to: '/admin/partner-product-categories', activeIndex: 23, icon: 'fa-solid fa-box' },
                { title: 'Product Brands', to: '/admin/partner-product-brands', activeIndex: 24, icon: 'fa-solid fa-star' },
                { title: 'Product Statuses', to: '/admin/partner-product-statuses', activeIndex: 32, icon: 'fa-solid fa-flag' },
                { title: 'Products', to: '/admin/partner-products', activeIndex: 25, icon: 'fa-solid fa-basket-shopping' },
                settings['APP_MODULE_PARTNER_PRODUCT_SET']==='1' 
                  ? { title: 'Product Sets', to: '/admin/partner-product-sets', activeIndex: 30, icon: 'fa-solid fa-bag-shopping' }: null,
                settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_REWARD']==='1' 
                  ? { title: 'Product Giveaway Rules', to: '/admin/partner-product-giveaway-rules', activeIndex: 33, icon: 'fa-solid fa-gift' }: null,
                settings['APP_MODULE_PARTNER_SUBSCRIPTION']==='1' 
                  ? { title: 'Product Subscriptions', to: '/admin/partner-product-subscriptions', activeIndex: 34, icon: 'fas fa-award' }: null,
                settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_COUPON']==='1' || settings['APP_ENABLE_FEATURE_PARTNER_CASH_COUPON']==='1' 
                  ? { title: 'Product Coupons', to: '/admin/partner-product-coupons', activeIndex: 26, icon: 'fa-solid fa-tags' }: null,
                { title: 'Shipping Methods', to: '/admin/partner-shippings', activeIndex: 27, icon: 'fa-solid fa-truck-ramp-box' },
                settings['APP_ENABLE_FEATURE_PARTNER_SHIPPING_COUPON']==='1' 
                  ? { title: 'Shipping Coupons', to: '/admin/partner-shipping-coupons', activeIndex: 28, icon: 'fa-solid fa-tags' }: null,
                settings['APP_MODULE_PARTNER_EVENT']==='1' 
                  ? { title: 'Events', to: '/admin/partner-events', activeIndex: 31, icon: 'fa-solid fa-calendar-days' }: null,
                isSuperAdmin? 
                  settings['APP_ENABLE_SERVICE_FEE']==='1' 
                    ? { title: 'Settings', to: '/admin/partner-settings', activeIndex: 29, icon: 'fa-solid fa-gear' } 
                    : { title: 'Free Shipping Rule', to: '/admin/partner-settings', activeIndex: 29, icon: 'fa-solid fa-truck' }: null,
              ].filter(d => d?.title),
            },
            {
              title: 'Customer',
              activeIndexes: [41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60],
              children: [
                settings['APP_MODULE_CUSTOMER_GROUP']==='1' 
                  ? { title: 'Customer Groups', to: '/admin/customer-groups', activeIndex: 50, icon: 'fa-solid fa-people-line' }: null,
                { title: 'Customer Tiers', to: '/admin/customer-tiers', activeIndex: 41, icon: 'fa-solid fa-crown' },
                { title: 'Customers', to: '/admin/customers', activeIndex: 42, icon: 'fa-solid fa-users' },
                { title: 'Guest Accounts', to: '/admin/customer-guests', activeIndex: 46, icon: 'fa-solid fa-users' },
                { title: 'Orders', to: '/admin/customer-orders', activeIndex: 43, icon: 'fa-solid fa-credit-card' },
                settings['APP_MODULE_PAYMENT_2C2P']==='1' 
                  ? { title: '2C2P Carts', to: '/admin/customer-2c2p-carts', activeIndex: 47, icon: 'fa-solid fa-shield' }: null,
                settings['APP_MODULE_PARTNER_SUBSCRIPTION']==='1' 
                  ? { title: '2C2P Subscription Carts', to: '/admin/customer-subscription-2c2p-carts', activeIndex: 51, icon: 'fa-solid fa-shield' }: null,
                settings['APP_MODULE_PAYMENT_STRIPE']==='1' 
                  ? { title: 'Stripe Carts', to: '/admin/customer-stripe-carts', activeIndex: 48, icon: 'fa-solid fa-shield' }: null,
                firebaseDB && settings['APP_MODULE_CHAT_CUSTOMER']==='1' && settings[`${prefix}ENABLE_CHAT_CUSTOMER`]==='1' 
                  ? { title: 'Messages', to: '/admin/customer-messages', activeIndex: 44, icon: 'fa-solid fa-message' }: null,
                firebaseDB && settings['APP_MODULE_CHAT_GUEST']==='1' && settings[`${prefix}ENABLE_CHAT_GUEST`]==='1' 
                  ? { title: 'Guest Messages', to: '/admin/guest-messages', activeIndex: 49, icon: 'fa-solid fa-message' }: null,
                settings['MOBILE_ENABLE']==='1' 
                  ? { title: 'Notifications', to: '/admin/customer-notifications', activeIndex: 45, icon: 'fa-solid fa-bullhorn' }: null,
              ].filter(d => d?.title),
            },
            settings['APP_MODULE_SELLER']==='1'? {
              title: 'Seller',
              activeIndexes: [61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80],
              children: [
                { title: 'Seller Shop Brands', to: '/admin/seller-shop-brands', activeIndex: 64, icon: 'fa-solid fa-star' },
                { title: 'Seller Shop Types', to: '/admin/seller-shop-types', activeIndex: 61, icon: 'fa-solid fa-crown' },
                { title: 'Seller Shops', to: '/admin/seller-shops', activeIndex: 63, icon: 'fa-solid fa-shop' },
              ].filter(d => d?.title),
            }: null,
            {
              title: 'Content Management',
              activeIndexes: [111,112,113,114,115,116,117,118,119,120],
              children: [
                { title: 'Content Categories', to: '/admin/cms-content-categories', activeIndex: 111, icon: 'fa-solid fa-layer-group' },
                { title: 'Contents', to: '/admin/cms-contents', activeIndex: 112, icon: 'fa-solid fa-book-open' },
                { title: 'Banners', to: '/admin/cms-banners', activeIndex: 113, icon: 'fa-solid fa-star' },
                { title: 'Popups', to: '/admin/cms-popups', activeIndex: 114, icon: 'fa-solid fa-clipboard' },
                settings['APP_MODULE_CMS_CHECKOUT']==='1' 
                  ? { title: 'Checkout Contents', to: '/admin/cms-checkout-contents', activeIndex: 116, icon: 'fa-solid fa-rectangle-ad' }: null,
                isSuperAdmin? { title: 'Settings', to: '/admin/cms-settings', activeIndex: 115, icon: 'fa-solid fa-gear' }: null,
              ].filter(d => d?.title),
            },
            {
              title: 'Business Report',
              activeIndexes: [
                101,102,103,104,105,106,107,108,109,110,
                141,142,143,144,145,146,147,148,149,150,
              ],
              children: [
                {
                  title: 'Partner Shops', to: '/admin/report-partner-shops',
                  activeIndex: 101, icon: 'fa-solid fa-chart-column',
                  value: 'report_partner_shops'
                },
                settings['APP_REPORT_PARTNER_PERFORMANCE']==='1'? {
                  title: 'Partner Performances', to: '/admin/report-partner-performances',
                  activeIndex: 106, icon: 'fa-solid fa-chart-column',
                  value: 'report_partner_performances'
                }: null,
                false? {
                  title: 'Partner Commissions', to: '/admin/report-partner-commissions',
                  activeIndex: 102, icon: 'fa-solid fa-chart-column',
                  value: 'report_partner_commissions'
                }: null,
                settings['APP_REPORT_PARTNER_PRODUCT']==='1'? {
                  title: 'Partner Products', to: '/admin/report-partner-products',
                  activeIndex: 109, icon: 'fa-solid fa-chart-column',
                  value: 'report_partner_products'
                }: null,
                settings['APP_REPORT_PARTNER_PRODUCT_MIX_TRACKING']==='1'? {
                  title: 'Partner Product Mix Trackings', to: '/admin/report-partner-product-mix-trackings',
                  activeIndex: 145, icon: 'fa-solid fa-chart-column',
                  value: 'report_partner_product_mix_trackings'
                }: null,
                settings['APP_REPORT_PARTNER_COUPON']==='1' 
                && (settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_COUPON']==='1' 
                || settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_COUPON']==='1' 
                || settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_COUPON']==='1')? {
                  title: 'Partner Coupons', to: '/admin/report-partner-coupons',
                  activeIndex: 103, icon: 'fa-solid fa-chart-column',
                  value: 'report_partner_coupons'
                }: null,
                settings['APP_REPORT_PARTNER_EVENT']==='1' && settings['APP_MODULE_PARTNER_EVENT']==='1'? {
                  title: 'Partner Events', to: '/admin/report-partner-events',
                  activeIndex: 108, icon: 'fa-solid fa-chart-column',
                  value: 'report_partner_events'
                }: null,
                settings['APP_REPORT_CUSTOMER']==='1'? {
                  title: 'Customers', to: '/admin/report-customers',
                  activeIndex: 107, icon: 'fa-solid fa-chart-column',
                  value: 'report_customers'
                }: null,
                settings['APP_REPORT_CUSTOMER_ORDER']==='1'? {
                  title: 'Customer Orders', to: '/admin/report-customer-orders',
                  activeIndex: 104, icon: 'fa-solid fa-chart-column',
                  value: 'report_customer_orders'
                }: null,
                settings['APP_REPORT_CUSTOMER_PRODUCT_ORDER']==='1'? {
                  title: 'Customer Product Orders', to: '/admin/report-customer-product-orders',
                  activeIndex: 147, icon: 'fa-solid fa-chart-column',
                  value: 'report_customer_product_orders'
                }: null,
                settings['APP_REPORT_CUSTOMER_PURCHASING']==='1'? {
                  title: 'Customer Purchasings', to: '/admin/report-customer-purchasings',
                  activeIndex: 110, icon: 'fa-solid fa-chart-column',
                  value: 'report_customer_purchasings'
                }: null,
                settings['APP_REPORT_CUSTOMER_PURCHASE_CATEGORY']==='1'? {
                  title: 'Customer Purchase Categories', to: '/admin/report-customer-purchase-categories',
                  activeIndex: 142, icon: 'fa-solid fa-chart-column',
                  value: 'report_customer_purchase_categories'
                }: null,
                settings['APP_REPORT_CUSTOMER_UNPURCHASE_CATEGORY']==='1'? {
                  title: 'Customer Unpurchase Categories', to: '/admin/report-customer-unpurchase-categories',
                  activeIndex: 143, icon: 'fa-solid fa-chart-column',
                  value: 'report_customer_unpurchase_categories'
                }: null,
                false? {
                  title: 'Customer Settlements', to: '/admin/report-customer-settlements',
                  activeIndex: 105, icon: 'fa-solid fa-chart-column',
                  value: 'report_customer_settlements'
                }: null,
                settings['APP_REPORT_CUSTOMER_TRACKING']==='1'? {
                  title: 'Customer Trackings', to: '/admin/report-customer-trackings',
                  activeIndex: 141, icon: 'fa-solid fa-chart-column',
                  value: 'report_customer_trackings'
                }: null,
                settings['APP_REPORT_CUSTOMER_PURCHASE_TIER']==='1'? {
                  title: 'Customer Purchase Tiers', to: '/admin/report-customer-purchase-tiers',
                  activeIndex: 144, icon: 'fa-solid fa-chart-column',
                  value: 'report_customer_purchase_tiers'
                }: null,
              ].filter(d => {
                if(!d?.title) return false;
                if(isSuperAdmin) return true;
                const p = permissions.find(k => k.value === d.value && k.type === 'C2U' && k.read === 1);
                return p? true: false;
              }),
            },
            settings['APP_MODULE_MARKETING_TOOL']==='1'? {
              title: 'Marketing Tools',
              activeIndexes: [131,132,133,133,135,136,137,138,139,140],
              children: [
                { title: 'Product Promotions', to: '/admin/tool-partner-product-promotions', activeIndex: 132, icon: 'fa-solid fa-tags' },
                { title: 'Customer Card Scanner', to: '/admin/tool-customer-card-scanner', activeIndex: 131, icon: 'fa-solid fa-id-card' },
              ].filter(d => d?.title),
            }: null,
            settings['APP_MODULE_EXTERNAL_APP']==='1' && isSuperAdmin? {
              title: 'External App',
              activeIndexes: [121,122,123,124,125,126,127,128,129,130],
              children: [
                { title: 'External Shops', to: '/admin/external-shops', activeIndex: 122, icon: 'fa-solid fa-shop' },
                { title: 'External Admins', to: '/admin/external-admins', activeIndex: 121, icon: 'fa-solid fa-users' },
              ].filter(d => d?.title),
            }: null,
            settings['APP_MODULE_SUPPLY_CHAIN']==='1' && isSuperAdmin? {
              title: 'Supply Chain',
              activeIndexes: [200,201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,216,217,218,219],
              children: [
                { title: 'Supply Chain Admins', to: '/admin/supply-chain-admins', activeIndex: 200, icon: 'fa-solid fa-users' },
              ].filter(d => d?.title),
            }: null,
          ].filter(d => d?.title);
        }else if(user.isPartner()){
          const withCommission = (settings['APP_COMMISSION_PARTNER_CENTER']==='1' && shopTypes.indexOf(1) > -1) 
            || (settings['APP_COMMISSION_PARTNER_PARTNER']==='1' && shopTypes.indexOf(2) > -1) 
            || (settings['APP_COMMISSION_PARTNER_DEALER']==='1' && shopTypes.indexOf(3) > -1);
          _menu = [
            {
              title: 'Partner',
              activeIndexes: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
              children: [
                { title: 'Dashboard', to: '/partner', activeIndex: 1, icon: 'fa-solid fa-chart-simple' },
                user.partnerShops.length? { title: 'Register Customer', to: '/partner/register-customer', activeIndex: 2, icon: 'fa-solid fa-user-plus' }: null,
                { title: 'Sales Report', to: '/partner/sales-report', activeIndex: 3, icon: 'fa-solid fa-chart-column' },
                withCommission? { title: 'Commission Table', to: '/partner/commission-table', activeIndex: 4, icon: 'fa-solid fa-table-list' }: false,
                withCommission? { title: 'Commissions', to: '/partner/commissions', activeIndex: 5, icon: 'fa-solid fa-star' }: false,
                withCommission? { title: 'Commission Report', to: '/partner/commission-report', activeIndex: 6, icon: 'fa-solid fa-chart-column' }: false,
              ].filter(d => d?.title),
            }, {
              title: 'Partner',
              activeIndexes: [21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40],
              children: [
                user.partnerShops.length? { title: 'Partner Shops', to: '/partner/partner-shops', activeIndex: 22, icon: 'fa-solid fa-shop' }: null,
                { title: 'Products', to: '/partner/partner-products', activeIndex: 25, icon: 'fa-solid fa-basket-shopping' },
                settings['APP_MODULE_PARTNER_PRODUCT_SET']==='1' 
                  ? { title: 'Product Sets', to: '/partner/partner-product-sets', activeIndex: 30, icon: 'fa-solid fa-bag-shopping' }: null,
                settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_REWARD']==='1' 
                  ? { title: 'Product Giveaway Rules', to: '/partner/partner-product-giveaway-rules', activeIndex: 33, icon: 'fa-solid fa-gift' }: null,
                settings['APP_MODULE_PARTNER_SUBSCRIPTION']==='1' 
                  ? { title: 'Product Subscriptions', to: '/partner/partner-product-subscriptions', activeIndex: 34, icon: 'fas fa-award' }: null,
                settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_COUPON']==='1' || settings['APP_ENABLE_FEATURE_PARTNER_CASH_COUPON']==='1' 
                  ? { title: 'Product Coupons', to: '/partner/partner-product-coupons', activeIndex: 26, icon: 'fa-solid fa-tags' }: null,
                settings['APP_ENABLE_FEATURE_PARTNER_SHIPPING_COUPON']==='1' 
                  ? { title: 'Shipping Coupons', to: '/partner/partner-shipping-coupons', activeIndex: 28, icon: 'fa-solid fa-tags' }: null,
              ].filter(d => d?.title),
            }, {
              title: 'Customer',
              activeIndexes: [41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60],
              children: [
                { title: 'Customers', to: '/partner/customers', activeIndex: 42, icon: 'fa-solid fa-users' },
                { title: 'Orders', to: '/partner/customer-orders', activeIndex: 43, icon: 'fa-solid fa-credit-card' },
                firebaseDB && settings['APP_MODULE_CHAT_CUSTOMER']==='1' && settings[`${prefix}ENABLE_CHAT_CUSTOMER`]==='1' 
                  ? { title: 'Messages', to: '/partner/customer-messages', activeIndex: 44, icon: 'fa-solid fa-message' }: null,
              ].filter(d => d?.title),
            },
          ].filter(d => d?.title);
        }else if(user.isSalesManager()){
          const withCommission = (externalShop.isValid() && settings[`${prefix}APP_COMMISSION_SALES`]==='1') 
            || (settings['APP_COMMISSION_SALES']==='1' && !shopTypes.length) 
            || (settings['APP_COMMISSION_SALES_CENTER']==='1' && shopTypes.indexOf(1) > -1) 
            || (settings['APP_COMMISSION_SALES_PARTNER']==='1' && shopTypes.indexOf(2) > -1) 
            || (settings['APP_COMMISSION_SALES_DEALER']==='1' && shopTypes.indexOf(3) > -1);
          _menu = [
            {
              title: 'Sales Manager',
              activeIndexes: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
              children: [
                { title: 'Dashboard', to: '/sales-manager', activeIndex: 1, icon: 'fa-solid fa-chart-simple' },
                { title: 'Register Customer', to: '/sales-manager/register-customer', activeIndex: 2, icon: 'fa-solid fa-user-plus' },
                { title: 'Sales Report', to: '/sales-manager/sales-report', activeIndex: 3, icon: 'fa-solid fa-chart-column' },
                withCommission? { title: 'Commission Table', to: '/sales-manager/commission-table', activeIndex: 4, icon: 'fa-solid fa-table-list' }: null,
                withCommission? { title: 'Commissions', to: '/sales-manager/commissions', activeIndex: 5, icon: 'fa-solid fa-star' }: null,
                withCommission? { title: 'Commission Report', to: '/sales-manager/commission-report', activeIndex: 6, icon: 'fa-solid fa-chart-column' }: null,
              ].filter(d => d?.title),
            }, {
              title: 'Partner',
              activeIndexes: [21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40],
              children: [
                user.partnerShops.length? { title: 'Partner Shops', to: '/sales-manager/partner-shops', activeIndex: 22, icon: 'fa-solid fa-shop' }: null,
                { title: 'Products', to: '/sales-manager/partner-products', activeIndex: 25, icon: 'fa-solid fa-basket-shopping' },
                settings['APP_MODULE_PARTNER_PRODUCT_SET']==='1' 
                  ? { title: 'Product Sets', to: '/sales-manager/partner-product-sets', activeIndex: 30, icon: 'fa-solid fa-bag-shopping' }: null,
                settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_REWARD']==='1' 
                  ? { title: 'Product Giveaway Rules', to: '/sales-manager/partner-product-giveaway-rules', activeIndex: 33, icon: 'fa-solid fa-gift' }: null,
                settings['APP_MODULE_PARTNER_SUBSCRIPTION']==='1' 
                  ? { title: 'Product Subscriptions', to: '/sales-manager/partner-product-subscriptions', activeIndex: 34, icon: 'fas fa-award' }: null,
                settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_COUPON']==='1' || settings['APP_ENABLE_FEATURE_PARTNER_CASH_COUPON']==='1' 
                  ? { title: 'Product Coupons', to: '/sales-manager/partner-product-coupons', activeIndex: 26, icon: 'fa-solid fa-tags' }: null,
                settings['APP_ENABLE_FEATURE_PARTNER_SHIPPING_COUPON']==='1' 
                  ? { title: 'Shipping Coupons', to: '/sales-manager/partner-shipping-coupons', activeIndex: 28, icon: 'fa-solid fa-tags' }: null,
              ].filter(d => d?.title),
            }, {
              title: 'Customer',
              activeIndexes: [41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60],
              children: [
                { title: 'Customers', to: '/sales-manager/customers', activeIndex: 42, icon: 'fa-solid fa-users' },
                { title: 'Orders', to: '/sales-manager/customer-orders', activeIndex: 43, icon: 'fa-solid fa-credit-card' },
                firebaseDB && settings['APP_MODULE_CHAT_CUSTOMER']==='1' && settings[`${prefix}ENABLE_CHAT_CUSTOMER`]==='1' 
                  ? { title: 'Messages', to: '/sales-manager/customer-messages', activeIndex: 44, icon: 'fa-solid fa-message' }: null,
              ].filter(d => d?.title),
            },
          ].filter(d => d?.title);
        }else if(user.isExAdmin() && externalShop.isValid()){
          const isExSuperAdmin = user.isExSuperAdmin();
          _menu = [
            {
              title: 'Admin',
              activeIndexes: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
              children: [
                { title: 'Dashboard', to: '/external', activeIndex: 1, icon: 'fa-solid fa-chart-simple' },
                { title: 'Admins', to: '/external/admins', activeIndex: 2, icon: 'fa-solid fa-users' },
                settings['APP_ENABLE_MODULE_SALES_MANAGER']==='1' 
                  ? { title: 'Sales Managers', to: '/external/sales-managers', activeIndex: 3, icon: 'fa-solid fa-people-group' }: null,
                { title: 'Shipping Statuses', to: '/external/shipping-statuses', activeIndex: 4, icon: 'fa-solid fa-truck-ramp-box' },
                isExSuperAdmin? { title: 'Settings', to: '/external/settings', activeIndex: 5, icon: 'fa-solid fa-gear' }: null,
              ].filter(d => d?.title),
            }, {
              title: 'Partner',
              activeIndexes: [21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40],
              children: [
                { title: 'Product Categories', to: '/external/partner-product-categories', activeIndex: 23, icon: 'fa-solid fa-box' },
                { title: 'Product Brands', to: '/external/partner-product-brands', activeIndex: 24, icon: 'fa-solid fa-star' },
                { title: 'Products', to: '/external/partner-products', activeIndex: 25, icon: 'fa-solid fa-basket-shopping' },
                settings['APP_MODULE_PARTNER_PRODUCT_SET']==='1' 
                  ? { title: 'Product Sets', to: '/external/partner-product-sets', activeIndex: 30, icon: 'fa-solid fa-bag-shopping' }: null,
                settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_REWARD']==='1' 
                  ? { title: 'Product Giveaway Rules', to: '/external/partner-product-giveaway-rules', activeIndex: 33, icon: 'fa-solid fa-gift' }: null,
                settings['APP_MODULE_PARTNER_SUBSCRIPTION']==='1' 
                  ? { title: 'Product Subscriptions', to: '/external/partner-product-subscriptions', activeIndex: 34, icon: 'fas fa-award' }: null,
                settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_COUPON']==='1' || settings['APP_ENABLE_FEATURE_PARTNER_CASH_COUPON']==='1' 
                  ? { title: 'Product Coupons', to: '/external/partner-product-coupons', activeIndex: 26, icon: 'fa-solid fa-tags' }: null,
                { title: 'Shipping Methods', to: '/external/partner-shippings', activeIndex: 27, icon: 'fa-solid fa-truck-ramp-box' },
                settings['APP_ENABLE_FEATURE_PARTNER_SHIPPING_COUPON']==='1' 
                  ? { title: 'Shipping Coupons', to: '/external/partner-shipping-coupons', activeIndex: 28, icon: 'fa-solid fa-tags' }: null,
                isExSuperAdmin? { title: 'Settings', to: '/external/partner-settings', activeIndex: 29, icon: 'fa-solid fa-gear' }: null,
              ].filter(d => d?.title),
            }, {
              title: 'Customer',
              activeIndexes: [41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60],
              children: [
                settings['APP_MODULE_CUSTOMER_GROUP']==='1' 
                  ? { title: 'Customer Groups', to: '/external/customer-groups', activeIndex: 50, icon: 'fa-solid fa-crown' }: null,
                { title: 'Customer Tiers', to: '/external/customer-tiers', activeIndex: 41, icon: 'fa-solid fa-crown' },
                { title: 'Customers', to: '/external/customers', activeIndex: 42, icon: 'fa-solid fa-users' },
                { title: 'Orders', to: '/external/customer-orders', activeIndex: 43, icon: 'fa-solid fa-credit-card' },
                settings['APP_MODULE_PAYMENT_2C2P']==='1' 
                  ? { title: '2C2P Carts', to: '/external/customer-2c2p-carts', activeIndex: 47, icon: 'fa-solid fa-shield' }: null,
                settings['APP_MODULE_PARTNER_SUBSCRIPTION']==='1' 
                  ? { title: '2C2P Subscription Carts', to: '/external/customer-subscription-2c2p-carts', activeIndex: 51, icon: 'fa-solid fa-shield' }: null,
                firebaseDB && settings['APP_MODULE_CHAT_CUSTOMER']==='1' && settings[`${prefix}ENABLE_CHAT_CUSTOMER`]==='1' 
                  ? { title: 'Messages', to: '/external/customer-messages', activeIndex: 44, icon: 'fa-solid fa-message' }: null,
                firebaseDB && settings['APP_MODULE_CHAT_GUEST']==='1' && settings[`${prefix}ENABLE_CHAT_GUEST`]==='1' 
                  ? { title: 'Guest Messages', to: '/external/guest-messages', activeIndex: 49, icon: 'fa-solid fa-message' }: null,
              ].filter(d => d?.title),
            }, {
              title: 'Content Management',
              activeIndexes: [111,112,113,114,115,116,117,118,119,120],
              children: [
                { title: 'Content Categories', to: '/external/cms-content-categories', activeIndex: 111, icon: 'fa-solid fa-layer-group' },
                { title: 'Contents', to: '/external/cms-contents', activeIndex: 112, icon: 'fa-solid fa-book-open' },
                { title: 'Banners', to: '/external/cms-banners', activeIndex: 113, icon: 'fa-solid fa-star' },
                { title: 'Popups', to: '/external/cms-popups', activeIndex: 114, icon: 'fa-solid fa-clipboard' },
                isExSuperAdmin? { title: 'Settings', to: '/external/cms-settings', activeIndex: 115, icon: 'fa-solid fa-gear' }: null,
              ].filter(d => d?.title),
            }, {
              title: 'Business Report',
              activeIndexes: [
                101,102,103,104,105,106,107,108,109,110,
                141,142,143,144,145,146,147,148,149,150,
              ],
              children: [
                { title: 'Partner Shops', to: '/external/report-partner-shops', activeIndex: 101, icon: 'fa-solid fa-chart-column' },
                { title: 'Partner Products', to: '/external/report-partner-products', activeIndex: 102, icon: 'fa-solid fa-chart-column' },
                { title: 'Partner Coupons', to: '/external/report-partner-coupons', activeIndex: 103, icon: 'fa-solid fa-chart-column' },
                { title: 'Customers', to: '/external/report-customers', activeIndex: 107, icon: 'fa-solid fa-chart-column' },
                { title: 'Customer Orders', to: '/external/report-customer-orders', activeIndex: 104, icon: 'fa-solid fa-chart-column' },
                { title: 'Customer Product Orders', to: '/external/report-customer-product-orders', activeIndex: 147, icon: 'fa-solid fa-chart-column' },
              ].filter(d => d?.title),
            },
          ].filter(d => d?.title);
        }else if(user.isSupplyChainAdmin()){
          const isSuperAdmin = user.isSupplyChainSuperAdmin();
          _menu = [
            {
              title: 'Supply Chain',
              activeIndexes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
              children: [
                { title: 'Dashboard', to: '/supply-chain', activeIndex: 1, icon: 'fa-solid fa-chart-simple' },
                { title: 'Admins', to: '/supply-chain/admins', activeIndex: 2, icon: 'fa-solid fa-users' },
                { title: 'Warehouses', to: '/supply-chain/warehouses', activeIndex: 9, icon: 'fa-solid fa-warehouse' },
                { title: 'Partner Shops', to: '/supply-chain/partner-shops', activeIndex: 10, icon: 'fa-solid fa-shop' },
                isSuperAdmin? { title: 'Settings', to: '/supply-chain/settings', activeIndex: 5, icon: 'fa-solid fa-gear' }: null,
              ].filter(d => d?.title),
            },
          ].filter(d => d?.title);
        }
      }
      setMenu(_menu);
      setMenuToggle(_menu.map(() => false));
    };
    onPreload();
  }, [user, settings]);
  /* eslint-enable */

  return !props.theme || !isReady || currentPath.indexOf('/setup') === 0? (
    <></>
  ): !isSignedIn? (<>
    {DEV_PROCESS? (
      <div className="dev-notice">
        <div className="ss-tag bg-danger bradius">
          <p className="fw-700 ls-1">{DEV_MODE}</p>
        </div>
      </div>
    ): (<></>)}
    {SYSTEM_LANGUAGES.length > 1 && lang? (
      <div className="global-lang">
        <div className="wrapper">
          <div className="lang-item">
            <div className="flag" style={{ backgroundImage: `url('/assets/img/flag/${lang}.jpg')` }}></div>
            <div className="lang">{lang}</div>
          </div>
          <div className="dropdown bcolor-fgray">
            <div className="wrapper">
              {SYSTEM_LANGUAGES.map((d, i) => (
                <Fragment key={`lang_${i}`}>
                  {d !== lang? (
                    <div className="lang-item" onClick={e => processLanguageUpdate(e, d)}>
                      <div className="flag" style={{ backgroundImage: `url('/assets/img/flag/${d}.jpg')` }}></div>
                      <div className="lang">{d}</div>
                    </div>
                  ): (<></>)}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    ): (<></>)}
  </>): (
    ( currentPath.indexOf('/admin') === 0 && user.isAdmin() ) 
    || ( currentPath.indexOf('/partner') === 0 && user.isPartner() ) 
    || ( currentPath.indexOf('/sales-manager') === 0 && user.isSalesManager() ) 
    || ( currentPath.indexOf('/external') === 0 && user.isExAdmin() ) 
    || ( currentPath.indexOf('/supply-chain') === 0 && user.isSupplyChainAdmin() )? (
      <>
        {/* Topnav */}
        <nav className="topnav bcolor-fgray">
          <div className="wrapper">
            <Link to={user.path()} className="logo-container h-color-p">
              <img className="logo" alt="Logo" 
                src={appLogo(settings[`${prefix}SYSTEM_LOGO`])} 
              />
              <div className="text fw-900">
                {appShortName(settings[`${prefix}SYSTEM_SHORT_NAME`])} 
              </div>
            </Link>
            {currentPath.indexOf('/admin/intelligence') < 0? (
              <div className="sidenav-toggle" onClick={onSidenavActiveToggle}>
                <div className={`hamburger ${sidenavActive? 'active': ''}`}>
                  <div></div><div></div><div></div>
                </div>
              </div>
            ): (<></>)}
            
            {DEV_PROCESS? (
              <div className="ss-tag bg-danger bradius">
                <p className="fw-700 ls-1">{DEV_MODE}</p>
              </div>
            ): (<></>)}
  
            <div className="options">
              <TopnavRealTime />

              {/* Currency */}
              {settings['APP_ENABLE_MULTI_CURRENCIES'] === '1' && props.currencies.length? (
                <div className="option">
                  <div className="option-icon">
                    <div className="text">{props.useCurrency.code}</div>
                  </div>
                  <div className="dropdown bcolor-fgray w-auto" style={{ left: 0 }}>
                    <div className="wrapper p-0 pt-1 pb-1">
                      {props.currencies.map((d, i) => (
                        <Fragment key={`currency_${i}`}>
                          {d.status && d._id !== props.useCurrency._id? (
                            <div className="currency-item" onClick={e => onCurrencyChange(e, d)}>
                              <p className="sm fw-500 text-center">{d.code}</p>
                            </div>
                          ): (<></>)}
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              ): (<></>)}

              {/* Language */}
              {SYSTEM_LANGUAGES.length > 1 && lang? (
                <div className="option">
                  <div className="option-icon">
                    <div className="flag bcolor-sgray" style={{ backgroundImage: `url('/assets/img/flag/${lang}.jpg')` }}></div>
                  </div>
                  <div className="dropdown bcolor-fgray w-auto" style={{ right: '.5rem' }}>
                    <div className="wrapper p-0 pt-1 pb-1">
                      {SYSTEM_LANGUAGES.map((d, i) => (
                        <Fragment key={`lang_${i}`}>
                          {d !== lang? (
                            <div className="lang-item" onClick={e => processLanguageUpdate(e, d)}>
                              <div className="flag bcolor-sgray" style={{ backgroundImage: `url('/assets/img/flag/${d}.jpg')` }}></div>
                            </div>
                          ): (<></>)}
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              ): (<></>)}
  
              {/* Profile */}
              <div className="option">
                <div className="option-icon">
                  <div className="avatar xs">
                    <div className="avatar-bg" style={{ backgroundImage: `url('${user.avatar.path}')` }}></div>
                  </div>
                </div>
                <div className="dropdown bcolor-fgray">
                  <div className="wrapper bcolor-fgray">
                    <h6 className="name">{user.displayName()}</h6>
                    <p className="role color-gray">{translate('Role', langObj)} : {user.displayRole()}</p>
                  </div>
                  <div className="wrapper bcolor-fgray">
                    <Link to={`${user.path()}/profile`} className="dropdown-item">
                      <div className="icon"><em className="fa-solid fa-user"></em></div>
                      {translate('Personal Info', langObj)}
                    </Link>
                    {user.isOwner? (
                      <Link to={`${user.path()}/app-settings`} className="dropdown-item">
                        <div className="icon"><em className="fa-solid fa-gear"></em></div>
                        {translate('App Settings', langObj)}
                      </Link>
                    ): (<></>)}
                    {false && user.isSuperAdmin()? (
                      <Link to={`${user.path()}/data-import`} className="dropdown-item">
                        <div className="icon"><em className="fa-solid fa-file-import"></em></div>
                        {translate('Data Import', langObj)}
                      </Link>
                    ): (<></>)}
                    {settings['APP_ENABLE_THEME'] === '1' && props.themes?.length? (
                      <div className="dropdown-item c-pointer" onClick={onThemeToggle}>
                        <div className="icon"><em className="fa-solid fa-palette"></em></div>
                        {translate('Theme Settings', langObj)}
                      </div>
                    ): (<></>)}
                    <div className="dropdown-item c-pointer" onClick={processSignout}>
                      <div className="icon"><em className="fa-solid fa-right-to-bracket"></em></div>
                      {translate('Sign Out', langObj)}
                    </div>
                  </div>
                </div>
              </div>
  
            </div>
          </div>
        </nav>
  
        {/* Sidenav */}
        {currentPath.indexOf('/intelligence') < 0? (
          <nav className={`sidenav ${sidenavActive? 'active': ''}`}>
            <ul className="wrapper">
              {menu.map((d, i) => (
                <div key={`menu_${i}`} 
                  className={`menu-set ${d.activeIndexes.indexOf(props.sidenavActiveIndex)>-1 || menuToggle[i]? 'active': ''}`} 
                >
                  {d.to? (
                    <Link className="menu-header" to={d.to}>
                      {d.title}
                    </Link>
                  ): (
                    <div className="menu-header" onClick={e => onMenuToggle(e, i)}>
                      {d.title}
                      {d.children && d.children.length? (
                        <div className="chev"><em className="fa-solid fa-chevron-right"></em></div>
                      ): (<></>)}
                    </div>
                  )}
                  {d.children && d.children.length? (
                    <div className="menu-container">
                      {d.children.map((k, j) => (
                        <Link key={`submenu_${i}_${j}`} to={k.to} 
                          className={`menu ${props.sidenavActiveIndex===k.activeIndex? 'active': ''}`} 
                        >
                          <div className="icon"><em className={k.icon}></em></div>
                          <span className="label">{k.title}</span>
                        </Link>
                        ))}
                    </div>
                  ): (<></>)}
                </div>
              ))}
            </ul>
          </nav>
        ): (<></>)}

        {/* Theme */}
        {settings['APP_ENABLE_THEME'] === '1' && props.themes && props.themes.length? (
          <Dialog open={themeProcess? true: false} onClose={() => onThemeToggle()} 
            fullWidth={true} maxWidth="sm" scroll="paper" 
            PaperProps={{ component: 'form', onSubmit: onThemeUpdate }} 
          >
            <DialogTitle component="div" className="p-0">
              <div className="dialog-header">
                <h6 className="fw-600 lh-xs">{translate('Theme Settings', langObj)}</h6>
                <div className="btn-close" onClick={onThemeToggle}>
                  <div className="hamburger active">
                    <div></div><div></div><div></div>
                  </div>
                </div>
              </div>
            </DialogTitle>
            <DialogContent dividers={true} className="with-grids">
              <div className="grids">
                <div className="grid sm-100">
                  <p>{translate('Theme', langObj)} <span className="color-danger">*</span></p>
                  <div className="mt-1">
                    <Swiper 
                      spaceBetween={12} slidesPerView={1.5} 
                      breakpoints={{
                        768: { slidesPerView: 3 },
                        576: { slidesPerView: 2.5 },
                      }} 
                    >
                      {props.themes.map((d, i) => (
                        <SwiperSlide key={`theme_${i}`}>
                          <div className={`a c-pointer ${theme.value.theme !== d.value? 'op-20': ''}`} 
                            onClick={e => { e?.preventDefault(); onThemeChage('theme', d.value); }} 
                          >
                            <div className="ss-img horizontal-02 no-hover bradius border-1 bcolor-fgray">
                              <div className="img-bg" 
                                style={{ backgroundImage: `url('${d?.image?.path || '/assets/img/default/img.jpg'}')` }} 
                              ></div>
                            </div>
                            <p className="xs text-center mt-1">{d.name}</p>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
                {props.themes.find(d => d.value === theme.value.theme)? (
                  <div className="grid sm-100">
                    <p>{translate('Color', langObj)} <span className="color-danger">*</span></p>
                    <div className="mt-1">
                      <Swiper 
                        spaceBetween={12} slidesPerView={1.5} 
                        breakpoints={{
                          768: { slidesPerView: 3 },
                          576: { slidesPerView: 2.5 },
                        }} 
                      >
                        {props.themes.find(d => d.value === theme.value.theme).colors.map((d, i) => (
                          <SwiperSlide key={`color_${i}`}>
                            <div className={`a c-pointer ${theme.value.color !== d.value? 'op-20': ''}`} 
                              onClick={e => { e?.preventDefault(); onThemeChage('color', d.value); }} 
                            >
                              <div className="ss-img no-hover bradius border-1 bcolor-fgray">
                                <div className="img-bg" 
                                  style={{ backgroundImage: `url('${d?.image?.path ||'/assets/img/default/img.jpg'}')` }} 
                                ></div>
                              </div>
                              <p className="xs text-center mt-1">{d.name}</p>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                ): (<></>)}
              </div>
            </DialogContent>
            <DialogActions>
              <div className="btns mt-0">
                <button type="submit" className="btn btn-action btn-p">
                  {translate('Save', langObj)}
                </button>
                <button onClick={onThemeToggle} 
                  type="button" className="btn btn-action btn-default" 
                >
                  {translate('Close', langObj)}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        ): (<></>)}
      </>
    ): (<></>)
  );
}

Topnav.defaultProps = {
  
};
Topnav.propTypes = {
  processSignout: PropTypes.func.isRequired,
  userRead: PropTypes.func.isRequired,
  userUpdate: PropTypes.func.isRequired,
  feProcessList: PropTypes.func.isRequired,
  smProcessList: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  theme: state.app.theme,
  themes: state.app.themes,
  currencies: state.app.currencies,
  useCurrency: state.app.useCurrency,
  sidenavActiveIndex: state.app.sidenavActiveIndex,
});
export default connect(mapStateToProps, {
  processSignout: userSignout,
  userRead: userRead,
  userUpdate: userUpdate,
  feProcessList: feProcessList,
  smProcessList: smProcessList,
})(Topnav);