import { lazy, Suspense, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { Routes, Route, Navigate } from 'react-router-dom';

import { AuthPageLoading } from '../views/auth';

export default function RouteSalesManager() {
  const { status, user, partnerShops, externalShop, prefix, settings } = useContext(AuthContext);
  
  const shopTypes = partnerShops.map(d => d?.type ?? 0);
  const withCommission = (externalShop.isValid() && settings[`${prefix}APP_COMMISSION_SALES`]==='1') 
    || (settings['APP_COMMISSION_SALES']==='1' && !shopTypes.length) 
    || (settings['APP_COMMISSION_SALES_CENTER']==='1' && shopTypes.indexOf(1) > -1) 
    || (settings['APP_COMMISSION_SALES_PARTNER']==='1' && shopTypes.indexOf(2) > -1) 
    || (settings['APP_COMMISSION_SALES_DEALER']==='1' && shopTypes.indexOf(3) > -1);

  const LazyRoute = ({ dom: Element }) => <Element />;
  return status === 'authenticated' && user.isSalesManager()? (<>
    <Suspense fallback={<AuthPageLoading />}>
      <Routes>
        {/* Sales Manager */}
        <Route path="/sales-manager" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/DashboardPage'))} />} />
        <Route path="/sales-manager/dashboard" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/DashboardPage'))} />} />
        <Route path="/sales-manager/register-customer" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/RegisterCustomerPage'))} />} />
        <Route path="/sales-manager/sales-report" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/SalesReportPage'))} />} />
          
        {withCommission? (<>
          <Route path="/sales-manager/commission-table" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/CommissionTablePage'))} />} />
          <Route path="/sales-manager/commissions" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/CommissionsPage'))} />} />
          <Route path="/sales-manager/commission-report" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/CommissionReportPage'))} />} />
        </>): (<></>)}

        {/* Personal */}
        <Route path="/sales-manager/profile" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/ProfileViewPage'))} />} />
        <Route path="/sales-manager/profile/update" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/ProfilePage'))} />} />

        {/* Partner */}
        {partnerShops.length? (<>
          <Route path="/sales-manager/partner-shops" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/PartnerShopsPage'))} />} />
          <Route path="/sales-manager/partner-shop/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/PartnerShopViewPage'))} />} />
        </>): (<></>)}

        <Route path="/sales-manager/partner-products" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/PartnerProductsPage'))} />} />
        <Route path="/sales-manager/partner-product/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/PartnerProductViewPage'))} />} />
          
        {settings['APP_MODULE_PARTNER_PRODUCT_SET']==='1'? (<>
          <Route path="/sales-manager/partner-product-sets" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/PartnerProductSetsPage'))} />} />
          <Route path="/sales-manager/partner-product-set/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/PartnerProductSetViewPage'))} />} />
        </>): (<></>)}

        {settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_REWARD']==='1'? (<>
          <Route path="/sales-manager/partner-product-giveaway-rules" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/PartnerProductGiveawayRulesPage'))} />} />
          <Route path="/sales-manager/partner-product-giveaway-rule/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/PartnerProductGiveawayRuleViewPage'))} />} />
        </>): (<></>)}

        {settings['APP_MODULE_PARTNER_SUBSCRIPTION']==='1'? (<>
          <Route path="/sales-manager/partner-product-subscriptions" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/PartnerProductSubscriptionsPage'))} />} />
          <Route path="/sales-manager/partner-product-subscription/view/:dataId/*" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/PartnerProductSubscriptionViewPage'))} />} />
        </>): (<></>)}

        {settings['APP_ENABLE_FEATURE_PARTNER_PRODUCT_COUPON']==='1' || settings['APP_ENABLE_FEATURE_PARTNER_CASH_COUPON']==='1'? (<>
          <Route path="/sales-manager/partner-product-coupons" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/PartnerProductCouponsPage'))} />} />
          <Route path="/sales-manager/partner-product-coupon/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/PartnerProductCouponViewPage'))} />} />
        </>): (<></>)}

        {settings['APP_ENABLE_FEATURE_PARTNER_SHIPPING_COUPON']==='1'? (<>
          <Route path="/sales-manager/partner-shipping-coupons" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/PartnerShippingCouponsPage'))} />} />
          <Route path="/sales-manager/partner-shipping-coupon/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/PartnerShippingCouponViewPage'))} />} />
        </>): (<></>)}

        {/* Customer */}
        <Route path="/sales-manager/customers" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/CustomersPage'))} />} />
        <Route path="/sales-manager/customer/view/:dataId/*" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/CustomerViewPage'))} />} />

        <Route path="/sales-manager/customer-orders" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/CustomerOrdersPage'))} />} />
        <Route path="/sales-manager/customer-order/view/:dataId" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/CustomerOrderViewPage'))} />} />
        <Route path="/sales-manager/customer-order/:process/*" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/CustomerOrderPage'))} />} />

        {settings['APP_MODULE_CHAT_CUSTOMER']==='1' && settings[`${prefix}ENABLE_CHAT_CUSTOMER`]==='1'? (
          <Route path="/sales-manager/customer-messages/*" element={<LazyRoute dom={lazy(() => import('../views/sales-manager/CustomerMessagesPage'))} />} />
        ): (<></>)}
        
        {/* Must Have */}
        <Route path="/auth/customer-signup-qr-code/:partnerShopCode/*" element={<LazyRoute dom={lazy(() => import('../views/auth/CustomerSignupQrCodePage'))} />} />
        <Route path="/payment/failed" element={<LazyRoute dom={lazy(() => import('../views/payment/FailedPage'))} />} />
        <Route path="/payment/success/:dataId" element={<LazyRoute dom={lazy(() => import('../views/payment/SuccessPage'))} />} />

        <Route path="*" element={<Navigate replace to="/sales-manager" />} />
      </Routes>
    </Suspense>
  </>): (<></>);
}
