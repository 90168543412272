import { unescape } from 'html-escaper';
import { translate } from '../helpers/frontend';
import { SYSTEM_LANGUAGES } from '../actions/variables';

export class DataCountryModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.name = data.name? unescape(data.name): '';
    this.countryCode = data.countryCode? data.countryCode: '';
    this.telephoneCode = data.telephoneCode? data.telephoneCode: '';

    this.lat = data.lat? Number(data.lat): null;
    this.lng = data.lng? Number(data.lng): null;
    
    this.order = data.order? Number(data.order): 1;
    this.status = data.status? Number(data.status): 0;
    
    this.enabledTax = data.enabledTax? Number(data.enabledTax): 0;
    this.isCountryTax = data.isCountryTax? Number(data.isCountryTax): 0;
    this.productIncludeTax = data.productIncludeTax? Number(data.productIncludeTax): 0;
    this.taxName = data.taxName? unescape(data.taxName): '';
    this.partnerTax = data.partnerTax? Number(data.partnerTax): 0;
    this.customerTax = data.customerTax? Number(data.customerTax): 0;
    this.consumerTax = data.consumerTax? Number(data.consumerTax): 0;

    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'name', 'taxName' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
  displayEnabledTax(langObj={}) {
    if(this.isValid()){
      if(this.enabledTax === 1) return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }
  displayCountryTax(langObj={}) {
    if(this.isValid()){
      if(this.enabledTax === 1 && this.isCountryTax === 1){
        return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      }else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }
  displayProductIncludeTax(langObj={}) {
    if(this.isValid()){
      if(this.enabledTax === 1 && this.isCountryTax === 1 && this.productIncludeTax === 1){
        return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      }else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }
}
