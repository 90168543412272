import { API_URL } from './variables';
import { alertChange, alertLoading } from './alert.actions';
import { apiHeader, apiHeaderFormData } from '../helpers/header';

import { CDN_URL } from '../actions/variables';
import { FileModel, UserPreferenceModel, LogUserModel, PaginateModel } from '../models';


export const userFileUpload = (
  file, loading=false, path='', resize=0,
  accept='image/png,image/jpeg,image/jpg',
  bypass=false,
) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    if(loading) dispatch(alertLoading(true));
    try {
      const formData = new FormData();
      formData.append('file', file);
      
      let url = `${CDN_URL}file/single`;
      if(path){
        url += `/${path}`;
        if(resize) url += `/${resize}`;
      }else if(resize){
        url += `/uploads/${resize}`;
      }
      const _fetch = await fetch(url, {
        method: 'POST',
        headers: apiHeaderFormData(accept, bypass),
        body: formData,
      });
      const _data = await _fetch.json();
      if(!_fetch.ok || _fetch.status !== 200){
        if(loading) dispatch(alertChange('Warning', _data?.message || '', _data.error));
        reject(_data); return false;
      }
      
      let result = new FileModel(_data.data.file);
      if(loading) dispatch(alertLoading(false));
      resolve(result); return true;
    } catch(err) {
      console.log(err);
      if(loading) dispatch(alertLoading(false));
      reject(err); return false;
    }
  });
};
export const userFilesUpload = (
  files, loading=false, path='', resize=0,
  accept='image/png,image/jpeg,image/jpg',
) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    if(loading) dispatch(alertLoading(true));
    try {
      const formData = new FormData();
      Object.keys(files).forEach(key => {
        formData.append('files', files[key]);
      });
      
      let url = `${CDN_URL}file/multiple`;
      if(path){
        url += `/${path}`;
        if(resize) url += `/${resize}`;
      }else if(resize){
        url += `/uploads/${resize}`;
      }
      const _fetch = await fetch(url, {
        method: 'POST',
        headers: apiHeaderFormData(accept),
        body: formData,
      });
      const _data = await _fetch.json();
      if(!_fetch.ok || _fetch.status !== 200){
        if(loading) dispatch(alertChange('Warning', _data?.message || '', _data.error));
        reject(_data); return false;
      }
      
      let result = _data.data.files.map(d => new FileModel(d));
      if(loading) dispatch(alertLoading(false));
      resolve(result); return true;
    } catch(err) {
      console.log(err);
      if(loading) dispatch(alertLoading(false));
      reject(err); return false;
    }
  });
};


export const userSignin = (input) => async (dispatch=null) => {
  if(dispatch) dispatch(alertLoading(true));
  const _fetch = await fetch(`${API_URL}auth/signin`, {
    method: 'POST',
    headers: apiHeader(),
    body: JSON.stringify(input),
  });
  const _data = await _fetch.json();
  if(!_fetch.ok || _fetch.status !== 200) {
    if(dispatch) dispatch(alertChange('Warning', _data?.message || '', _data.error));
    return false;
  }else{
    if(dispatch) dispatch(alertChange('Info', 'เข้าสู่ระบบสำเร็จแล้ว'));
    return _data.data;
  }
};
export const userSignout = () => async (dispatch=null) => {
  if(dispatch) dispatch(alertLoading(true));
  try {
    await fetch(`${API_URL}user/signout`, {
      method: 'PATCH',
      headers: apiHeader(),
    });
  } catch {}
  if(dispatch) dispatch(alertChange('Info', 'ออกจากระบบสำเร็จแล้ว'));
  return true;
};

export const userForgetPassword = (input) => async (dispatch=null) => {
  if(dispatch) dispatch(alertLoading(true));
  const _fetch = await fetch(`${API_URL}auth/forget-password`, {
    method: 'POST',
    headers: apiHeader(),
    body: JSON.stringify(input),
  });
  const _data = await _fetch.json();
  if(!_fetch.ok || _fetch.status !== 200) {
    if(dispatch) dispatch(alertChange('Warning', _data?.message || '', _data.error));
    return false;
  }
  
  if(dispatch) dispatch(alertChange('Info', 'ขอการตั้งรหัสผ่านใหม่สำเร็จแล้ว'));
  return true;
};
export const userCheckResetPassword = (token) => async (dispatch=null) => {
  return new Promise(async (resolve, reject) => {
    if(dispatch) dispatch(alertLoading(true));
    const _fetch = await fetch(`${API_URL}auth/check-reset-token?resetToken=${token}`, {
      method: 'GET',
      headers: apiHeader(),
    });
    if(dispatch) dispatch(alertLoading(false));
    if(!_fetch.ok || _fetch.status !== 200) {
      resolve(false);
    } else {
      resolve(true);
    }
  });
};
export const userResetPassword = (input) => async (dispatch=null) => {
  return new Promise(async (resolve, reject) => {
    if(dispatch) dispatch(alertLoading(true));
    const _fetch = await fetch(`${API_URL}auth/reset-password`, {
      method: 'PATCH',
      headers: apiHeader(),
      body: JSON.stringify(input),
    });
    const _data = await _fetch.json();
    if(!_fetch.ok || _fetch.status !== 200) {
      if(dispatch) dispatch(alertChange('Warning', _data?.message || '', _data.error));
      resolve(false);
    } else {
      if(dispatch) dispatch(alertChange('Info', 'การตั้งรหัสผ่านใหม่สำเร็จแล้ว'));
      resolve(true);
    }
  });
};

export const userRead = (type, input={}) => async (dispatch=null) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${API_URL}user/${type}`;
      let sep = '?';
      Object.keys(input).forEach(k => {
        if (input[k] || input[k]===0){ url += `${sep}${k}=${input[k]}`; sep = '&'; }
      });

      const _fetch = await fetch(url, {
        method: 'GET',
        headers: apiHeader(),
      });
      const _data = await _fetch.json();
      if(!_fetch.ok || _fetch.status !== 200){
        reject(false); return false;
      }

      let result = _data.data.result;
      if(type === 'preference'){
        if(input && input['preference']){
          result = new UserPreferenceModel({ ...result, preference: input['preference'] });
        }else{
          result = new UserPreferenceModel(result);
        }
      }
      
      resolve(result); return true;
    } catch(err) {
      console.log(err);
      reject(err);
    }
  });
};
export const userUpdate = (type, input={}, loading=true) => async (dispatch=null) => {
  return new Promise(async (resolve, reject) => {
    try {
      if(loading && dispatch) dispatch(alertLoading(true));

      const _fetch = await fetch(`${API_URL}user/${type}`, {
        method: 'PATCH',
        headers: apiHeader(),
        body: JSON.stringify(input),
      });
      const _data = await _fetch.json();
      if(!_fetch.ok || _fetch.status !== 200){
        if(loading && dispatch) dispatch(alertChange('Warning', _data?.message || '', _data?.error || []));
        reject(_data); return false;
      }
      
      if(loading && dispatch) dispatch(alertChange('Info', _data?.message || ''));
      resolve(_data);
    } catch(err) {
      console.log(err);
      if(loading && dispatch) dispatch(alertChange('Danger', 'Internal server error.'));
      reject(err);
    }
  });
};

export const userLogList = (type, input={}, loading=false) => async (dispatch=null) => {
  return new Promise(async (resolve, reject) => {
    if(loading && dispatch) dispatch(alertLoading(true));
    try {
      const _fetch = await fetch(`${API_URL}user/logs`, {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify({ ...input, type: type }),
      });
      const _data = await _fetch.json();
      if(!_fetch.ok || _fetch.status !== 200){
        if(loading && dispatch) dispatch(alertLoading(false));
        reject(_data); return false;
      }

      let result = {
        paginate: new PaginateModel(_data.data?.paginate || {}),
        dataFilter: _data.data?.dataFilter || {},
        result: _data.data?.result || [],
      };
      if(type === 'user'){
        result.result = result.result.map(d => new LogUserModel(d));
      }

      if(loading && dispatch) dispatch(alertLoading(false));
      resolve(result); return true;
    } catch(err) {
      console.log(err);
      if(loading && dispatch) dispatch(alertLoading(false));
      reject(err); return false;
    }
  });
};


export const customerSignup = (input={}, loading=false) => async (dispatch=null) => {
  return new Promise(async (resolve, reject) => {
    if(loading && dispatch) dispatch(alertLoading(true));
    try {
      const _fetch = await fetch(`${API_URL}auth/customer-signup`, {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(input)
      });
      const _data = await _fetch.json();
      if(!_fetch.ok || _fetch.status !== 200){
        if(loading && dispatch) dispatch(alertChange('Warning', _data?.message || '', _data?.error || []));
        reject(_data); return false;
      }
      
      if(loading && dispatch) dispatch(alertChange('Info', _data?.message || ''));
      resolve(_data); return true;
    } catch(err) {
      console.log(err);
      if(loading && dispatch) dispatch(alertChange('Danger', 'Internal server error.'));
      reject(err); return false;
    }
  });
};


export const sendOTP = (input={}, loading=true) => async (dispatch=null) => {
  return new Promise(async (resolve, reject) => {
    try {
      if(loading && dispatch) dispatch(alertLoading(true));

      const _fetch = await fetch(`${API_URL}auth/send-otp`, {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(input),
      });
      const _data = await _fetch.json();
      if(!_fetch.ok || _fetch.status !== 200){
        if(loading && dispatch) dispatch(alertChange('Warning', _data?.message || '', _data?.error || []));
        reject(_data); return false;
      }
      
      if(_data?.data?.requestId){
        const res = _data.data;
        if(loading && dispatch) dispatch(alertLoading(false));
        resolve({ requestId: res.requestId, refCode: res.refCode });
        return true;
      }else{
        if(loading && dispatch) dispatch(alertChange('Warning', _data?.message || '', _data?.error || []));
        reject(_data); return false;
      }
    } catch(err) {
      console.log(err);
      if(loading && dispatch) dispatch(alertChange('Danger', 'Internal server error.'));
      reject(err);
    }
  });
};
export const verifyOTP = (input={}, loading=true) => async (dispatch=null) => {
  return new Promise(async (resolve, reject) => {
    try {
      if(loading && dispatch) dispatch(alertLoading(true));

      const _fetch = await fetch(`${API_URL}auth/verify-otp`, {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(input),
      });
      const _data = await _fetch.json();
      if(!_fetch.ok || _fetch.status !== 200){
        if(loading && dispatch) dispatch(alertChange('Warning', _data?.message || '', _data?.error || []));
        reject(_data); return false;
      }
      
      if(loading && dispatch) dispatch(alertLoading(false));
      resolve(true); return true;
    } catch(err) {
      console.log(err);
      if(loading && dispatch) dispatch(alertChange('Danger', 'Internal server error.'));
      reject(err);
    }
  });
};
