import {
  SellerShopBrandModel, SellerShopTypeModel, FileModel, AddressModel, WorkingHourModel,
} from '.';
import { translate } from '../helpers/frontend';
import { unescape } from 'html-escaper';

/*
  priceRange :
    0 = 
    1 = ฿ = ต่ำกว่า 100 บาท
    2 = ฿฿ = 100-150 บาท
    3 = ฿฿฿ = 150-200 บาท
    4 = ฿฿฿฿ = มากกว่า 200 บาท
*/

export class SellerShopModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.brand = new SellerShopBrandModel(data.brand || {});
    this.type = new SellerShopTypeModel(data.type || {});
    
    this.code = data.code || null;
    this.businessId = data.businessId || null;
    
    this.name = data.name? unescape(data.name): null;
    this.description = data.description? unescape(data.description): null;
    this.url = data.url || null;
    this.youtubeVideoId = data.youtubeVideoId || null;
    
    this.logo = new FileModel(data.logo || {});
    this.image = new FileModel(data.image || {});
    this.gallery = data.gallery?.length? data.gallery.map(d => new FileModel(d)): [];

    this.email = data.email || null;
    this.address = new AddressModel(data.address || {});
    this.telephones = data.telephones?.length? data.telephones: [];
    
    this.line = data.line || null;
    this.facebook = data.facebook || null;
    this.instagram = data.instagram || null;
    this.website = data.website || null;

    this.workingHours = data.workingHours?.length
      ? data.workingHours.map(d => new WorkingHourModel(d))
      : [0, 1, 2, 3, 4, 5, 6].map(d => new WorkingHourModel({ dayIndex: d, isOpened: 0 }));
    
    this.averageRating = data.averageRating || data.averageRating===0? Number(data.averageRating): null;

    this.status = data.status ?? 0;


    // Attribute
    this.capacity = data.capacity? unescape(data.capacity): null;
    this.priceRange = data.priceRange || data.priceRange===0? Number(data.priceRange): null;
    this.hasParkingSpace = data.hasParkingSpace || data.hasParkingSpace===0? Number(data.hasParkingSpace): null;
    this.hasWifi = data.hasWifi || data.hasWifi===0? Number(data.hasWifi): null;
    this.acceptCreditCards = data.acceptCreditCards || data.acceptCreditCards===0? Number(data.acceptCreditCards): null;
    this.hasDelivery = data.hasDelivery || data.hasDelivery===0? Number(data.hasDelivery): null;
    this.isAromaShopMember = data.isAromaShopMember || data.isAromaShopMember===0? Number(data.isAromaShopMember): null;

    
    // Localization
    this.nameEN = data.nameEN? unescape(data.nameEN): null;
    this.descriptionEN = data.descriptionEN? unescape(data.descriptionEN): null;
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
  displayPriceRange(langObj={}) {
    if(this.isValid()){
      if(this.priceRange === 1) return `฿ = ${translate('less than', langObj)} 100 ${translate('Baht', langObj)}`;
      else if(this.priceRange === 2) return `฿฿ = 100-150 ${translate('Baht', langObj)}`;
      else if(this.priceRange === 3) return `฿฿฿ = 150-200 ${translate('Baht', langObj)}`;
      else if(this.priceRange === 4) return `฿฿฿฿ = ${translate('more than', langObj)} 200 ${translate('Baht', langObj)}`;
      else return '';
    }else{
      return '';
    }
  }
}
