import { unescape } from 'html-escaper';
import { FileModel } from '.';
import { formatDate, nextDays, translate } from '../helpers/frontend';

/*
  type : Number
    1 = จัดส่งโดยส่วนกลาง
      subtype : Number
        1 = LF
        2 = Kerry Express
    2 = Click & Collect
    3 = Delivery by 3rd Party
      subtype : Number
        1 = Grab
        2 = Kerry Express
    4 = By Appointment
    5 = Self Delivery
    6 = Nim Express
      subtype : Number
        1 = LF
        2 = Nim Express

  shortages : Array
    shortage : Object
      _id: Partner product id,
      name: Partner product name,
      shortage: Number,
      unit: String

  shop : Object
    _id
    code
    name
    type
    url
    workingHours
    status
*/

export class PartnerShippingFrontendModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.icon = new FileModel(data.icon? data.icon: {});

    this.type = data.type? Number(data.type): null;
    this.subtype = data.subtype? Number(data.subtype): null;

    this.name = data.name? unescape(data.name): null;
    this.displayName = data.displayName? unescape(data.displayName): null;
    this.description = data.description? unescape(data.description): null;
    
    this.price = data.price? Number(data.price): 0;
    this.priceActual = data.priceActual? Number(data.priceActual): 0;
    this.discount = data.discount? Number(data.discount): 0;
    
    this.lfPickupDuration = data.lfPickupDuration || data.lfPickupDuration===0
      ? Number(data.lfPickupDuration): null;
    this.minDuration = data.minDuration? Number(data.minDuration): 0;
    this.maxDuration = data.maxDuration? Number(data.maxDuration): 0;

    this.pickupDate = data.pickupDate? data.pickupDate: null;
    this.pickupTime = data.pickupTime? data.pickupTime: null;

    this.shortages = data.shortages && data.shortages.length? data.shortages: [];
    
    this.shop = data.shop? data.shop: null;
  }

  isValid() {
    return this._id? true: false;
  }

  hasShortages() {
    if(this.isValid() && this.shortages.length){
      return true;
    }else{
      return false;
    }
  }

  displayDeliveryDate(langObj={}) {
    if(this.isValid()){
      if(this.type === 2){
        return '';
      }else{
        return `${translate('Receive within', langObj)} ${formatDate(nextDays(this.maxDuration))}`;
      }
    }else{
      return '';
    }
  }
  displayPickupTime(langObj={}) {
    if(this.isValid() && this.type===2 && this.pickupDate && this.pickupTime){
      return `${translate('Pick up on', langObj)} ${formatDate(this.pickupDate)} ${this.pickupTime}`;
    }else{
      return '';
    }
  }
}
