import { unescape } from 'html-escaper';
import {
  DataCountryModel,
  DataDistrictModel,
  DataProvinceModel,
  DataSubdistrictModel,
} from '.';
import { SYSTEM_LANGUAGES } from '../actions/variables';
import { translate } from '../helpers/frontend';

export class AddressModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.addressLocal = data.addressLocal? data.addressLocal: SYSTEM_LANGUAGES[0];
    this.address = data.address? unescape(data.address): '';
    this.subdistrict = new DataSubdistrictModel(data.subdistrict? data.subdistrict: {});
    this.district = new DataDistrictModel(data.district? data.district: {});
    this.province = new DataProvinceModel(data.province? data.province: {});
    this.country = new DataCountryModel(data.country? data.country: {});
    this.zipcode = data.zipcode? data.zipcode: '';

    this.lat = data.lat? Number(data.lat): null;
    this.lng = data.lng? Number(data.lng): null;

    this.addressText = data.addressText? unescape(data.addressText): '';

    this.telephone = data.telephone? data.telephone: '';
    this.instruction = data.instruction? unescape(data.instruction): '';

    this.isPrimary = data.isPrimary? Number(data.isPrimary): 0;
    this.isSelected = data.isSelected? Number(data.isSelected): 0;
  }

  isValid() {
    return this._id? true: false;
  }
  isValidAddress() {
    if(this.isValid()){
      return this.address && this.subdistrict.isValid() && this.district.isValid() 
        && this.province.isValid() && this.country.isValid() && this.zipcode;
    }else{
      return false;
    }
  }

  prefixSubdistrict(lang='', langObj={}) {
    if(!lang || lang === 'TH'){
      if(!this.province.name) return 'แขวง / ตำบล';
      else if(this.province.name.includes('กรุงเทพ')) return 'แขวง';
      else return 'ตำบล';
    }else{
      return translate('Subdistrict', langObj);
    }
  }
  prefixDistrict(lang='', langObj={}) {
    if(!lang || lang === 'TH'){
      if(!this.province.name) return 'เขต / อำเภอ';
      else if(this.province.name.includes('กรุงเทพ')) return 'เขต';
      else return 'อำเภอ';
    }else{
      return translate('District', langObj);
    }
  }

  displayAddress(lang='', langObj={}) {
    if(lang === 'TH'){
      return (<>
        <span className="ws-prewrap">{this.address}</span> <br />
        {this.prefixSubdistrict()}{this.subdistrict.name} {this.prefixDistrict()}{this.district.name} <br />
        {this.province.name} ประเทศ{this.country.name} {this.zipcode}
      </>);
    }else if(lang === 'EN'){
      return (<>
        <span className="ws-prewrap">{this.address}</span> <br />
        Subdistrict {this.subdistrict.name}, District {this.district.name} <br />
        {this.province.name}, {this.country.name} {this.zipcode}
      </>);
    }else{
      return (<>
        <span className="ws-prewrap">{this.address}</span> <br />
        {this.prefixSubdistrict(lang, langObj)} {this.subdistrict.name},
        {' '}{this.prefixDistrict(lang, langObj)} {this.district.name} <br />
        {translate('Province', langObj)} {this.province.name}, {this.country.name} {this.zipcode}
      </>);
    }
  }
  displayAddressLine(lang='', langObj={}) {
    if(lang === 'TH'){
      return (<>
        {this.address}
        {' '}{this.prefixSubdistrict()}{this.subdistrict.name} {this.prefixDistrict()}{this.district.name}
        {' '}{this.province.name} ประเทศ{this.country.name} {this.zipcode}
      </>);
    }else if(lang === 'EN'){
      return (<>
        {this.address}
        {', '}Subdistrict {this.subdistrict.name}, District {this.district.name}
        {', '}{this.province.name}, {this.country.name} {this.zipcode}
      </>);
    }else{
      return (<>
        {this.address}
        {', '}{this.prefixSubdistrict(lang, langObj)} {this.subdistrict.name}
        {', '}{this.prefixDistrict(lang, langObj)} {this.district.name}
        {', '}{translate('Province', langObj)} {this.province.name}, {this.country.name} {this.zipcode}
      </>);
    }
  }
}
