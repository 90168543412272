import { 
  CustomerGroupModel, PartnerProductModel,
  PartnerProductCategoryModel, PartnerShopModel, UserModel,
} from '.';
import { unescape } from 'html-escaper';
import { translate } from '../helpers/frontend';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  productStatus

  status
    0 = แบบร่าง
    1 = ส่งแล้ว
*/

export class ToolPartnerProductPromotionModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.name = data.name? unescape(data.name): null;
    this.description = data.description? unescape(data.description): null;

    this.products = data.products && data.products.length
      ? data.products.map(d => new PartnerShopModel(d)): [];
    this.productStatus = data.productStatus? Number(data.productStatus): 0;
    
    this.discountStartAt = data.discountStartAt? data.discountStartAt: null;
    this.discountEndAt = data.discountEndAt? data.discountEndAt: null;

    this.status = data.status? Number(data.status): 0;
    
    this.availableType = data.availableType? Number(data.availableType): 0;
    this.rules = data.rules && data.rules.length? data.rules: [];

    this.products = data.products && data.products.length
      ? data.products.map(d => new PartnerProductModel(d)): [];
    this.productCategories = data.productCategories && data.productCategories.length
      ? data.productCategories.map(d => new PartnerProductCategoryModel(d)): [];

    this.forCustomerGroups = data.forCustomerGroups && data.forCustomerGroups.length
      ? data.forCustomerGroups.map(d => new CustomerGroupModel(d)): [];

    this.forAllPartnerShops = data.forAllPartnerShops? data.forAllPartnerShops: 0;
    this.forPartnerShops = data.forPartnerShops && data.forPartnerShops.length
      ? data.forPartnerShops.map(d => new PartnerShopModel(d)): [];

    this.createdBy = new UserModel(data.createdBy || {});
    this.createdAt = data.createdAt || null;
    this.updatedBy = new UserModel(data.updatedBy || {});
    this.updatedAt = data.updatedAt || null;

    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'name', 'description' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }

  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Applied',langObj)}</span>);
      else return (<span className="ss-tag bg-fgray color-dark">{translate('Draft',langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-fgray color-dark">{translate('Draft',langObj)}</span>);
    }
  }
  displayProductStatus(langObj={}) {
    if(this.isValid()){
      if(this.productStatus === 6) return (<span className="ss-tag bg-p">{translate('text_product_status_6', langObj)}</span>);
      else if(this.productStatus === 5) return (<span className="ss-tag bg-info">{translate('text_product_status_5', langObj)}</span>);
      else if(this.productStatus === 4) return (<span className="ss-tag bg-p">{translate('text_product_status_4', langObj)}</span>);
      else if(this.productStatus === 3) return (<span className="ss-tag bg-gray">{translate('text_product_status_3', langObj)}</span>);
      else if(this.productStatus === 2) return (<span className="ss-tag bg-success">{translate('text_product_status_2', langObj)}</span>);
      else if(this.productStatus === 1) return (<span className="ss-tag bg-gray">{translate('text_product_status_1', langObj)}</span>);
      else if(this.productStatus === -2) return (<span className="ss-tag bg-danger">{translate('Giveaway', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('text_product_status_0', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('text_product_status_0', langObj)}</span>);
    }
  }
}
