import {
  UserModel, CustomerModel, CustomerGroupModel, PartnerShopModel,
  PartnerProductModel, PartnerProductCouponModel, PartnerProductGiveawayRuleModel,
  PartnerShippingModel, PartnerShippingFrontendModel, PartnerShippingCouponModel,
  PartnerProductRatingModel, ShippingStatusMappingModel, PaymentMethodModel,
  ShippingAddressModel, BillingAddressModel, CustomerOrderRatingModel,
} from '.';
import { unescape } from 'html-escaper';
import { translate, formatDate } from '../helpers/frontend';

/*
  paymentStatus
    -1 = คืนเงินแล้ว
    0  = ทดสอบ
    1  = รอการชำระเงิน COD
    2  = ชำระเงินแล้ว COD
    3  = ชำระเงินแล้ว
    4  = ผ่อนชำระแล้ว
*/

export class CustomerOrderModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.type = data.type? unescape(data.type): 'C2U';
    this.partnerShop = new PartnerShopModel(data.partnerShop || {});
    
    this.orderId = data.orderId? unescape(data.orderId): null;

    this.orderIdSAP = data.orderIdSAP? unescape(data.orderIdSAP): null;
    this.orderStatusSAP = data.orderStatusSAP? Number(data.orderStatusSAP): 0;
    this.orderStatusSAPCash = data.orderStatusSAPCash? Number(data.orderStatusSAPCash): 0;

    this.orderIdKerry = data.orderIdKerry? unescape(data.orderIdKerry): null;
    this.orderStatusKerry = data.orderStatusKerry? Number(data.orderStatusKerry): 0;
    this.pickupDateKerry = data.pickupDateKerry ?? null;
    this.pickupTimeKerry = data.pickupTimeKerry ?? null;

    this.orderId2C2P = data.orderId2C2P? unescape(data.orderId2C2P): null;
    this.orderStatus2C2P = data.orderStatus2C2P? Number(data.orderStatus2C2P): 0;

    this.orderIdEnpro = data.orderIdEnpro ?? null;
    this.addressIdEnpro = data.addressIdEnpro ?? null;

    this.paymentToken2C2P = data.paymentToken2C2P ?? null;
    
    this.customer = new CustomerModel(data.customer || {});
    this.customerGroup = new CustomerGroupModel(data.customerGroup || {});
    this.shop = new PartnerShopModel(data.shop || {});
    this.partner = new UserModel(data.partner || {});
    this.salesManager = new UserModel(data.salesManager || {});

    this.products = data.products?.length
      ? data.products.map(d => new PartnerProductModel(d)): [];
    this.subtotal = data.subtotal || data.subtotal===0? Number(data.subtotal): null;
    
    this.vatName = data.vatName? unescape(data.vatName): '';
    this.vat = data.vat || data.vat===0? Number(data.vat): null;
    this.vatPercent = data.vatPercent || data.vatPercent===0? Number(data.vatPercent): null;
    
    this.total = data.total || data.total===0? Number(data.total): null;
    
    this.coupon = new PartnerProductCouponModel(data.coupon || {});
    this.couponDiscount = data.couponDiscount? Number(data.couponDiscount): 0;
    this.couponMissingPaymentDiscount = data.couponMissingPaymentDiscount 
      ? Number(data.couponMissingPaymentDiscount): 0;
    this.couponDiffInstallmentDiscount = data.couponDiffInstallmentDiscount 
      ? Number(data.couponDiffInstallmentDiscount): 0;
    
    this.shipping = new PartnerShippingModel(data.shipping || {});
    this.shippingFrontend = new PartnerShippingFrontendModel(data.shippingFrontend || {});
    this.shippingCost = data.shippingCost? Number(data.shippingCost): 0;
    this.shippingCoupon = new PartnerShippingCouponModel(data.shippingCoupon || {});
    this.shippingDiscount = data.shippingDiscount? Number(data.shippingDiscount): 0;
    
    this.cashCoupon = new PartnerProductCouponModel(data.cashCoupon || {});
    this.cashDiscount = data.cashDiscount? Number(data.cashDiscount): 0;
    this.cashMissingPaymentDiscount = data.cashMissingPaymentDiscount 
      ? Number(data.cashMissingPaymentDiscount): 0;
    
    this.pointBurn = data.pointBurn || data.pointBurn===0? Number(data.pointBurn): null;
    this.pointDiscount = data.pointDiscount || data.pointDiscount===0? Number(data.pointDiscount): null;
    
    this.grandTotal = data.grandTotal || data.grandTotal===0? Number(data.grandTotal): null;
    
    this.hasDownPayment = data.hasDownPayment? Number(data.hasDownPayment): 0;
    this.downPayment = data.downPayment? Number(data.downPayment): 0;
    this.missingPayment = data.missingPayment? Number(data.missingPayment): 0;
    this.fullMissingPayment = data.fullMissingPayment? Number(data.fullMissingPayment): 0;
    
    this.grandTotalDefault = data.grandTotalDefault || data.grandTotalDefault===0 
      ? Number(data.grandTotalDefault): null;
    this.hasCustomDownPayment = data.hasCustomDownPayment? 1: 0;
    this.customDownPayment = data.customDownPayment || data.customDownPayment===0 
      ? Number(data.customDownPayment): null;
    this.downPaymentDefault = data.downPaymentDefault || data.downPaymentDefault===0 
      ? Number(data.downPaymentDefault): null;
    this.missingPaymentDefault = data.missingPaymentDefault || data.missingPaymentDefault===0 
      ? Number(data.missingPaymentDefault): null;

    this.pointEarn = data.pointEarn? Number(data.pointEarn): 0;
    this.pointEarnStatus = data.pointEarnStatus? Number(data.pointEarnStatus): 0;

    this.shippingAddress = new ShippingAddressModel(data.shippingAddress || {});
    this.billingAddress = new BillingAddressModel(data.billingAddress || {});

    this.shippingStatusDates = data.shippingStatusDates?.length? data.shippingStatusDates: [];
    this.shippingStatus = new ShippingStatusMappingModel(data.shippingStatus
      ? { ...data.shippingStatus, createdAt: this.shippingStatusDates[0] }: {});
    this.shippingHistory = data.shippingHistory?.length
      ? data.shippingHistory.map((d, i) => new ShippingStatusMappingModel({ ...d, createdAt: this.shippingStatusDates[i] })): [];

    this.minDeliveryDate = data.minDeliveryDate ?? null;
    this.maxDeliveryDate = data.maxDeliveryDate ?? null;
    this.pickupDate = data.pickupDate ?? null;
    this.pickupTime = data.pickupTime ?? null;
    this.deliveredDate = data.deliveredDate ?? null;

    this.paymentMethod = new PaymentMethodModel(data.paymentMethod || {});
    this.paymentStatus = data.paymentStatus || [-1, 0].indexOf(data.paymentStatus)>-1
      ? Number(data.paymentStatus): null;
    this.paymentDate = data.paymentDate ?? null;
    
    this.packagings = data.packagings?.length? data.packagings: [];
    
    this.note = data.note? unescape(data.note): null;

    this.receivedCoupons = data.receivedCoupons?.length? data.receivedCoupons: [];
    this.productGiveawayRule = new PartnerProductGiveawayRuleModel(data?.productGiveawayRule || {});
    
    this.rating = data.rating || data.rating===0? Number(data.rating): null;
    this.orderRating = new CustomerOrderRatingModel(data.orderRating || {});
    this.productRating = new PartnerProductRatingModel(data.productRating || {});

    this.subscription = data.subscription || null;
    this.subscriptionPlan = data.subscriptionPlan || null;

    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
  }

  isValid() {
    return this._id? true: false;
  }
  isValidShippingAddress() {
    return this.shippingAddress.shippingFirstname && this.shippingAddress.shippingLastname;
  }
  isValidBillingAddress() {
    return this.billingAddress.billingName && this.billingAddress.taxId;
  }
  isValidDownPayment() {
    return this.isValid() && this.hasDownPayment 
      && this.downPayment && this.missingPayment;
  }
  isValidCustomDownPayment() {
    return this.isValid() && (this.customDownPayment || this.customDownPayment===0) && (
      (this.downPaymentDefault || this.downPaymentDefault===0) 
      || (this.missingPaymentDefault || this.missingPaymentDefault===0) 
    );
  }
  
  hasSupplierProducts() {
    let res = false;
    if(this.isValid() && this.products.length){
      this.products.forEach(d => { if(d.shipBySupplier) res = true; });
    }
    return res;
  }

  displayShippingStatus() {
    if(this.isValid() && this.shippingStatus.isValid() && this.shippingStatus.shippingStatus.isValid()){
      let temp = this.shippingStatus.shippingStatus;
      if(temp.order === 1) return (<span className="ss-tag bg-warning">{temp.name}</span>);
      else if(temp.order === 2) return (<span className="ss-tag bg-info">{temp.name}</span>);
      else if(temp.order === 3) return (<span className="ss-tag bg-success">{temp.name}</span>);
      else if(temp.order === 4) return (<span className="ss-tag bg-danger">{temp.name}</span>);
      else return (<></>);
    }else{
      return (<></>);
    }
  }
  displayPaymentStatus(langObj={}) {
    if(this.isValid()){
      if(this.paymentStatus === 4) return (<span className="ss-tag bg-p">{translate('Paid Installment', langObj)}</span>);
      else if(this.paymentStatus === 3) return (<span className="ss-tag bg-success">{translate('Paid', langObj)}</span>);
      else if(this.paymentStatus === 2) return (<span className="ss-tag bg-info">{translate('Paid COD', langObj)}</span>);
      else if(this.paymentStatus === 1) return (<span className="ss-tag bg-warning">{translate('Waiting for COD', langObj)}</span>);
      else if(this.paymentStatus === -1) return (<span className="ss-tag bg-danger">{translate('Refunded', langObj)}</span>);
      else return (<span className="ss-tag bg-danger">{translate('Testing', langObj)}</span>);
    }else{
      return (<></>);
    }
  }
  displayDeliveryRange(langObj={}) {
    if(this.isValid() && this.shipping.isValid()){
      if(this.shipping.type === 2){
        return `${formatDate(this.pickupDate)} ${translate('Time', langObj)} ${this.pickupTime}`;
      }else{
        let minDate = new Date(this.minDeliveryDate);
        let maxDate = new Date(this.maxDeliveryDate);
        if(minDate.getDate() === maxDate.getDate()){
          return formatDate(this.minDeliveryDate);
        }else{
          return `${formatDate(this.minDeliveryDate)} - ${formatDate(this.maxDeliveryDate)}`;
        }
      }
    }else{
      return (<></>);
    }
  }
  displayDeliveredStatus(langObj={}) {
    if(this.isValid()){
      if(this.shippingStatus.isValid() && this.shippingStatus.shippingStatus.isValid() 
      && this.shippingStatus.shippingStatus.order === 4){
        return (<>{translate('Cancel/Return', langObj)}</>);
      }else{
        let now = new Date();
        let maxDate = new Date(this.maxDeliveryDate);
        let onTime = Math.ceil((maxDate - now)/(1000 * 60 * 60 * 24));
        if(!this.deliveredDate){
          if(this.shipping.type === 2) return (<span className="color-info">{translate('ยังไม่ได้มารับสินค้า', langObj)}</span>);
          else if(onTime >= 0) return (<>{translate('กำลังจัดส่งตรงเวลา', langObj)}</>);
          else return (<span className="color-danger">{translate('กำลังจัดส่งล่าช้า', langObj)} {-onTime} {translate('Days', langObj)}</span>);
        }else{
          let deliveredDate = new Date(this.deliveredDate);
          onTime = Math.ceil((maxDate - deliveredDate)/(1000 * 60 * 60 * 24));
          if(this.shipping.type === 2) return (<>{translate('มารับสินค้าแล้ว', langObj)}</>);
          else if(onTime >= 0) return (<>{translate('จัดส่งแล้วตรงเวลา', langObj)}</>);
          else return (<span className="color-warning">{translate('จัดส่งแล้วล่าช้า', langObj)} {-onTime} {translate('Days', langObj)}</span>);
        }
      }
    }else{
      return '';
    }
  }

  displayStatusSAP(langObj={}) {
    if(this.isValid() && (
      [1,4,6,7,8,9,10,11].indexOf(this.shipping.type) > -1 
      || this.subscription || (this.shipping.type===2 && this.shop?.usePOS)
    )){
      if(this.orderStatusSAP === 1) return (<span className="ss-tag bg-success">{translate('Success', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Failed', langObj)}</span>);
    }else{
      return (<></>);
    }
  }
  displayStatusSAPCash(langObj={}) {
    if(this.isValid() && (
      [1,4,6,7,8,9,10,11].indexOf(this.shipping?.type) > -1 
      || this.subscription || (this.shipping.type===2 && this.shop?.usePOS)
    )){
      if(this.orderStatusSAPCash === 1) return (<span className="ss-tag bg-success">{translate('Success', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Failed', langObj)}</span>);
    }else{
      return (<></>);
    }
  }

  displayStatusKerry(langObj={}) {
    if(this.isValid() && this.orderIdKerry){
      if(this.orderStatusKerry === 1) return (<span className="ss-tag bg-success">{translate('Success', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Failed', langObj)}</span>);
    }else{
      return (<></>);
    }
  }
  displayStatus2C2P(langObj={}) {
    if(this.isValid() && this.orderId2C2P){
      if(this.orderStatus2C2P === 1) return (<span className="ss-tag bg-success">{translate('Success', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Failed', langObj)}</span>);
    }else{
      return (<></>);
    }
  }

  displayRatingStatus(langObj={}, ratingStatus){
    if(ratingStatus){
      if(ratingStatus === 1) return (<span className="ss-tag bg-success">{translate('Show', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Hide', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Hide', langObj)}</span>);
    }
  }
  displayRatingAnonymous(langObj={}, isAnonymous){
    if(isAnonymous){
      if(isAnonymous === 1) return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }

  displaySubscriptionFreeShippingCost(langObj={}){
    const _data = this.subscription;
    if(_data?._id){
      if(_data?.freeShippingCost === 1) return (<span className="ss-tag bg-success">{translate('Yes', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('No', langObj)}</span>);
    }
  }
  displaySubscriptionFrequency(langObj={}){
    const _data = this.subscription;
    if(!_data?._id) return '';
    let _text = '';
    if(_data.recurringType === 1){
      if(_data.recurringInterval === 1) _text += translate('Per Day', langObj);
      else _text += translate('Per _X_ Days', langObj, { '_X_': _data.recurringInterval });
    }else if(_data.recurringType === 2){
      if(_data.recurringInterval === 1) _text += translate('Per Month', langObj);
      else _text += translate('Per _X_ Months', langObj, { '_X_': _data.recurringInterval });
    }else if(_data.recurringType === 3){
      if(_data.recurringInterval === 1) _text += translate('Per Year', langObj);
      else _text += translate('Per _X_ Years', langObj, { '_X_': _data.recurringInterval });
    }
    if(_data.recurringCount === 1) _text += ` ${translate('For 1 Time', langObj)}`;
    else _text += ` ${translate('For _X_ Times', langObj, { '_X_': _data.recurringCount })}`;
    return _text;
  }
}
