import {
  CmsContentCategoryModel, FileModel,
  PartnerShopModel, PartnerProductCategoryModel, PartnerProductModel,
  PartnerProductCouponModel, PartnerShippingCouponModel,
  CustomerGroupModel
} from '.';
import { unescape } from 'html-escaper';
import { translate } from '../helpers/frontend';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  type : String
    - C2U
    - Store

  status :
    0 = Inactive
    1 = Active
    2 = Featured
*/

export class CmsContentModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.type = data.type? unescape(data.type): 'C2U';
    
    this.category = new CmsContentCategoryModel(data.category || {});
    
    this.title = data.title? unescape(data.title): null;
    this.description = data.description? unescape(data.description): null;
    this.keywords = data.keywords? unescape(data.keywords): null;
    
    this.metaTitle = data.metaTitle? unescape(data.metaTitle): null;
    this.metaDescription = data.metaDescription? unescape(data.metaDescription): null;
    this.metaKeywords = data.metaKeywords? unescape(data.metaKeywords): null;

    this.url = data.url || null;
    this.image = new FileModel(data.image || {});
    this.gallery = data.gallery?.length? data.gallery.map(d => new FileModel(d)): [];
    
    this.content = data.content? unescape(data.content): null;

    this.youtubeVideoId = data.youtubeVideoId || null;
    this.pdfs = data.pdfs?.length 
      ? data.pdfs.map(d => {
        return {
          name: d.name? unescape(d.name): null,
          pdf: new FileModel(d.pdf || {}),
          pages: d.pages?.length? d.pages.map(k => new FileModel(k)): [],
        };
      }).filter(d => d.name): [];

    this.relatedPartnerShops = data.relatedPartnerShops?.length
      ? data.relatedPartnerShops.map(d => new PartnerShopModel(d)): [];
    this.relatedPartnerProductCategories = data.relatedPartnerProductCategories?.length
      ? data.relatedPartnerProductCategories.map(d => new PartnerProductCategoryModel(d)): [];
    this.relatedPartnerProducts = data.relatedPartnerProducts?.length
      ? data.relatedPartnerProducts.map(d => new PartnerProductModel(d)): [];
      
    this.relatedPartnerProductCoupons = data.relatedPartnerProductCoupons?.length
      ? data.relatedPartnerProductCoupons.map(d => new PartnerProductCouponModel(d)): [];
    this.relatedPartnerShippingCoupons = data.relatedPartnerShippingCoupons?.length
      ? data.relatedPartnerShippingCoupons.map(d => new PartnerShippingCouponModel(d)): [];

    this.forCustomerGroups = data.forCustomerGroups?.length
      ? data.forCustomerGroups.map(d => new CustomerGroupModel(d)): [];

    this.order = data.order? Number(data.order): 1;
    this.status = data.status? Number(data.status): 0;

    this.isDeletable = data.isDeletable? Number(data.isDeletable): 0;

    this.countVisitors = data.countVisitors? Number(data.countVisitors): 0;

    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'title', 'description', 'content' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 2) return (<span className="ss-tag bg-info">{translate('Featured', langObj)}</span>);
      else if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }

  initPdf() {
    return {
      name: null,
      pdf: new FileModel(),
      pages: [],
    };
  }

  existInGroup(groupId) {
    if(groupId && this.forCustomerGroups && this.forCustomerGroups.length) {
      const containGroup = this.forCustomerGroups.find(d => d._id === groupId) !== undefined;
      if(containGroup){
        return true;
      }else return false;
    } else return false;
  }
}
