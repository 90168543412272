import { apiHeader } from '../helpers/header';
import { API_URL } from './variables';
import { CLIENT_IP, SIDENAV_ACTIVE_INDEX } from './types';


export const updateClientIp = () => async (dispatch) => {
  const _fetch = await fetch('https://geolocation-db.com/json/');
  const _data = await _fetch.json();
  dispatch({ 
    type: CLIENT_IP, 
    payload: _data.IPv4 
  });
  return _data.IPv4;
};
export const setSidenavActiveIndex = (index) => (dispatch) => {
  dispatch({
    type: SIDENAV_ACTIVE_INDEX,
    payload: index
  });
  return true;
};

export const checkReachedLimit = (type='') => {
  return new Promise(async (resolve, reject) => {
    try {
      const _fetch = await fetch(`${API_URL}app/reached-limit?type=${type}`, {
        method: 'GET',
        headers: apiHeader(),
      });
      if(!_fetch.ok || _fetch.status !== 200){
        resolve(false); return false;
      }else{
        const _data = await _fetch.json();
        resolve(_data.data); return _data.data;
      }
    } catch (err) {
      resolve(false); return false;
    }
  });
};
