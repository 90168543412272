
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

export const getFirebaseApp = (settings={}, shop=null) => {
  const appName = shop && shop.type === 9? `${shop.code}`: 'MAIN';
  const prefix = shop && shop.type === 9? `${shop.code}_`: '';
  if(settings[`${prefix}FIREBASE_API_KEY`] && settings[`${prefix}FIREBASE_AUTH_DOMAIN`] 
  && settings[`${prefix}FIREBASE_PROJECT_ID`] && settings[`${prefix}FIREBASE_STORAGE_BUCKET`] 
  && settings[`${prefix}FIREBASE_MESSAGING_SENDER_ID`] && settings[`${prefix}FIREBASE_APP_ID`] 
  && settings[`${prefix}FIREBASE_MEASUREMENT_ID`]){
    try {
      const _firebaseApps = getApps();
      const _firebaseApp = _firebaseApps.find(app => app?.name === appName);
      if(!_firebaseApp){
        const _firebaseConfig = {
          apiKey: settings[`${prefix}FIREBASE_API_KEY`],
          authDomain: settings[`${prefix}FIREBASE_AUTH_DOMAIN`],
          projectId: settings[`${prefix}FIREBASE_PROJECT_ID`],
          storageBucket: settings[`${prefix}FIREBASE_STORAGE_BUCKET`],
          messagingSenderId: settings[`${prefix}FIREBASE_MESSAGING_SENDER_ID`],
          appId: settings[`${prefix}FIREBASE_APP_ID`],
          measurementId: settings[`${prefix}FIREBASE_MEASUREMENT_ID`],
        };
        initializeApp(_firebaseConfig, appName);
      }
      return getApp(appName);
    } catch {}
  }
  return null;
};
export const getFirebaseDB = (settings={}, shop=null) => {
  const appName = shop && shop.type === 9? `${shop.code}`: 'MAIN';
  const prefix = shop && shop.type === 9? `${shop.code}_`: '';
  if(settings[`${prefix}FIREBASE_API_KEY`] && settings[`${prefix}FIREBASE_AUTH_DOMAIN`] 
  && settings[`${prefix}FIREBASE_PROJECT_ID`] && settings[`${prefix}FIREBASE_STORAGE_BUCKET`] 
  && settings[`${prefix}FIREBASE_MESSAGING_SENDER_ID`] && settings[`${prefix}FIREBASE_APP_ID`] 
  && settings[`${prefix}FIREBASE_MEASUREMENT_ID`]){
    try {
      const _firebaseApps = getApps();
      const _firebaseApp = _firebaseApps.find(app => app?.name === appName);
      if(!_firebaseApp){
        const _firebaseConfig = {
          apiKey: settings[`${prefix}FIREBASE_API_KEY`],
          authDomain: settings[`${prefix}FIREBASE_AUTH_DOMAIN`],
          projectId: settings[`${prefix}FIREBASE_PROJECT_ID`],
          storageBucket: settings[`${prefix}FIREBASE_STORAGE_BUCKET`],
          messagingSenderId: settings[`${prefix}FIREBASE_MESSAGING_SENDER_ID`],
          appId: settings[`${prefix}FIREBASE_APP_ID`],
          measurementId: settings[`${prefix}FIREBASE_MEASUREMENT_ID`],
        };
        initializeApp(_firebaseConfig, appName);
      }
      return getFirestore(getApp(appName));
    } catch {}
  }
  return null;
};
export const getFirebaseAuth = (settings={}, shop=null) => {
  const appName = shop && shop.type === 9? `${shop.code}`: 'MAIN';
  const prefix = shop && shop.type === 9? `${shop.code}_`: '';
  if(settings[`${prefix}FIREBASE_API_KEY`] && settings[`${prefix}FIREBASE_AUTH_DOMAIN`] 
  && settings[`${prefix}FIREBASE_PROJECT_ID`] && settings[`${prefix}FIREBASE_STORAGE_BUCKET`] 
  && settings[`${prefix}FIREBASE_MESSAGING_SENDER_ID`] && settings[`${prefix}FIREBASE_APP_ID`] 
  && settings[`${prefix}FIREBASE_MEASUREMENT_ID`]){
    try {
      const _firebaseApps = getApps();
      const _firebaseApp = _firebaseApps.find(app => app?.name === appName);
      if(!_firebaseApp){
        const _firebaseConfig = {
          apiKey: settings[`${prefix}FIREBASE_API_KEY`],
          authDomain: settings[`${prefix}FIREBASE_AUTH_DOMAIN`],
          projectId: settings[`${prefix}FIREBASE_PROJECT_ID`],
          storageBucket: settings[`${prefix}FIREBASE_STORAGE_BUCKET`],
          messagingSenderId: settings[`${prefix}FIREBASE_MESSAGING_SENDER_ID`],
          appId: settings[`${prefix}FIREBASE_APP_ID`],
          measurementId: settings[`${prefix}FIREBASE_MEASUREMENT_ID`],
        };
        initializeApp(_firebaseConfig, appName);
      }
      return getAuth(getApp(appName));
    } catch {}
  }
  return null;
};
