import PropTypes from 'prop-types';
import { useEffect, useState, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { formatDate, formatTime, translate } from '../helpers/frontend';

import { connect } from 'react-redux';
import { processList as smProcessList } from '../actions/sales-manager.actions';

import { collection, onSnapshot, query, where, doc, updateDoc, getDocs } from 'firebase/firestore';
import { getFirebaseDB } from '../helpers/firebase';

function TopnavRealTime(props) {
  const { user, externalShop, partnerShops, prefix, settings, langObj } = useContext(AuthContext);
  
  const history = useNavigate();
  const [firebaseDb, setFirebaseDb] = useState(null);
  
  // START: Order & Message Notifications
  const [knownOrderIds, setKnownOrderIds] = useState([]);
  const [newOrders, setNewOrders] = useState([]);
  const audioAlert = new Audio('/assets/mp3/alert-01.mp3');

  const [alertInterval, setAlertInterval] = useState(false);
  const [playAlert, setPlayAlert] = useState(false);
  const onCheckPlayAlert = (orders=[]) => {
    let temp = false;
    if(!playAlert && orders.length){
      let orderIds = orders.map(d => d._id);
      let knownIds = [ ...knownOrderIds ];
      orderIds.forEach(d => {
        if(knownOrderIds.indexOf(d) < 0){
          temp = true;
          knownIds.push(d);
        }
      });
      setKnownOrderIds(knownIds);
    }
    setPlayAlert(temp);
  };
  const onClickLinkOrder = (e, index, data) => {
    e?.preventDefault();
    const temp = [ ...newOrders ];
    temp.splice(index, 1);
    setNewOrders(temp);
    history(`${user.path()}/customer-order/view/${data._id}`);
  };
  const onClickLinkOrders = async (e) => {
    e?.preventDefault();
    try {
      if(firebaseDb && user.isSignedIn()){
        if(user.isAdmin()){
          const _docs = await getDocs(query(
            collection(firebaseDb, 'customer_orders'),
            where('admin.isRead', '==', false),
          ));
          _docs.forEach(_doc => {
            const _d = _doc.data();
            if(_d?._id) updateDoc(doc(firebaseDb, 'customer_orders', _d._id), { admin: { isRead: true } });
          });
        }else if(user.isExAdmin() && externalShop.isValid()){
          const _docs = await getDocs(query(
            collection(firebaseDb, 'customer_orders'),
            where('exAdmin.isRead', '==', false),
            where('shopCode', '==', externalShop.code),
          ));
          _docs.forEach(_doc => {
            const _d = _doc.data();
            if(_d?._id) updateDoc(doc(firebaseDb, 'customer_orders', _d._id), { exAdmin: { isRead: true } });
          });
        }else if(user.isPartner()){
          const _docs = await getDocs(query(
            collection(firebaseDb, 'customer_orders'),
            where('partner._id', '==', user._id),
            where('partner.isRead', '==', false),
          ));
          _docs.forEach(_doc => {
            const _d = _doc.data();
            if(_d?._id) updateDoc(doc(firebaseDb, 'customer_orders', _d._id), { partner: { isRead: true } });
          });
        }else if(user.isSalesManager()){
          const _docs = await getDocs(query(
            collection(firebaseDb, 'customer_orders'),
            where('salesManager._id', '==', user._id),
            where('salesManager.isRead', '==', false),
          ));
          _docs.forEach(_doc => {
            const _d = _doc.data();
            if(_d?._id) updateDoc(doc(firebaseDb, 'customer_orders', _d._id), { salesManager: { isRead: true } });
          });
        }
      }
    } catch {}
    history(`${user.path()}/customer-orders`);
  };

  const [hasNewMessages, setHasNewMessages] = useState(false);
  const [hasGuestMessages, setHasGuestMessages] = useState(false);
  // END: Order & Message Notifications


  /* eslint-disable */
  useEffect(() => {
    return () => setKnownOrderIds([]);
  }, [user]);
  useEffect(() => {
    setFirebaseDb(() => getFirebaseDB(settings, externalShop));
  }, [settings]);

	useEffect(() => {
    var unsubscribe1 = () => {};
    const onLoad1 = async () => {
      setPlayAlert(false);
      if(firebaseDb && user.isSignedIn()){
        if(user.isAdmin()){
          unsubscribe1 = onSnapshot(
            query(
              collection(firebaseDb, 'customer_orders'),
              where('admin.isRead', '==', false),
            ),
            querySnapshot => {
              let temp = [];
              querySnapshot.forEach(d => temp.unshift(d.data()));
              setNewOrders(temp);
              onCheckPlayAlert(temp);
            }
          );
        }else if(user.isExAdmin() && externalShop.isValid()){
          unsubscribe1 = onSnapshot(
            query(
              collection(firebaseDb, 'customer_orders'),
              where('exAdmin.isRead', '==', false),
              where('shopCode', '==', externalShop.code),
            ),
            querySnapshot => {
              let temp = [];
              querySnapshot.forEach(d => temp.unshift(d.data()));
              setNewOrders(temp);
              onCheckPlayAlert(temp);
            }
          );
        }else if(user.isPartner()){
          unsubscribe1 = onSnapshot(
            query(
              collection(firebaseDb, 'customer_orders'),
              where('partner._id', '==', user._id),
              where('partner.isRead', '==', false),
            ),
            querySnapshot => {
              let temp = [];
              querySnapshot.forEach(d => temp.unshift(d.data()));
              setNewOrders(temp);
              onCheckPlayAlert(temp);
            }
          );
        }else if(user.isSalesManager()){
          unsubscribe1 = onSnapshot(
            query(
              collection(firebaseDb, 'customer_orders'),
              where('salesManager._id', '==', user._id),
              where('salesManager.isRead', '==', false),
            ),
            querySnapshot => {
              let temp = [];
              querySnapshot.forEach(d => temp.unshift(d.data()));
              setNewOrders(temp);
              onCheckPlayAlert(temp);
            }
          );
        }
      }
    };
    onLoad1();
    return () => unsubscribe1();
  }, [firebaseDb]);
  
	useEffect(() => {
    var unsubscribe2 = () => {};
    const onLoad2 = async () => {
      setHasNewMessages(false);
      if(settings['APP_MODULE_CHAT_CUSTOMER']==='1' 
      && settings[`${prefix}ENABLE_CHAT_CUSTOMER`]==='1'){
        if(firebaseDb && user.isSignedIn()){
          if(user.isAdmin()){
            unsubscribe2 = onSnapshot(
              query(
                collection(firebaseDb, 'customer_chatrooms'),
                where('isReadAdmin', '==', false)
              ),
              querySnapshot => {
                let temp = false;
                querySnapshot.forEach(() => temp = true);
                setHasNewMessages(temp);
              }
            );
          }else if(user.isExAdmin()){
            unsubscribe2 = onSnapshot(
              query(
                collection(firebaseDb, 'customer_chatrooms'),
                where('isReadAdmin', '==', false),
                where('partnerShop.code', '==', externalShop.code)
              ),
              querySnapshot => {
                let temp = false;
                querySnapshot.forEach(() => temp = true);
                setHasNewMessages(temp);
              }
            );
          }else if(user.isPartner() && partnerShops.length){
            unsubscribe2 = onSnapshot(
              query(
                collection(firebaseDb, 'customer_chatrooms'),
                where('isReadPartner', '==', false),
                where('partnerShop._id', 'in', partnerShops.map(d => d._id))
              ),
              querySnapshot => {
                let temp = false;
                querySnapshot.forEach(() => temp = true);
                setHasNewMessages(temp);
              }
            );
          }else if(user.isSalesManager() && partnerShops.length){
            const res = await props.smProcessList('customer-ids');
            const customerIds = res?.result || [];
            if(customerIds.length){
              unsubscribe2 = onSnapshot(
                query(
                  collection(firebaseDb, 'customer_chatrooms'),
                  where('isReadSalesManager', '==', false),
                  where('partnerShop._id', 'in', partnerShops.map(d => d._id)),
                  where('customer._id', 'array-contains', customerIds),
                ),
                querySnapshot => {
                  let temp = false;
                  querySnapshot.forEach(() => temp = true);
                  setHasNewMessages(temp);
                }
              );
            }
          }
        }
      }
    };
    onLoad2();
    return () => unsubscribe2();
  }, [firebaseDb]);
	useEffect(() => {
    var unsubscribe3 = () => {};
    const onLoad3 = async () => {
      setHasGuestMessages(false);
      if(settings['APP_MODULE_CHAT_GUEST']==='1' 
      && settings[`${prefix}ENABLE_CHAT_GUEST`]==='1'){
        if(firebaseDb && user.isSignedIn()){
          if(user.isAdmin()){
            unsubscribe3 = onSnapshot(
              query(
                collection(firebaseDb, 'guest_chatrooms'),
                where('isReadAdmin', '==', false),
                where('partnerShop.code', '==', 'CENTER'),
              ),
              querySnapshot => {
                let temp = false;
                querySnapshot.forEach(() => temp = true);
                setHasGuestMessages(temp);
              }
            );
          }else if(user.isExAdmin()){
            unsubscribe3 = onSnapshot(
              query(
                collection(firebaseDb, 'guest_chatrooms'),
                where('isReadAdmin', '==', false),
                where('partnerShop.code', '==', externalShop.code),
              ),
              querySnapshot => {
                let temp = false;
                querySnapshot.forEach(() => temp = true);
                setHasGuestMessages(temp);
              }
            );
          }
        }
      }
    };
    onLoad3();
    return () => unsubscribe3();
  }, [firebaseDb]);

	useEffect(() => {
    if(alertInterval) clearInterval(alertInterval);
    if(playAlert){
      const _interval = setInterval(async () => {
        try {
          await audioAlert.play();
          onCheckPlayAlert();
        } catch {}
      }, 1000);
      setAlertInterval(() => _interval);
    }
  }, [playAlert]);
  /* eslint-enable */

  return !firebaseDb || user.isSupplyChainAdmin()? (<></>): (<>
    {/* Message */}
    {settings['APP_MODULE_CHAT_CUSTOMER']==='1' 
    && settings[`${prefix}ENABLE_CHAT_CUSTOMER`]==='1'? (
      <div className="option">
        <Link className="option-icon" to={`${user.path()}/customer-messages`}>
          <em className="fa-regular fa-message"></em>
          {hasNewMessages? (<div className="dot"></div>): (<></>)}
        </Link>
      </div>
    ): (<></>)}
    {settings['APP_MODULE_CHAT_GUEST']==='1' 
    && settings[`${prefix}ENABLE_CHAT_GUEST`]==='1' 
    && (user.isAdmin() || user.isExAdmin())? (
      <div className="option">
        <Link className="option-icon" to={`${user.path()}/guest-messages`}>
          <em className="fa-solid fa-headset"></em>
          {hasGuestMessages? (<div className="dot"></div>): (<></>)}
        </Link>
      </div>
    ): (<></>)}

    {/* Notification */}
    <div className="option">
      <div className="option-icon">
        <em className="fa-regular fa-bell"></em>
        {newOrders.length? (
          <div className="dot"></div>
        ): (<></>)}
      </div>
      <div className="dropdown bcolor-fgray pull-right">
        {!newOrders.length? (
          <div className="wrapper bcolor-fgray">
            <div className="text-empty">{translate('No Notification', langObj)}</div>
          </div>
        ): (<>
          <div className="wrapper bcolor-fgray p-0">
            <div className="dropdown-scroll">
              {newOrders.map((d, i) => (
                <div key={`noti_${i}`} className="dropdown-item ai-start" 
                  onClick={e => onClickLinkOrder(e, i, d)} 
                >
                  <div className="icon"><em className="fa-solid fa-credit-card"></em></div>
                  <div className="info color-gray">
                    <span className="fw-500">{translate('New order from', langObj)}</span> <br />
                    {translate('unisex_prefix', langObj)}
                    {' '}{d.customer && d.customer.name && d.customer.name.trim() 
                      ? d.customer.name: 'Guest Account'} <br />
                    {translate('At', langObj)}
                    {' '}{formatDate(new Date(d.createdAt.seconds * 1000))}
                    {' '}{formatTime(new Date(d.createdAt.seconds * 1000))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="wrapper bcolor-fgray text-center">
            <div onClick={onClickLinkOrders} 
              className="a d-block p xs fw-600 h-color-p c-pointer pt-1 pb-1" 
            >
              {translate('View All Orders', langObj)}
            </div>
          </div>
        </>)}
      </div>
    </div>
  </>);
}

TopnavRealTime.defaultProps = {
  
};
TopnavRealTime.propTypes = {
  smProcessList: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
	
});
export default connect(mapStateToProps, {
  smProcessList: smProcessList,
})(TopnavRealTime);
