import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import AuthContext from '../../context/AuthContext';
import { onMounted, translate, appLogo, appBg, appCopyright } from '../../helpers/frontend';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { setSidenavActiveIndex } from '../../actions/app.actions';
import { processRead } from '../../actions/frontend.actions';

function Page404(props) {
  const { settings, langObj } = useContext(AuthContext);

  /* eslint-disable */
	useEffect(() => { onMounted(); props.setSidenavActiveIndex(0) }, []);
  useEffect(() => {
    const checkSetup = async () => {
      const res = await props.processRead('setup');
      if(!res?.isSetup) window.location.href = '/setup';
    };
    checkSetup();
  }, []);
  /* eslint-enable */

  return (
    <section className="auth-01 section-padding">
      <div className="bg-img" style={{ backgroundImage: `url('${appBg(settings['SYSTEM_BG'])}')` }}></div>
      <div className="container">
        <div className="auth-container bradius box-shadow">
          <div className="auth-body bg-white">
            <div className="title">
              <img className="logo" src={appLogo(settings['SYSTEM_LOGO'])} alt="Logo" />
              <div className="text">
                <h5 className="fw-900 lh-sm">{translate('404 Error', langObj)}</h5>
                <p className="fw-600 op-70">{translate('Back Office System', langObj)}</p>
              </div>
            </div>
            <p className="fw-500 text-center mt-6">
              {translate('text_404_error_1', langObj)} <br />
              {translate('Please try again later', langObj)}
            </p>
            <div className="btns pt-2">
              <Link to="/" className="btn btn-action btn-p btn-lg w-full">
                {translate('Back to Homepage', langObj)}
              </Link>
            </div>
          </div>
          <div className="auth-footer bg-s color-white">
            <p className="xxs fw-300 text-center">
              {appCopyright(settings['SYSTEM_COMPANY_NAME'])}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

Page404.defaultProps = {};
Page404.propTypes = {
	setSidenavActiveIndex: PropTypes.func.isRequired,
  processRead: PropTypes.func.isRequired,
};
export default connect(() => ({}), {
  setSidenavActiveIndex: setSidenavActiveIndex,
  processRead: processRead,
})(Page404);