import { PartnerShopModel, PartnerProductModel, PartnerProductUnitModel } from '.';

/*
  status
    0 = Inactive
    1 = Coming soon
    2 = Active
    3 = Clearance
    4 = New
    5 = Featured
    6 = On Sales
*/

export class PartnerShopStockModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.shop = new PartnerShopModel(data.shop? data.shop: {});
    this.product = new PartnerProductModel(data.product? data.product: {});
    this.productUnit = new PartnerProductUnitModel(data.productUnit? data.productUnit: {});

    this.price = data.price? Number(data.price): null;
    this.priceInVAT = data.priceInVAT? Number(data.priceInVAT): null;
    this.memberPrice = data.memberPrice? Number(data.memberPrice): null;
    this.memberPriceInVAT = data.memberPriceInVAT? Number(data.memberPriceInVAT): null;

    this.discountPrice = data.discountPrice? Number(data.discountPrice): null;
    this.discountPriceInVAT = data.discountPriceInVAT? Number(data.discountPriceInVAT): null;
    this.discountStartAt = data.discountStartAt? data.discountStartAt: null;
    this.discountEndAt = data.discountEndAt? data.discountEndAt: null;
    
    this.stock = data.stock || data.stock===0? Math.floor(Number(data.stock)): null;
    this.status = data.status? Number(data.status): 0;
  }

  isValid() {
    return this._id? true: false;
  }

  displayStatus() {
    if(this.isValid()){
      if(this.status >= 1) return (<span className="ss-tag bg-success">เปิดขาย</span>);
      else return (<span className="ss-tag bg-warning">ปิดขาย</span>);
    }else{
      return (<span className="ss-tag bg-warning">ปิดขาย</span>);
    }
  }
}
