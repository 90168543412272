import {
  DATA_COUNTRIES, DATA_PROVINCES, DATA_DISTRICTS, DATA_SUBDISTRICTS,
} from '../actions/types';

const initialState = {
  countries: [],
  provinces: [],
  districts: [],
  subdistricts: [],
};

const dataReducer = (state = initialState, action) => {
  switch(action.type) {

    case DATA_COUNTRIES:
      return { ...state, countries: action.payload };
    case DATA_PROVINCES:
      return { ...state, provinces: action.payload };
    case DATA_DISTRICTS:
      return { ...state, districts: action.payload };
    case DATA_SUBDISTRICTS:
      return { ...state, subdistricts: action.payload };

    default:
      return state;
  }
};

export default dataReducer;