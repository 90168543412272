import { unescape } from 'html-escaper';
import { FileModel } from '.';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  type : String
    - C2U
    - External
*/

export class CmsSettingModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.type = data.type? unescape(data.type): 'C2U';
    
    this.title = data.title? unescape(data.title): null;
    this.description = data.description? unescape(data.description): null;
    this.keywords = data.keywords? unescape(data.keywords): null;
    
    this.url = data.url? unescape(data.url): null;
    
    this.logo = new FileModel(data.logo? data.logo: {});
    this.logoWhite = new FileModel(data.logoWhite? data.logoWhite: {});
    this.logoSquare = new FileModel(data.logoSquare? data.logoSquare: {});
    this.logoSquareWhite = new FileModel(data.logoSquareWhite? data.logoSquareWhite: {});

    this.favicon = new FileModel(data.favicon? data.favicon: {});
    this.image = new FileModel(data.image? data.image: {});

    this.colorPrimary = data.colorPrimary? unescape(data.colorPrimary): null;
    this.colorSecondary = data.colorSecondary? unescape(data.colorSecondary): null;
    this.colorTertiary = data.colorTertiary? unescape(data.colorTertiary): null;
    
    this.socialFb = data.socialFb? unescape(data.socialFb): null;
    this.socialIg = data.socialIg? unescape(data.socialIg): null;
    this.socialLn = data.socialLn? unescape(data.socialLn): null;
    this.socialYt = data.socialYt? unescape(data.socialYt): null;

    this.companyName = data.companyName? unescape(data.companyName): null;
    this.companyAddress = data.companyAddress? unescape(data.companyAddress): null;
    this.companyEmail = data.companyEmail? unescape(data.companyEmail): null;
    this.companyTelephone = data.companyTelephone? unescape(data.companyTelephone): null;
    this.companyLat = data.companyLat? Number(data.companyLat): null;
    this.companyLng = data.companyLng? Number(data.companyLng): null;

    this.showSection1 = data.showSection1? 1: 0;
    this.showSection2 = data.showSection2? 1: 0;
    this.showSection3 = data.showSection3? 1: 0;
    this.showSection4 = data.showSection4? 1: 0;
    this.showSection5 = data.showSection5? 1: 0;

    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'companyName', 'companyAddress' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }
}
