import { unescape } from 'html-escaper';

export class LanguageModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.key = data.key? unescape(data.key): '';
    
    this.EN = data.EN? unescape(data.EN): '';
    this.TH = data.TH? unescape(data.TH): '';
    this.KO = data.KO? unescape(data.KO): '';
    this.ZH = data.ZH? unescape(data.ZH): '';
    this.JA = data.JA? unescape(data.JA): '';
    this.RU = data.RU? unescape(data.RU): '';
    this.DE = data.DE? unescape(data.DE): '';
    this.MY = data.MY? unescape(data.MY): '';
  }
  
  isValid() {
    return this._id? true: false;
  }
}
