import {
  CLIENT_IP, SIDENAV_ACTIVE_INDEX,
  APP_DATABASE_BACKUPS, APP_DATA_IMPORTS,
  APP_LANGUAGES,
  APP_USE_CURRENCY, APP_CURRENCY, APP_CURRENCIES, APP_THEME, APP_THEMES,
  APP_USERS, APP_PAYMENT_METHODS, APP_PACKAGINGS,
  APP_SHIPPING_STATUSES, APP_SHIPPING_SUB_STATUSES, APP_SHIPPING_STATUS_MAPPINGS,
} from '../actions/types';
import { CurrencyModel } from '../models';

const initialState = {
  clientIp: null,
  sidenavActiveIndex: 0,

  databaseBackups: [],
  dataImports: [],
  
  languages: [],

  useCurrency: new CurrencyModel(),
  currency: new CurrencyModel(),
  currencies: [],

  theme: null,
  themes: [],

  users: [],
  paymentMethods: [],
  packagings: [],
  shippingStatuses: [],
  shippingSubStatuses: [],
  shippingStatusMappings: [],
};

const appReducer = (state = initialState, action) => {
  switch(action.type) {

    case CLIENT_IP:
      return { ...state, clientIp: action.payload };
    case SIDENAV_ACTIVE_INDEX:
      return { ...state, sidenavActiveIndex: action.payload };

    case APP_DATABASE_BACKUPS:
      return { ...state, databaseBackups: action.payload };
    case APP_DATA_IMPORTS:
      return { ...state, dataImports: action.payload };

    case APP_LANGUAGES:
      return { ...state, languages: action.payload };
      
    case APP_USE_CURRENCY:
      return { ...state, useCurrency: action.payload };
    case APP_CURRENCY:
      return { ...state, currency: action.payload };
    case APP_CURRENCIES:
      return { ...state, currencies: action.payload };

    case APP_THEME:
      return { ...state, theme: action.payload };
    case APP_THEMES:
      return { ...state, themes: action.payload };
      
    case APP_USERS:
      return { ...state, users: action.payload };

    case APP_PAYMENT_METHODS:
      return { ...state, paymentMethods: action.payload };

    case APP_PACKAGINGS:
      return { ...state, packagings: action.payload };

    case APP_SHIPPING_STATUSES:
      return { ...state, shippingStatuses: action.payload };
    case APP_SHIPPING_SUB_STATUSES:
      return { ...state, shippingSubStatuses: action.payload };
    case APP_SHIPPING_STATUS_MAPPINGS:
      return { ...state, shippingStatusMappings: action.payload };

    default:
      return state;
  }
};

export default appReducer;