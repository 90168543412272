import { CustomerModel, PartnerShopModel } from '.';
import { unescape } from 'html-escaper';

export class CustomerChatroomModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.customer = new CustomerModel(data.customer? data.customer: {});
    this.partnerShop = new PartnerShopModel(data.partnerShop? data.partnerShop: {});
    
    this.firebaseChatroomId = data.firebaseChatroomId? unescape(data.firebaseChatroomId): null;
    this.isArchived = data.isArchived? Number(data.isArchived): 0;

    // Firebase Data
    this.isReadAdmin = typeof data.isReadAdmin === 'boolean'? data.isReadAdmin: true;
    this.isReadPartner = typeof data.isReadPartner === 'boolean'? data.isReadPartner: true;
    this.isReadSalesManager = typeof data.isReadSalesManager === 'boolean'? data.isReadSalesManager: true;
    this.isReadCustomer = typeof data.isReadCustomer === 'boolean'? data.isReadCustomer: true;

    this.recentMessage = data.recentMessage? {
      text: data.recentMessage.text? data.recentMessage.text: '',
      images: data.recentMessage.images? data.recentMessage.images: [],
      sender: data.recentMessage.sender? data.recentMessage.sender: {
        _id: null, name: '', icon: ''
      },
      createdAt: data.recentMessage.createdAt? data.recentMessage.createdAt: null,
      fromCustomer: data.recentMessage.fromCustomer? data.recentMessage.fromCustomer: false
    }: {
      text: '',
      images: [],
      sender: { _id: null, name: '', icon: '' },
      createdAt: null,
      fromCustomer: false
    };

    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
  }

  isValid() {
    return this._id && this.firebaseChatroomId? true: false;
  }

  hasRecentMessage() {
    if(this.isValid() && (this.recentMessage.text || this.recentMessage.images.length)){
      return true;
    }else{
      return false;
    }
  }
}
