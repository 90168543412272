import { unescape } from 'html-escaper';

/*
  dataType : String
    String
    URL
    Number
    Date
    Boolean
    FilePath
    Array
*/

export class DataImportModel {
  constructor(data={}) {
    this._id = data?._id || null;

    this.name = unescape(data.name? data.name: '');
    this.tableName = data.tableName? data.tableName: '';

    this.columns = data.columns && data.columns.length 
      ? data.columns.map(d => this.cleanColumn(d)): [];

    this.status = data.status? data.status: 0;
  }

  isValid() {
    return this._id? true: false;
  }

  cleanColumn(data={}, validate=false) {
    let col = {
      name: unescape(data.name? data.name: ''),
      columnName: data.columnName? data.columnName: '',
      dataType: data.dataType? data.dataType: '',
      isRequired: data.isRequired? data.isRequired: 0,
      isUnique: data.isUnique? data.isUnique: 0,
      options: data.options && data.options.length? data.options: [],
      note: unescape(data.note? data.note: ''),

      mapColumn: unescape(data.mapColumn? data.mapColumn: ''),
      mapIndex: data.mapIndex || data.mapIndex===0? Number(data.mapIndex): null,
      data: data.data? `${data.data}`.trim(): null,
      errors: data.errors && data.errors.length? data.errors: [],
    };
    if(validate){
      let _errors = [];
      if(col.isRequired && !col.data) _errors.push(`${col.name} is required`);

      if(col.isRequired || (col.data || col.data===0 || col.data===false)){
        if(col.dataType === 'URL'){
          col.data = `${col.data}`.trim();
          if(!(/^[a-zA-Z0-9-]+$/).test(col.data)) _errors.push(`${col.name} must be valid URL`);
        }else if(col.dataType === 'Number'){
          if(isNaN(col.data)) _errors.push(`${col.name} must be number`);
          else col.data = Number(col.data);
        }else if(col.dataType === 'Date'){
          
        }else if(col.dataType === 'Boolean'){
          
        }else if(col.dataType === 'FilePath'){
          try{
            col.data = `${col.data}`.trim();
            let url = new URL(`${col.data}`);
            col.data = url.href;
          } catch {
            _errors.push(`${col.name} must be valid file path`);
          }
        }else if(col.dataType === 'Array'){
          
        }
      }

      if(col.options && col.options.length){
        if(col.options.indexOf(col.data) < 0) _errors.push(`${col.name} must be one of options`);
      }

      col.errors = _errors;
    }
    return col;
  }

  checkUnique(importData=[]){
    if(importData.length){
      let uniqueCols = importData[0].filter(d => d.isUnique);
      if(uniqueCols.length){
        let colNames = uniqueCols.map(d => d.columnName);
        let colValues = {};
        colNames.forEach(colName => {
          let values = [];
          importData.forEach((row, i) => {
            let temp = row.find(col => col.columnName === colName);
            values[i] = temp? temp.data: null;
          });
          colValues[colName] = values;
        });
        importData.forEach((row, i) => {
          row.forEach(col => {
            if(col.data && colValues[col.columnName]){
              let _i = colValues[col.columnName].findIndex(d => d === col.data);
              if(_i > -1 && _i !== i){
                col.errors.push(`${col.name} duplicates with row ${_i+2}`);
              }
            }
          });
        });
      }
    }
    return importData;
  }
  hasError(importData=[]){
    let _hasError = false;
    if(importData.length){
      for(let i=0; i<importData.length; i++){
        let row = importData[i];
        for(let j=0; j<row.length; j++){
          let col = row[j];
          if(col.errors && col.errors.length) _hasError = true;
          if(_hasError) break;
        }
        if(_hasError) break;
      }
    }
    return _hasError;
  }
}
