import { unescape } from 'html-escaper';
import moment from 'moment';
import {
  APP_LOGO, APP_NAME,
  APP_PREFIX,
  APP_SHORT_NAME,
  APP_YEAR, SYSTEM_LANGUAGES,
} from '../actions/variables';


export const appLogo = (obj=null, fallback='/assets/img/logo.png') => {
  if(obj && typeof obj === 'string') obj = JSON.parse(unescape(obj));
  if(APP_LOGO) return APP_LOGO;
  else if(obj && obj.path) return obj.path;
  else return fallback;
};
export const appBg = (obj=null, fallback='/assets/img/bg/01.jpg') => {
  if(obj && typeof obj === 'string') obj = JSON.parse(unescape(obj));
  if(obj && obj.path) return obj.path;
  else return fallback;
};
export const appName = (name, fallback='MainStreet') => {
  if(APP_NAME) return APP_NAME;
  else if(name) return name;
  else return fallback;
};
export const appShortName = (name, fallback='MainStreet') => {
  if(APP_SHORT_NAME) return APP_SHORT_NAME;
  else if(name) return name;
  else return fallback;
};
export const appCopyright = (cname='') => {
  cname = cname? cname: 'Aroma Group Company Limited';
  let year = moment(new Date()).format('Y');
  let copyright = `© ${APP_YEAR}${year !== `${APP_YEAR}`? '-'+year: ''} ${cname}`;
  if(copyright[copyright.length-1] !== '.') copyright += '.';
  return copyright;
};

export const getLanguage = () => {
  let localLang = localStorage.getItem(`${APP_PREFIX}_LANGUAGE`);
  return localLang? localLang: SYSTEM_LANGUAGES.length? SYSTEM_LANGUAGES[0]: 'TH';
};
export const translate = (key='', obj={}, data=null) => {
  if(!key) return '';
  else{
    const cleanKey = `${key}`.toLowerCase();
    const temp = obj[cleanKey];
    let result = temp || temp===''? unescape(temp): unescape(key);
    if(data){
      Object.keys(data).forEach(k => {
        const _cleanKey = `${data[k]}`.toLowerCase();
        const _temp = obj[_cleanKey];
        result = result.replace(k, _temp || _temp===''? unescape(_temp): unescape(data[k]));
      });
    }
    return result;
  }
};
export const googleTranslate = async (googleKey, lang, texts) => {
  try {
    const _fetch = await fetch(`https://translation.googleapis.com/language/translate/v2?key=${googleKey}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ q: texts, target: lang }),
    });
    if(_fetch.ok && _fetch.status === 200){
      const _data = await _fetch.json();
      if(_data?.data?.translations){
        return _data.data.translations.map(d => unescape(`${d.translatedText}`));
      }
    }
    return [];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const onMounted = () => {
  return true;
};

export const scrollToRef = (ref) => {
  if(ref && ref.current){
    window.scrollTo({ top: ref.current.offsetTop - 82, left: 0, behavior: 'smooth' });
  }
};
export const scrollToBottomRef = (ref, animate=true) => {
  if(ref?.current){
    if(animate) ref.current.scrollIntoView({ behavior: 'smooth' });
    else ref.current.scrollIntoView();
  }
};

export const formatNumber = (value, digits=2, trimDigits=false) => {
  let val = (value / 1).toFixed(digits);
  val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if(digits < 1 || !trimDigits) return val;
  else{
    let replacer = (0).toFixed(digits).replace('0.', '.');
    return val.replace(replacer, '');
  }
};
export const formatDate = (value=null, format='DD/MM/YYYY', thai=true) => {
  let string;
  if(!value) string = moment(new Date()).format(format);
  else string = moment(new Date(String(value))).format(format);
  if(string) {
    if(thai) {
      string = string.split('/');
      if(string.length < 3){
        return `${string[0]}/${parseInt(string[1])+543}`;
      }else{
        return `${string[0]}/${string[1]}/${parseInt(string[2])+543}`;
      }
    } else {
      return string;
    }
  } else {
    return '';
  }
};
export const formatTime = (value=null, format='hh:mm A') => {
  let string;
  if(!value) string = moment(new Date()).format(format);
  else string = moment(new Date(String(value))).format(format);
  if(string) {
    return string;
  } else {
    return '';
  }
};
export const formatSeconds = (sec) => {
  if(sec) {
    sec = Math.round(sec);
    let hours = Math.floor(sec/3600);
    (hours >= 1)? sec -= (hours*3600): hours = '00';
    let min = Math.floor(sec/60);
    (min >= 1)? sec -= (min*60): min = '00';
    (sec < 1)? sec = '00': void 0;
  
    (min.toString().length === 1)? min = '0'+min: void 0;    
    (sec.toString().length === 1)? sec = '0'+sec: void 0;    
    
    return hours+':'+min+':'+sec;
  } else {
    return '00:00:00';
  }
};

export const formatTextSecret = (str, showDigits=3) => {
  if(str) {
    let len = str.length;
    let res = [...str].map((d, i) => {
      if(i < len - showDigits) return '*';
      else return d;
    }).join('');
    return (<span title={str}>{res}</span>);
  } else {
    return (<></>);
  }
};

export const formatUrl = (str) => {
  return `${str || ''}`.trim()
    .replace(/\\|\/|\?|%|&|!|#|\^|\*|\(|\)|=|<|>|{|}|\[|\]/g, '')
    .replace(/  +/g, ' ').replace(/ /g, '-').toLowerCase();
};

export const displayDay = (i, langObj={}) => {
  let days = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];
  let temp = langObj[ days[Number(i)].toLowerCase() ];
  if(temp) return temp;
  else{
    days = [ 'อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์' ];
    return days[Number(i)];
  }
};
export const displayMonth = (i, langObj={}) => {
  let temp = langObj[`month_${i+1}`];
  if(temp) return temp;
  else{
    let months = [
      'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
      'กรกฏาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม',
    ];
    return months[Number(i)];
  }
};

export const nextDays = (n=0) => {
  let today = new Date();
  return new Date(today.setDate(today.getDate() + n));
};
export const nexHours = (n=0) => {
  let today = new Date();
  return new Date(today.setHours(today.getHours() + n));
};
export const isToday = (date) => {
  let today = new Date()
  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
};

export const hex2rgb = (hex) => {
  return {
    r: parseInt(hex.slice(1, 3), 16), 
    g: parseInt(hex.slice(3, 5), 16), 
    b: parseInt(hex.slice(5, 7), 16)
  };
};

export const digitsCeil = (val) => {
  let digits = `${Math.round(val)}`.length - 1;
  let res = Math.ceil(val / 10**digits) * 10**digits;
  if((res - val) / 10**digits > 0.5){
    return res - 5 * 10**(digits-1);
  }else{
    return res;
  }
};

export const textToCapital = (text) => {
  if(!text) return '';
  text = `${text}`;
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const mimetypePreview = (mimetype='', path='') => {
  if(mimetype){
    if(['image/png', 'image/jpeg', 'image/jpg', 'image/gif'].indexOf(mimetype) > -1){
      return path || '/assets/img/file/img.png';
    }else if(['text/csv'].indexOf(mimetype) > -1){
      return '/assets/img/file/csv.png';
    }else if(['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].indexOf(mimetype) > -1){
      return '/assets/img/file/xls.png';
    }else if(['application/pdf'].indexOf(mimetype) > -1){
      return '/assets/img/file/pdf.png';
    }else if(['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].indexOf(mimetype) > -1){
      return '/assets/img/file/doc.png';
    }else if(['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'].indexOf(mimetype) > -1){
      return '/assets/img/file/ppt.png';
    }else if(['application/zip', 'application/x-zip-compressed', 'application/x-zip'].indexOf(mimetype) > -1){
      return '/assets/img/file/zip.png';
    }else if(['video/mp4', 'video/webm'].indexOf(mimetype) > -1){
      return '/assets/img/file/video.png';
    }else if(['audio/mpeg', 'audio/mp3'].indexOf(mimetype) > -1){
      return '/assets/img/file/audio.png';
    }
  }
  return '/assets/img/file/unknown.png';
};
