import { ShippingStatusModel } from '.';
import { unescape } from 'html-escaper';
import { translate } from '../helpers/frontend';
import { SYSTEM_LANGUAGES } from '../actions/variables';

/*
  status
    0 = ไม่แสดง
    1 = แสดง
*/

export class ShippingSubStatusModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.shippingStatus = new ShippingStatusModel(data.shippingStatus? data.shippingStatus: {});
    
    this.name = data.name? unescape(data.name): null;
    this.description = data.description? unescape(data.description): null;

    this.order = data.order? Number(data.order): null;
    this.status = data.status? Number(data.status): 0;

    // Localization
    this.localization(data);
  }
  localization(data){
    let self = this;
    self.localizedKeys = [ 'name', 'description' ];
    SYSTEM_LANGUAGES.forEach(lang => {
      self.localizedKeys.forEach(k => {
        let key = `${k}${lang}`;
        self[key] = data[key]? unescape(data[key]): data[k];
      });
    });
  }

  isValid() {
    return this._id? true: false;
  }
  
  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Show', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Hide', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Hide', langObj)}</span>);
    }
  }
}
