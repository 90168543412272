import { unescape } from 'html-escaper';
import { CustomerModel, PartnerShopModel, FileModel } from '.';
import { translate } from '../helpers/frontend';

export class CustomerOrderRatingModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;

    this.order = {
      _id: data?.order?._id || null,
      orderId: data?.order?.orderId || null,
    };
    this.customer = new CustomerModel(data?.customer || {});
    this.shop = new PartnerShopModel(data?.shop || {});
    
    this.rating = data.rating? Number(data.rating): null;
    this.comment = data.comment? unescape(data.comment): null;
    this.images = data.images?.length? data.images.map(d => new FileModel(d)): [];

    this.isShown = data.isShown? Number(data.isShown): 0;
    this.isAnonymous = data.isAnonymous? Number(data.isAnonymous): 1;
    
    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
  }

  isValid() {
    return this._id? true: false;
  }
  isValidOrder() {
    return this.isValid() && this.order._id? true: false;
  }

  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.isShown === 1) return (<span className="ss-tag bg-success">{translate('Show', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Hide', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Hide', langObj)}</span>);
    }
  }
}
