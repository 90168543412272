import { PartnerShopModel, CustomerModel, CustomerOrderModel } from '.';

/*
  status
    0 = Pending
    1 = Earn
    2 = Paid
*/

export class PartnerShopCommissionModel {
  constructor(data={}) {
    this._id = data?._id || null;
    
    this.shop = new PartnerShopModel(data.shop? data.shop: {});
    this.customer = new CustomerModel(data.customer? data.customer: {});
    this.order = new CustomerOrderModel(data.order? data.order: {});

    this.grandTotal = data.grandTotal? Number(data.grandTotal): 0;
    this.productCommission = data.productCommission? Number(data.productCommission): 0;
    
    this.appFeePercent = data.appFeePercent? Number(data.appFeePercent): 0;
    this.appFee = data.appFee? Number(data.appFee): 0;

    this.paymentFeePercent = data.paymentFeePercent? Number(data.paymentFeePercent): 0;
    this.paymentFee = data.paymentFee? Number(data.paymentFee): 0;

    this.shippingCost = data.shippingCost? Number(data.shippingCost): 0;
    this.shippingFeePercent = data.shippingFeePercent? Number(data.shippingFeePercent): 0;
    this.shippingFee = data.shippingFee? Number(data.shippingFee): 0;
    
    this.isCOD = data.isCOD? Number(data.isCOD): 0;
    this.codFeePercent = data.codFeePercent? Number(data.codFeePercent): 0;
    this.codFee = data.codFee? Number(data.codFee): 0;
    
    this.totalCommission = data.totalCommission? Number(data.totalCommission): 0;

    this.status = data.status? Number(data.status): 0;
    
    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
  }

  isValid() {
    return this._id? true: false;
  }

  displayStatus() {
    if(this.isValid()){
      if(this.status === 2) return (<span className="ss-tag bg-success">Paid</span>);
      else if(this.status === 1) return (<span className="ss-tag bg-success">Earn</span>);
      else return (<span className="ss-tag bg-warning">Pending</span>);
    }else{
      return (<></>);
    }
  }
}
