import { translate } from '../helpers/frontend';

import {
  PartnerShippingModel,
  PartnerShopModel, PartnerProductCategoryModel, PartnerProductModel,
  CustomerTierModel, CustomerGroupModel, UserModel,
} from '.';

/*
  status : Number
    0 = Inactive
    1 = Active
    
  availableType : Number
    1 = All products
    2 = Include products
    3 = Exclude products
    4 = Include product categories
    5 = Exclude product categories
    
  discountRules : Array
    discountRule : Object
      availableType = 2, 3
        productId : String
        unitId : String
      availableType = 4, 5
        categoryId : String
*/

export class PartnerFreeShippingRuleModel {
  constructor(data={}) {
    this._id = data?._id || null;
    this.channel = data.channel? data.channel: 'C2U';

    this.status = data.status? data.status: 0;

    this.shippings = data.shippings && data.shippings.length
      ? data.shippings.map(d => new PartnerShippingModel(d)): [];

    this.minimumOrder = data.minimumOrder? Number(data.minimumOrder): null;
    this.maximumOrder = data.maximumOrder? Number(data.maximumOrder): null;
    
    this.availableType = data.availableType? Number(data.availableType): 1;
    this.discountRules = data.discountRules && data.discountRules.length? data.discountRules: [];

    this.products = data.products && data.products.length
      ? data.products.map(d => new PartnerProductModel(d)): [];
    this.productCategories = data.productCategories && data.productCategories.length
      ? data.productCategories.map(d => new PartnerProductCategoryModel(d)): [];

    this.forAllPartnerShops = data.forAllPartnerShops? data.forAllPartnerShops: 0;
    this.forPartnerShops = data.forPartnerShops && data.forPartnerShops.length
      ? data.forPartnerShops.map(d => new PartnerShopModel(d)): [];

    this.forAllCustomerTiers = data.forAllCustomerTiers? data.forAllCustomerTiers: 0;
    this.forCustomerTiers = data.forCustomerTiers && data.forCustomerTiers.length
      ? data.forCustomerTiers.map(d => new CustomerTierModel(d)): [];

    this.forCustomerGroups = data.forCustomerGroups && data.forCustomerGroups.length
      ? data.forCustomerGroups.map(d => new CustomerGroupModel(d)): [];

    this.updatedBy = new UserModel(data.updatedBy || {});
    this.updatedAt = data.updatedAt || null;
  }

  isValid() {
    return this._id? true: false;
  }

  displayStatus(langObj={}) {
    if(this.isValid()){
      if(this.status === 1) return (<span className="ss-tag bg-success">{translate('Active', langObj)}</span>);
      else return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }else{
      return (<span className="ss-tag bg-warning">{translate('Inactive', langObj)}</span>);
    }
  }
}
