import { PartnerProductModel, PartnerProductUnitModel } from '.';

export class PartnerProductSetModel {
  constructor(data={}) {
    this.productId = data.productId? data.productId: null;
    this.product = new PartnerProductModel(data.product? data.product: {});
    
    this.productUnitId = data.productUnitId? data.productUnitId: null;
    this.productUnit = new PartnerProductUnitModel(data.productUnit? data.productUnit: {});

    this.quantity = data.quantity? Number(data.quantity): 0;
    this.totalSalesPriceInVAT = data.totalSalesPriceInVAT? Number(data.totalSalesPriceInVAT): 0;
  }
}
